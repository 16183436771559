import { db as t } from "../offlinestorage/offlinestorage.js";
const s = async (e, a) => {
  const n = {
    id: e.id,
    content: a,
    content_updated_at: e.content_updated_at,
    space_id: e.space_id,
    name: e.name,
    saved_on: new Date()
  };
  await t.pages.put(n, e.id);
}, c = async (e) => t.pages.where({ space_id: e }).toArray(), d = async (e) => t.pages.delete(e);
export {
  c as getOfflinePagesBySpace,
  d as removeOfflinePage,
  s as saveContentLocal
};
