var y = Object.defineProperty;
var z = (G, e, t) => e in G ? y(G, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : G[e] = t;
var F = (G, e, t) => (z(G, typeof e != "symbol" ? e + "" : e, t), t);
import X from "axios";
import { TEXT_TYPES as q, NOTION_BLOCK_TYPE as O, BLOCK_TYPE as f, BLOCK_TYPE_UNSUPPORTED as W } from "./types.js";
import { ICollectionDataItemMetadataKeys as N } from "../../../types.js";
import { NotionAPI as C } from "./api.js";
import { COLLECTION_FILTER_CURRENT_ITEM_PREFIX as Z, ITEM_PAGE_DEFAULT_FIELDS as R, DEFAULT_COLLECTION_DATA_ID as Y, COLLECTION_SLUG_COLUMN_NAME as Q, OAUTH_REDIRECT_URI as p } from "../../../constants.js";
import { makeValidPath as tt } from "../../../utils/makeValidPath.js";
import { NOTION_KEYS as U, NOTION_FILTER as j, NOTION_FILTER_TYPE as J, NOTION_FILTER_SEARCH_CONDITION as D } from "./constants.js";
import { capitalizeFirstLetter as et } from "../../../utils/capitalizeFirstLetter.js";
import { capitalizeEachWord as nt } from "../../../utils/capitalizeEachWord.js";
import { isDate as st } from "../../../utils/isDate.js";
import { AppAPI as P } from "../api.js";
import { convertNotionTextToTdTextLeaf as K, convertNotionBlockTypeToTdType as at, unsupportedBlockPlaceholder as it, getAdditionalProps as rt, initialValueEmpty as ot } from "../../../utils/notionBlockSync.js";
import { errToString as v } from "../../../utils/errors.js";
import { RevalidateAPI as ct } from "../../revalidate/api.js";
class bt {
  constructor(e) {
    F(this, "api");
    F(this, "NOT_EDITABLE_COLUMNS", ["formula", "relation", "rollup", "people", "created_by", "created_time", "last_edited_time", "last_edited_by"]);
    F(this, "getPropertyValue", (e) => {
      var i, a, r, s, d, o, c, l, u, m, _, h, T, E, x, b, M, I, B;
      const t = e.type;
      let n = null;
      switch (t) {
        case "title":
        case "rich_text":
          n = (a = (i = e == null ? void 0 : e[t]) == null ? void 0 : i.map((w) => w.plain_text)) == null ? void 0 : a.join("");
          break;
        case "string":
        case "number":
          n = e == null ? void 0 : e[t];
          break;
        case "select":
          n = (r = e == null ? void 0 : e[t]) == null ? void 0 : r.name;
          break;
        case "multi_select":
          n = (s = e == null ? void 0 : e[t]) == null ? void 0 : s.map((w) => w.name).join(", ");
          break;
        case "date":
          n = this.displayFormattedDate((d = e == null ? void 0 : e[t]) == null ? void 0 : d.start), (o = e == null ? void 0 : e[t]) != null && o.end && (n = `${n} - ${this.displayFormattedDate((c = e == null ? void 0 : e[t]) == null ? void 0 : c.end)}`);
          break;
        case "people":
          n = (l = e == null ? void 0 : e[t]) == null ? void 0 : l.map((w) => w.name).join(", ");
          break;
        case "files":
          ((m = (u = e == null ? void 0 : e[t]) == null ? void 0 : u[0]) == null ? void 0 : m.type) === "file" ? n = (T = (h = (_ = e == null ? void 0 : e[t]) == null ? void 0 : _[0]) == null ? void 0 : h.file) == null ? void 0 : T.url : n = (b = (x = (E = e == null ? void 0 : e[t]) == null ? void 0 : E[0]) == null ? void 0 : x.external) == null ? void 0 : b.url;
          break;
        case "checkbox":
          n = e == null ? void 0 : e[t];
          break;
        case "url":
          n = e == null ? void 0 : e[t];
          break;
        case "email":
          n = e == null ? void 0 : e[t];
          break;
        case "phone_number":
          n = e == null ? void 0 : e[t];
          break;
        case "formula":
          n = this.getPropertyValue(e == null ? void 0 : e[t]);
          break;
        case "created_time":
          n = this.displayFormattedDate(e == null ? void 0 : e[t]);
          break;
        case "created_by":
          n = (M = e == null ? void 0 : e[t]) == null ? void 0 : M.name;
          break;
        case "last_edited_time":
          n = this.displayFormattedDate(e == null ? void 0 : e[t]);
          break;
        case "last_edited_by":
          n = (I = e == null ? void 0 : e[t]) == null ? void 0 : I.name;
          break;
        case "rollup": {
          const w = e == null ? void 0 : e[t].type;
          if (w === "array") {
            n = ((B = e == null ? void 0 : e[t][w]) == null ? void 0 : B.map(($) => this.getPropertyValue($))).join(",") || "";
            break;
          }
          n = this.getPropertyValue(e == null ? void 0 : e[t]), e != null && e[t].function.includes("percent") && (n = `${Math.round(Number(n) * 1e3) / 10}%`);
          break;
        }
        case "relation":
        default:
          n = "";
      }
      return n;
    });
    F(this, "formatDate", (e) => {
      const n = new Date(Date.parse(`${e} GMT+0000`)).toISOString().split("Z")[0], [i, a] = n.split("T"), r = a.split(":").slice(0, 2).join(":");
      return `${i}${r !== "00:00" ? ` ${r}` : ""}`;
    });
    F(this, "displayFormattedDate", (e) => {
      try {
        const n = new Date(Date.parse(e)).toISOString().split("Z")[0], [i, a] = n.split("T"), r = a.split(":").slice(0, 2).join(":");
        return `${i}${r !== "00:00" ? ` ${r}` : ""}`;
      } catch {
        return e;
      }
    });
    F(this, "generatePropertyValue", (e, t) => {
      var i;
      let n = null;
      switch (e) {
        case "title":
        case "rich_text":
          n = [
            {
              type: "text",
              text: {
                content: t,
                link: null
              },
              plain_text: t,
              href: null
            }
          ];
          break;
        case "string":
        case "number":
          n = Number(t);
          break;
        case "select":
          n = {
            name: t
          };
          break;
        case "multi_select":
          n = (i = t == null ? void 0 : t.split(",")) == null ? void 0 : i.map((r) => ({
            name: r.trim()
          }));
          break;
        case "date":
          n = {
            start: null,
            end: null
          }, t != null && t.start && (n.start = this.formatDate(t.start)), t != null && t.end && (n.end = this.formatDate(t.end)), typeof t == "string" && (n.start = this.formatDate(t));
          break;
        case "people":
          let a = t;
          Array.isArray(t) || (a = [t]), n = a == null ? void 0 : a.map((r) => ({
            name: r
          }));
          break;
        case "files":
          t != null && t.includes("secure.notion") ? n = [
            {
              type: "file",
              name: "File",
              file: {
                url: t
              }
            }
          ] : n = [
            {
              type: "external",
              name: "File",
              external: {
                url: t
              }
            }
          ];
          break;
        case "checkbox":
        case "url":
        case "email":
        case "phone_number":
        case "created_time":
          n = t;
          break;
        case "created_by":
          n = {
            name: t
          };
          break;
        case "last_edited_time":
          n = t;
          break;
        case "last_edited_by":
          n = {
            name: t
          };
          break;
        case "relation":
        case "rollup":
        case "formula":
        default:
          n = "";
      }
      return n;
    });
    F(this, "COLUMNS_GENERATE_ITEM_PAGE", [
      {
        id: R.METADATA.fieldName,
        name: R.METADATA.fieldName,
        type: "rich_text"
      },
      {
        id: R.SLUG.fieldName,
        name: R.SLUG.fieldName,
        type: "rich_text"
      },
      {
        id: R.PUBLISHED_CONTENT.fieldName,
        name: R.PUBLISHED_CONTENT.fieldName,
        type: "rich_text"
      },
      {
        id: R.CONTENT.fieldName,
        name: R.CONTENT.fieldName,
        type: "rich_text"
      }
    ]);
    F(this, "JSONFileToContent", async (e, t) => {
      var a;
      const n = e, i = (a = t == null ? void 0 : t.pageSchema) == null ? void 0 : a.content;
      if (e && e[i]) {
        const r = e[i], s = await X.get(r, {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0"
          }
        }), d = s != null && s.data ? JSON.parse(s == null ? void 0 : s.data) : null;
        n[i] = d;
      }
      return n;
    });
    this.api = e;
  }
  async listTables(e, t, n) {
    var s;
    const i = await new C(this.api).listTables(e, t, n), a = (s = i == null ? void 0 : i.data) == null ? void 0 : s.results;
    return a && a.map((d) => {
      var o, c;
      return {
        id: d.id,
        name: (c = (o = d.title) == null ? void 0 : o[0]) == null ? void 0 : c.plain_text,
        url: d.url
      };
    });
  }
  async addColumnNames(e, t, n, i, a) {
    var c, l;
    const r = t == null ? void 0 : t[U.TABLE_ID], s = await new C(this.api).addColumnNames(e, r, n, i, a), d = s == null ? void 0 : s.data;
    return d && {
      id: d.id,
      name: (l = (c = d.title) == null ? void 0 : c[0]) == null ? void 0 : l.plain_text,
      url: d.url
    };
  }
  async getTable(e, t, n, i) {
    var o, c;
    const a = t == null ? void 0 : t[U.TABLE_ID], r = await new C(this.api).getTable(e, a, n, i), s = r == null ? void 0 : r.data;
    return s && {
      id: s.id,
      name: (c = (o = s.title) == null ? void 0 : o[0]) == null ? void 0 : c.plain_text,
      url: s.url
    };
  }
  async getTableInfo(e, t, n, i) {
    var o, c;
    const a = t == null ? void 0 : t[U.TABLE_ID], r = await new C(this.api).getTable(e, a, n, i), s = r == null ? void 0 : r.data;
    return s && {
      id: s.id,
      name: (c = (o = s.title) == null ? void 0 : o[0]) == null ? void 0 : c.plain_text,
      url: s.url,
      integration: t == null ? void 0 : t.integration,
      childrenTemplatePageID: t == null ? void 0 : t.childrenPageTemplateID
    };
  }
  async getColumnNames(e, t, n, i) {
    var o;
    const a = t == null ? void 0 : t[U.TABLE_ID], r = await new C(this.api).getTable(e, a, n, i), s = (o = r == null ? void 0 : r.data) == null ? void 0 : o.properties;
    if (!s)
      return {};
    const d = {};
    return Object.keys(s).forEach((c) => {
      d[c] = {
        id: s[c].id,
        type: s[c].type,
        name: c
      };
    }), d;
  }
  async getColumnSelectOptions(e, t, n, i, a) {
    var o, c;
    const r = n == null ? void 0 : n[U.TABLE_ID], s = await new C(this.api).getTable(e, r, i, a), d = (c = (o = s == null ? void 0 : s.data) == null ? void 0 : o.properties) == null ? void 0 : c[t];
    return d ? d[d.type].options.map((l) => l.name) : [];
  }
  getDataQuery(e, t, n) {
    var o, c, l;
    const i = t == null ? void 0 : t[U.TABLE_ID], { metadata: a } = t, r = (a == null ? void 0 : a[N.FILTER_COLUMN]) && typeof ((a == null ? void 0 : a[N.FILTER_VALUE]) && !((o = a == null ? void 0 : a[N.FILTER_VALUE].toString()) != null && o.includes(Z))) ? {
      property: a[N.FILTER_COLUMN],
      [a[N.FILTER_COLUMN_TYPE]]: {
        [a[N.FILTER_CONDITION]]: this.formatFilterQuery((l = (c = j) == null ? void 0 : c[a[N.FILTER_COLUMN_TYPE]]) == null ? void 0 : l[a[N.FILTER_CONDITION]], a[N.FILTER_VALUE])
      }
    } : void 0, s = a != null && a[N.SORT_COLUMN] ? [
      {
        property: a == null ? void 0 : a[N.SORT_COLUMN],
        direction: a == null ? void 0 : a[N.SORT_DIRECTION]
      }
    ] : void 0;
    return new C(this.api).getDataQuery(e, i, r, s, n, a == null ? void 0 : a[N.MAX_ITEMS]);
  }
  constructGetDataResult(e) {
    const t = e == null ? void 0 : e.results;
    if (!t)
      return {
        data: [],
        has_more: e == null ? void 0 : e.has_more,
        next_cursor: e == null ? void 0 : e.next_cursor
      };
    const n = [];
    return t.forEach((i) => {
      if (i != null && i.properties) {
        const a = {}, r = i == null ? void 0 : i.properties;
        Object.entries(r).forEach(([s, d]) => {
          const o = this.getPropertyValue(d);
          a[s] = o;
        }), a[Y] = i == null ? void 0 : i.id, n.push(a);
      }
    }), {
      data: n,
      has_more: e == null ? void 0 : e.has_more,
      next_cursor: e == null ? void 0 : e.next_cursor
    };
  }
  async getData(e, t, n, i, a) {
    const r = this.getDataQuery(e, t, n), s = await new C(this.api).callQuery(r.path, r.data, e, i, a);
    return this.constructGetDataResult(s == null ? void 0 : s.data);
  }
  async getDataByID(e, t, n, i, a) {
    var l;
    const r = await new C(this.api).getDataByID(e, t, i, a), s = r == null ? void 0 : r.data, d = this.pageToCollectionResultDataItem(s), o = await this.JSONFileToContent(d, n), c = (l = n == null ? void 0 : n.pageSchema) == null ? void 0 : l.metadata;
    return o[c] = o[c] ? JSON.parse(o[c]) : o[c], o;
  }
  tableConnected(e) {
    return e && (e == null ? void 0 : e[U.TABLE_ID]);
  }
  pageToCollectionResultDataItem(e) {
    let t = null;
    if (e != null && e.properties) {
      const n = {}, i = e == null ? void 0 : e.properties;
      Object.entries(i).forEach(([a, r]) => {
        const s = this.getPropertyValue(r);
        n[a] = s;
      }), n[Y] = e == null ? void 0 : e.id, t = n;
    }
    return t;
  }
  getItemPageDataID(e) {
    const t = e == null ? void 0 : e.integration, n = e == null ? void 0 : e[U.TABLE_ID];
    return `${t}_${n}`;
  }
  getDefaultItemPageSchema(e) {
    let t = "Name";
    return e.forEach((n) => {
      n.type === "title" && (t = n.name);
    }), {
      name: t,
      metadata: R.METADATA.fieldName,
      slug: R.SLUG.fieldName,
      content: R.CONTENT.fieldName,
      published_content: R.PUBLISHED_CONTENT.fieldName
    };
  }
  async connectCollectionGenerateColumns(e, t, n, i) {
    const a = this.COLUMNS_GENERATE_ITEM_PAGE;
    return await this.addColumnNames(e, t, a, n, i), a;
  }
  async generateTypedreamChildren(e, t, n, i, a, r) {
    const s = t.type, d = (c) => c.length > 0 ? t[s].text.map((l) => K(l, s, r, t[s].color)) : [{ text: "" }], o = q.includes(e);
    if (t.has_children && s !== O.TO_DO) {
      const c = await this.getNotionBlocksThenConvertToTdBlocks(t.id, n, i, a, r);
      if (s === O.TOGGLE)
        return [{
          id: r(),
          type: f.PARAGRAPH,
          children: d(t[s].text)
        }, ...c];
      if (s === O.HEADING_1 || s === O.HEADING_2 || s === O.HEADING_3) {
        const l = (u) => {
          switch (u) {
            case O.HEADING_1:
              return f.HEADING1;
            case O.HEADING_2:
              return f.HEADING2;
            case O.HEADING_3:
              return f.HEADING3;
            default:
              return f.PARAGRAPH;
          }
        };
        return [{
          id: r(),
          type: l(t.type),
          children: d(t[s].text)
        }, ...c];
      }
      if (e === f.NUMBERED_LIST || e === f.BULLETED_LIST) {
        const l = c.map((_) => _.type === f.NUMBERED_LIST || _.type === f.BULLETED_LIST || _.type === f.LIST_ITEM || _.type === f.LIST_ITEM_CONTENT ? _ : {
          type: f.LIST_ITEM_CONTENT,
          id: r(),
          children: [_]
        }), u = {
          type: f.LIST_ITEM_CONTENT,
          id: r(),
          children: d(t[s].text)
        };
        return [{
          type: f.LIST_ITEM,
          id: r(),
          children: [
            u,
            ...l
          ]
        }];
      }
      return c.length === 0 ? [{ text: "" }] : c;
    }
    if (e === f.NUMBERED_LIST || e === f.BULLETED_LIST)
      return [{
        type: f.LIST_ITEM,
        id: r(),
        children: d(t[s].text)
      }];
    if (s === O.BOOKMARK || s === O.LINK_PREVIEW)
      return [{
        type: f.LINK,
        url: t[s].url || "",
        id: r(),
        children: [{
          text: t[s].url || ""
        }]
      }];
    if (s === O.FILE) {
      const c = t[s].type;
      return [{
        type: f.LINK,
        url: t[s][c].url || "",
        id: r(),
        children: [{
          text: t[s][c].url || ""
        }]
      }];
    }
    return s === O.CHILD_DATABASE || s === O.CHILD_PAGE ? [{
      text: `${t[s].title || ""} (Unsupported block type)`
    }] : s === O.EQUATION ? [{ text: t[s].expression || "" }] : s === O.CALLOUT ? [{
      id: r(),
      type: f.PARAGRAPH,
      children: d(t[s].text)
    }] : o && t[s].text.length !== 0 ? t[s].text.map((c) => K(c, s, r, t[s].color)) : [{ text: "" }];
  }
  async convertNotionBlocksToTdBlocks(e, t, n, i, a) {
    return (await Promise.all(e.map(async (s) => {
      const d = s.type, o = at(d, s);
      if (o === W)
        return it(a);
      let c = [];
      d === O.TO_DO && s.has_children && (c = await this.getNotionBlocksThenConvertToTdBlocks(s.id, t, n, i, a));
      const l = await this.generateTypedreamChildren(o, s, t, n, i, a), u = await rt(o, s, t, this.api, n, i);
      return c.length > 0 ? [
        {
          id: s.id,
          type: o,
          children: l,
          ...u
        },
        ...c
      ] : {
        id: s.id,
        type: o,
        children: l,
        ...u
      };
    }))).flat();
  }
  async getNotionBlocks(e, t, n, i, a, r) {
    var o, c, l;
    const s = await new C(this.api).getBlockChildrenById(t, e, n, i, r), d = (o = s == null ? void 0 : s.data) == null ? void 0 : o.results;
    if ((c = s == null ? void 0 : s.data) != null && c.has_more) {
      const u = await this.getNotionBlocks(e, t, n, i, a, (l = s == null ? void 0 : s.data) == null ? void 0 : l.next_cursor);
      return [...d, ...u];
    }
    return d;
  }
  async getNotionBlocksThenConvertToTdBlocks(e, t, n, i, a) {
    const r = await this.getNotionBlocks(e, t, n, i, a, void 0);
    return r.length === 0 ? [] : await this.convertNotionBlocksToTdBlocks(r, t, n, i, a);
  }
  async getNotionBlocksThenConvertAndGetFileUrl(e, t, n, i, a) {
    var l;
    const r = await this.getNotionBlocksThenConvertToTdBlocks(e, t, n, i, a), s = r.length === 0 ? JSON.stringify(JSON.stringify(ot(a))) : JSON.stringify(JSON.stringify([{ children: r }]));
    let d = "";
    const o = await new P(this.api).updateCollectionContent(t, e, s, n, i), c = (l = o == null ? void 0 : o.data) == null ? void 0 : l.file_name;
    return d = new P(this.api).getCollectionContentURL(c), d;
  }
  async prefillDefaultItemPage(e, t, n, i, a, r, s, d) {
    const o = {
      has_more: !0,
      next_cursor: void 0
    };
    let c = 0;
    for (; o.has_more; )
      try {
        const l = await this.getData(e, i, o.next_cursor, a, r), { data: u, has_more: m, next_cursor: _ } = l;
        for (; (u == null ? void 0 : u.length) > 0; ) {
          const h = u.pop(), T = h[t.name], E = tt(T), x = {
            metatags: {
              title: T,
              description: void 0
            }
          };
          let b = {
            [t.slug]: h[t.slug] ? void 0 : E,
            [t.metadata]: h[t.metadata] ? void 0 : JSON.stringify(x)
          };
          if (!h[t.content]) {
            const I = await this.getNotionBlocksThenConvertAndGetFileUrl(h.id, e, a, r, s);
            b = {
              ...b,
              [t.content]: I
            };
          }
          const M = n.map((I) => {
            if (b[I.name])
              return {
                column: I,
                value: this.generatePropertyValue(I.type, b[I.name])
              };
          }).filter((I) => I);
          try {
            await new C(this.api).updateItem(e, h[Y], M, a, r);
          } catch (I) {
            console.log("Error updating item:", T, ", error:", v(I)), u.push(h);
          }
          d(c), c += 1;
        }
        o.has_more = m, o.next_cursor = _;
      } catch (l) {
        console.log("Error getting data for cursor:", o.next_cursor, ", error:", v(l));
      }
  }
  async importContentPage(e, t, n, i, a, r, s, d) {
    var T;
    const o = await this.getNotionBlocksThenConvertAndGetFileUrl(t, e, r, s, d), c = {
      [n.content]: o
    }, l = i.map((E) => {
      if (c[E.name])
        return {
          column: E,
          value: this.generatePropertyValue(E.type, c[E.name])
        };
    }).filter((E) => E), u = await new C(this.api).updateItem(e, t, l, r, s), m = u == null ? void 0 : u.data;
    let _ = this.pageToCollectionResultDataItem(m);
    _ = await this.JSONFileToContent(_, a);
    const h = (T = a == null ? void 0 : a.pageSchema) == null ? void 0 : T.metadata;
    return _[h] = _[h] ? JSON.parse(_[h]) : _[h], _;
  }
  async contentToJSONFileURL(e, t, n, i, a, r) {
    var c;
    let s = null, d = null;
    const o = [];
    if (n.forEach((l) => {
      var u, m;
      ((u = l.column) == null ? void 0 : u.name) === ((m = i == null ? void 0 : i.pageSchema) == null ? void 0 : m.content) ? (s = l.value, d = l.column) : o.push(l);
    }), s) {
      const l = JSON.stringify(s), u = await new P(this.api).updateCollectionContent(e, t, l, a, r), m = (c = u == null ? void 0 : u.data) == null ? void 0 : c.file_name, _ = new P(this.api).getCollectionContentURL(m), h = {
        column: d,
        value: _
      };
      o.push(h);
    }
    return o;
  }
  async updateItem(e, t, n, i, a, r) {
    var _;
    const s = await this.contentToJSONFileURL(e, t, n, i, a, r), d = (_ = i == null ? void 0 : i.pageSchema) == null ? void 0 : _.metadata, o = [];
    s.forEach((h) => {
      let T = h.value;
      h.column.name === d && (T = JSON.stringify(T));
      const E = {
        column: h.column,
        value: T
      };
      o.push(E);
    });
    const c = o.map((h) => ({
      column: h.column,
      value: this.generatePropertyValue(h.column.type, h.value)
    })), l = await new C(this.api).updateItem(e, t, c, a, r), u = l == null ? void 0 : l.data;
    let m = this.pageToCollectionResultDataItem(u);
    return m = await this.JSONFileToContent(m, i), m[d] = m[d] ? JSON.parse(m[d]) : m[d], m;
  }
  nonEditableColumns() {
    return this.NOT_EDITABLE_COLUMNS;
  }
  async publishCollectionContent(e) {
    var w, V;
    const { spaceID: t, dataID: n, publishedContentColumn: i, itemPageData: a, customization: r, pagePath: s, typedreamURL: d, customDomain: o, spacePermissionTokenHandler: c, subscriptionTokenHandler: l } = e, u = [d.includes("localhost") ? `http://${d.split("/")[0]}` : `https://${d.split("/")[0]}`];
    o && u.push(`https://${o}`);
    const m = await new C(this.api).getDataByID(t, n, c, l), _ = m == null ? void 0 : m.data, h = this.pageToCollectionResultDataItem(_), T = h == null ? void 0 : h[(w = a == null ? void 0 : a.pageSchema) == null ? void 0 : w.content], E = await new P(this.api).publishCollectionContent({
      spaceID: t,
      dataID: n,
      draftContentURL: T,
      space: { customization: r },
      spacePermissionTokenHandler: c,
      subscriptionTokenHandler: l
    }), x = (V = E == null ? void 0 : E.data) == null ? void 0 : V.file_name, b = new P(this.api).getCollectionContentURL(x), M = {
      column: i,
      value: b
    }, I = await this.updateItem(t, n, [M], a, c, l), B = await new ct(this.api).revalidateByPath({
      spaceID: t,
      paths: [s],
      revalidateEndpoints: u
    }, c);
    return {
      result: I,
      revalidate_status: B.data.revalidate_status
    };
  }
  updateItemValidation(e, t) {
    const n = { ...e };
    return Object.keys(n).forEach((i) => {
      n[i] = null;
    }), Object.entries(e).forEach(([i, a]) => {
      var r, s;
      if (a != null) {
        if (((r = t == null ? void 0 : t[i]) == null ? void 0 : r.type) === "date") {
          const d = new Date(a);
          (!(d instanceof Date) || Number.isNaN(d.valueOf())) && (n[i] = "Please follow this format YYYY-MM-DD HH:MM:SS");
        }
        ((s = t == null ? void 0 : t[i]) == null ? void 0 : s.type) === "number" && (Number.isNaN(a) || Number.isNaN(parseFloat(a)) || Number.isNaN(Number(a))) && (n[i] = "Please enter a valid number");
      }
      i === Q && (a === "" || a === null || a === void 0) && (n[i] = "Must be filled");
    }), n;
  }
  async createItem(e, t, n, i) {
    const a = await new C(this.api).createItem(e, t == null ? void 0 : t[U.TABLE_ID], n, i), r = a == null ? void 0 : a.data;
    return this.pageToCollectionResultDataItem(r);
  }
  formatFilterQuery(e, t) {
    let n = t;
    switch (e) {
      case J.STRING:
        n = `${t}`;
        break;
      case J.NUMBER:
        if (Number.isNaN(t) || Number.isNaN(parseFloat(t)) || Number.isNaN(Number(t)))
          return;
        n = parseFloat(t);
        break;
      case J.DATE:
        if (!st(t))
          return;
        n = this.formatDate(t);
        break;
      case J.BOOLEAN:
        if (typeof t != "string")
          return t;
        if ((t == null ? void 0 : t.toLowerCase()) !== "true" && (t == null ? void 0 : t.toLowerCase()) !== "false")
          return;
        n = (t == null ? void 0 : t.toLowerCase()) === "true";
        break;
    }
    return n;
  }
  async search(e, t, n, i, a, r, s) {
    var b, M, I, B, w, V, $;
    const d = t == null ? void 0 : t[U.TABLE_ID], o = t == null ? void 0 : t.metadata, c = o != null && o[N.FILTER_COLUMN] ? {
      property: o[N.FILTER_COLUMN],
      [o[N.FILTER_COLUMN_TYPE]]: {
        [o[N.FILTER_CONDITION]]: this.formatFilterQuery((M = (b = j) == null ? void 0 : b[o[N.FILTER_COLUMN_TYPE]]) == null ? void 0 : M[o[N.FILTER_CONDITION]], o[N.FILTER_VALUE])
      }
    } : void 0;
    let l = o == null ? void 0 : o[N.SEARCH_COLUMNS];
    l = (l == null ? void 0 : l.length) > 0 ? l : Object.keys(i);
    let u = [];
    n && l.forEach((A) => {
      const L = i[A];
      if (D[L.type]) {
        const { key: g, type: k } = D[L.type], S = this.formatFilterQuery(k, n);
        if (S != null && S !== "") {
          if (k === J.STRING) {
            u = u.concat([
              {
                property: L.name,
                [L.type]: {
                  [g]: S
                }
              },
              {
                property: L.name,
                [L.type]: {
                  [g]: S.toLowerCase()
                }
              },
              {
                property: L.name,
                [L.type]: {
                  [g]: S.toUpperCase()
                }
              },
              {
                property: L.name,
                [L.type]: {
                  [g]: et(S)
                }
              },
              {
                property: L.name,
                [L.type]: {
                  [g]: nt(S)
                }
              }
            ]);
            return;
          }
          u.push({
            property: L.name,
            [L.type]: {
              [g]: S
            }
          });
        }
      }
    });
    let m = c;
    (u == null ? void 0 : u.length) > 0 && c && (m = {
      and: [
        c,
        {
          or: u
        }
      ]
    }), (u == null ? void 0 : u.length) > 0 && !c && (m = {
      or: u
    });
    const _ = o != null && o[N.SORT_COLUMN] ? [
      {
        property: o == null ? void 0 : o[N.SORT_COLUMN],
        direction: o == null ? void 0 : o[N.SORT_DIRECTION]
      }
    ] : void 0, h = C.getDataQuery(e, d, m, _, a, o == null ? void 0 : o[N.MAX_ITEMS]), T = await new C(this.api).callQuery(h.path, h.data, e, r, s), E = (I = T == null ? void 0 : T.data) == null ? void 0 : I.results;
    if (!E)
      return {
        data: [],
        has_more: (B = T == null ? void 0 : T.data) == null ? void 0 : B.has_more,
        next_cursor: (w = T == null ? void 0 : T.data) == null ? void 0 : w.next_cursor
      };
    const x = [];
    return E.forEach((A) => {
      if (A != null && A.properties) {
        const L = {}, g = A == null ? void 0 : A.properties;
        Object.entries(g).forEach(([k, S]) => {
          const H = this.getPropertyValue(S);
          L[k] = H;
        }), L[Y] = A == null ? void 0 : A.id, x.push(L);
      }
    }), {
      data: x,
      has_more: (V = T == null ? void 0 : T.data) == null ? void 0 : V.has_more,
      next_cursor: ($ = T == null ? void 0 : T.data) == null ? void 0 : $.next_cursor
    };
  }
  getOAuthConnectURL(e, t) {
    const n = Math.random().toString(36).substring(5, 10), i = new URL("https://api.notion.com/v1/oauth/authorize");
    return i.searchParams.append("client_id", t), i.searchParams.append("redirect_uri", `${e}${p}`), i.searchParams.append("response_type", "code"), i.searchParams.append("owner", "user"), i.searchParams.append("state", n), { url: i.toString(), state: n };
  }
  addTableID(e, t) {
    return {
      ...e,
      [U.TABLE_ID]: t
    };
  }
}
export {
  bt as NotionAPP
};
