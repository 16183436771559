var o = Object.defineProperty;
var p = (i, t, r) => t in i ? o(i, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : i[t] = r;
var n = (i, t, r) => (p(i, typeof t != "symbol" ? t + "" : t, r), r);
import { ICollectionIntegration as e } from "../../types.js";
import { NotionAPP as s } from "./notion/app.js";
class c {
  constructor(t) {
    n(this, "api");
    this.api = t;
  }
  app(t) {
    if (!t)
      return null;
    const { integration: r } = t;
    switch (r) {
      case e.NOTION:
        return new s(this.api);
      default:
        return null;
    }
  }
}
export {
  c as App
};
