import React, { PropsWithChildren, Ref } from 'react';
import classNames from 'classnames';
import { BaseProps, OrNull } from './types';
import styles from './Button.module.css';

const ButtonAccent = React.forwardRef(
  (
    {
      className,
      onClick,
      children,
      disabled,
      loading,
      small,
      ...props
    }: PropsWithChildren<{
      onClick?: any;
      disabled?: boolean;
      loading?: boolean;
      small?: boolean;
    }> &
      BaseProps,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => (
    <button
      type="button"
      className={classNames(
        className,
        'button',
        styles.buttonBase,
        styles.buttonAccent,
        {
          'is-loading': loading,
          [styles.small]: small,
        },
      )}
      onClick={disabled || loading ? () => {} : onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  ),
);

export default ButtonAccent;
