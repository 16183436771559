const s = (e) => ({
  ...e,
  children_keys: e.children_keys ? e.children_keys.filter((i) => e.children && Object.keys(e.children).includes(i)) : e.children_keys,
  children: e.children ? c(e.children) : e.children
}), c = (e) => {
  const i = {};
  return Object.keys(e).forEach((n) => {
    i[n] = s(e[n]);
  }), i;
}, d = (e) => ({
  ...e,
  routes: c({ root: e.routes }).root
});
export {
  d as sanitizeSpace
};
