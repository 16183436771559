import { ComponentClass } from 'react';
import '../styles/reactGridStyles.css';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import 'requestidlecallback-polyfill';
import { useRouter } from 'next/router';
import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import '../styles/styles.scss';
import '../styles/globals.css';
import '@typedream/components/dist/style.css';
import '@typedream/dream-editor/dist/style.css';
import Script from 'next/script';
import mixpanel from 'mixpanel-browser';
import { API } from '@typedream/data';
import Analytics from '@/head/Analytics';
import MicrosoftClarity from '@/head/MicrosoftClarity';
import PublishSettingsUpdatePopup from '@/components/PublishSettingsUpdatePopup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TokenProvider from '../utils/jwt/tokenProvider';
import AdsTrackConversion from '../head/AdsTrackConversion';
import { EDITOR_API_BASE_URL, MIX_PANEL_KEY, PORTAL_ID } from '../constants';
import AdsTrackPurchase from '../head/AdsTrackPurchase';
import Notification from '../components/Notification';
import Rewardful from '../head/Rewardful';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const Font = dynamic(() => import('../head/Font'));
const TwitterPixel = dynamic(() => import('../head/TwitterPixel'));
// import DevTool from '../head/ReactDevTools';

mixpanel.init(MIX_PANEL_KEY);
API.init(EDITOR_API_BASE_URL);

function MyApp({ Component, pageProps }: AppProps
  & {
    Component: ComponentClass & { getInitialProps?(context: NextPageContext): any; }
    & { theme: string }
  }) {
  const router = useRouter();

  return (
    <DndProvider backend={HTML5Backend}>
      {
        // Temporarily disable Microsoft Clarity on the editor page
        router.pathname !== '/s/[slug]/[space_id]/[page_id]'
        && <MicrosoftClarity />
      }
      <Rewardful />
      <Analytics />
      <AdsTrackConversion />
      <AdsTrackPurchase />
      <Script src="https://js.stripe.com/v3/" />
      {/* <DevTool />  */}
      <TokenProvider>
        <ThemeProvider
          forcedTheme={Component.theme || null}
          storageKey={`${router.query.slug?.toString()}-theme`}
        >
          <Font />
          <Component {...pageProps} />
          <div id={PORTAL_ID} />
          <Notification />
        </ThemeProvider>
      </TokenProvider>
      <TwitterPixel />
      <PublishSettingsUpdatePopup />
    </DndProvider>
  );
}

export default MyApp;
