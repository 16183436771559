import { PUBLISH_SETTINGS_POPUP_HIDE_EXPIRY_DAYS, PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY } from '@/constants';
import {
  SetState, GetState,
} from 'zustand';

export type IPublishSettingsPopupSlice = {
  publishSettings: {
    open: boolean;
    openPopup: () => void;
    closePopup: () => void;
  }
};

export type StoreSlice<T> = (
  set: SetState<IPublishSettingsPopupSlice>,
  get: GetState<IPublishSettingsPopupSlice>,
) => T;

export const createPublishSettingsSlice: StoreSlice<IPublishSettingsPopupSlice> = (set) => {
  const openPopup = () => {
    if (localStorage
      && (localStorage.getItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY) === null
        || Number(localStorage.getItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY)) < Date.now())) {
      set((state) => ({
        ...state,
        publishSettings: {
          ...state.publishSettings,
          open: true,
        },
      }));
      if (Number(localStorage.getItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY)) < Date.now()) {
        localStorage.removeItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY);
      }
    }
  };
  const closePopup = () => {
    const addDaysTimestamp = PUBLISH_SETTINGS_POPUP_HIDE_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
    const expiryTimestamp = Date.now() + addDaysTimestamp;
    localStorage.setItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY, String(expiryTimestamp));

    set((state) => ({
      ...state,
      publishSettings: {
        ...state.publishSettings,
        open: false,
      },
    }));
  };

  const state: IPublishSettingsPopupSlice = {
    publishSettings: {
      open: false,
      openPopup,
      closePopup,
    },
  };
  return state;
};
