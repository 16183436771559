const t = {
  align: "align_center",
  backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/gradient-1.png",
  children: [
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Open an account right from your phone"
        }
      ],
      openOptions: !1,
      type: "h3"
    },
    {
      children: [
        {
          color: "white",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "It takes less than 15 minutes."
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      background: "white",
      children: [
        {
          text: "Get started ->"
        }
      ],
      color: "indigo",
      gradient: !1,
      openOptions: !1,
      type: "button",
      url: ""
    }
  ],
  height: 303,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 900
}, e = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/summer_background.png",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "rgb(52,43, 141, 1)",
                  gradient: !1,
                  styled: !0,
                  text: "Don't miss out",
                  background: "white"
                }
              ],
              type: "h2"
            },
            {
              children: [
                {
                  color: "rgb(52,43, 141, 1)",
                  gradient: !1,
                  styled: !0,
                  text: "Get "
                },
                {
                  bold: !0,
                  color: "rgb(52,43, 141, 1)",
                  gradient: !1,
                  styled: !0,
                  text: "10% OFF"
                },
                {
                  color: "rgb(52,43, 141, 1)",
                  gradient: !1,
                  styled: !0,
                  text: " by subscribing to our mailing list."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3862903225806458
        },
        {
          children: [
            {
              align: "align_center",
              background: "white",
              children: [
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 310.109375,
                  openOptions: !1,
                  type: "media_embed",
                  url: "https://typedream.us6.list-manage.com/subscribe?u=b495c157b6c1f84178afae0f4&id=eeccdaac12",
                  width: 380
                }
              ],
              height: 388.015625,
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.6137096774193554
        }
      ],
      height: 392.359375,
      type: "column",
      width: 800
    }
  ],
  height: 432.359375,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 800
}, i = {
  align: "align_center",
  background: "purple",
  backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/gradient-7.png",
  children: [
    {
      children: [
        {
          children: [
            {
              background: "white",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "purple",
                  name: "zap",
                  type: "solid"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Join the waitlist"
                }
              ],
              openOptions: !1,
              type: "h2"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "rgba(255,255,255,0.7)",
                  fontSize: 20,
                  gradient: !1,
                  styled: !0,
                  text: "Join the waitlist and we'll contact you for access."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3886363636363638
        },
        {
          children: [
            {
              align: "align_center",
              background: "white",
              children: [
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 239.26171875,
                  html: `<div id="root"></div>
  <style>
    #root h1 {
      color: #000000 !important;
      font-size: 35px !important;
    }
    .container--waitlistapi {
      margin: 0 auto; // centers the widget
      background-color: #ffffff;
    }
    .button--waitlistapi {
      background-color: #000000;
    }
    .statusTextContainer--waitlistapi {
      color: #000000;
    }
    .referralLinkField--text {
      color: #000000;
    }
  </style>
  <script 
    src="https://unpkg.com/waitlistapi/dist/widget.js" 
    id="Waitlist-API-Script" 
    data-config="{
      'name': 'w1', 
      'api_key': '5ITUZY', 
      'waitlist_link': 'https://typedream.com/templates/waitlist',
      'config': {'targetElementId': 'root'}
    }"
  ><\/script>`,
                  openOptions: !1,
                  type: "media_embed",
                  width: 409
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.6113636363636367
        }
      ],
      height: 431,
      type: "column",
      width: 900
    }
  ],
  height: 471,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 900
}, n = {
  align: "align_center",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Let's chat."
                }
              ],
              openOptions: !1,
              type: "h1"
            }
          ],
          type: "column_item",
          width: 0.5882513661202183
        },
        {
          children: [
            {
              children: [
                {
                  color: "default",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Email"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "frank@seawater.com"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Phone",
                  color: "default",
                  gradient: !1
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "+14158735235"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.4117486338797818
        }
      ],
      height: 548,
      type: "column",
      width: 1e3
    }
  ],
  height: 588,
  openOptions: !1,
  type: "container",
  width: 1700,
  width_max: "100%"
}, l = {
  align: "align_center",
  background: "white",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 150.78125,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          fontFamily: "libre_caslon_text",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "Ready for a better you?",
          color: "black",
          gradient: !1
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: "Follow the scent of nature, give your skin what it deserves."
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      align: "align_center",
      background: "black",
      children: [
        {
          bold: !0,
          text: "Shop skin products \u2192"
        }
      ],
      color: "text_on_accent",
      openOptions: !1,
      type: "button",
      url: "",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 150.78125,
      openOptions: !1,
      type: "spacer",
      width: 700
    }
  ],
  height: 647,
  openOptions: !1,
  type: "container",
  width: 2300,
  width_max: "100%"
}, o = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Sign Up to join 130,000 others "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "in building projects together."
                }
              ],
              openOptions: !1,
              type: "h3"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 175.387451171875,
                  openOptions: !1,
                  type: "media_embed",
                  url: "https://tally.so/embed/w2EAEe?alignLeft=1&hideTitle=1&transparentBackground=1",
                  width: 320
                }
              ],
              height: 270.8125,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 600
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 255.375,
      type: "column",
      width: 800
    }
  ],
  height: 319.890625,
  openOptions: !1,
  type: "container",
  width_max: "100%"
}, a = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-8.gif",
  children: [
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "cardy 2.0"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontFamily: "quicksand",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "something's cooking."
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      background: "default",
      children: [
        {
          children: [
            {
              text: ""
            }
          ],
          height: 239.26171875,
          html: `<div id="root"></div>
  <style>
    #root h1 {
      color: #000000;
      font-size: 35px !important;
    }
    .container--waitlistapi {
      margin: 0 auto; // centers the widget
      background-color: #ffffff;
    }
    .button--waitlistapi {
      background-color: #000000;
    }
    .statusTextContainer--waitlistapi {
      color: #000000;
    }
    .referralLinkField--text {
      color: #000000;
    }
  </style>
  <script 
    src="https://unpkg.com/waitlistapi/dist/widget.js" 
    id="Waitlist-API-Script" 
    data-config="{
      'name': 'w1', 
      'api_key': '5ITUZY', 
      'waitlist_link': 'https://typedream.com/templates/waitlist',
      'config': {'targetElementId': 'root'}
    }"
  ><\/script>`,
          openOptions: !1,
          type: "media_embed",
          width: 409
        }
      ],
      height: 311.25,
      openOptions: !1,
      style: "card",
      type: "container",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 900
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "clear",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "twitter",
                  type: "logo"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              open_in_new_tab: !0,
              type: "button",
              url: "http://twitter.com/typedreamhq",
              width: 900
            }
          ],
          type: "column_item",
          width: 0.3269862711864406
        },
        {
          children: [
            {
              align: "align_center",
              background: "clear",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "github",
                  type: "logo"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: "",
              width: 900
            }
          ],
          type: "column_item",
          width: 0.3256637288135594
        },
        {
          children: [
            {
              align: "align_center",
              background: "clear",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "product-hunt",
                  type: "logo"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: "",
              width: 900
            }
          ],
          type: "column_item",
          width: 0.34735
        }
      ],
      height: 108,
      type: "column",
      width: 700
    }
  ],
  height: 895.8125,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 1800
};
export {
  l as CALL_TO_ACTION_BIG_WHITE,
  n as CALL_TO_ACTION_EMAIL,
  t as CALL_TO_ACTION_GRADIENT,
  o as CALL_TO_ACTION_PERSONAL_BLOG,
  a as CALL_TO_ACTION_SOMETHINGS_COOKING,
  e as CALL_TO_ACTION_SUBSCRIBE,
  i as CALL_TO_ACTION_WAITLIST
};
