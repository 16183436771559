var o = Object.defineProperty;
var p = (s, i, r) => i in s ? o(s, i, { enumerable: !0, configurable: !0, writable: !0, value: r }) : s[i] = r;
var t = (s, i, r) => (p(s, typeof i != "symbol" ? i + "" : i, r), r);
import { PlausibleAPI as a } from "./analytics/api.js";
import { AppAPI as P } from "./app/api.js";
import { HistoryAPI as I } from "./history/api.js";
import { LockAPI as A } from "./locks/api.js";
import { PageAPI as h } from "./page/api.js";
import { SpaceAPI as c } from "./space/api.js";
import { SpacePermissionAPI as l } from "./spacepermission/api.js";
import { SubscriptionAPI as w } from "./subscription/api.js";
import { TemplateAPI as m } from "./template/api.js";
import { UserAPI as u } from "./user/api.js";
import { CollectionAPI as d } from "./collection/api.js";
import { ProductsAPI as f } from "./product/api.js";
import { PaymentAPI as y } from "./payment/api.js";
import { StripeAPI as z } from "./app/stripe/api.js";
import { DocumentAPI as E } from "./document/api.js";
import { RevalidateAPI as g } from "./revalidate/api.js";
const e = class {
  constructor(i) {
    t(this, "api");
    t(this, "analyticsAPI");
    t(this, "appAPI");
    t(this, "documentAPI");
    t(this, "historyAPI");
    t(this, "lockAPI");
    t(this, "pageAPI");
    t(this, "spaceAPI");
    t(this, "spacePermissionAPI");
    t(this, "subscriptionAPI");
    t(this, "templateAPI");
    t(this, "userAPI");
    t(this, "collectionAPI");
    t(this, "productsAPI");
    t(this, "paymentAPI");
    t(this, "stripeAPI");
    t(this, "revalidateAPI");
    this.api = i, this.analyticsAPI = new a(i), this.appAPI = new P(i), this.documentAPI = new E(i), this.historyAPI = new I(i), this.lockAPI = new A(i), this.pageAPI = new h(i), this.spaceAPI = new c(i), this.spacePermissionAPI = new l(i), this.subscriptionAPI = new w(i), this.templateAPI = new m(i), this.userAPI = new u(i), this.collectionAPI = new d(i), this.productsAPI = new f(i), this.paymentAPI = new y(i), this.stripeAPI = new z(i), this.revalidateAPI = new g(i);
  }
  static getOrCreateInstance(i) {
    if (!i) {
      if (!e.defaultInstance)
        throw new Error("API is not initialized, call API.init(apiBaseURL);");
      return e.defaultInstance;
    }
    let r = e.instances.get(i);
    return r || (r = new e(i), e.instances.set(i, r), r);
  }
  static init(i) {
    if (e.defaultInstance)
      throw new Error("API is already initialized, this is likely due to running .init() twice in your program");
    e.defaultInstance = new e(i), e.instances.set(i, this.defaultInstance);
  }
  analytics() {
    if (!this.analyticsAPI || !this.analyticsAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.analyticsAPI;
  }
  app() {
    if (!this.appAPI || !this.appAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.appAPI;
  }
  document() {
    if (!this.documentAPI || !this.documentAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.documentAPI;
  }
  history() {
    if (!this.historyAPI || !this.historyAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.historyAPI;
  }
  lock() {
    if (!this.lockAPI || !this.lockAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.lockAPI;
  }
  page() {
    if (!this.pageAPI || !this.pageAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.pageAPI;
  }
  space() {
    if (!this.spaceAPI || !this.spaceAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.spaceAPI;
  }
  spacePermission() {
    if (!this.spacePermissionAPI || !this.spacePermissionAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.spacePermissionAPI;
  }
  subscription() {
    if (!this.subscriptionAPI || !this.subscriptionAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.subscriptionAPI;
  }
  template() {
    if (!this.templateAPI || !this.templateAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.templateAPI;
  }
  user() {
    if (!this.userAPI || !this.userAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.userAPI;
  }
  collection() {
    if (!this.collectionAPI || !this.collectionAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.collectionAPI;
  }
  products() {
    if (!this.productsAPI || !this.productsAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.productsAPI;
  }
  payment() {
    if (!this.paymentAPI || !this.paymentAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.paymentAPI;
  }
  stripe() {
    if (!this.stripeAPI || !this.stripeAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.stripeAPI;
  }
  revalidate() {
    if (!this.revalidateAPI || !this.revalidateAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.revalidateAPI;
  }
};
let n = e;
t(n, "defaultInstance"), t(n, "instances", /* @__PURE__ */ new Map());
export {
  n as API
};
