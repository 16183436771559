const e = {
  children: [
    {
      children: [
        {
          align: "align_left",
          aspect_ratio: 1.139384116693679,
          children: [
            {
              text: ""
            }
          ],
          height: 432.71846105974384,
          openOptions: !1,
          type: "img",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/card.png",
          width: 493.03254153160435
        }
      ],
      type: "column_item",
      width: 0.5546052631578953
    },
    {
      children: [
        {
          children: [
            {
              text: "Get a complete bank account"
            }
          ],
          type: "h3"
        },
        {
          children: [
            {
              color: "gray",
              gradient: !1,
              styled: !0,
              text: "Open bank accounts with virtual cards, team management, API access, and more"
            }
          ],
          type: "p"
        },
        {
          children: [
            {
              text: ""
            },
            {
              children: [
                {
                  text: "Learn more ->"
                }
              ],
              type: "a",
              url: "/"
            },
            {
              text: ""
            }
          ],
          type: "p"
        }
      ],
      type: "column_item",
      width: 0.44539473684210507
    }
  ],
  height: 514,
  type: "column",
  width: 1e3
}, t = {
  align: "align_center",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 55.734375,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          text: "Financial Software that "
        },
        {
          color: "nebula",
          gradient: !0,
          styled: !0,
          text: "accelerates"
        },
        {
          text: " your growth."
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Cardy allows you to handle your finances "
        },
        {
          bold: !0,
          color: "default",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "with transparency and insights"
        },
        {
          bold: !0,
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: " that helps fuel your company's growth."
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "column_dynamic",
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "gray",
              children: [
                {
                  aspect_ratio: 1.1387900355871892,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 350.34375,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/card.png",
                  width: 398.9679715302493
                },
                {
                  children: [
                    {
                      text: "Get a complete bank account"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Open bank accounts with virtual cards, team management, API access, and more."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      children: [
                        {
                          text: "Learn more ->"
                        }
                      ],
                      open_in_new_tab: !1,
                      type: "a",
                      url: "https://typedream.com/"
                    },
                    {
                      text: "\uFEFF"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 519.34375,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 400
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              background: "gray",
              children: [
                {
                  aspect_ratio: 1.1015490533562822,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 581,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/graph.png",
                  width: 640
                },
                {
                  children: [
                    {
                      text: "Auto-Generate Reports"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Easily generate monthly, quarterly, and yearly reports with just one click."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      children: [
                        {
                          text: "Learn more ->"
                        }
                      ],
                      open_in_new_tab: !1,
                      type: "a",
                      url: "https://typedream.com/"
                    },
                    {
                      text: "\uFEFF"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 519.34375,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 400
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              background: "gray",
              children: [
                {
                  aspect_ratio: 1.0692446043165469,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 598.5534062237174,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/LandingPageMockup.png",
                  width: 640
                },
                {
                  children: [
                    {
                      text: "Manage from your phone"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Manage your account with confidence using our powerful mobile app."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      children: [
                        {
                          text: "Learn more ->"
                        }
                      ],
                      open_in_new_tab: !1,
                      type: "a",
                      url: "https://typedream.com/"
                    },
                    {
                      text: "\uFEFF"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 519.34375,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 400
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        }
      ],
      height: 584.9375,
      type: "column",
      width: 1400
    }
  ],
  height: 1011.046875,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, n = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "A "
                },
                {
                  color: "candy",
                  gradient: !0,
                  styled: !0,
                  text: "technology-first"
                },
                {
                  color: "water",
                  gradient: !0,
                  styled: !0,
                  text: " "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "approach to financial management"
                }
              ],
              type: "h1"
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Built with security and "
                },
                {
                  bold: !0,
                  color: "white",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "easy-of-use"
                },
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: " in mind."
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: "\uFEFF"
                },
                {
                  children: [
                    {
                      color: "accent",
                      gradient: !1,
                      styled: !0,
                      text: "Check the documentation ->"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://typedream.com/",
                  width: null
                },
                {
                  text: " \uFEFF"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.6438658027342828
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3561341972657165
        }
      ],
      height: 353,
      type: "column",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              background: "indigo",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "fast-forward",
                  type: "regular"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Fast & secure platform"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "We release hundreds of features to help you manage your finances securely with speed."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              background: "indigo",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "line-chart",
                  type: "regular"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Built-in Reports"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Generate useful insights & quick summaries in seconds with our state-of-the-art AI software."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              background: "indigo",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "show",
                  type: "regular"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Transparent"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "We believe in transparency when it comes in your companies finances and team management."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              background: "indigo",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "pulse",
                  type: "regular"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Reliable"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Our systems operate with 99.9%+ uptime and is certified with the highest standards."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        }
      ],
      height: 348,
      type: "column",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    }
  ],
  height: 841,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, i = {
  children: [
    {
      children: [
        {
          align: "align_right",
          aspect_ratio: 0.7280701754385961,
          children: [
            {
              text: ""
            }
          ],
          height: 199.98268072289147,
          type: "img",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/3d_man.png",
          width: 145.60142543859635
        },
        {
          align: "align_center",
          background: "black",
          children: [
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: "1"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Audit your workflows"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Pinpointing weaknesses in communication, collaboration and production"
                }
              ],
              type: "p"
            }
          ],
          height: 113,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 500,
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/blue.png"
        }
      ],
      type: "column_item",
      width: 0.27413909730189023
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          children: [
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: "2"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Create the perfect system"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Solving outlined weaknesses efficiently"
                }
              ],
              type: "p"
            }
          ],
          height: 113,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 500,
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/blue.png"
        },
        {
          children: [
            {
              text: ""
            }
          ],
          height: 17,
          openOptions: !1,
          type: "spacer",
          width: 700
        },
        {
          align: "align_center",
          background: "black",
          children: [
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: "3"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Train you and your team"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Ensuring a smooth transition to the new system"
                }
              ],
              type: "p"
            }
          ],
          height: 113,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 500,
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/blue.png"
        }
      ],
      type: "column_item",
      width: 0.2741494443014551
    },
    {
      children: [
        {
          children: [
            {
              text: ""
            }
          ],
          height: 53,
          openOptions: !1,
          type: "spacer",
          width: 700
        }
      ],
      type: "column_item",
      width: 0.06355411674778286
    },
    {
      children: [
        {
          children: [
            {
              text: "Become operationally remarkable \u{1F48E}"
            }
          ],
          type: "h2"
        },
        {
          children: [
            {
              color: "gray",
              fontSize: 20,
              fontStyle: "subtitle2",
              lineHeight: 1.4,
              styled: !0,
              text: "We'll improve your employee's success, happiness and retention. "
            }
          ],
          type: "p"
        }
      ],
      type: "column_item",
      width: 0.3881573416488718
    }
  ],
  height: 645,
  type: "column",
  width: 1100
}, l = {
  align: "align_center",
  background: "#EFF2F0",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Skills",
                  color: "black",
                  gradient: !1
                }
              ],
              openOptions: !1,
              type: "h1"
            }
          ],
          type: "column_item",
          width: 0.5046693348580142
        },
        {
          children: [
            {
              children: [
                {
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Branding",
                  color: "black",
                  gradient: !1
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "UI/UX"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Photography"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.23003621116828676
        },
        {
          children: [
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Animation"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Illustration"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "SEO/Marketing"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.265294453973699
        }
      ],
      height: 286,
      type: "column",
      width: 1e3
    }
  ],
  height: 326,
  openOptions: !1,
  type: "container",
  width: 1700,
  width_max: "100%"
}, o = {
  align: "align_center",
  background: "rgba(255, 175, 101, 1)",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Our Fuel Philosophy",
                  color: "black",
                  gradient: !1
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  color: "rgba(0,0,0,0.5)",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "At every production stage, we aim to source our ingredients from local farms with the highest quality. We want to contribute to making a better world."
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "rgba(0,0,0,0.5)",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Learn More \u2192"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              aspect_ratio: 1.5000000000000002,
              children: [
                {
                  text: ""
                }
              ],
              height: 466.66666666666663,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/5593e9cb-c221-45ae-bc3d-c8996740ae3c_fuel-orange-plant_png.png",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 500,
      type: "column",
      width: 1100
    }
  ],
  height: 621.65625,
  openOptions: !1,
  type: "container",
  width: 1800,
  width_max: "100%"
}, r = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  gradient: !1,
                  styled: !0,
                  text: "Sourced from the Earth."
                }
              ],
              openOptions: !1,
              type: "h1",
              width: 1e3
            },
            {
              align: "align_left",
              children: [
                {
                  color: "rgba(255, 255, 255, 0.5)",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "We dedicated our time and effort in growing the best ingredients for your skin and crafting the best product for your body."
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.45027915254237294
        },
        {
          children: [
            {
              aspect_ratio: 0.75,
              children: [
                {
                  text: ""
                }
              ],
              height: 449.3333333333333,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/91a83a6b-3b91-491d-80c5-425a83adb9fe_brown-kettle_png.png",
              width: 337
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 16.96875,
              openOptions: !1,
              type: "spacer"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Handcrafted by our lovely team"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Our Workshop in Napa"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 151.140625,
              mobile_hide: !0,
              openOptions: !1,
              type: "spacer"
            }
          ],
          type: "column_item",
          width: 0.32570288135593223
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 240.328125,
              mobile_hide: !0,
              openOptions: !1,
              type: "spacer"
            },
            {
              aspect_ratio: 0.6666666666666666,
              children: [
                {
                  text: ""
                }
              ],
              height: 360.1875,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/6082b905-4f13-4ec9-b5ec-75c4491f0683_hand-green_png.png",
              width: 240.125
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 16.96875,
              openOptions: !1,
              type: "spacer"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Farm to Bottle"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Our Farm in California"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.2240079661016949
        }
      ],
      height: 823.375,
      type: "column",
      width: 1200
    }
  ],
  height: 895.375,
  openOptions: !1,
  type: "container",
  width: 2300,
  width_max: "100%"
}, a = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      align: "align_center",
      background: "default",
      backgroundImage: "https://api.typedream.com/v0/document/public/8b8bc455-0704-419e-8cd5-3bb6dda52c9e_Group_19_png.png?bucket=document",
      children: [
        {
          children: [
            {
              children: [
                {
                  align: "align_center",
                  background: "default",
                  children: [
                    {
                      children: [
                        {
                          text: "A free, self-guided course to help you take your first into building a business online."
                        }
                      ],
                      openOptions: !1,
                      type: "h3"
                    },
                    {
                      children: [
                        {
                          text: "Taken from interviews of 50+ successful bootstrapped founders, we're setting up a step-by-step guide on making money online based on your skills."
                        }
                      ],
                      type: "p"
                    }
                  ],
                  height: 183,
                  openOptions: !1,
                  type: "container",
                  width: 400
                }
              ],
              type: "column_item",
              width: 0.5
            },
            {
              children: [
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.5
            }
          ],
          height: 380.25,
          type: "column"
        }
      ],
      height: 523.96875,
      openOptions: !1,
      type: "container",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    }
  ],
  height: 735.96875,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, c = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      aspect_ratio: 1.618421052631581,
      children: [
        {
          text: ""
        }
      ],
      height: 433.6853709669167,
      openOptions: !1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/b296741a-bd1a-4b2b-8ad3-ad8ae3457f8e_Group_21_png.png?bucket=document",
      width: 701.885534591195
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  text: "How it's structured"
                }
              ],
              openOptions: !1,
              type: "h3"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              children: [
                {
                  bold: !0,
                  text: "The goal to this guide is to help you uncover more resources online and help you become independent in growing your business"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: "1. Lesson"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "These lessons would cover different topics in online business that you can then discover further at your own time"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: "2. Resources"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "We'll list other resources where you can deep dive into a topic"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: "3. Founders Story"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "We'll include some lessons, tips, and experiences from our interviews to help you navigate different obstacles you may face."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 576,
      type: "column",
      vertical_align: "align_start"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    }
  ],
  height: 1221.671875,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
};
export {
  i as FEATURES_3_BENEFITS,
  t as FEATURES_3_CARDS,
  n as FEATURES_HIGHLIGHTS,
  e as FEATURES_LEFT_RIGHT,
  l as FEATURES_LIGHT_PORTFOLIO,
  o as FEATURES_PHILOSOPHY,
  r as FEATURES_SOURCE,
  a as FEATURES_UX_COURSE_1,
  c as FEATURES_UX_COURSE_2
};
