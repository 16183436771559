var c = Object.defineProperty;
var n = (o, r, i) => r in o ? c(o, r, { enumerable: !0, configurable: !0, writable: !0, value: i }) : o[r] = i;
var l = (o, r, i) => (n(o, typeof r != "symbol" ? r + "" : r, i), i);
import { JwtToken as f } from "./JwtToken.js";
import { SPACE_ACTION as s, SUBSCRIPTION_PLAN as e } from "../../types.js";
class I extends f {
  constructor(i) {
    super(i);
    l(this, "payload");
    this.payload = this.decodePayload();
  }
  decodePayload() {
    return super.decodePayload();
  }
  getProductID() {
    return this.payload.product_id;
  }
  getSubscriptionID() {
    return this.payload.subscription_id;
  }
  getSpaceID() {
    return this.payload.space_id;
  }
  getStatus() {
    return this.payload.status;
  }
  getPlan(i) {
    return i[this.getProductID()];
  }
  getMetadata() {
    return this.payload.metadata;
  }
  isAllowed(i, d) {
    const a = this.getPlan(d);
    let t = !0;
    switch (i) {
      case s.UpdateAffiliate:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateCustomDomain:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateCustomization:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateMetadata:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateSubdomain:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.InviteMember:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateAnalytics:
        a === e.FREE && (t = !1);
        break;
      case s.ReadBasicAnalytics:
        a === e.FREE && (t = !1);
        break;
      case s.ReadAdvancedAnalytics:
        a === e.FREE && (t = !1);
        break;
      case s.CreateLock:
        a !== e.PRO && (t = !1);
        break;
      case s.EmailCollectionCard:
        a === e.FREE && (t = !1);
        break;
      case s.PublishUnlimitedPages:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.Collection:
      default:
        t = !0;
    }
    return t;
  }
}
export {
  I as SubscriptionToken
};
