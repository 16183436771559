import React from 'react';
import Script from 'next/script';

declare global {
  interface Window {
    gtag_report_purchase_conversion: Function;
  }
}

/*  Event snippet for Upgrade Plan conversion page
    In your html page, add the snippet and call
    gtag_report_purchase_conversion when someone clicks on
    the chosen link or button.
*/
function AdsTrackPurchase() {
  return (
    <Script id="gtag-report-purchase-conversion">
      {` 
      function gtag_report_purchase_conversion(transactionID) {
        gtag('event', 'conversion', {
            'send_to': 'AW-664087335/UUy_CJzd-oIDEKfW1LwC',
            'transaction_id': transactionID
        });
        return false;
      }
    `}
    </Script>
  );
}
export default AdsTrackPurchase;
