const e = {
  align: "align_center",
  background: "default",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "Events"
        }
      ],
      openOptions: !1,
      type: "h2"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: "Wedding Ceremony"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "Saturday, 12th February 2022"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "11:30 a.m. PST"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "The Julia Morgan Ballroom"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 568,
      openOptions: !1,
      type: "media_embed",
      url: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12611.532106710076!2d-122.402175!3d37.792781!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa900e19e96d254c4!2sThe%20Julia%20Morgan%20Ballroom!5e0!3m2!1sen!2sus!4v1644214929279!5m2!1sen!2sus",
      width: 803.859375
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: "Zoom Link"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "To be updated soon."
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    }
  ],
  height: 1208.375,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
};
export {
  e as EVENTS_WEDDING
};
