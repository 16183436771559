var i = Object.defineProperty;
var E = (e, t, s) => t in e ? i(e, t, { enumerable: !0, configurable: !0, writable: !0, value: s }) : e[t] = s;
var a = (e, t, s) => (E(e, typeof t != "symbol" ? t + "" : t, s), s);
import D from "dexie";
import { OFFLINE_DB as o, DB_VERSION as r, PAGE_CONTENT_INDEXED_SCHEMA as _, ITEM_PAGE_DATA_CONTENT_INDEXED_SCHEMA as m } from "./constants.js";
class p extends D {
  constructor() {
    super(o);
    a(this, "pages");
    a(this, "itemData");
    this.version(r).stores({
      pages: _,
      itemData: m
    }), this.pages = this.table("pages"), this.itemData = this.table("itemData");
  }
}
const T = new p();
export {
  p as OfflineStorage,
  T as db
};
