import { makeApiSliceCreator as o } from "@typedream/zustand-query";
import { listDocuments as i } from "./actions.js";
const c = o("documentsApi", (e) => ({
  endpoints: (t) => ({
    listDocuments: t.query(i(e))
  })
}));
export {
  c as createDocumentsApiSlice
};
