import { makeApiSliceCreator as c } from "@typedream/zustand-query";
import { queryProducts as u, getProduct as r, getProductCollection as d, countProducts as P, createProduct as a, updateProduct as n, publishProduct as s, updateContactEmail as i } from "./actions.js";
const m = c("productsApi", (o) => ({
  endpoints: (e) => ({
    queryProducts: e.query(u(o)),
    getProduct: e.query(r(o)),
    getProductCollection: e.query(d(o)),
    countProducts: e.query(P(o)),
    createProduct: e.mutation(a(o), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["countProducts", t[0].spaceID],
        ["getProductCollection", t[0].spaceID]
      ]
    }),
    updateProduct: e.mutation(n(o), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["getProduct", t[0].productID, t[0].spaceID]
      ]
    }),
    publishProduct: e.mutation(s(o), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["getProduct", t[0].productID, t[0].spaceID]
      ]
    }),
    updateContactEmail: e.mutation(i(o), {
      invalidateQueries: ({ args: t }) => [
        ["getProductCollection", t[0].spaceID]
      ]
    })
  })
}));
export {
  m as createProductsApiSlice
};
