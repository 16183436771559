import { makeApiSliceCreator as o } from "@typedream/zustand-query";
import { getVersionList as r, getVersionContent as i } from "./actions.js";
const p = o("historyApi", (e) => ({
  endpoints: (t) => ({
    getVersionList: t.query(r(e)),
    getVersionContent: t.query(i(e))
  })
}));
export {
  p as createHistoryApiSlice
};
