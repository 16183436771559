import { API as i } from "../../api/api.js";
const k = (t) => async (n) => {
  if (!n)
    return [];
  const { getState: o } = t, { tokenHandlers: e } = o(), { spacePermissionTokenHandler: s } = e;
  return i.getOrCreateInstance().lock().list(n, s);
}, l = (t) => async (n) => {
  const { getState: o } = t, { spaceID: e, newLock: s } = n;
  if (!e)
    throw new Error("Space ID is not defined");
  const { tokenHandlers: c } = o(), { spacePermissionTokenHandler: r, subscriptionTokenHandler: a } = c;
  return i.getOrCreateInstance().lock().create(e, r, a, s);
}, p = (t) => async (n) => {
  const { getState: o } = t, { spaceID: e, lockID: s, data: c } = n;
  if (!e)
    throw new Error("Space ID is not defined");
  const { tokenHandlers: r } = o(), { spacePermissionTokenHandler: a } = r;
  return i.getOrCreateInstance().lock().update(e, s, a, c);
}, I = (t) => async (n) => {
  const { getState: o } = t, { spaceID: e, lockID: s } = n;
  if (!e)
    throw new Error("Space ID is not defined");
  const { tokenHandlers: c } = o(), { spacePermissionTokenHandler: r } = c;
  return i.getOrCreateInstance().lock().delete(e, r, s);
}, D = (t) => async (n) => {
  const { getState: o } = t, { spaceID: e, pageID: s, lockID: c } = n;
  if (!e)
    throw new Error("Space ID is not defined");
  const { tokenHandlers: r } = o(), { spacePermissionTokenHandler: a } = r;
  return i.getOrCreateInstance().lock().addLock(e, a, s, c);
}, g = (t) => async (n) => {
  const { getState: o } = t, { spaceID: e, pageID: s, lockID: c } = n;
  if (!e)
    throw new Error("Space ID is not defined");
  const { tokenHandlers: r } = o(), { spacePermissionTokenHandler: a } = r;
  return i.getOrCreateInstance().lock().removeLock(e, a, s, c);
};
export {
  D as addLock,
  l as createLock,
  I as deleteLock,
  k as listLocks,
  g as removeLock,
  p as updateLock
};
