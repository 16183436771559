var t;
(function(e) {
  e.PARAGRAPH = "p", e.HEADING1 = "h1", e.HEADING2 = "h2", e.HEADING3 = "h3", e.NUMBERED_LIST = "ol", e.BULLETED_LIST = "ul", e.LIST_ITEM = "li", e.LIST_ITEM_CONTENT = "lic", e.TODO_LIST = "action_item", e.CODE_BLOCK = "code_block", e.CODE_LINE = "code_line", e.BLOCKQUOTE = "blockquote", e.LINK = "a", e.IMAGE = "img", e.COLUMN = "column", e.COLUMN_ITEM = "column_item", e.BUTTON = "button", e.ALIGN_LEFT = "align_left", e.ALIGN_CENTER = "align_center", e.ALIGN_RIGHT = "align_right", e.MEDIA_EMBED = "media_embed", e.DIVIDER = "divider", e.CONTAINER = "container", e.SECTION_TEMPLATE = "section_template", e.SPACER = "spacer", e.SYNCED_CONTAINER = "synced_container", e.COLLECTION = "collection", e.COLLECTION_ITEM = "collection_item", e.ACCORDION = "accordion", e.ACCORDION_ITEM = "accordion_item", e.ACCORDION_ITEM_HEADER = "accordion_item_header", e.ACCORDION_ITEM_CONTENT = "accordion_item_content", e.RICH_TEXT_CONTENT = "rich_text_content";
})(t || (t = {}));
const n = [
  t.PARAGRAPH,
  t.HEADING1,
  t.HEADING2,
  t.HEADING3,
  t.NUMBERED_LIST,
  t.BULLETED_LIST,
  t.LIST_ITEM,
  t.TODO_LIST,
  t.CODE_BLOCK,
  t.BLOCKQUOTE,
  t.LINK,
  t.BUTTON
];
var o;
(function(e) {
  e.PLAIN = "plain", e.CARD = "card";
})(o || (o = {}));
const A = "unsupported";
var i;
(function(e) {
  e.PARAGRAPH = "paragraph", e.HEADING_1 = "heading_1", e.HEADING_2 = "heading_2", e.HEADING_3 = "heading_3", e.BULLETED_LIST_ITEM = "bulleted_list_item", e.NUMBERED_LIST_ITEM = "numbered_list_item", e.TO_DO = "to_do", e.TOGGLE = "toggle", e.CODE = "code", e.CHILD_PAGE = "child_page", e.CHILD_DATABASE = "child_database", e.EMBED = "embed", e.IMAGE = "image", e.VIDEO = "video", e.FILE = "file", e.PDF = "pdf", e.BOOKMARK = "bookmark", e.CALLOUT = "callout", e.QUOTE = "quote", e.EQUATION = "equation", e.DIVIDER = "divider", e.TABLE_OF_CONTENTS = "table_of_contents", e.BREADCRUMB = "breadcrumb", e.COLUMN = "column", e.COLUMN_LIST = "column_list", e.LINK_PREVIEW = "link_preview", e.SYNCED_BLOCK = "synced_block", e.TEMPLATE = "template", e.LINK_TO_PAGE = "link_to_page", e.TABLE = "table", e.TABLE_ROW = "table_row", e.UNSUPPORTED = "unsupported";
})(i || (i = {}));
export {
  t as BLOCK_TYPE,
  A as BLOCK_TYPE_UNSUPPORTED,
  o as CONTAINER_STYLE,
  i as NOTION_BLOCK_TYPE,
  n as TEXT_TYPES
};
