import { STokenHandler as o } from "../../utils/jwt/subscriptionTokenHandler.js";
import { PTokenHandler as r } from "../../utils/jwt/spacePermissionTokenHandler.js";
const a = () => {
  const e = new r(), n = new o(e);
  return {
    tokenHandlers: {
      spacePermissionTokenHandler: e,
      subscriptionTokenHandler: n
    }
  };
};
export {
  a as createAuthSlice
};
