import { v4 as a } from "uuid";
const p = (n) => async (t) => {
  if (!t)
    return {};
  const { getState: s } = n, { spacesApi: o } = s(), e = o.endpoints.getSpace.get(t).data;
  return e ? e.customization.transclusion_reference || {} : {};
}, d = (n) => (t, s, o) => {
  if (!t)
    return Promise.resolve();
  const e = s || a(), c = `SyncBlock-${e.slice(0, 2)}`, i = new Date().getTime(), r = o || {
    id: a(),
    type: "container",
    name: c,
    children: [],
    transclusion_enabled: !0,
    transclusion_reference_id: e,
    transclusion_updated_at: i
  }, { getState: u } = n;
  return u().spacesApi.endpoints.updateSpaceCustomizationPartial([{
    spaceID: t,
    customizationUpdateRequests: [
      {
        customization_value: r,
        customization_path: ["transclusion_reference", e]
      }
    ]
  }]), Promise.resolve(r);
};
export {
  d as createTransclusionReference,
  p as getAllSyncedContainers
};
