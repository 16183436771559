import type {
  DreamValue,
} from '@typedream/core';
import { SPACE_ACTION, SPACE_ROLE, SUBSCRIPTION_PLAN } from './types';

export const EDITOR_API_BASE_URL = process.env.NEXT_PUBLIC_EDITOR_API_BASE_URL;
export const EDITOR_DOMAIN = process.env.NEXT_PUBLIC_EDITOR_DOMAIN;
export const RENDERER_DOMAIN = process.env.NEXT_PUBLIC_RENDERER_DOMAIN;
export const LINK_IN_BIO_DOMAIN = process.env.NEXT_PUBLIC_LINK_IN_BIO_DOMAIN || 'dumpl.ink';
export const OLD_PATH_RESOLUTION_DOMAIN = 'typedream.site';
export const BUCKET_URL = process.env.NEXT_PUBLIC_BUCKET_URL;
export const BUCKET_URL2 = process.env.NEXT_PUBLIC_BUCKET_URL2;
export const NOTION_CLIENT_ID = process.env.NEXT_PUBLIC_NOTION_CLIENT_ID;
export const OAUTH_REDIRECT_URI = '/app/auth/notion';
export const MIX_PANEL_KEY = 'aafb8cfde053fc023ac5926bda69211b';
export const ZENDESK_KEY = 'f871bdf7-2c33-4fbd-bb5d-7e7ab05bea84';

export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
export const DEV = !!EDITOR_API_BASE_URL?.includes('localhost');
export const CI = process.env.NEXT_PUBLIC_CI?.toLowerCase() === 'true';

export const DEFAULT_SPACE_NAME = 'site';
export const DEFAULT_SPACE_NAME_PLURAL = 'sites';

export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';

export const EMPTY_PAGE_ID = '00000000-0000-0000-0000-000000000000';

export const DUPLICATE_PAGE_STORAGE_KEY = 'duplicate_page';
export const DUPLICATE_PAGE_URL = `https://${EDITOR_DOMAIN}/duplicate?url=`;
export const SAVE_AS_TEMPLATE_LINK = 'https://typedream.com/docs/how-to-save-a-page-as-a-template';
export const SOURCE_STORAGE_KEY = 'utm_source';
export const PROMO_SOURCE_STORAGE_KEY = 'promo_utm_source';
export const PROMO_SOURCE_STORAGE_EXPIRY = 1000 * 24 * 60 * 60 * 2; // Promo is saved for 2 days
export const USER_STORAGE_KEY = 'user';
export const SPACE_STORAGE_KEY = 'spaces';
export const SPACE_CREATION_STORAGE_KEY = 'spaces_create';
export const ONBOARDING_STORAGE_KEY = 'onboarding';
export const COLLECTION_STORAGE_KEY = 'collection_popup';
export const CUSTOM_DOMAIN_POPUP_STORAGE_KEY = 'custom_domain_popup_expiry';
export const CHROME_WARNING_POPUP_HIDE_STORAGE_KEY = 'chrome_warning_popup_hide_expiry';
export const CHROME_WARNING_POPUP_HIDE_EXPIRY_DAYS = 1;
export const PUBLISH_SETTINGS_POPUP_HIDE_EXPIRY_DAYS = 90;
export const PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY = 'publish_settings_popup_hide_expiry';
export const CUSTOM_DOMAIN_POPUP_REMIND_DAYS = 7; // in days
export const CREATE_SITE_TYPE_STORAGE_KEY = 'create_site_type';
export const CREATE_SITE_TYPE_OTHER_STORAGE_KEY = 'create_site_type_other';
export const CREATE_SITE_HOME_ID_STORAGE_KEY = 'create_site_home_id';
export const CREATE_SITE_PAGES_ID_STORAGE_KEY = 'create_site_pages_id';
export const CREATE_SITE_SCRATCH_PAGE_ID = DEV ? 'cad698ec-f42a-4816-9f04-c44e70b67d95' : 'd44792c6-d949-42fa-ad5e-a4791c1768d2';
export const SPACE_PERMISSION_STORAGE_KEY = 'space_permissions';
export const DEFAULT_SPACE_STORAGE_KEY = 'default_space';
export const COMPANY_NAME = 'Govest, Inc.';
export const LOGO_NAME = 'Typedream';
export const LOGO_NAME_ABBREVIATED = 'TD';
export const LOGIN_SEO_DESCRIPTION = 'Login to your Typedream account to start creating your website.';
export const LOGO_TWITTER = '@typedreamHQ';
export const LOGO_EMAIL = 'support@typedream.com';
export const LOGO_TWITTER_LINK = 'https://twitter.com/typedreamHQ';
export const LOGO_CIRCLE_URL = '/images/typedream-logo-circle.svg';
export const LOGO_URL = '/logo.svg';
export const LOGO_WITH_TEXT_URL = '/images/logo_svg.svg';
export const LOGO_FAVICON_URL = '/favicon.ico';
export const LANDING_URL = 'https://typedream.com';
export const LOGO_DOMAIN = RENDERER_DOMAIN || 'typedream.site';
export const VERCEL_DNS_LEARN_MORE_LINK = 'https://vercel.com/support/articles/why-is-my-vercel-domain-unverified';
export const DNS_LEARN_MORE_LINK = 'https://typedream.com/docs/custom-domain/faq';
export const CUSTOM_DOMAIN_REDIRECT_WWW_LINK = 'https://typedream.com/docs/custom-domain/how-to-redirect-your-www-to-your-main-host';
export const CUSTOM_DOMAIN_REDIRECT_WWW_LINK_TYPEDREAM = 'https://typedream.com/docs/custom-domain/how-to-redirect-your-www-to-your-main-host-using-typedream';
export const CNAME_FLATTENING_LINK = 'https://typedream.com/docs/custom-domain/cname-flattening';
export const SPACE_PERMISSION_HEADER_KEY = 'Space-Permission';
export const SUBSCRIPTION_HEADER_KEY = 'Subscription';
export const LAST_BACKEDUP_DATA = 'page_backup';
export const ALLOW_PROMO_CODE_STORAGE_KEY = 'allow_promo_code';
export const PROMO_CODE_STORAGE_KEY = 'promo_code';
export const ALLOW_PROMO_CODE_STORAGE_EXPIRY = 1000 * 24 * 60 * 60 * 2;
export const NETWORK_ERROR = 'Network Error';
export const UPGRADE_BANNER_EXPIRY_IN_DAYS = 3;
export const UPGRADE_BANNER_KEY_PREFIX = 'banner-expiry-';
export const RICH_TEXT_CONTENT_EXPLANATION_DONE_STORAGE_KEY = 'rich_text_content_explanation_done';
export const RICH_TEXT_CONTENT_IMPORT_NOTION_PAGE = 'rich_text_content_import_notion_page';
export const ALL_PAGE_PUBLIC_STORAGE_KEY_PREFIX = 'all-page-public-';
export const ASSET_TYPEDREAM_LINK = 'https://www.typedream.com/assets';
export const XYZ_DOMAIN_INFO_LINK = 'https://typedream.com/pricing/xyz-learn-more';
export const XYZ_DOMAIN_GUIDE_LINK = 'https://typedream.com/docs/custom-domain/xyz';
export const PORTAL_ID = 'td-portal';

export const TERMS_OF_SERVICE_LINK = process.env.NEXT_PUBLIC_TERMS_OF_SERVICE_LINK;
export const PRIVACY_POLICY_LINK = process.env.NEXT_PUBLIC_PRIVACY_POLICY_LINK;

export const SAVE_INTERVAL = 10; // seconds
export const BACKUP_INTERVAL = 30 * 60; // 30 minutes

export const FETCH_REALTIME_INTERVAL = 3; // seconds
export const FETCH_TIMESERIES_INTERVAL = 30; // seconds

export const LOGIN_PATH = '/login';

export const MODEL_STATE = Object.freeze({
  initial: 1,
  loading: 2,
  error: 3,
  ready: 4,
});

export const DEFAULT_POPUP_WIDTH = 640;
export const DEFAULT_POPUP_HEIGHT = 400;
export const DEFAULT_NEW_PAGE_NAME = 'Untitled';
export const DEFAULT_NEW_PAGE_SLUG = 'untitled';
export const DEFAULT_NEW_PAGE_SLUG_RANDOM_LENGTH = 4;
export const MAX_SLUG_RETRY = 10;
export const DEFAULT_SIDE_OPTIONS_WIDTH = 350;
export const DEFAULT_SIDE_OPTIONS_METADATA_WIDTH = 500;

export const DEFAULT_SETTINGS_WIDTH = 800;

export const SPACE_PLAN_FREE_PRODUCT_ID = 'FREE';

export const SPACE_PLAN_MINI_PRODUCT_ID = process.env.NEXT_PUBLIC_SPACE_PLAN_MINI_PRODUCT_ID;
export const SPACE_PLAN_MINI_MONTHLY_PRICE = parseInt(
  process.env.NEXT_PUBLIC_SPACE_PLAN_MINI_MONTHLY_PRICE,
  10,
);
export const SPACE_PLAN_MINI_ANNUAL_PRICE = parseInt(
  process.env.NEXT_PUBLIC_SPACE_PLAN_MINI_ANNUAL_PRICE,
  10,
);

export const SPACE_PLAN_LAUNCH_PRODUCT_ID = process.env.NEXT_PUBLIC_SPACE_PLAN_LAUNCH_PRODUCT_ID;
export const SPACE_PLAN_LAUNCH_MONTHLY_PRICE = parseInt(
  process.env.NEXT_PUBLIC_SPACE_PLAN_LAUNCH_MONTHLY_PRICE,
  10,
);
export const SPACE_PLAN_LAUNCH_ANNUAL_PRICE = parseInt(
  process.env.NEXT_PUBLIC_SPACE_PLAN_LAUNCH_ANNUAL_PRICE,
  10,
);

export const SPACE_PLAN_PRO_PRODUCT_ID = process.env.NEXT_PUBLIC_SPACE_PLAN_PRO_PRODUCT_ID;
export const SPACE_PLAN_PRO_MONTHLY_PRICE = parseInt(
  process.env.NEXT_PUBLIC_SPACE_PLAN_PRO_MONTHLY_PRICE,
  10,
);
export const SPACE_PLAN_PRO_ANNUAL_PRICE = parseInt(
  process.env.NEXT_PUBLIC_SPACE_PLAN_PRO_ANNUAL_PRICE,
  10,
);

export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
export const XYZ_DOMAIN_COUPON_CODE = 'TYPEXYZ22';

export const SPACE_ROLE_TO_DESCRIPTION = {
  [SPACE_ROLE.OWNER]: {
    title: 'Owner',
    text: 'Can edit and share with others',
  },
  [SPACE_ROLE.ADMIN]: {
    title: 'Full Access',
    text: 'Can edit and share with others',
  },
  [SPACE_ROLE.WRITER]: {
    title: 'Can edit',
    text: 'Can edit, but not share with others',
  },
  [SPACE_ROLE.READER]: {
    title: 'Can view',
    text: 'Cannot edit or share with others',
  },
};

export const SPACE_SUBSCRIPTIONS = {
  [SPACE_PLAN_LAUNCH_PRODUCT_ID]: SUBSCRIPTION_PLAN.LAUNCH,
  [SPACE_PLAN_FREE_PRODUCT_ID]: SUBSCRIPTION_PLAN.FREE,
  [SPACE_PLAN_MINI_PRODUCT_ID]: SUBSCRIPTION_PLAN.MINI,
  [SPACE_PLAN_PRO_PRODUCT_ID]: SUBSCRIPTION_PLAN.PRO,
};

export const SPACE_SUBSCRIPTIONS_PRICE = {
  [SUBSCRIPTION_PLAN.MINI]: {
    annual: SPACE_PLAN_MINI_ANNUAL_PRICE,
    monthly: SPACE_PLAN_MINI_MONTHLY_PRICE,
  },
  [SUBSCRIPTION_PLAN.LAUNCH]: {
    annual: SPACE_PLAN_LAUNCH_ANNUAL_PRICE,
    monthly: SPACE_PLAN_LAUNCH_MONTHLY_PRICE,
  },
  [SUBSCRIPTION_PLAN.FREE]: {
    annual: 0,
    monthly: 0,
  },
  [SUBSCRIPTION_PLAN.PRO]: {
    annual: SPACE_PLAN_PRO_ANNUAL_PRICE,
    monthly: SPACE_PLAN_PRO_MONTHLY_PRICE,
  },
};
export const SPACE_PERMISSIONS = {
  [SPACE_ACTION.InviteMember]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.RemoveMember]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ViewMembers]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.TransferOwnership]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdatePermissions]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateSettings]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateCustomDomain]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateSubdomain]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ReadSettings]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: true,
  },
  [SPACE_ACTION.ReadBilling]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateBilling]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.DeleteSpace]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.CreatePage]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.EditContent]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ReadDraft]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ReadPublishedContent]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: true,
  },
  [SPACE_ACTION.ReadPageInfo]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: true,
  },
};

export const SUBSCRIPTION_PLAN_NAME = {
  [SUBSCRIPTION_PLAN.FREE]: 'Free',
  [SUBSCRIPTION_PLAN.MINI]: 'Mini',
  [SUBSCRIPTION_PLAN.LAUNCH]: 'Launch',
  [SUBSCRIPTION_PLAN.PRO]: 'Pro',
};
export const DEFAULT_META_DESCRIPTION = 'A website made with Typedream.';
export const MINI_PLAN_BENEFITS = [
  'Email Collection (Link in Bio)',
  'Analytics for Link in Bio',
];
export const LAUNCH_PLAN_BENEFITS = [
  'Everything in Mini',
  'Unlimited Pages',
  'Add a Custom Domain',
  'Invite Collaborators',
  'Search Engine Optimization',
  'Code Injection',
];
export const PRO_PLAN_BENEFITS = [
  'Everything in Launch',
  '5,000 Collection Items (CMS)',
  '5,000 Form Submissions',
  'Connect Notion Database for CMS',
  'Unlimited Protected Pages (Locks)',
];
export const PRODUCT_HUNT_SOURCE = 'product_hunt';
export const ALLOW_PROMO_CODE_QUERY = 'allowPromoCode';

export const DEFAULT_COLLECTION_DATA_ID = 'id';
export const COLLECTION_CONTENT_COLUMN_NAME = 'TD:page_content';
export const COLLECTION_COLUMN_NAME_PREFIX = 'TD:';
export const COLLECTION_SLUG_COLUMN_NAME = 'TD:slug';
export const COLLECTION_PUBLISHED_CONTENT_COLUMN_NAME = 'TD:page_content_published';
export const COLLECTION_CONNECT_ITEM_PAGE_URL = 'ITEM_PAGE_URL';

export const ITEM_PAGE_DEFAULT_FIELDS = {
  METADATA: {
    fieldName: `${LOGO_NAME_ABBREVIATED}:metadata`,
    label: 'Metadata',
    description: 'For SEO Settings, Twitter cards, etc.',
  },
  SLUG: {
    fieldName: `${LOGO_NAME_ABBREVIATED}:slug`,
    label: 'Slug',
    description: 'To specify the URL for each item',
  },
  PUBLISHED_CONTENT: {
    fieldName: COLLECTION_PUBLISHED_CONTENT_COLUMN_NAME,
    label: 'Page Content',
    description: 'To store your page\'s published rich text content',
  },
  CONTENT: {
    fieldName: COLLECTION_CONTENT_COLUMN_NAME,
    label: 'Page Content Draft',
    description: 'To store your page\'s draft rich text content',
  },
};
export const RICH_TEXT_CONTENT_LABEL = 'rich text content';
export const BANNER_HEIGHT = 40;

export const OAUTH_CALLBACK_MESSAGE = 'oauth_callback_message';

// features flags
export const ENABLE_WEB3 = process.env.NEXT_PUBLIC_ENABLE_WEB3?.toLowerCase() === 'true';
export const ENABLE_ROOT_ELEMENT_SETTINGS = process.env.NEXT_PUBLIC_ENABLE_ROOT_ELEMENT_SETTINGS?.toLowerCase() === 'true';
export const ENABLE_PRODUCT = process.env.NEXT_PUBLIC_ENABLE_PRODUCT?.toLowerCase() === 'true';
export const ENABLE_STABLE_DIFF_GENERATOR = process.env.NEXT_PUBLIC_ENABLE_STABLE_DIFF_GENERATOR?.toLowerCase() === 'true';
export const ENABLE_COLLECTION_ITEM_PAGE_DREAM_EDITOR = process.env.NEXT_PUBLIC_ENABLE_COLLECTION_ITEM_PAGE_DREAM_EDITOR?.toLowerCase() === 'true';
export const ENABLE_AI_CONTENT_GENERATOR = process.env.NEXT_PUBLIC_ENABLE_AI_CONTENT_GENERATOR?.toLowerCase() === 'true';
export const ENABLE_LINK_IN_BIO = process.env.NEXT_PUBLIC_ENABLE_LINK_IN_BIO?.toLowerCase() === 'true';
export const ENABLE_ONDEMAND_ISR = process.env.NEXT_PUBLIC_ENABLE_ONDEMAND_ISR?.toLowerCase() === 'true';
export const ENABLE_DREAM_EDITOR = process.env.NEXT_PUBLIC_ENABLE_DREAM_EDITOR?.toLowerCase() === 'true';
export const ENABLE_NEW_WORKSPACE = process.env.NEXT_PUBLIC_ENABLE_NEW_WORKSPACE?.toLowerCase() === 'true';
export const ENABLE_NEW_DASHBOARD = process.env.NEXT_PUBLIC_ENABLE_NEW_DASHBOARD?.toLowerCase() === 'true';

export const COLLECTION_START_FROM_SCRATCH = 'scratch';
export const PRODUCT_DESCRIPTION_PLACEHOLDER = 'Downloadable digital files or product';
export const PRODUCT_NAME_PLACEHOLDER = 'Product Name';

export const EMPTY_SLUG_MESSAGE = 'This item contains empty slug';
export const DUPLICATE_SLUG_MESSAGE = 'This item has the same slug with';

export const MAX_UNDO_STEPS = 20;

// Product Categories and Filter
export const PRODUCT_PAGE = 'page';
export const PRODUCT_MULTIPLE_PAGES = 'multiple_pages';
export const PRODUCT_FORM_PAGE = 'form';
export const PRODUCT_BLOG_PAGE = 'blog_page';
export const PRODUCT_LINK_IN_BIO = 'link_in_bio';
export const PRODUCT_PRODUCT = 'product';
export const PRODUCT_TEMPLATE = 'template';

// TODO: Move unnecessary constant
export const CATEGORIES_PAGE = [
  {
    label: 'All styles',
    key: 'all',
  },
  {
    label: 'Dark mode',
    key: 'dark-mode',
  },
  {
    label: 'Gradients',
    key: 'gradients',
  },
  {
    label: 'Glassmorphism',
    key: 'glassmorphism',
  },
  {
    label: 'Monochrome',
    key: 'monochrome',
  },
  {
    label: '3D',
    key: '3d',
  },
  {
    label: 'Luxury',
    key: 'luxury',
  },
  {
    label: 'Notion-themed',
    key: 'notion-themed',
  },
  {
    label: 'Clean',
    key: 'clean',
  },
];
export const CATEGORIES_FORM_PAGE = [
  {
    label: 'All types',
    key: 'all',
  },
  {
    label: 'Feedback',
    key: 'feedback',
  },
  {
    label: 'Research',
    key: 'research',
  },
  {
    label: 'Lead capture',
    key: 'lead-capture',
  },
  {
    label: 'Application',
    key: 'application',
  },
  {
    label: 'Poll',
    key: 'poll',
  },
  {
    label: 'Booking',
    key: 'booking',
  },
  {
    label: 'Order',
    key: 'order',
  },
  {
    label: 'Survey',
    key: 'survey',
  },
];
export const CATEGORIES_BLOG_PAGE = [
  {
    label: 'All styles',
    key: 'all',
  },
  {
    label: 'Dark mode',
    key: 'dark-mode',
  },
  {
    label: 'Gradients',
    key: 'gradients',
  },
  {
    label: 'Glassmorphism',
    key: 'glassmorphism',
  },
  {
    label: 'Monochrome',
    key: 'monochrome',
  },
  {
    label: '3D',
    key: '3d',
  },
  {
    label: 'Luxury',
    key: 'luxury',
  },
  {
    label: 'Notion-themed',
    key: 'notion-themed',
  },
  {
    label: 'Clean',
    key: 'clean',
  },
];

export const FILTER_PAGE = [
  {
    label: 'All categories',
    key: 'all',
  },
  {
    label: 'Web 3',
    key: 'web3',
  },
  {
    label: 'Shop',
    key: 'shop',
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
  },
  {
    label: 'Company OS',
    key: 'company-os',
  },
  {
    label: 'Community',
    key: 'community',
  },
  {
    label: 'CMS',
    key: 'cms',
  },
  {
    label: 'Notion-themed',
    key: 'notion-themed',
  },
];
export const FILTER_FORM_PAGE = [
  {
    label: 'All categories',
    key: 'all',
  },
  {
    label: 'Creators',
    key: 'creators',
  },
  {
    label: 'Product',
    key: 'product',
  },
  {
    label: 'Marketing',
    key: 'marketing',
  },
  {
    label: 'HR',
    key: 'hr',
  },
  {
    label: 'Office',
    key: 'office',
  },
  {
    label: 'Personal',
    key: 'personal',
  },
];
export const FILTER_BLOG_PAGE = [
  {
    label: 'All categories',
    key: 'all',
  },
  {
    label: 'Web 3',
    key: 'web3',
  },
  {
    label: 'Shop',
    key: 'shop',
  },
  {
    label: 'Portfolio',
    key: 'portfolio',
  },
  {
    label: 'Company OS',
    key: 'company-os',
  },
  {
    label: 'Community',
    key: 'community',
  },
  {
    label: 'CMS',
    key: 'cms',
  },
  {
    label: 'Notion-themed',
    key: 'notion-themed',
  },
];

export const EMPTY_PAGE_CONTENT: DreamValue = [
  {
    children: [
      {
        children: [
          {
            id: '47a6cc0e-774c-4873-90e6-2b68632ead62',
            text: '',
          },
        ],
        height: 70,
        id: 'a7ee8e8b-1c1e-4f6b-a522-b616ba724a8d',
        type: 'spacer',
      },
      {
        children: [
          {
            id: 'c5d5d43b-625a-4b40-b91d-a9c64eb82433',
            text: 'Welcome to Typedream',
          },
        ],
        id: '843f16ed-a1f1-4237-a7bf-befbe34b4796',
        type: 'h1',
      },
      {
        children: [
          {
            color: 'gray',
            gradient: false,
            id: '6f852c69-3aa3-4294-9e59-95572d1deb2d',
            styled: true,
            text: "Start typing and build your stunning website! We're here to help, you can chat us by clicking the \"Chat with us!\" button on the left side bar.",
          },
        ],
        id: 'c7dc76e4-0f9a-4379-ab4a-d086e7302eac',
        type: 'p',
      },
      {
        children: [
          {
            color: 'gray',
            gradient: false,
            id: '9bd39351-6d99-4949-a46c-a19bf17a2f33',
            styled: true,
            text: '',
          },
        ],
        id: '6793efec-2fc8-4dd7-8b06-4cb2bbe40941',
        type: 'p',
      },
      {
        background: 'indigo',
        children: [
          {
            bold: true,
            id: '00397d91-be6b-4398-9327-7897c4ab90a7',
            text: 'See the guide →',
          },
        ],
        color: 'text_on_accent',
        id: '4e487481-311f-46dd-be2f-4d1e2a65825d',
        internal_link: false,
        openOptions: false,
        type: 'button',
        url: 'https://typedream.com/templates/getting-started',
      },
      {
        children: [
          {
            id: 'a2fa578b-428d-4653-b843-7e2d0407a146',
            text: '',
          },
        ],
        id: 'fb3a491d-5dd0-4e99-9736-72306a426b9a',
        type: 'p',
      },
    ],
    id: '0560d11e-542b-41d2-8262-907099bc2f9e',
    type: 'root',
  },
];

export const EMPTY_FORM_PAGE_CONTENT = (formID: string): DreamValue => [
  {
    children: [
      {
        children: [
          {
            id: '47a6cc0e-774c-4873-90e6-2b68632ead62',
            text: '',
          },
        ],
        height: 70,
        id: 'a7ee8e8b-1c1e-4f6b-a522-b616ba724a8d',
        type: 'spacer',
      },
      {
        children: [
          {
            id: 'c5d5d43b-625a-4b40-b91d-a9c64eb82433',
            text: 'Welcome to Typedream',
          },
        ],
        id: '843f16ed-a1f1-4237-a7bf-befbe34b4796',
        type: 'h1',
      },
      {
        children: [
          {
            color: 'gray',
            gradient: false,
            id: '6f852c69-3aa3-4294-9e59-95572d1deb2d',
            styled: true,
            text: "Start typing and build your stunning website! We're here to help, you can chat us by clicking the \"Chat with us!\" button on the left side bar.",
          },
        ],
        id: 'c7dc76e4-0f9a-4379-ab4a-d086e7302eac',
        type: 'p',
      },
      {
        children: [
          {
            color: 'gray',
            gradient: false,
            id: '9bd39351-6d99-4949-a46c-a19bf17a2f33',
            styled: true,
            text: '',
          },
        ],
        id: '6793efec-2fc8-4dd7-8b06-4cb2bbe40941',
        type: 'p',
      },
      {
        background: 'indigo',
        children: [
          {
            bold: true,
            id: '00397d91-be6b-4398-9327-7897c4ab90a7',
            text: 'See the guide →',
          },
        ],
        color: 'text_on_accent',
        id: '4e487481-311f-46dd-be2f-4d1e2a65825d',
        internal_link: false,
        openOptions: false,
        type: 'button',
        url: 'https://typedream.com/templates/getting-started',
      },
      {
        children: [
          {
            id: 'a2fa578b-428d-4653-b843-7e2d0407a146',
            text: '',
          },
        ],
        id: 'fb3a491d-5dd0-4e99-9736-72306a426b9a',
        type: 'p',
      },
      {
        children: [
          {
            id: 'c5d5d43b-625a-4b40-b91d-a9c64eb82433',
            text: 'You can also insert your form by typing ',
            styled: true,
            fontStyle: 'paragraph',
            fontSize: 16,
            color: 'gray',
            gradient: false,
          },
          {
            id: 'c5d5d43b-625a-4b40-b91d-a9c64eb82433',
            styled: true,
            fontStyle: 'paragraph',
            fontSize: 16,
            color: 'gray',
            gradient: false,
            text: '/form',
            code: true,
          },
          {
            id: 'c5d5d43b-625a-4b40-b91d-a9c64eb82433',
            styled: true,
            fontStyle: 'paragraph',
            fontSize: 16,
            color: 'gray',
            gradient: false,
            text: ', like this 👇',
          },
        ],
        id: '843f16ed-a1f1-4237-a7bf-befbe34b4796',
        type: 'p',
      },
      {
        id: 'c042421b-e5b3-4e3f-ba0c-eda3b17752fa',
        type: 'form',
        children: [
          {
            text: '',
          },
        ],
        openOptions: false,
        form_id: formID,
      },
      {
        children: [
          {
            id: 'a2fa578b-428d-4653-b843-7e2d0407a146',
            text: '',
          },
        ],
        id: 'fb3a491d-5dd0-4e99-9736-72306a426b9a',
        type: 'p',
      },
    ],
    id: '0560d11e-542b-41d2-8262-907099bc2f9e',
    type: 'root',
  },
];

export const EMPTY_BLOG_PAGE_CONTENT: DreamValue = [
  {
    children: [
      {
        children: [
          {
            id: '47a6cc0e-774c-4873-90e6-2b68632ead62',
            text: '',
          },
        ],
        height: 70,
        id: 'a7ee8e8b-1c1e-4f6b-a522-b616ba724a8d',
        type: 'spacer',
      },
      {
        children: [
          {
            id: 'c5d5d43b-625a-4b40-b91d-a9c64eb82433',
            text: "Let's build a blog",
          },
        ],
        id: '843f16ed-a1f1-4237-a7bf-befbe34b4796',
        type: 'h1',
      },
      {
        children: [
          {
            text: 'Let’s get your first blog post out! ',
            color: 'gray',
            fontSize: 20,
            fontStyle: 'subtitle2',
            gradient: false,
            lineHeight: 1.4,
            styled: true,
          },
        ],
        id: '70e773f5-c9c5-4b5b-82eb-ad71f3b1466a',
        type: 'p',
      },
      {
        children: [
          {
            children: [
              {
                children: [
                  {
                    color: 'gray',
                    fontSize: 20,
                    fontStyle: 'subtitle2',
                    gradient: false,
                    lineHeight: 1.4,
                    styled: true,
                    text: 'Make sure you have your ',
                  },
                  {
                    bold: true,
                    color: 'default',
                    fontSize: 20,
                    fontStyle: 'subtitle2',
                    gradient: false,
                    lineHeight: 1.4,
                    styled: true,
                    text: 'Notion database ready',
                  },
                  {
                    color: 'gray',
                    fontSize: 20,
                    fontStyle: 'subtitle2',
                    gradient: false,
                    lineHeight: 1.4,
                    styled: true,
                    text: '. ',
                  },
                ],
                id: 'ae95ec05-ccad-4029-895a-555b2e934737',
                type: 'lic',
              },
            ],
            id: 'b0510481-2541-4a1a-8527-0294997fc0a3',
            type: 'li',
          },
          {
            children: [
              {
                children: [
                  {
                    color: 'gray',
                    fontSize: 20,
                    fontStyle: 'subtitle2',
                    gradient: false,
                    lineHeight: 1.4,
                    styled: true,
                    text: 'Then, click on ',
                  },
                  {
                    bold: true,
                    color: 'default',
                    fontSize: 20,
                    fontStyle: 'subtitle2',
                    gradient: false,
                    lineHeight: 1.4,
                    styled: true,
                    text: 'Select Collection Template',
                  },
                  {
                    color: 'gray',
                    fontSize: 20,
                    fontStyle: 'subtitle2',
                    gradient: false,
                    lineHeight: 1.4,
                    styled: true,
                    text: ' below to start 👇 ',
                  },
                ],
                id: 'a83e3741-5778-4db0-83a6-5e4c3eb83bf0',
                type: 'lic',
              },
            ],
            id: 'b2379cf5-737e-4126-9b70-00c5298e66ff',
            type: 'li',
          },
        ],
        id: '6cb71016-a6b8-431a-b978-22c8a0824490',
        type: 'ol',
      },
      {
        children: [
          {
            id: 'a2fa578b-428d-4653-b843-7e2d0407a146',
            text: '',
          },
        ],
        id: 'fb3a491d-5dd0-4e99-9736-72306a426b9a',
        type: 'p',
      },
      {
        children: [
          {
            id: '8b5dd280-4b3b-47e6-885d-315fa6148c80',
            text: '',
          },
        ],
        id: '7ff68e05-b376-4d34-965f-deb2db0e9134',
        openOptions: false,
        type: 'collection',
      },
    ],
    id: '0560d11e-542b-41d2-8262-907099bc2f9e',
    type: 'root',
  },
];

export const EMPTY_LINK_IN_BIO_PAGE_CONTENT: DreamValue = [
  {
    children: [
      {
        children: [
          {
            text: '',
          },
        ],
        id: 'daf13260-b481-45b3-b949-3a53354eb7d1',
        image: 'https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/TemplateAssets%2FJoelProfilePic.png',
        subtitle: 'I’m a carpenter & zombie survivor',
        title: 'Joel Miller',
        type: 'profile',
      },
      {
        children: [
          {
            backgroundVideo: 'https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/TemplateAssets%2FStockVideos%2Fchicken-thumbnail.png',
            buttonText: 'Survival Recipe ->',
            children: [
              {
                text: '',
              },
            ],
            gridProps: {
              h: 1,
              w: 1,
              x: 0,
              y: 0,
            },
            id: 'daf1f80-b481-45b3-b949-3a53354eb7d1',
            title: 'I’m a carpenter & zombie survivor',
            type: 'card_background',
          },
          {
            backgroundImage: 'https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/TemplateAssets%2Fmap-background.png',
            buttonText: 'Marked Locations ->',
            children: [
              {
                text: '',
              },
            ],
            gridProps: {
              h: 1,
              w: 2,
              x: 1,
              y: 0,
            },
            id: 'daf1f81-b481-45b3-b949-3a53354eb7d1',
            title: 'Avoid the Infected',
            type: 'card_background',
          },
          {
            backgroundVideo: 'https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/TemplateAssets%2FStockVideos%2FCup%20Of%20Coffee.mp4',
            buttonText: 'Make a deal ->',
            children: [
              {
                text: '',
              },
            ],
            gridProps: {
              h: 1,
              w: 2,
              x: 0,
              y: 1,
            },
            id: 'daf1f82-b481-45b3-b949-3a53354eb7d1',
            title: 'Where to find coffee',
            type: 'card_background',
          },
          {
            backgroundImage: 'https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/TemplateAssets%2Fforest.png',
            buttonText: 'Find me ->',
            children: [
              {
                text: '',
              },
            ],
            gridProps: {
              h: 1,
              w: 1,
              x: 2,
              y: 1,
            },
            id: 'daf1f83-b481-45b3-b949-3a53354eb7d1',
            title: 'Need help?',
            type: 'card_background',
          },
        ],
        cols: 3,
        id: 'da9fg80-b481-45b3-b949-3a53354eb7d1',
        type: 'grid',
      },
    ],
    id: 'c2f7e6e7-1c62-4dd7-b601-613215d15804',
    type: 'root',
    vertical_align: 'align_start',
    vertical_snap_scroll: true,
  },
];
