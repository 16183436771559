import { makeApiSliceCreator as i } from "@typedream/zustand-query";
import { getSharedLink as r, createSite as a } from "./actions.js";
const o = i("analyticsApi", (e) => ({
  endpoints: (t) => ({
    getSharedLink: t.query(r(e)),
    createSite: t.mutation(a(e), {
      onSuccess: () => {
        e.getState().subscriptionApi.invalidateQueries("getSubscription");
      }
    })
  })
}));
export {
  o as createAnalyticsApiSlice
};
