import { SPACE_ACTION as h, SPACE_ROLE as O } from "../../types.js";
import { sanitizeSpace as k } from "../../utils/sanitizeSpace.js";
import { validFQDN as S } from "../../utils/validFQDN.js";
import { API as d } from "../../api/api.js";
const U = () => async () => {
  var o;
  const t = await d.getOrCreateInstance().space().list();
  return Object.fromEntries((o = t == null ? void 0 : t.data) == null ? void 0 : o.map((a) => [a.id, k(a)]));
}, A = (t) => async (e) => {
  if (!e)
    return;
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: n } } = o(), s = await d.getOrCreateInstance().space().get(e, a, n);
  return k(s == null ? void 0 : s.data);
}, N = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: a } = o(), { spacePermissionTokenHandler: n } = a;
  return !e || !n ? [] : await d.getOrCreateInstance().spacePermission().listMembers(e, n);
}, L = () => async (t) => {
  if (!t.name && !t.random_name)
    throw new Error("Site Name cannot be empty");
  if (!t.slug && !t.random_name)
    throw new Error("Site slug cannot be empty");
  const e = await d.getOrCreateInstance().space().create(t);
  return k(e == null ? void 0 : e.data);
}, M = () => async (t) => {
  if (!t.name && !t.random_name)
    throw new Error("Site Name cannot be empty");
  if (!t.slug && !t.random_name)
    throw new Error("Site slug cannot be empty");
  const e = await d.getOrCreateInstance().space().createNoPages(t);
  return k(e == null ? void 0 : e.data);
}, z = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = o();
  await d.getOrCreateInstance().space().delete(e, a);
}, $ = (t) => {
  let e, o;
  return async (a) => {
    const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: c }, spacesApi: r } = n(), { spaceID: m, newNavbar: i, force: u = !1 } = a, p = r.endpoints.getSpace.get(m).data, l = p == null ? void 0 : p.updated_at;
    if (l === e && i === o && !u)
      return;
    e = l, o = i;
    const { data: g } = await d.getOrCreateInstance().space().updateNavbar(m, i, l, u, s, c, () => Promise.resolve());
    return g;
  };
}, q = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: n } } = o(), { spaceID: s, routes: c } = e;
  await d.getOrCreateInstance().space().updateRoutes(s, c, a, n);
}, F = (t) => async (e) => {
  var I;
  const { getState: o } = t, { spaceID: a, customization: n, allPublic: s, pageIDs: c, currentPageOnly: r, subscriptionPlanMap: m, typedreamURL: i, customDomain: u } = e, { tokenHandlers: { spacePermissionTokenHandler: p, subscriptionTokenHandler: l } } = o(), g = d.getOrCreateInstance(), w = await l.getSubscription(a), P = [i.includes("localhost") ? `http://${i.split("/")[0]}` : `https://${i.split("/")[0]}`];
  u && P.push(`https://${u}`);
  let f;
  const C = !!(w != null && w.isAllowed(h.PublishUnlimitedPages, m)), H = r;
  if (H) {
    const b = C;
    f = await g.space().publishByPageIDs(a, { customization: n }, c, s, b, p, l);
  } else
    f = await g.space().publish(a, { customization: n }, C && s, p, l);
  const D = (I = f.data.pages) == null ? void 0 : I.map((b) => b.id), T = await g.revalidate().revalidate({
    spaceID: a,
    pageIDs: H ? c : D,
    allPages: !H,
    revalidateEndpoints: P
  }, p);
  return {
    space: f.data.space,
    revalidate_status: T.data.revalidate_status
  };
}, j = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: n } } = o(), { spaceID: s, customizationUpdateRequests: c } = e, { data: r } = await d.getOrCreateInstance().space().updateCustomizationPartial(s, c, a, n);
  return r;
}, B = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: n } } = o(), s = d.getOrCreateInstance(), { spaceID: c, name: r, slug: m, iconfile: i, originalIconURL: u } = e, p = { name: r, slug: m, icon: u };
  if (i) {
    const g = (await s.document().upload(i, c, a, n)).data.file_name, w = s.document().getURL(g);
    p.icon = w;
  }
  await s.space().updateSettings(c, p, a, n);
}, x = (t) => async (e) => {
  const { getState: o } = t, { spaceID: a, metadata: n, twitterImage: s, facebookImage: c } = e, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: m } } = o(), i = d.getOrCreateInstance(), u = { ...n };
  if (s) {
    const l = (await i.document().upload(s, a, r, m)).data.file_name, g = i.document().getURL(l);
    u.twitter.image = g;
  }
  if (c) {
    const l = (await i.document().upload(c, a, r, m)).data.file_name, g = i.document().getURL(l);
    u.facebook.image = g;
  }
  await i.space().updateMetadata(a, u, r, m);
}, Q = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: n } } = o(), { spaceID: s, newMember: c } = e;
  if (!c.email)
    throw new Error("Email cannot be empty");
  if (!c.role || !Object.values(O).includes(c.role))
    throw new Error("Invalid role");
  if (!c.space_id || c.space_id !== s)
    throw new Error("Invalid space id");
  await d.getOrCreateInstance().spacePermission().addMember(s, c, a, n);
}, G = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = o(), { spaceID: n, userID: s } = e;
  if (!s || !n)
    throw new Error("Space ID or User ID is not provided to remove this collaborator");
  await d.getOrCreateInstance().spacePermission().removeMember(n, s, a);
}, J = (t) => async (e) => {
  const { getState: o } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = o(), { spaceID: n, newOwner: s } = e;
  await d.getOrCreateInstance().spacePermission().transfer(n, s, a);
}, K = (t) => async (e) => {
  const { getState: o } = t, { spaceID: a } = e, n = e.customDomain.trim(), s = e.redirectCustomDomain ? e.redirectCustomDomain.trim() : void 0, { tokenHandlers: { spacePermissionTokenHandler: c, subscriptionTokenHandler: r } } = o();
  if (!n)
    throw new Error("Custom domain cannot be empty");
  if (!S(n))
    throw new Error(`The specified custom domain "${n}" is not a fully qualified domain name.`);
  if (s && !S(s))
    throw new Error(`The specified custom domain "${s}" is not a fully qualified domain name.`);
  await d.getOrCreateInstance().space().updateCustomDomain(a, n, s || "", c, r);
}, V = (t) => async (e) => {
  const { getState: o } = t, { spaceID: a } = e, n = e.redirectCustomDomain.trim(), { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: c } } = o();
  if (!n)
    throw new Error("Custom domain cannot be empty");
  if (!S(n))
    throw new Error(`The specified custom domain "${n}" is not a fully qualified domain name.`);
  await d.getOrCreateInstance().space().updateCustomDomainRedirect(a, n, s, c);
}, W = (t) => async (e) => {
  var r, m;
  const { getState: o } = t, { spacesApi: a, tokenHandlers: { spacePermissionTokenHandler: n, subscriptionTokenHandler: s } } = o(), c = (m = (r = a.endpoints.getSpace.get(e)) == null ? void 0 : r.data) == null ? void 0 : m.custom_domain;
  if (!c)
    throw new Error("Custom domain cannot be empty");
  await d.getOrCreateInstance().space().deleteCustomDomain(e, c, n, s);
}, X = (t) => async (e) => {
  var r, m, i;
  const { getState: o } = t, { spacesApi: a, tokenHandlers: { spacePermissionTokenHandler: n, subscriptionTokenHandler: s } } = o(), c = (i = (m = (r = a.endpoints.getSpace.get(e)) == null ? void 0 : r.data) == null ? void 0 : m.customization) == null ? void 0 : i.redirect_custom_domain;
  if (!c)
    throw new Error("Custom domain cannot be empty");
  await d.getOrCreateInstance().space().deleteCustomDomainRedirect(e, c, n, s);
};
export {
  Q as addSpacePermission,
  L as createSpace,
  M as createSpaceNoPages,
  W as deleteCustomDomain,
  X as deleteCustomDomainRedirect,
  z as deleteSpace,
  A as getSpace,
  N as getSpacePermissions,
  U as listSpaces,
  F as publish,
  G as removeSpacePermission,
  $ as saveNavbar,
  J as transferSpacePermission,
  K as updateCustomDomain,
  V as updateCustomDomainRedirect,
  q as updateRoutes,
  j as updateSpaceCustomizationPartial,
  x as updateSpaceMetadata,
  B as updateSpaceSettings
};
