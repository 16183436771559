import { API as l } from "../../api/api.js";
const m = (n) => async (t, e, s, r) => {
  if (!t)
    return;
  const { getState: a } = n, { tokenHandlers: o } = a(), { spacePermissionTokenHandler: c, subscriptionTokenHandler: i } = o;
  return (await l.getOrCreateInstance().payment().list(r, t, e, s, c, i)).data;
}, u = (n) => async (t, e, s) => {
  if (!t)
    return;
  const { getState: r } = n, { tokenHandlers: a } = r(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: c } = a;
  return (await l.getOrCreateInstance().payment().listAll(t, e, s, o, c)).data;
}, k = (n) => async (t, e, s) => {
  if (!t)
    return;
  const { getState: r } = n, { tokenHandlers: a } = r(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: c } = a;
  return (await l.getOrCreateInstance().payment().count(t, e, s, o, c)).data;
};
export {
  k as countPayments,
  u as listAllPayments,
  m as listPayments
};
