import React, { useMemo } from 'react';
import TokenContext from './tokenContext';
import { useStore } from '../../store';

// TODO: Remove TokenContext and TokenProvider

/**
 * ```jsx
 * <TokenProvider>
 *   <MyApp />
 * </TokenProvider>
 * ```
 *
 * Provides the TokenContext to its child components.
 */
function TokenProvider({ children }: { children: any }) {
  const spacePermissionTokenHandler = useStore(
    (state) => state.tokenHandlers.spacePermissionTokenHandler,
  );
  const subscriptionTokenHandler = useStore(
    (state) => state.tokenHandlers.subscriptionTokenHandler,
  );

  return (
    <TokenContext.Provider
      value={useMemo(
        () => ({ spacePermissionTokenHandler, subscriptionTokenHandler }),
        [spacePermissionTokenHandler, subscriptionTokenHandler],
      )}
    >
      {children}
    </TokenContext.Provider>
  );
}

export default TokenProvider;
