var h = Object.defineProperty;
var f = (p, t, i) => t in p ? h(p, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : p[t] = i;
var w = (p, t, i) => (f(p, typeof t != "symbol" ? t + "" : t, i), i);
import { getSpacePermissionConfig as e } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as b } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as r } from "../EditorAPI.js";
class S {
  constructor(t) {
    w(this, "api");
    this.api = t;
  }
  async get(t, i) {
    const o = await e(t, i), a = `/subscription/${t}`;
    try {
      const c = await new r(this.api).get(a, !0, o);
      return c == null ? void 0 : c.data;
    } catch (s) {
      throw s.response.data;
    }
  }
  async createSubscription(t, i, o) {
    const a = await e(t, o), s = `/subscription/create_subscription/${t}`, n = await new r(this.api).post(s, i, !0, a);
    return n == null ? void 0 : n.data;
  }
  async updateSubscription(t, i, o) {
    const a = await e(t, o), s = `/subscription/update_subscription/${t}`, n = await new r(this.api).put(s, i, !0, a);
    return n == null ? void 0 : n.data;
  }
  async cancelSubscription(t, i, o) {
    const a = await e(t, o), s = `/subscription/cancel_subscription/${t}`, n = await new r(this.api).put(s, i, !0, a);
    return n == null ? void 0 : n.data;
  }
  async checkPromoCode(t) {
    const i = `/subscription/promo_code/${t}`, a = await new r(this.api).get(i, !1);
    return a == null ? void 0 : a.data;
  }
  async getStripePortal(t, i, o) {
    const a = await e(t, o), s = `/subscription/manage/${t}`, c = {
      return_url: i
    }, u = await new r(this.api).post(s, c, !0, a);
    return u == null ? void 0 : u.data;
  }
  async updateAffiliate(t, i, o, a) {
    const s = await b(t, o, a), c = {
      affiliate: i
    }, n = `/subscription/affiliate/${t}`;
    return await new r(this.api).put(n, c, !0, s);
  }
}
export {
  S as SubscriptionAPI
};
