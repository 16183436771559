import { API as c } from "../../api/api.js";
const k = (e) => async (t) => {
  const { getState: n } = e, { tokenHandlers: { subscriptionTokenHandler: a, spacePermissionTokenHandler: s } } = n(), { spaceID: r, timezone: o, is_slug: i, typedream_domain: d } = t;
  return (await c.getOrCreateInstance().analytics().createSite(r, {
    timezone: o,
    is_slug: i,
    typedream_domain: d
  }, s, a)).data;
}, g = (e) => async (t, n, a) => {
  const { getState: s } = e, { tokenHandlers: { spacePermissionTokenHandler: r } } = s();
  return {
    shared_link: (await c.getOrCreateInstance().analytics().getSharedLink(t, n, a, r)).data.url
  };
};
export {
  k as createSite,
  g as getSharedLink
};
