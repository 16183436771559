import { getPageIDToPath } from '@typedream/data';
import { createSelector } from 'reselect';
import { useStore } from '.';

export const selectPagesPaths = (spaceID: string) => createSelector(
  useStore.getState().spacesApi.endpoints.getSpace.select(spaceID),
  useStore.getState().pagesApi.endpoints.listPages.select(spaceID),
  (space, pages) => {
    if (pages?.data && space?.data?.routes) {
      return getPageIDToPath(space.data.routes, [], pages.data);
    }
    return {};
  },
);
