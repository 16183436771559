var N = Object.defineProperty;
var g = (d, t, o) => t in d ? N(d, t, { enumerable: !0, configurable: !0, writable: !0, value: o }) : d[t] = o;
var O = (d, t, o) => (g(d, typeof t != "symbol" ? t + "" : t, o), o);
import { generateSlug as T } from "random-word-slugs";
import { EditorAPI as u } from "../../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as h } from "../../../utils/getPermissionAndSubscriptionConfig.js";
import { ICollectionIntegration as l } from "../../../types.js";
import { COLLECTION_SLUG_COLUMN_NAME as f } from "../../../constants.js";
class I {
  constructor(t) {
    O(this, "api");
    this.api = t;
  }
  async listTables(t, o, s) {
    const i = await h(t, o, s), a = {
      url: "https://api.notion.com/v1/search",
      method: "POST",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      },
      body: {
        filter: {
          value: "database",
          property: "object"
        }
      }
    }, e = `/app/proxy/${t}/${l.NOTION}`, n = new u(this.api);
    return n.logoutOnUnauthorized = !1, await n.post(e, a, !0, i);
  }
  async getTable(t, o, s, i) {
    const a = await h(t, s, i), e = {
      url: `https://api.notion.com/v1/databases/${o}`,
      method: "GET",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      }
    }, n = `/app/proxy/${t}/${l.NOTION}`, p = new u(this.api);
    return p.logoutOnUnauthorized = !1, await p.post(n, e, !0, a);
  }
  getDataQuery(t, o, s, i, a, e) {
    const n = `/app/proxy/${t}/${l.NOTION}`, p = {
      url: `https://api.notion.com/v1/databases/${o}/query`,
      method: "POST",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      },
      body: {
        filter: s,
        sorts: i,
        start_cursor: a || void 0,
        page_size: e || void 0
      }
    };
    return { path: n, data: p, app: l.NOTION };
  }
  async callQuery(t, o, s, i, a) {
    const e = await h(s, i, a), n = new u(this.api);
    return n.logoutOnUnauthorized = !1, await n.post(t, o, !0, e);
  }
  async getDataByID(t, o, s, i) {
    const a = await h(t, s, i), e = {
      url: `https://api.notion.com/v1/pages/${o}`,
      method: "GET",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      }
    }, n = `/app/proxy/${t}/${l.NOTION}`, p = new u(this.api);
    return p.logoutOnUnauthorized = !1, await p.post(n, e, !0, a);
  }
  async getBlockChildrenById(t, o, s, i, a) {
    const e = await h(t, s, i);
    let n = `https://api.notion.com/v1/blocks/${o}/children`;
    a && (n = `${n}?start_cursor=${a}`);
    const p = {
      url: n,
      method: "GET",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      }
    }, c = `/app/proxy/${t}/${l.NOTION}`, r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.post(c, p, !0, e);
  }
  async addColumnNames(t, o, s, i, a) {
    const e = await h(t, i, a), n = {};
    s.forEach((y) => {
      n[y.name] = {
        [y.type]: {}
      };
    });
    const p = {
      url: `https://api.notion.com/v1/databases/${o}`,
      method: "PATCH",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      },
      body: {
        properties: n
      }
    }, c = `/app/proxy/${t}/${l.NOTION}`, r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.post(c, p, !0, e);
  }
  async updateItem(t, o, s, i, a) {
    const e = await h(t, i, a), n = {};
    s.forEach((y) => {
      n[y.column.name] = {
        [y.column.type]: y.value
      };
    });
    const p = {
      url: `https://api.notion.com/v1/pages/${o}`,
      method: "PATCH",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      },
      body: {
        properties: n
      }
    }, c = `/app/proxy/${t}/${l.NOTION}`, r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.post(c, p, !0, e);
  }
  async createItem(t, o, s, i) {
    const a = await h(t, s, i), e = T(), n = {
      parent: { database_id: o },
      properties: {
        [f]: {
          rich_text: [
            {
              type: "text",
              text: {
                content: e,
                link: null
              },
              plain_text: e,
              href: null
            }
          ]
        }
      }
    }, p = {
      url: "https://api.notion.com/v1/pages",
      method: "POST",
      header: {
        "Content-Type": "application/json",
        "Notion-Version": "2021-08-16"
      },
      body: n
    }, c = `/app/proxy/${t}/${l.NOTION}`, r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.post(c, p, !0, a);
  }
}
export {
  I as NotionAPI
};
