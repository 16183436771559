import M from "fast-json-stable-stringify";
import { nanoid as j } from "nanoid";
const k = (a) => a.then((l) => ({ data: l, error: void 0 })).catch((l) => ({ data: void 0, error: l })), E = (a, l, q, o, x) => {
  const { getState: h, setState: C } = a, Q = h();
  C({
    [l]: {
      ...Q[l],
      [q]: {
        ...Q[l][q],
        [o]: {
          ...Q[l][q][o],
          ...x
        }
      }
    }
  });
}, $ = (a, l) => `${a}(${M(l).slice(1, -1)})`, D = (a, l) => {
  const q = (o) => {
    const x = (w) => {
      const t = Object.entries(o.getState()[a].endpoints).find(([, y]) => y === w);
      return t == null ? void 0 : t[0];
    }, h = {
      query: (w, t) => {
        const y = (n) => {
          const e = x(r), c = $(e, n);
          return [e, c];
        }, m = /* @__PURE__ */ new Map(), r = (n, e) => {
          const c = n || [], [i, s] = y(c);
          if (m.has(s))
            return m.get(s);
          const f = w(...c), g = j();
          t != null && t.onStart && t.onStart({ args: c, promise: f }), queueMicrotask(() => {
            E(o, a, "queries", s, {
              status: "loading",
              error: void 0,
              requestId: g,
              endpointName: i
            });
          }), f.then((S) => {
            E(o, a, "queries", s, {
              status: "success",
              data: S
            }), t != null && t.onSuccess && t.onSuccess({ args: c, data: S });
          }).catch((S) => {
            E(o, a, "queries", s, {
              status: "error",
              error: S
            }), t != null && t.onError && t.onError({ args: c, error: S });
          }).finally(() => {
            m.delete(s);
          });
          const d = k(f), N = Object.assign(d, {
            requestId: g,
            unwrap: () => f
          });
          return m.set(s, N), N;
        };
        r.select = (...n) => {
          const [, e] = y(n);
          return (c) => c[a].queries[e];
        };
        const v = (n) => ({
          ...n,
          isIdle: n.status === "idle",
          isLoading: n.status === "loading",
          isError: n.status === "error",
          isSuccess: n.status === "success",
          isStale: n.status === "stale"
        }), u = /* @__PURE__ */ new WeakMap();
        return r.get = (...n) => {
          const e = () => r.select(...n)(o.getState()), [c, i] = y(n);
          let s = e();
          return s || (s = {
            status: "idle",
            endpointName: c
          }, E(o, a, "queries", i, s)), u.has(s) || u.set(s, {
            ...v(s),
            updateData: (f) => {
              const g = e(), d = f(g.data);
              return E(o, a, "queries", i, {
                data: d
              }), {
                undo: () => {
                  E(o, a, "queries", i, g);
                }
              };
            }
          }), u.get(s);
        }, r.subscribe = (n, e) => {
          const c = n || [];
          let i, s;
          return o.subscribe((g) => {
            const d = r.select(...c)(g);
            if (d && d !== i) {
              const N = r.get(...c);
              e(N, s), i = d, s = N;
            }
          });
        }, r.__type = "query", r;
      },
      mutation: (w, t) => {
        const { invalidateQueries: y = () => [] } = t || {}, m = (u, n) => {
          const e = u || [], { fixedCacheKey: c } = n || {}, i = w(...e), s = j(), f = x(m), g = c || s;
          t != null && t.onStart && t.onStart({ args: e, promise: i }), queueMicrotask(() => {
            E(o, a, "mutations", g, {
              status: "loading",
              error: void 0,
              requestId: s,
              endpointName: f
            });
          }), i.then((S) => {
            E(o, a, "mutations", g, {
              status: "success",
              data: S
            }), t != null && t.onSuccess && t.onSuccess({ args: e, data: S });
            const I = y({
              args: e,
              data: S
            });
            o.getState()[a].invalidateQueries(...I);
          }).catch((S) => {
            E(o, a, "mutations", g, {
              status: "error",
              error: S
            }), t != null && t.onError && t.onError({ args: e, error: S });
          });
          const d = k(i);
          return Object.assign(d, {
            requestId: s,
            unwrap: () => i
          });
        };
        m.select = (u) => (n) => n[a].mutations[u];
        const r = (u) => ({
          ...u,
          isIdle: u.status === "idle",
          isLoading: u.status === "loading",
          isError: u.status === "error",
          isSuccess: u.status === "success"
        }), v = /* @__PURE__ */ new WeakMap();
        return m.get = (u) => {
          let e = (() => m.select(u)(o.getState()))();
          return e || (e = {
            status: "idle",
            endpointName: x(m)
          }, E(o, a, "mutations", u, e)), v.has(e) || v.set(e, {
            ...r(e),
            reset: () => E(o, a, "mutations", u, {
              status: "idle",
              data: void 0,
              error: void 0
            })
          }), v.get(e);
        }, m.subscribe = (u, n) => {
          let e, c;
          return o.subscribe((s) => {
            const f = m.select(u)(s);
            if (f && f !== e) {
              const g = m.get(u);
              n(g, c), e = f, c = g;
            }
          });
        }, m.__type = "mutation", m;
      }
    }, C = (...w) => {
      const t = {
        ...o.getState()[a].queries
      }, y = [];
      w.filter((r) => Array.isArray(r)).forEach(([r, ...v]) => {
        const u = $(r, v);
        y.push(...Object.keys(t).filter((n) => {
          var d, N;
          const e = t[n];
          if (e.endpointName !== r)
            return !1;
          const c = n.split(e.endpointName), i = (c == null ? void 0 : c.length) > 1 ? JSON.parse(`[${(d = c == null ? void 0 : c[1]) == null ? void 0 : d.slice(1, -1)}]`) : [], s = u.split(e.endpointName), f = (s == null ? void 0 : s.length) > 1 ? JSON.parse(`[${(N = s[1]) == null ? void 0 : N.slice(1, -1)}]`) : [];
          let g = !0;
          return (i == null ? void 0 : i.length) !== (f == null ? void 0 : f.length) ? !1 : (f.forEach((S, I) => {
            S !== "*" && M(i == null ? void 0 : i[I]) !== M(S) && (g = !1);
          }), g);
        }));
      }), w.filter((r) => typeof r == "string").forEach((r) => {
        const v = new RegExp(`${r}(.*)`);
        y.push(...Object.keys(t).filter((u) => v.test(u)));
      }), y.forEach((r) => {
        t[r] && (t[r] = {
          ...t[r],
          status: "stale"
        });
      });
      const m = o.getState();
      o.setState({
        [a]: {
          ...m[a],
          queries: t
        }
      });
    }, Q = {
      endpoints: l(o).endpoints(h),
      queries: {},
      mutations: {},
      invalidateQueries: C
    };
    return { [a]: Q };
  };
  return q.path = a, q;
};
export {
  D as makeApiSliceCreator
};
