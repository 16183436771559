import React from 'react';
import classNames from 'classnames';
import styles from './Dialog.module.css';

export function StyledDialogHeader({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.dialogHeader}>
      {children}
    </div>
  );
}

export function StyledDialogModalContent({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={classNames(styles.dialogModalContent, className)}
    >
      {children}
    </div>
  );
}

StyledDialogModalContent.defaultProps = {
  className: undefined,
};

export function StyledDialogFooter({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div
      className={styles.dialogFooter}
    >
      {children}
    </div>
  );
}

export function StyledDialogModal({
  children,
  height,
  width,
}: {
  children: React.ReactNode,
  height: number | string,
  width: number | string,
}) {
  return (
    <div
      className={styles.defaultContent}
      style={{
        height: Number.isFinite(height) ? `min(${height}px, 90vh)` : height,
        width: Number.isFinite(width) ? `min(${width}px, 90vw)` : width,
      }}
    >
      {children}
    </div>
  );
}

export function StyledDialogOverlay() {
  return <div className={styles.defaultOverlay} />;
}
