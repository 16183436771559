import { createSelector as L } from "reselect";
import { MAX_SLUG_RETRY as y, DEFAULT_NEW_PAGE_SLUG_RANDOM_LENGTH as D, DEFAULT_NEW_PAGE_SLUG as A, DEFAULT_NEW_PAGE_NAME as O, RESERVED_PATHS as R, DUPLICATED_TABLE_ID_PREFIX as M } from "../../constants.js";
import { isAllowedCreatePage as v } from "./utils.js";
import { saveContentLocal as N } from "../../api/page/offline.js";
import { getPageIDToPath as G } from "../../utils/getPageIDToPath.js";
import { API as h } from "../../api/api.js";
import { App as z } from "../../api/app/app.js";
import { errToString as C } from "../../utils/errors.js";
const V = (i) => async (c) => {
  var n;
  if (!c)
    return {};
  const { getState: a } = i, { tokenHandlers: t } = a(), { spacePermissionTokenHandler: o } = t;
  if (!o)
    return {};
  const e = await h.getOrCreateInstance().page().list(c, o);
  return (n = e == null ? void 0 : e.data) == null ? void 0 : n.reduce((s, l) => ({ ...s, [l.id]: l }), {});
}, J = (i) => async (c, a) => {
  if (!c || !a)
    return;
  const { getState: t } = i, { tokenHandlers: o } = t(), e = h.getOrCreateInstance(), d = new z(e.api), { spacePermissionTokenHandler: n, subscriptionTokenHandler: s } = o;
  if (!n)
    return;
  const l = async (p) => {
    var P;
    const g = "You haven't connected the notion integration", m = "Request failed with status code 400", w = (P = p == null ? void 0 : p.customization) == null ? void 0 : P.collection_data;
    if (w) {
      const H = Object.entries(w).map(async ([U, k]) => {
        var S, _, b;
        let T = k;
        if ((S = d.app(k)) != null && S.tableConnected(k))
          try {
            const f = await ((_ = d.app(k)) == null ? void 0 : _.getData(c, k, "", n, s)), I = (f == null ? void 0 : f.data) || [];
            T = {
              ...k,
              data: I,
              hasMore: f == null ? void 0 : f.has_more,
              nextCursor: f == null ? void 0 : f.next_cursor,
              fetchedCursor: void 0,
              err: null
            };
          } catch (f) {
            C(f) !== g && (C(f) === m || ((b = k.tableID) == null ? void 0 : b.startsWith(M))) ? T = {
              ...k,
              err: "We can't connect to your database, please connect to a different one"
            } : T = {
              ...k,
              err: C(f)
            };
          }
        return [U, T];
      }), E = Object.fromEntries(await Promise.all(H));
      return {
        ...p,
        customization: {
          ...p == null ? void 0 : p.customization,
          collection_data: E
        }
      };
    }
    return p;
  }, r = await e.page().get(a, c, n);
  return await l(r == null ? void 0 : r.data);
}, K = (i) => async (c) => {
  var m;
  const { getState: a } = i, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: o } } = a(), { spaceID: e, pagePath: d, routeAtPath: n, isChildrenPageTemplate: s = !1 } = c, l = n.children ? Object.keys(n.children) : [];
  let r = A, u = 0;
  for (; l.includes(r) && u < y; ) {
    const w = Math.random().toString(36).substring(5, 5 + D);
    r = `${A}-${w}`, u += 1;
  }
  if (l.includes(r))
    throw new Error("Error creating page, unable to make a random slug for your new page");
  const p = v(r, d, n, s);
  if (p)
    throw new Error(p);
  const g = await h.getOrCreateInstance().page().create(e, O, r, d, s, t, o);
  return (m = g == null ? void 0 : g.data) == null ? void 0 : m.page;
}, Q = (i) => async (c) => {
  var s;
  const { getState: a } = i, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: o } } = a(), { spaceID: e, content: d } = c, n = await h.getOrCreateInstance().page().createHomePage(e, d, t, o);
  return (s = n == null ? void 0 : n.data) == null ? void 0 : s.page;
}, Z = (i) => async (c) => {
  const { getState: a } = i, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: o } } = a(), { spaceID: e, pageID: d, slug: n, pagePath: s } = c;
  if (!d)
    throw new Error("Page id is not defined");
  await h.getOrCreateInstance().page().delete(d, e, n, s, t, o);
}, ee = (i) => {
  let c, a;
  return async (t) => {
    var w;
    const { getState: o } = i, { tokenHandlers: { spacePermissionTokenHandler: e, subscriptionTokenHandler: d }, pagesApi: n } = o(), { spaceID: s, pageID: l, newContent: r, force: u = !1 } = t, p = (w = n.endpoints.getPage.get(s, l)) == null ? void 0 : w.data, g = p == null ? void 0 : p.content_updated_at;
    if (g === c && r === a && !u)
      return;
    c = g, a = r;
    const { data: m } = await h.getOrCreateInstance().page().updateContent(l, s, r, g, u, e, d, () => {
      var H;
      const P = (H = n.endpoints.getPage.get(s, l)) == null ? void 0 : H.data;
      return N(P, r);
    });
    return m;
  };
}, W = (i, c) => {
  const { getState: a } = c;
  return L(a().spacesApi.endpoints.getSpace.select(i), a().pagesApi.endpoints.listPages.select(i), (t, o) => {
    var e;
    return (o == null ? void 0 : o.data) && ((e = t == null ? void 0 : t.data) == null ? void 0 : e.routes) ? G(t.data.routes, [], o.data) : {};
  });
}, te = (i) => async (c) => {
  var H;
  const { spaceID: a, pageID: t, name: o, slug: e, isPublic: d, allowDuplicate: n } = c, { getState: s } = i, { tokenHandlers: { spacePermissionTokenHandler: l, subscriptionTokenHandler: r }, spacesApi: u } = s(), p = W(a, i)(s()), { path: g } = p[t], m = g.length === 0, w = g.slice(0, -1);
  if (!t)
    throw new Error("Page id is not defined");
  if (!m && e.length <= 0 || o.length <= 0)
    throw new Error("Page URL or Name cannot be empty.");
  if ((g == null ? void 0 : g.length) === 1 && R.includes(e))
    throw new Error(`The URL "${e}" is reserved. Please use another URL for this page.`);
  let { routes: P } = u.endpoints.getSpace.get(a).data;
  if (w.forEach((E) => {
    P.children && (P = P.children[E]);
  }), ((H = P == null ? void 0 : P.children) == null ? void 0 : H[e]) && e !== g[g.length - 1])
    throw new Error(`The URL "${e}" is already used for a different page. Please use a unique URL for each page.`);
  await h.getOrCreateInstance().page().updateInfo(t, a, o, e, w, d, n, l, r);
}, ne = (i) => async (c) => {
  const { getState: a } = i, { spaceID: t, pageID: o, metadata: e, newTwitterImage: d, newFacebookImage: n } = c, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: l } } = a(), r = h.getOrCreateInstance(), u = { ...e };
  if (d) {
    const g = (await r.document().upload(d, t, s, l)).data.file_name, m = r.document().getURL(g);
    u.twitter.image = m;
  }
  if (n) {
    const g = (await r.document().upload(n, t, s, l)).data.file_name, m = r.document().getURL(g);
    u.facebook.image = m;
  }
  await r.page().updateMetadata(o, t, u, s, l);
}, ae = (i) => async (c) => {
  const { getState: a } = i, { spaceID: t, pageID: o, customizationUpdateRequests: e } = c, { tokenHandlers: { spacePermissionTokenHandler: d, subscriptionTokenHandler: n } } = a(), { data: s } = await h.getOrCreateInstance().page().updateCustomizationPartial(o, t, e, d, n);
  return s;
}, oe = (i) => async (c) => {
  const { getState: a } = i, { create: t, destinationSpaceID: o, slug: e, pagePath: d, duplicateOwnPage: n } = c, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: l } } = a(), r = await h.getOrCreateInstance().page().duplicate(t, o, e, d, n, s, l), u = r == null ? void 0 : r.data;
  if (!u)
    throw new Error("Unable to get destination space after duplicating");
  return u;
};
export {
  Q as createHomePage,
  K as createPage,
  Z as deletePage,
  oe as duplicatePage,
  J as getPage,
  V as listPages,
  ee as saveContent,
  ae as updatePageCustomization,
  te as updatePageInfo,
  ne as updatePageMetadata
};
