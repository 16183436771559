const e = {
  align: "align_center",
  background: "black",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontSize: 20,
          gradient: !1,
          lineHeight: "1.4",
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Trusted by thousands of companies"
        }
      ],
      type: "p",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "Join our clients in saving hundreds of hours per year on finances"
        }
      ],
      type: "p",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontSize: 20,
                  gradient: !1,
                  lineHeight: "1.4",
                  styled: !0,
                  text: "50,000+"
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  styled: !0,
                  text: "Companies"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontSize: 20,
                  gradient: !1,
                  lineHeight: "1.4",
                  styled: !0,
                  text: "$300 million+"
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  styled: !0,
                  text: "Transactions"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontSize: 20,
                  gradient: !1,
                  lineHeight: "1.4",
                  styled: !0,
                  text: "$250 million+"
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  styled: !0,
                  text: "Funds Managed"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        }
      ],
      height: 154.859375,
      type: "column",
      width: 800
    }
  ],
  height: 371.859375,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, t = {
  align: "align_center",
  background: "default",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Powering the 500+ Companies"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "subtitle3",
          styled: !0,
          text: "Join our clients in saving hundreds of hours per year on finances"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1.1962616822429908,
              children: [
                {
                  text: ""
                }
              ],
              height: 42.994018554687514,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/airtable.png",
              width: 51.432096962616846
            }
          ],
          type: "column_item",
          width: 0.2
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 43.45867406542058,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/slack.png",
              width: 43.45867406542058
            }
          ],
          type: "column_item",
          width: 0.2
        },
        {
          children: [
            {
              aspect_ratio: 1.6897689768976898,
              children: [
                {
                  text: ""
                }
              ],
              height: 31.912127913715693,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/typedream.png",
              width: 53.924123735387575
            }
          ],
          type: "column_item",
          width: 0.2
        },
        {
          children: [
            {
              aspect_ratio: 0.6688311688311689,
              children: [
                {
                  text: ""
                }
              ],
              height: 45.77634884538609,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/figma.png",
              width: 30.616648903082908
            }
          ],
          type: "column_item",
          width: 0.2
        },
        {
          children: [
            {
              aspect_ratio: 1.0666666666666669,
              children: [
                {
                  text: ""
                }
              ],
              height: 49.650710061331786,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/sketch.png",
              width: 52.96075739875391
            }
          ],
          type: "column_item",
          width: 0.2
        }
      ],
      height: 89.64999389648438,
      type: "column",
      width: 900
    }
  ],
  height: 371.859375,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, n = {
  align: "align_center",
  background: "black",
  backgroundImage: null,
  children: [
    {
      children: [
        {
          color: "white",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Join 1000+ happy startups that use Cardy"
        }
      ],
      type: "h3"
    },
    {
      align: null,
      background: null,
      backgroundImage: null,
      children: [
        {
          children: [
            {
              align: "undefined",
              aspect_ratio: 1.7777777777777777,
              children: [
                {
                  text: ""
                }
              ],
              height: 205.591796875,
              open_in_new_tab: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/office.png",
              width: "365.49652777777777",
              width_max: "undefined"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  fontSize: 13,
                  gradient: !1,
                  styled: !0,
                  text: "Photo by "
                },
                {
                  children: [
                    {
                      color: "white",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Ant Rozetsky"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://unsplash.com/@rozetsky?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                },
                {
                  color: "white",
                  fontSize: 13,
                  gradient: !1,
                  styled: !0,
                  text: " on "
                },
                {
                  children: [
                    {
                      color: "white",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Unsplash"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://unsplash.com/s/photos/work?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: "0.5"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  color: "water",
                  fontSize: 20,
                  gradient: !0,
                  lineHeight: "1.4",
                  styled: !0,
                  text: 'Our teams were able to move 5x faster with the new flexibility and transparency that Cardy has brought to us using their software."'
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontSize: 16,
                  gradient: !1,
                  styled: !0,
                  text: "\u2013 James Godfrey, CFO, Cardamom"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: "0.5"
        }
      ],
      height: 253,
      type: "column",
      vertical_align: null,
      width: 700
    }
  ],
  height: 350,
  open_in_new_tab: !1,
  style: "card",
  type: "container",
  url: null,
  vertical_align: null,
  width: 800
}, i = {
  align: "align_center",
  children: [
    {
      children: [
        {
          text: "Why our customers love us"
        }
      ],
      openOptions: !1,
      type: "h2",
      align: "align_center"
    },
    {
      type: "column",
      children: [
        {
          type: "column_item",
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  background: "purple",
                  children: [
                    {
                      text: ""
                    }
                  ],
                  color: "text_on_accent",
                  icon: {
                    content: {
                      color: "white",
                      name: "heart",
                      type: "solid"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: "Managing our company's finances has never been easier. Literally took 10 minutes to set up."
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      text: "\u2013 James Godfrey, CFO, Cardamom"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              height: 228,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start"
            }
          ],
          width: 0.553947368421052
        },
        {
          type: "column_item",
          children: [
            {
              align: "align_center",
              background: "purple",
              children: [
                {
                  background: "default",
                  children: [
                    {
                      text: ""
                    }
                  ],
                  color: "text_on_accent",
                  icon: {
                    content: {
                      name: "happy-heart-eyes",
                      type: "solid",
                      color: "purple"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: "We've made reports 5 minutes before meetings. It just works!",
                      styled: !0,
                      color: "white",
                      gradient: !1
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      text: "\u2013 James Godfrey, CFO, Cardamom",
                      styled: !0,
                      color: "white",
                      gradient: !1
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              height: 228,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start"
            }
          ],
          width: 0.44605263157894764
        }
      ],
      height: 316,
      width: 900
    },
    {
      type: "column",
      children: [
        {
          type: "column_item",
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  background: "purple",
                  children: [
                    {
                      text: ""
                    }
                  ],
                  color: "text_on_accent",
                  icon: {
                    content: {
                      name: "like",
                      type: "solid",
                      color: "white"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: "Loving it, honestly the best purchase of the year."
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      text: "\u2013 James Godfrey, CFO, Cardamom"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              height: 228,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start"
            }
          ],
          width: 0.4197368421052637
        },
        {
          type: "column_item",
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  background: "purple",
                  children: [
                    {
                      text: ""
                    }
                  ],
                  color: "text_on_accent",
                  icon: {
                    content: {
                      name: "zap",
                      type: "solid",
                      color: "white"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: "Really satisfied with the service. Incredibly intuitive and helped us a lot.",
                      styled: !0,
                      color: "default",
                      gradient: !1
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      text: "\u2013 James Godfrey, CFO, Cardamom",
                      styled: !0,
                      color: "default",
                      gradient: !1
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              height: 228,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start"
            }
          ],
          width: 0.5802631578947361
        }
      ],
      height: 316,
      width: 900
    }
  ],
  height: 748,
  openOptions: !1,
  type: "container",
  width_max: "100%"
}, l = {
  align: "align_center",
  background: "rgba(240, 195, 254, 1)",
  children: [
    {
      aspect_ratio: 1.1965811965811965,
      children: [
        {
          text: ""
        }
      ],
      height: 85.328125,
      openOptions: !1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/d1607aa1-1f0b-4328-836a-514ab57def41_fuel-hearts_png.png",
      width: 102.10202991452991
    },
    {
      align: "align_center",
      children: [
        {
          fontFamily: "libre_caslon_text",
          styled: !0,
          text: "what they say...",
          color: "black",
          gradient: !1
        }
      ],
      openOptions: !1,
      type: "h1"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  background: "black",
                  children: [
                    {
                      text: ""
                    }
                  ],
                  color: "text_on_accent",
                  icon: {
                    content: {
                      color: "white",
                      name: "heart",
                      type: "solid"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                },
                {
                  align: "align_center",
                  children: [
                    {
                      fontSize: 16,
                      fontStyle: "paragraph",
                      styled: !0,
                      text: "LOVE IT! I tried the Pineapple Fuel and immediately fell in love. It tasted like I juiced it myself, except it's 10x better. ",
                      color: "black",
                      gradient: !1
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "default",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      bold: !0,
                      fontFamily: "libre_caslon_text",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      styled: !0,
                      text: "CASHEW JUICE",
                      color: "black",
                      gradient: !1
                    }
                  ],
                  type: "p"
                }
              ],
              height: 301.328125,
              openOptions: !1,
              type: "container",
              width: 400
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  background: "black",
                  children: [
                    {
                      text: ""
                    }
                  ],
                  color: "text_on_accent",
                  icon: {
                    content: {
                      color: "white",
                      name: "happy-heart-eyes",
                      type: "solid"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                },
                {
                  align: "align_center",
                  children: [
                    {
                      fontSize: 16,
                      fontStyle: "paragraph",
                      styled: !0,
                      text: "The juice is perfect for anyone looking for a healthy cleansing. The ingredients are so natural and the blend is perfectly tasty.",
                      color: "black",
                      gradient: !1
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "default",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      bold: !0,
                      fontFamily: "libre_caslon_text",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      styled: !0,
                      text: "POPLOVIN",
                      color: "black",
                      gradient: !1
                    }
                  ],
                  type: "p"
                }
              ],
              height: 301.328125,
              openOptions: !1,
              type: "container",
              width: 400
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 317,
      type: "column",
      width: 700
    }
  ],
  height: 793.65625,
  openOptions: !1,
  type: "container",
  width: 1800,
  width_max: "100%"
}, a = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://api.typedream.com/v0/document/public/f95018a7-0031-4e20-9ee2-f8ae0feeac2a_gradient_11_png.png",
  children: [
    {
      align: "align_center",
      children: [
        {
          align: "align_center",
          children: [
            {
              color: "default",
              fontSize: 20,
              gradient: !1,
              lineHeight: "1.4",
              styled: !0,
              text: ""
            }
          ],
          type: "p"
        },
        {
          align: "align_center",
          children: [
            {
              bold: !0,
              color: "white",
              fontFamily: "space_mono",
              fontSize: 24,
              fontStyle: "subtitle1",
              gradient: !1,
              lineHeight: 1.4,
              styled: !0,
              text: "sowing the seeds of a better tomorrow."
            }
          ],
          type: "p",
          width: 800
        },
        {
          align: "align_center",
          children: [
            {
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      gradient: !1,
                      styled: !0,
                      text: "8"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      fontSize: 16,
                      gradient: !1,
                      styled: !0,
                      text: `leading
industries`
                    }
                  ],
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.3333333333333333
            },
            {
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      gradient: !1,
                      styled: !0,
                      text: "$20bn"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      fontSize: 16,
                      gradient: !1,
                      styled: !0,
                      text: `combined
valuation`
                    }
                  ],
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.3333333333333333
            },
            {
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      gradient: !1,
                      styled: !0,
                      text: "12"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontFamily: "space_mono",
                      fontSize: 16,
                      gradient: !1,
                      styled: !0,
                      text: `successful
IPOs`
                    }
                  ],
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.3333333333333333
            }
          ],
          height: 180.5,
          type: "column",
          width: 800
        },
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/8c4d24ce-37bd-4e4b-9925-04f8a375d0ec_Group_1_7_png.png",
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "f"
                },
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "eatured companies"
                }
              ],
              openOptions: !1,
              type: "h2"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  children: [
                    {
                      aspect_ratio: 3.695652173913043,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      height: 81.98823529411766,
                      openOptions: !1,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/b7d92b20-e536-482c-815c-9c5cd9642a64_image_2_1_png.png",
                      width: 303
                    },
                    {
                      align: "align_center",
                      children: [
                        {
                          color: "white",
                          fontFamily: "space_mono",
                          gradient: !1,
                          styled: !0,
                          text: "stacks.co"
                        }
                      ],
                      openOptions: !1,
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.33333
                },
                {
                  children: [
                    {
                      aspect_ratio: 3.479591836734693,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      height: 98.00000000000001,
                      openOptions: !1,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/b121e3b2-c206-41c8-a783-8c445122bb3b_image_3_1_png.png",
                      width: 341
                    },
                    {
                      align: "align_center",
                      children: [
                        {
                          color: "white",
                          fontFamily: "space_mono",
                          gradient: !1,
                          styled: !0,
                          text: "snackpass.co"
                        }
                      ],
                      openOptions: !1,
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.33333
                },
                {
                  children: [
                    {
                      aspect_ratio: 1.9183673469387756,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      height: 86.41107047872342,
                      openOptions: !1,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/b7a23e38-30f6-49df-b834-96df28b9d25a_image_4_1_png.png",
                      width: 165.7681760204082
                    },
                    {
                      align: "align_center",
                      children: [
                        {
                          color: "white",
                          fontFamily: "space_mono",
                          gradient: !1,
                          styled: !0,
                          text: "repl.it"
                        }
                      ],
                      openOptions: !1,
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.33333
                }
              ],
              height: 191.078125,
              type: "column",
              width: 1e3
            },
            {
              children: [
                {
                  children: [
                    {
                      aspect_ratio: 2.833333333333333,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      height: 85.37697963800909,
                      openOptions: !1,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/ee906024-9353-4414-98e4-0e03a8cc1d68_image_5_1_png.png",
                      width: 241.90144230769238
                    },
                    {
                      align: "align_center",
                      children: [
                        {
                          color: "white",
                          fontFamily: "space_mono",
                          gradient: !1,
                          styled: !0,
                          text: "datacamp.com"
                        }
                      ],
                      openOptions: !1,
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.33333
                },
                {
                  children: [
                    {
                      aspect_ratio: 2.4,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      height: 85.52343749999997,
                      openOptions: !1,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/f273600d-a156-4c79-b200-0a351c44ab3f_image_8_png.png",
                      width: 205.25624999999994
                    },
                    {
                      align: "align_center",
                      children: [
                        {
                          color: "white",
                          fontFamily: "space_mono",
                          gradient: !1,
                          styled: !0,
                          text: "gather.town"
                        }
                      ],
                      openOptions: !1,
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.33333
                },
                {
                  children: [
                    {
                      aspect_ratio: 2.372549019607843,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      height: 79.6119576446281,
                      openOptions: !1,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/6274a145-20ba-4af9-98a1-60b0f28501de_image_9_png.png",
                      width: 188.88327205882354
                    },
                    {
                      align: "align_center",
                      children: [
                        {
                          color: "white",
                          fontFamily: "space_mono",
                          gradient: !1,
                          styled: !0,
                          text: "postman.com"
                        },
                        {
                          bold: !0,
                          fontFamily: "space_mono",
                          styled: !0,
                          text: "view all"
                        }
                      ],
                      openOptions: !1,
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.33333
                }
              ],
              height: 198.25,
              type: "column",
              width: 1e3
            }
          ],
          height: 591.3984375,
          openOptions: !1,
          type: "container",
          width: 1e3
        }
      ],
      height: 889.4921875,
      openOptions: !1,
      type: "container",
      width: 1100
    }
  ],
  height: 929.4921875,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, o = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  text: "Shoutout to"
                }
              ],
              openOptions: !1,
              type: "h3"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              children: [
                {
                  bold: !0,
                  text: "The super awesome team at Typedream and all the no-code founders we interviewed:"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Rosalie\xA0Alyssia"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Max\xA0Jens"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Tzivia\xA0Annabeth"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Sloan\xA0Diarmaid"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Aemilia\xA0Daniel"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Ramona\xA0Sarkis"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Leonty\xA0Efthalia"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "Zdeslav\xA0Taisa"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 440,
      type: "column",
      vertical_align: "align_start",
      width: 700
    }
  ],
  height: 640,
  openOptions: !1,
  type: "container",
  width_max: "100%"
};
export {
  a as CLIENTS_INVESTOR_PORTFOLIO,
  t as CLIENTS_LOGOS,
  e as CLIENTS_NUMBERS_DARK,
  i as CLIENTS_TESTIMONIAL_MANY,
  n as CLIENTS_TESTIMONIAL_ONE,
  l as CLIENTS_TESTIMONIAL_PURPLE,
  o as CLIENTS_UX_COURSE
};
