import { makeApiSliceCreator as m } from "@typedream/zustand-query";
import { listPayments as n, listAllPayments as s, countPayments as y } from "./actions.js";
const r = m("paymentApi", (t) => ({
  endpoints: (e) => ({
    listPayments: e.query(n(t)),
    listAllPayments: e.query(s(t)),
    countPayments: e.query(y(t))
  })
}));
export {
  r as createPaymentApiSlice
};
