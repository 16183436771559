import { generateSlug as w } from "random-word-slugs";
import { FormCollection as g } from "../../types.js";
import { API as f } from "../../api/api.js";
const z = (r) => async (t, e) => {
  var p;
  if (!t || !e)
    return;
  const { getState: a } = r, { spacesApi: n, tokenHandlers: o } = a(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: i } = o;
  try {
    await f.getOrCreateInstance().collection().delete(e, t, s, i);
  } catch (m) {
    console.error(m);
  }
  let c = n.endpoints.getSpace.get(t).data;
  if (!c) {
    const { data: m } = await n.endpoints.getSpace([t]);
    m && (c = m);
  }
  const d = (p = c == null ? void 0 : c.customization) == null ? void 0 : p.form_reference;
  if (!d)
    return;
  const u = d.content ? { ...d.content } : {}, l = d.published_content ? { ...d.published_content } : {};
  u[e] && delete u[e], l[e] && delete l[e], await n.endpoints.updateSpaceCustomizationPartial([{
    spaceID: t,
    customizationUpdateRequests: [
      {
        customization_value: {
          value: u,
          updated_at: new Date().toISOString()
        },
        customization_path: ["form_reference", "content", e],
        customization_delete: !0
      }
    ]
  }]), await n.endpoints.updateSpaceCustomizationPartial([{
    spaceID: t,
    customizationUpdateRequests: [
      {
        customization_value: {
          value: l,
          updated_at: new Date().toISOString()
        },
        customization_path: ["form_reference", "published_content", e],
        customization_delete: !0
      }
    ]
  }]);
}, y = (r) => async (t, e) => {
  if (!t || !e)
    throw Error("Either SpaceID, newForm is not provided");
  const { getState: a } = r, { tokenHandlers: n } = a(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n, i = new g(e), c = await f.getOrCreateInstance().collection().update(i, o, s);
  return new g(c).getFormData();
}, T = (r) => async (t, e, a, n) => {
  if (!t || !e)
    throw Error("Either SpaceID, or FormID is not provided");
  const { getState: o } = r, { tokenHandlers: s } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: c } = s;
  return await f.getOrCreateInstance().collection().queryItems({
    limit: a,
    offset: n
  }, t, e, i, c);
}, P = (r) => async (t, e) => {
  if (!t || !e)
    throw Error("Either SpaceID, or FormID is not provided");
  const { getState: a } = r, { tokenHandlers: n } = a(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n;
  return await f.getOrCreateInstance().collection().queryItemsCount(t, e, o, s);
}, v = (r) => async (t, e, a) => {
  if (!t || !e || !a)
    throw Error("Either SpaceID, FormID, or NewName is not provided");
  const { getState: n } = r, { tokenHandlers: o } = n(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: i } = o, c = await f.getOrCreateInstance().collection().updateField(t, e, ["name"], a, s, i);
  return new g(c).getFormData();
}, O = (r) => async (t, e) => {
  if (!t)
    return [];
  const { getState: a } = r, { tokenHandlers: n } = a(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n;
  return await f.getOrCreateInstance().collection().queryAllItems(t, e, o, s);
}, E = (r) => async (t) => {
  var u;
  if (!t)
    return [];
  const { getState: e } = r, { spacesApi: a, tokenHandlers: n } = e(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n;
  let i = a.endpoints.getSpace.get(t).data;
  if (!i) {
    const { data: l } = await a.endpoints.getSpace([t]);
    l && (i = l);
  }
  const c = (u = i == null ? void 0 : i.customization) == null ? void 0 : u.form_reference, d = await f.getOrCreateInstance().collection().query({}, t, o, s);
  if (d) {
    const l = [];
    return d.filter((p) => p.type === "form").forEach((p) => {
      var m, S;
      try {
        const _ = new g(p);
        l.push({
          ..._.getFormData(),
          published_at: ((S = (m = c == null ? void 0 : c.published_content) == null ? void 0 : m[p.id]) == null ? void 0 : S.updated_at) || ""
        });
      } catch {
        l.push({
          id: p.id,
          published_at: "",
          updated_at: "",
          schema: {},
          space_id: p.space_id
        });
      }
    }), l;
  }
  return [];
}, I = (r) => async (t, e) => {
  var p, m, S;
  if (!t)
    return null;
  const { getState: a } = r, { spacesApi: n, tokenHandlers: o } = a(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: i } = o, c = await f.getOrCreateInstance().collection().get(e, t, s, i), d = new g(c);
  let u = n.endpoints.getSpace.get(t).data;
  if (!u) {
    const { data: _ } = await n.endpoints.getSpace([t]);
    _ && (u = _);
  }
  const l = (p = u == null ? void 0 : u.customization) == null ? void 0 : p.form_reference;
  if (l) {
    const _ = ((m = l.content) == null ? void 0 : m[e]) || { updated_at: "" }, h = ((S = l.published_content) == null ? void 0 : S[e]) || { updated_at: "" };
    return {
      ...d.getFormData(),
      published_at: h.updated_at,
      updated_at: _.updated_at
    };
  }
  return null;
}, A = (r) => async (t, e) => {
  var s, i, c;
  if (!t || !e)
    return [];
  const { getState: a } = r, { spacesApi: n } = a();
  let o = n.endpoints.getSpace.get(t).data;
  if (!o) {
    const { data: d } = await n.endpoints.getSpace([t]);
    d && (o = d);
  }
  return ((c = (i = (s = o == null ? void 0 : o.customization) == null ? void 0 : s.form_reference) == null ? void 0 : i.content) == null ? void 0 : c[e].value) || [];
}, q = (r) => async (t, e) => {
  var s, i, c;
  if (!t || !e)
    return [];
  const { getState: a } = r, { spacesApi: n } = a();
  let o = n.endpoints.getSpace.get(t).data;
  if (!o) {
    const { data: d } = await n.endpoints.getSpace([t]);
    d && (o = d);
  }
  return ((c = (i = (s = o == null ? void 0 : o.customization) == null ? void 0 : s.form_reference) == null ? void 0 : i.published_content) == null ? void 0 : c[e].value) || [];
}, F = (r) => async (t) => {
  const { formID: e, data: a } = t, { getState: n } = r, { spacesApi: o } = n();
  return o.endpoints.updateSpaceCustomizationPartial([{
    spaceID: t.spaceID,
    customizationUpdateRequests: [
      {
        customization_value: {
          value: a,
          updated_at: new Date().toISOString()
        },
        customization_path: ["form_reference", "content", e]
      }
    ]
  }]), a;
}, R = (r) => async (t, e, a) => {
  const n = new g();
  if (!t)
    return n.getFormData();
  const { getState: o } = r, { tokenHandlers: s } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: c } = s;
  n.space_id = t, n.field.name = e || w(), a && (n.field.form_type = a);
  const d = n;
  delete d.id, delete d.deleted_at, delete d.updated_at, delete d.created_at;
  const u = await f.getOrCreateInstance().collection().create(d, i, c), l = new g(u);
  if (!await F(r)({
    spaceID: t,
    formID: u.id,
    data: []
  }))
    throw Error("Error updating form content after creating form");
  return l.getFormData();
}, U = (r) => async (t) => {
  const { formID: e, spaceID: a } = t, { getState: n } = r, { spacesApi: o, formsApi: s } = n(), { data: i, error: c } = await s.endpoints.getFormContent([a, e]);
  if (i && !c) {
    const d = await o.endpoints.updateSpaceCustomizationPartial([{
      spaceID: t.spaceID,
      customizationUpdateRequests: [
        {
          customization_value: {
            value: i,
            updated_at: new Date().toISOString()
          },
          customization_path: ["form_reference", "published_content", e]
        }
      ]
    }]);
    if (!d.data || d.error)
      throw Error(d.error);
    return i;
  }
  return [];
}, k = (r) => async (t) => {
  const { formID: e, data: a } = t, { getState: n } = r, { spacesApi: o } = n(), s = await o.endpoints.updateSpaceCustomizationPartial([{
    spaceID: t.spaceID,
    customizationUpdateRequests: [
      {
        customization_value: {
          value: a,
          updated_at: new Date().toISOString()
        },
        customization_path: ["form_reference", "content", e]
      },
      {
        customization_value: {
          value: a,
          updated_at: new Date().toISOString()
        },
        customization_path: ["form_reference", "published_content", e]
      }
    ]
  }]);
  if (!s.data || s.error)
    throw Error(s.error);
  return a;
}, D = (r) => async (t) => {
  const { spaceID: e, data: a, formName: n, formType: o } = t, s = new g();
  if (!e)
    return s.getFormData();
  const { getState: i } = r, { tokenHandlers: c } = i(), { spacePermissionTokenHandler: d, subscriptionTokenHandler: u } = c;
  s.space_id = e, s.field.name = n || w(), o && (s.field.form_type = o);
  const l = s;
  delete l.id, delete l.deleted_at, delete l.updated_at, delete l.created_at;
  const p = await f.getOrCreateInstance().collection().create(l, d, u), m = new g(p);
  if (!await k(r)({
    spaceID: e,
    formID: p.id,
    data: a
  }))
    throw Error("Error updating form content after creating form");
  return m.getFormData();
}, N = (r) => async (t) => {
  const { ids: e, formID: a, spaceID: n } = t, { getState: o } = r, { tokenHandlers: s } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: c } = s;
  return await f.getOrCreateInstance().collection().deleteItems(e, n, a, i, c);
};
export {
  D as createAndPublishForm,
  R as createForm,
  z as deleteForm,
  N as deleteSubmissions,
  O as getAllFormSubmissions,
  I as getForm,
  A as getFormContent,
  T as getFormSubmissions,
  P as getFormSubmissionsCount,
  q as getPublishedFormContent,
  E as listForms,
  U as publishFormContent,
  v as renameForm,
  k as updateAndPublishFormContent,
  y as updateForm,
  F as updateFormContent
};
