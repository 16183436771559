import { PermissionTokenHandler, SubscriptionTokenHandler } from '@typedream/data';
import { createContext } from 'react';

/**
 * Contains the authenticated state and authentication methods provided by the `useToken` hook.
 */
export interface TokenContextInterface {
  // The token handler object
  spacePermissionTokenHandler: PermissionTokenHandler;
  subscriptionTokenHandler: SubscriptionTokenHandler;
}

/**
 * The initial initialContext.
 */
export const initialContext: TokenContextInterface = {
  spacePermissionTokenHandler: null,
  subscriptionTokenHandler: null,
};

/**
 * The Token Context
 */
const TokenContext = createContext<TokenContextInterface>(initialContext);

export default TokenContext;
