import { makeApiSliceCreator as c } from "@typedream/zustand-query";
import { listLocks as s, createLock as i, updateLock as a, deleteLock as k, addLock as L, removeLock as n } from "./actions.js";
const m = c("locksApi", (t) => ({
  endpoints: (o) => ({
    listLocks: o.query(s(t)),
    createLock: o.mutation(i(t), {
      invalidateQueries: ({ args: e }) => [["listLocks", e[0].spaceID]]
    }),
    updateLock: o.mutation(a(t), {
      invalidateQueries: ({ args: e }) => [["listLocks", e[0].spaceID]]
    }),
    deleteLock: o.mutation(k(t), {
      invalidateQueries: ({ args: e }) => [["listLocks", e[0].spaceID]]
    }),
    addLock: o.mutation(L(t), {
      invalidateQueries: ({ args: e }) => [["listLocks", e[0].spaceID]]
    }),
    removeLock: o.mutation(n(t), {
      invalidateQueries: ({ args: e }) => [["listLocks", e[0].spaceID]]
    })
  })
}));
export {
  m as createLocksApiSlice
};
