import React from 'react';
import { Check } from '@styled-icons/boxicons-regular';
import { useClosePublishPopupDispatch, useOpenPublishPopup, useOpenPublishPopupDispatch } from '@/store';
import Popup from '../../sharedComponents/TextEditor/components/Popup';

import ButtonAccent from '../../sharedComponents/Button/ButtonAccent';

function PublishSettingsUpdatePopup() {
  const open = useOpenPublishPopup();
  const openPopup = useOpenPublishPopupDispatch();
  const closePopup = useClosePublishPopupDispatch();
  return (
    <Popup
      width={500}
      height={300}
      open={open}
      setopen={(o: boolean) => {
        if (o) {
          openPopup();
        } else {
          closePopup();
        }
      }}
      className="p-6"
    >
      <Check size={40} className="with-lighter-text-color mb-5" />
      <div className="is-size-6 with-text-color mb-5">
        Press Publish to publish your settings changes
      </div>
      <div className="is-subtitle with-light-text-color mb-5">
        After updating your settings on Site Settings or Page Settings, press
        {' '}
        <b>Publish</b>
        {' '}
        to publish your changes on desired pages.
      </div>
      <div className="is-flex is-align-items-center">
        <ButtonAccent
          onClick={closePopup}
          target="_blank"
        >
          Got it
        </ButtonAccent>
      </div>
    </Popup>
  );
}

export default React.memo(PublishSettingsUpdatePopup);
