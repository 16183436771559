import { makeApiSliceCreator as l } from "@typedream/zustand-query";
import { getPublicTemplate as p, getTemplate as a, listSectionTemplates as m, listTemplates as s, listTemplatesPublic as i, updateTemplateUsage as T } from "./action.js";
const r = l("templatesApi", (t) => ({
  endpoints: (e) => ({
    getPublicTemplate: e.query(p()),
    getTemplate: e.query(a(t)),
    listSectionTemplates: e.query(m()),
    listTemplates: e.query(s(t)),
    listTemplatesPublic: e.query(i()),
    updateTemplateUsage: e.mutation(T(t))
  })
}));
export {
  r as createTemplatesApiSlice
};
