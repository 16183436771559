import { SPACE_PERMISSION_HEADER_KEY as t } from "../constants.js";
import { logout as n } from "./jwt/logout.js";
async function f(e, r, i) {
  const o = await r.getSpacePermission(e);
  if (!o)
    throw n(i), new Error("You are not authorized to view this page.");
  return {
    headers: {
      [t]: `Bearer ${o.token}`
    }
  };
}
export {
  f as getSpacePermissionConfig
};
