var h = Object.defineProperty;
var g = (u, t, a) => t in u ? h(u, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : u[t] = a;
var d = (u, t, a) => (g(u, typeof t != "symbol" ? t + "" : t, a), a);
import { getPermissionAndSubscriptionConfig as r } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as p } from "../EditorAPI.js";
class y {
  constructor(t) {
    d(this, "api");
    this.api = t;
  }
  async create(t, a, i, n) {
    const o = await r(a, i, n), s = `/product/create/${a}`;
    return await new p(this.api).post(s, t, !0, o);
  }
  async query(t, a, i, n) {
    const o = await r(t, i, n), s = `/product/list/${t}?limit=${a.limit}&offset=${a.offset}&order=desc`;
    return await new p(this.api).get(s, !0, o);
  }
  async get(t, a, i, n) {
    const o = await r(a, i, n), s = `/product/${a}/${t}`;
    return await new p(this.api).get(s, !0, o);
  }
  async update(t, a, i, n, o) {
    const s = await r(a, n, o), c = `/product/${a}/${t}`;
    return await new p(this.api).put(c, i, !0, s);
  }
  async publish(t, a, i, n, o) {
    const s = await r(a, n, o), c = `/product/publish/${a}/${t}`, e = new p(this.api), w = { public: i };
    return await e.put(c, w, !0, s);
  }
  async getProductCollection(t, a, i) {
    const n = await r(t, a, i), o = `/product/collection/${t}`;
    return await new p(this.api).get(o, !0, n);
  }
  async updateContactEmail(t, a, i, n) {
    const o = await r(t, i, n), s = `/product/update/contact_email/${t}`, c = new p(this.api), e = { contact_email: a };
    return await c.put(s, e, !0, o);
  }
  async countProducts(t, a, i) {
    const n = await r(t, a, i), o = `/product/count/${t}`;
    return await new p(this.api).get(o, !0, n);
  }
}
export {
  y as ProductsAPI
};
