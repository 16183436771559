var R = Object.defineProperty;
var _ = (e, i, a) => i in e ? R(e, i, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[i] = a;
var n = (e, i, a) => (_(e, typeof i != "symbol" ? i + "" : i, a), a);
var t;
(function(e) {
  e.FREE = "free", e.MINI = "mini", e.LAUNCH = "launch", e.PRO = "pro";
})(t || (t = {}));
var s;
(function(e) {
  e.ACTIVE = "active", e.INACTIVE = "inactive";
})(s || (s = {}));
var d;
(function(e) {
  e.InviteMember = "InviteMember", e.RemoveMember = "RemoveMember", e.ViewMembers = "ViewMembers", e.TransferOwnership = "TransferOwnership", e.UpdatePermissions = "UpdatePermissions", e.UpdateSettings = "UpdateSettings", e.UpdateCustomDomain = "UpdateCustomDomain", e.UpdateSubdomain = "UpdateSubdomain", e.ReadSettings = "ReadSettings", e.ReadBilling = "ReadBilling", e.UpdateBilling = "UpdateBilling", e.DeleteSpace = "DeleteSpace", e.CreatePage = "CreatePage", e.EditContent = "EditContent", e.ReadDraft = "ReadDraft", e.ReadPublishedContent = "ReadPublishedContent", e.ReadPageInfo = "ReadPageInfo", e.CreateLock = "CreateLock", e.PublishUnlimitedPages = "PublishUnlimitedPages", e.PromoteToOwner = "PromoteToOwner", e.UpdateMetadata = "UpdateMetadata", e.UpdateCustomization = "UpdateCustomization", e.UpdateAffiliate = "UpdateAffiliate", e.UpdateAnalytics = "UpdateAnalytics", e.ReadBasicAnalytics = "ReadBasicAnalytics", e.ReadAdvancedAnalytics = "ReadAdvancedAnalytics", e.Collection = "Collection", e.EmailCollectionCard = "EmailCollectionCard";
})(d || (d = {}));
var o;
(function(e) {
  e.OWNER = "owner", e.ADMIN = "admin", e.WRITER = "writer", e.READER = "reader";
})(o || (o = {}));
var l;
(function(e) {
  e.FORM = "form", e.PAGE = "page", e.SECTION = "section", e.MOBILE_SECTION = "mobile_section", e.BLOG_PAGE = "blog_page", e.FORM_PAGE = "form_page";
})(l || (l = {}));
var m;
(function(e) {
  e.AIRTABLE = "airtable", e.NOTION = "notion", e.STRIPE = "stripe";
})(m || (m = {}));
var c;
(function(e) {
  e.MAX_ITEMS = "max_items", e.FILTER_COLUMN = "filter_column", e.FILTER_COLUMN_TYPE = "filter_column_type", e.FILTER_CONDITION = "filter_condition", e.FILTER_VALUE = "filter_value", e.SORT_COLUMN = "sort_column", e.SORT_DIRECTION = "sort_direction", e.SEARCH_COLUMNS = "search_columns", e.SHOW_SEARCH_BAR = "show_search_bar", e.VISITOR_FILTER_COLUMN = "visitor_filter_column", e.VISITOR_FILTER_STYLE = "visitor_filter_style", e.VISITOR_FILTER_COLOR = "visitor_filter_color", e.VISITOR_FILTER_COLUMN_TYPE = "visitor_filter_column_type";
})(c || (c = {}));
var u;
(function(e) {
  e.FILES = "files";
})(u || (u = {}));
function U(e) {
  return e && e.name && e.schema;
}
class v {
  constructor(i) {
    n(this, "id");
    n(this, "deleted_at");
    n(this, "updated_at");
    n(this, "created_at");
    n(this, "space_id");
    n(this, "schemaVersion", 0);
    n(this, "public_permission");
    n(this, "type");
    n(this, "field");
    function a(r) {
      return r && r.name;
    }
    if (i && a(i)) {
      this.id = i.id, this.space_id = i.space_id, this.deleted_at = new Date().toString(), this.updated_at = i.updated_at, this.created_at = new Date().toString(), this.type = "form", this.public_permission = 2, this.field = {
        name: i.name,
        schemaVersion: this.schemaVersion,
        schema: i.schema,
        form_type: i.form_type
      };
      return;
    }
    if (i && !a(i)) {
      if (this.id = i.id, this.space_id = i.space_id, this.created_at = i.created_at, this.updated_at = i.updated_at, this.deleted_at = i.deleted_at, this.type = "form", this.public_permission = 2, U(i.field))
        this.field = { ...i.field };
      else
        throw Error("Invalid form collection field schema");
      return;
    }
    this.id = "", this.space_id = "", this.deleted_at = new Date().toString(), this.updated_at = new Date().toString(), this.created_at = new Date().toString(), this.public_permission = 2, this.field = { name: "", schemaVersion: this.schemaVersion, schema: {} }, this.type = "form";
  }
  getFormData() {
    return {
      id: this.id,
      space_id: this.space_id,
      updated_at: this.updated_at || new Date().toString(),
      published_at: new Date().toString(),
      name: this.field.name,
      schemaVersion: this.field.schemaVersion,
      schema: this.field.schema,
      form_type: this.field.form_type
    };
  }
  getViewLimit(i) {
    if (this.type === "form") {
      if (this.field.form_type === "email_collection")
        switch (i) {
          case t.PRO:
          case t.LAUNCH:
          case t.MINI:
            return {
              unlimited: !0,
              minimumPlan: t.MINI
            };
          case t.FREE:
          default:
            return {
              limit: 1,
              unlimited: !1,
              minimumPlan: t.MINI
            };
        }
      switch (i) {
        case t.PRO:
          return {
            unlimited: !0,
            minimumPlan: t.PRO
          };
        case t.FREE:
        case t.MINI:
        case t.LAUNCH:
        default:
          return {
            limit: 1,
            unlimited: !1,
            minimumPlan: t.PRO
          };
      }
    }
    return {
      unlimited: !0,
      minimumPlan: t.FREE
    };
  }
}
var h;
(function(e) {
  e.DIGITAL_DOWNLOAD = "digital_download";
})(h || (h = {}));
var f;
(function(e) {
  e.HERO = "hero", e.CLIENTS = "clients", e.EVENTS = "events", e.FEATURES = "features", e.PRICING = "pricing", e.CALL_TO_ACTION = "call_to_action", e.FOOTER = "footer", e.SHOP = "shop", e.COLLECTION = "collection";
})(f || (f = {}));
var p;
(function(e) {
  e.HERO = "hero", e.HIGHLIGHTS = "highlights", e.LINKS = "links";
})(p || (p = {}));
export {
  v as FormCollection,
  u as ICollectionColumnType,
  c as ICollectionDataItemMetadataKeys,
  m as ICollectionIntegration,
  p as MOBILE_SECTION_TEMPLATE_CATEGORY,
  h as PRODUCT_TYPE,
  d as SPACE_ACTION,
  o as SPACE_ROLE,
  t as SUBSCRIPTION_PLAN,
  s as SUBSCRIPTION_STATUS,
  f as TEMPLATE_CATEGORY,
  l as TEMPLATE_TYPE
};
