import i from "./defaults/index.js";
import { API as c } from "../../api/api.js";
const m = (e) => {
  const t = { ...e };
  return Object.entries(i).forEach(([s, { title: n, templates: r }]) => {
    Object.entries(r).forEach(([a, p]) => {
      var l;
      t[s] || (t[s] = {
        title: n,
        templates: {}
      });
      const o = (l = e[s]) == null ? void 0 : l.templates[a];
      t[s] = {
        ...t[s],
        templates: {
          ...t[s].templates,
          [a]: p || o
        }
      };
    });
  }), t;
}, g = () => async () => {
  const e = await c.getOrCreateInstance().template().listSection();
  return m(e.data);
}, T = (e) => async (t, s, n) => {
  const { getState: r } = e, { tokenHandlers: a } = r(), { spacePermissionTokenHandler: p } = a;
  return (await c.getOrCreateInstance().template().list(t, s, p, n)).data;
}, O = () => async (e) => (await c.getOrCreateInstance().template().listPublic(e)).data, P = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: r } = n(), { spacePermissionTokenHandler: a } = r;
  return (await c.getOrCreateInstance().template().get(t, s, a)).data;
}, f = () => async (e) => (await c.getOrCreateInstance().template().getPublic(e)).data, I = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: r } = n(), { spacePermissionTokenHandler: a } = r;
  return (await c.getOrCreateInstance().template().use(t, s, a)).data;
};
export {
  f as getPublicTemplate,
  P as getTemplate,
  g as listSectionTemplates,
  T as listTemplates,
  O as listTemplatesPublic,
  I as updateTemplateUsage
};
