import { App as m } from "../../api/app/app.js";
import { API as d } from "../../api/api.js";
import { errToString as w } from "../../utils/errors.js";
const k = (s) => async (t, r, a) => {
  var o;
  if (!t)
    return;
  const { getState: n } = s, { tokenHandlers: { spacePermissionTokenHandler: p, subscriptionTokenHandler: i } } = n();
  try {
    return await ((o = new m(d.getOrCreateInstance().api).app(r)) == null ? void 0 : o.getData(t, r, a, p, i));
  } catch (e) {
    throw new Error(w(e));
  }
}, H = (s) => async (t, r, a, n) => {
  var e;
  const { getState: p } = s, { tokenHandlers: { spacePermissionTokenHandler: i, subscriptionTokenHandler: o } } = p();
  try {
    return await ((e = new m(d.getOrCreateInstance().api).app(n)) == null ? void 0 : e.updateItem(t, r, a, n, i, o));
  } catch (c) {
    throw new Error(w(c));
  }
};
export {
  k as listCollectionItems,
  H as updateCollectionItem
};
