const e = {
  align: "align_center",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      align: "align_center",
      children: [
        {
          text: "Manage your company finances "
        },
        {
          color: "candy",
          gradient: !0,
          styled: !0,
          text: "stress free"
        },
        {
          text: "."
        }
      ],
      type: "h1"
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "Cardy helps you manage employee "
        },
        {
          bold: !0,
          color: "default",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "credit cards, subscriptions, and recurring payments"
        },
        {
          bold: !0,
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: " with no hassle on your end."
        }
      ],
      type: "p",
      width: 700
    },
    {
      align: "align_center",
      background: "candy",
      children: [
        {
          bold: !0,
          text: "Start now \u2192"
        }
      ],
      color: "text_on_accent",
      openOptions: !1,
      type: "button",
      url: ""
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      aspect_ratio: 1.5909722222222218,
      children: [
        {
          text: ""
        }
      ],
      height: 810.9807944129203,
      type: "img",
      url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/CardyDashboard.png",
      width: 1290.2479166666667
    }
  ],
  height: 1266.96875,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, t = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 348,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/bead9758-c69e-4146-811f-bfc25a8fad6f_1192875a-5c10-485d-a197-3f4a388f1981_png.png",
              width: 348
            }
          ],
          type: "column_item",
          width: 0.40626420454545514
        },
        {
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Hi, I'm Dan"
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  color: "white",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "a "
                },
                {
                  bold: !0,
                  color: "candy",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !0,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Product Designer & UI enthusiast"
                },
                {
                  color: "white",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: " based in San Francisco, currently leading design in Cardy."
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              background: "white",
              children: [
                {
                  bold: !0,
                  text: "Chat with me \u2192"
                }
              ],
              color: "black",
              gradient: !1,
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          type: "column_item",
          width: 0.5937357954545448
        }
      ],
      height: 395.890625,
      type: "column",
      width: 800
    }
  ],
  height: 583.375,
  openOptions: !1,
  type: "container",
  width: 2200,
  width_max: "100%"
}, n = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/summer_background.png",
  children: [
    {
      type: "spacer",
      height: 66.296875,
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      width: 700
    },
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "rgb(52,43, 141, 1)",
                  gradient: !1,
                  styled: !0,
                  text: "Summer 2021",
                  fontFamily: "inter"
                }
              ],
              type: "h1"
            },
            {
              children: [
                {
                  text: "",
                  styled: !0,
                  fontStyle: "subtitle1",
                  fontSize: 24,
                  lineHeight: 1.4,
                  color: "rgb(65,53, 133, 1)",
                  gradient: !1
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              openOptions: !1,
              type: "p",
              children: [
                {
                  styled: !0,
                  fontStyle: "subtitle1",
                  fontSize: 24,
                  lineHeight: 1.4,
                  color: "rgb(65,53, 133, 1)",
                  gradient: !1,
                  text: "Get ready for Summer \u2013 ",
                  fontFamily: "libre_caslon_text"
                },
                {
                  styled: !0,
                  fontStyle: "subtitle1",
                  fontSize: 24,
                  lineHeight: 1.4,
                  color: "rgb(65,53, 133, 1)",
                  gradient: !1,
                  text: "protect your skin.",
                  bold: !0,
                  fontFamily: "libre_caslon_text"
                }
              ]
            },
            {
              children: [
                {
                  text: "Get a custom mixed suncream just for you.",
                  styled: !0,
                  fontStyle: "subtitle3",
                  fontSize: 16,
                  color: "gray"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              openOptions: !1,
              type: "p",
              children: [
                {
                  styled: !0,
                  fontStyle: "subtitle2",
                  fontSize: 20,
                  color: "gray",
                  lineHeight: 1.4,
                  text: ""
                }
              ]
            },
            {
              openOptions: !1,
              type: "button",
              children: [
                {
                  text: "Unlock Your "
                },
                {
                  text: "FREE",
                  bold: !0
                },
                {
                  text: " Offer"
                }
              ],
              url: "",
              background: "rgb(52,43, 141, 1)",
              color: "white",
              gradient: !1
            },
            {
              children: [
                {
                  text: "30-day trial. Just cover $3.85 in shipping + handling",
                  styled: !0,
                  fontStyle: "small",
                  fontSize: 13,
                  color: "gray",
                  gradient: !1
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              children: [
                {
                  color: "rgb(52,43, 141, 1)",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 430,
      type: "column",
      width: 900
    },
    {
      type: "spacer",
      height: 50,
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1
    }
  ],
  height: 586.296875,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, i = {
  type: "container",
  background: "default",
  align: "align_center",
  children: [
    {
      aspect_ratio: 0.682170542635659,
      children: [
        {
          text: ""
        }
      ],
      height: 57.6335227272727,
      openOptions: !1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/a07b0c6d-c5a2-44ed-baa4-e0a2ad95545e_flogo_png.png",
      width: 39.3158914728682
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          fontFamily: "libre_caslon_text",
          styled: !0,
          text: "Frank Seawater"
        }
      ],
      type: "h2"
    },
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "UI/UX & Web Designer Based in Spain"
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "ABOUT ME"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "I design for love! Hey, I'm Frank, and I'm passionate about UI/UX & web design."
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "CONTACT"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "frank@seawater.com"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "+34910603065"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "SERVICES"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Website Design"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Web Platform Design"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Mobile Application Design"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.6662500000000001,
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              height: 437.42682926829235,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/a0bc5e8e-8659-4602-a534-b7856b1d9c77_petrportrait_png.png",
              width: 291.43562499999985
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "CLIENTS"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "45+"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "PROJECTS"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "325+"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "EXPERIENCE"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "6+ Years"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  bold: !0,
                  color: "gray",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "SATISFACTION GUARANTEE"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "100%"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        }
      ],
      height: 483,
      type: "column",
      vertical_align: "align_start",
      width: 1100
    }
  ],
  openOptions: !1,
  height: 833.625,
  width: 1200,
  width_max: "100%"
}, l = {
  align: "align_center",
  backgroundImage: "https://api.typedream.com/v0/document/public/ecaa1a9f-59e0-4714-aca4-d68090e95ef2_fuel-bg_png.png",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 41.953125,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 41.953125,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      aspect_ratio: 1,
      children: [
        {
          color: "default",
          fontFamily: "libre_caslon_text",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "/im"
        }
      ],
      height: 144,
      openOptions: !1,
      optionIndex: 1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/85c8d5cf-279f-4126-9c84-66064e146430_fuel-logo_png.png",
      width: 144
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 54.921875,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          fontFamily: "libre_caslon_text",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: `Taste the Juice.
Fuel your Soul.`,
          color: "black",
          gradient: !1
        }
      ],
      openOptions: !1,
      optionIndex: 1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontFamily: "libre_caslon_text",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      optionIndex: 1,
      type: "p"
    },
    {
      aspect_ratio: 1.1540342298288508,
      children: [
        {
          text: ""
        }
      ],
      height: 805.5572364936438,
      openOptions: !1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/38387a20-ebc5-4199-9979-22869ab2aabe_fuel-pouch_png.png",
      width: 929.6406249999997
    }
  ],
  height: 1381.515625,
  openOptions: !1,
  type: "container",
  width: 1800,
  width_max: "100%"
}, a = {
  align: "align_center",
  backgroundImage: "https://api.typedream.com/v0/document/public/33834a3f-0212-4a18-bd58-683f46456d13_076226af-7dac-4183-b9a2-db018b01a06d_gradient_60_png_png.png",
  children: [
    {
      align: "align_center",
      background: "white",
      children: [
        {
          bold: !0,
          fontFamily: "space_mono",
          text: "penguinlabs"
        }
      ],
      color: "black",
      gradient: !1,
      openOptions: !1,
      type: "button",
      url: ""
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 74.984375,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontFamily: "space_mono",
          gradient: !1,
          italic: !0,
          styled: !0,
          text: "buy"
        },
        {
          color: "white",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          italic: !0,
          styled: !0,
          text: "  "
        },
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "and  "
        },
        {
          color: "white",
          fontFamily: "space_mono",
          gradient: !1,
          italic: !0,
          styled: !0,
          text: "own"
        }
      ],
      openOptions: !1,
      type: "h1"
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "penguin digital arts"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      background: "black",
      children: [
        {
          align: "align_center",
          children: [
            {
              color: "white",
              gradient: !1,
              styled: !0,
              text: "join the penguinlabs"
            }
          ],
          type: "h2"
        }
      ],
      height: 116,
      internal_link: !1,
      openOptions: !1,
      style: "card",
      type: "container",
      url: "#join-the-lab",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "The penguinlabs is a collection of 60,000 of penguin NFTs from the world's top artists."
        }
      ],
      openOptions: !1,
      type: "p"
    }
  ],
  height: 903,
  openOptions: !1,
  type: "container",
  width: 1400,
  width_max: "100%"
}, o = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://api.typedream.com/v0/document/public/09cffba2-baf2-44b4-a732-6c39739945cb_kendal-hnysCJrPpkc-unsplash_png.png",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Faraland & Co"
        }
      ],
      openOptions: !1,
      type: "h3",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 110.53125,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "Organic & Natural"
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          color: "rgba(255, 255, 255, 0.5)",
          fontFamily: "inter",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Your skin deserves 100% natural ingredients"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 800
    },
    {
      align: "align_center",
      children: [
        {
          color: "rgba(255, 255, 255, 0.54)",
          fontFamily: "libre_caslon_text",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 800
    },
    {
      align: "align_center",
      background: "white",
      children: [
        {
          text: "See our products \u2192"
        }
      ],
      color: "black",
      gradient: !1,
      openOptions: !1,
      type: "button",
      url: "",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 110.53125,
      openOptions: !1,
      type: "spacer",
      width: 700
    }
  ],
  height: 1079,
  openOptions: !1,
  type: "container",
  width: 2300,
  width_max: "100%"
}, r = {
  align: "align_center",
  backgroundImage: "https://api.typedream.com/v0/document/public/f95018a7-0031-4e20-9ee2-f8ae0feeac2a_gradient_11_png.png",
  children: [
    {
      align: "align_right",
      children: [
        {
          fontFamily: "space_mono",
          styled: !0,
          text: "\u26AA\uFE0F "
        },
        {
          color: "white",
          fontFamily: "space_mono",
          gradient: !1,
          styled: !0,
          text: "CardyFund"
        },
        {
          fontFamily: "space_mono",
          styled: !0,
          text: "  "
        }
      ],
      openOptions: !1,
      type: "h3",
      width_max: "100%"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_right",
              aspect_ratio: 0.8666666666666674,
              children: [
                {
                  text: ""
                }
              ],
              height: 347.0769230769228,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/a633ff9d-cdce-47cc-b08f-b18e8735a793_Platonic_2_-_Dodeca0011_png.png",
              width: 300.8
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "w"
                },
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "e            "
                },
                {
                  fontFamily: "space_mono",
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: " i"
                },
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "nvest       "
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "   "
                },
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "in the     "
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "     future.  "
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  fontFamily: "space_mono",
                  styled: !0,
                  text: "VIEW PORTFOLIO"
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "down-arrow-alt",
                  type: "regular"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              type: "button",
              url: "#companies"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_left",
              aspect_ratio: 0.7500000000000002,
              children: [
                {
                  text: ""
                }
              ],
              height: 640.0885416666663,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/a64602f2-6cdf-43ea-9dd0-f6499d121a90_simon-lee-U00xWfo5yJA-unsplash_jpg.jpg",
              width: 480.0664062499999
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 809,
      type: "column",
      width: 1100
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 10,
      html: '<div id="companies"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    }
  ],
  height: 915.328125,
  openOptions: !1,
  type: "container",
  width: 1e3,
  width_max: "100%"
}, c = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://api.typedream.com/v0/document/public/c829c6bd-2203-43d9-8d3d-98ab5b29b18a_MacBook_Pro_16__-_2_png.png",
  children: [
    {
      align: "align_center",
      background: "default",
      children: [
        {
          children: [
            {
              children: [
                {
                  background: "accent",
                  children: [
                    {
                      color: "orange",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  icon: {
                    content: {
                      color: "white",
                      name: "error-circle",
                      type: "solid"
                    },
                    type: "icon"
                  },
                  openOptions: !1,
                  type: "button",
                  url: ""
                }
              ],
              type: "column_item",
              width: 0.1251241310824226
            },
            {
              children: [
                {
                  children: [
                    {
                      color: "indigo",
                      gradient: !1,
                      styled: !0,
                      text: "Please follow "
                    },
                    {
                      children: [
                        {
                          color: "indigo",
                          gradient: !1,
                          styled: !0,
                          text: "this guide",
                          underline: !0
                        }
                      ],
                      internal_link: !1,
                      open_in_new_tab: !0,
                      type: "a",
                      url: "https://typedream.com/docs/integration/rampp"
                    },
                    {
                      color: "indigo",
                      gradient: !1,
                      styled: !0,
                      text: " to use this template"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.8748758689175774
            }
          ],
          height: 108,
          type: "column",
          width: 700
        }
      ],
      height: 148,
      openOptions: !1,
      style: "card",
      type: "container",
      width: 700
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1.1200000000000003,
              children: [
                {
                  text: ""
                }
              ],
              height: 412.9508928571428,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/3fbbf745-1d0b-4e0c-adb1-6c3551d60c6c_vighnesh-dudani-ZQSs0YZUNfA-unsplash_1_png.png",
              width: 462.50500000000005
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "SOCIETY"
                },
                {
                  color: "gray",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "\xB3"
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "MINT OUR ACCESS NFT"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "// gain access to our private discord"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "// receive airdrops and swag no one else can"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "// be the first to try new features"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 113.84375,
              html: `<!-- Minting button provided by Rampp.xyz -->
  <div class='rampp-minting-container'>
  <button
    class='rampp-minting-button' 
    style='display:none'
    data-styles='eyJvcGVuIjp7InRleHQiOiJNSU5UIiwic3R5bGVzIjoiYm9yZGVyOm5vbmU7d2lkdGg6IDE1cmVtO3BhZGRpbmc6MC41cmVtO2ZvbnQtc2l6ZTogMS4xMjVyZW07bGluZS1oZWlnaHQ6IDEuNzVyZW07dGV4dC1hbGlnbjogY2VudGVyO2N1cnNvcjogcG9pbnRlcjtib3JkZXItcmFkaXVzOjAuMzc1cmVtO2NvbG9yOiMwMDAwMDA7YmFja2dyb3VuZC1jb2xvcjojZGRkMWZmOyJ9LCJwYXVzZWQiOnsidGV4dCI6Ik1pbnQgQ2xvc2VkIiwic3R5bGVzIjoiYm9yZGVyOm5vbmU7d2lkdGg6IDE1cmVtO3BhZGRpbmc6MC41cmVtO2ZvbnQtc2l6ZTogMS4xMjVyZW07bGluZS1oZWlnaHQ6IDEuNzVyZW07dGV4dC1hbGlnbjogY2VudGVyO2N1cnNvcjogcG9pbnRlcjtib3JkZXItcmFkaXVzOjAuMzc1cmVtO2NvbG9yOiM2NzY1NjU7YmFja2dyb3VuZC1jb2xvcjojQ0NEQkRDO2N1cnNvcjpub3QtYWxsb3dlZDsifSwic3VwcGx5UmVhY2hlZCI6eyJ0ZXh0IjoiQWxsIFRva2VucyBNaW50ZWQhIiwic3R5bGVzIjoiYm9yZGVyOm5vbmU7d2lkdGg6IDE1cmVtO3BhZGRpbmc6MC41cmVtO2ZvbnQtc2l6ZTogMS4xMjVyZW07bGluZS1oZWlnaHQ6IDEuNzVyZW07dGV4dC1hbGlnbjogY2VudGVyO2N1cnNvcjogcG9pbnRlcjtib3JkZXItcmFkaXVzOjAuMzc1cmVtO2NvbG9yOiNmZjAwMDA7YmFja2dyb3VuZC1jb2xvcjojZmZiOGI4O2N1cnNvcjpub3QtYWxsb3dlZDsifSwiZXJyb3IiOnsidGV4dCI6Ik1pbnRpbmcgVW5hdmFpbGFibGUiLCJzdHlsZXMiOiJib3JkZXI6bm9uZTt3aWR0aDogMTVyZW07cGFkZGluZzowLjVyZW07Zm9udC1zaXplOiAxLjEyNXJlbTtsaW5lLWhlaWdodDogMS43NXJlbTt0ZXh0LWFsaWduOiBjZW50ZXI7Y3Vyc29yOiBwb2ludGVyO2JvcmRlci1yYWRpdXM6MC4zNzVyZW07Y29sb3I6I2ZmMDAwMDtiYWNrZ3JvdW5kLWNvbG9yOiNmZmI4Yjg7Y3Vyc29yOm5vdC1hbGxvd2VkOyJ9LCJjbGFpbVRleHQiOnsidGV4dCI6bnVsbCwic3R5bGVzIjoiY29sb3I6IHJnYmEoMTU2LCAxNjMsIDE3NSk7Zm9udC1zaXplOiAwLjc1cmVtO2xpbmUtaGVpZ2h0OiAxcmVtO3RleHQtYWxpZ246IGNlbnRlcjtwYWRkaW5nLXRvcDogMC4yNXJlbTtwYWRkaW5nLWJvdHRvbTogMC4yNXJlbTttYXJnaW46MDtmb250LWZhbWlseTpzYW5zLXNlcmlmOyJ9LCJxdWFudGl0eSI6eyJ0ZXh0IjpudWxsLCJzdHlsZXMiOiJ3aWR0aDo0MHB4O2NvbG9yOiNkZGQxZmY7Ym9yZGVyLXN0eWxlOnNvbGlkO2JvcmRlci13aWR0aDoxcHg7Ym9yZGVyLWNvbG9yOiNkZGQxZmY7Ym9yZGVyLXJhZGl1czowLjM3NXJlbTtmb250LXNpemU6MS4zcmVtO3RleHQtYWxpZ246Y2VudGVyOyJ9fQ=='
    data-abi-link="https://firebasestorage.googleapis.com/v0/b/nft-rampp.appspot.com/o/solidity_outputs%2FNVhnB0HtFKk7tJSy4QMD%2FHiddenVillageTestContract_data-9b0e090f-1f8e-49f4-83ea-eceeae74d3e2.json?alt=media"
    data-redirect="https://google.com"
    data-contract-address="0x178eF1577c71E632A95f6aF6295D3B206df53A9F"
    data-show-claim-count="undefined"
    data-network="testnet"
    data-format="single"></button>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/web3/1.7.0-rc.0/web3.min.js" crossorigin="anonymous" referrerpolicy="no-referrer"><\/script>
    <script type='text/javascript' src="https://rampp.xyz/embeds/embed.js"><\/script>
  <!-- End Rampp.xyz minting button-->`,
              openOptions: !1,
              type: "media_embed",
              width: 570
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 733.6875,
      type: "column",
      width: 1200,
      width_max: "100%"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    }
  ],
  height: 953.6875,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, d = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "gray",
                  name: "wrench",
                  type: "regular"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              type: "button",
              url: "#build"
            }
          ],
          type: "column_item",
          width: 0.16667
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "gray",
                  name: "pen",
                  type: "solid"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              type: "button",
              url: "#write"
            }
          ],
          type: "column_item",
          width: 0.16667
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "gray",
                  name: "headphone",
                  type: "regular"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              type: "button",
              url: "#listen"
            }
          ],
          type: "column_item",
          width: 0.16667
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "gray",
                  name: "brush",
                  type: "solid"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              type: "button",
              url: "#nft"
            }
          ],
          type: "column_item",
          width: 0.16667
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "gray",
                  name: "github",
                  type: "logo"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          type: "column_item",
          width: 0.16667
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "gray",
                  name: "twitter",
                  type: "logo"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          type: "column_item",
          width: 0.16667
        }
      ],
      height: 176,
      type: "column",
      width: 900
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 109,
      openOptions: !1,
      type: "spacer",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 800
    },
    {
      children: [
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "Hey, I'm "
        },
        {
          bold: !0,
          color: "nebula",
          gradient: !0,
          styled: !0,
          text: "Sally"
        },
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: " "
        }
      ],
      type: "h1",
      width: 800
    },
    {
      children: [
        {
          text: "I'm a third-year undergrad studying computer science and cognitive science. I grew up in New Jersey, currently living in NYC. My passions include web3, design, and boba \u{1F9CB}."
        }
      ],
      type: "p",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p",
      width: 800
    },
    {
      children: [
        {
          text: "Currently, I'm running a student-led class at my college, where I teach UI/UX design to fellow students. This summer I'll be working as a front-end developer at Cardy. "
        }
      ],
      type: "p",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p",
      width: 800
    },
    {
      children: [
        {
          text: "It's nice to meet you! \u{1F44B}\u{1F3FC}"
        }
      ],
      type: "p",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p",
      width: 800
    },
    {
      align: "align_right",
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "\u2193"
                }
              ],
              type: "p",
              width: 800
            }
          ],
          type: "column_item",
          width: 0.06864406779661027
        },
        {
          children: [
            {
              children: [
                {
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  styled: !0,
                  text: "Scroll "
                }
              ],
              type: "p",
              width: 800
            }
          ],
          type: "column_item",
          width: 0.9313559322033899
        }
      ],
      height: 113.59375762939453,
      type: "column",
      width: 800
    }
  ],
  height: 829.59375,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, p = {
  align: "align_center",
  background: "default",
  children: [
    {
      align: "align_center",
      backgroundImage: "https://api.typedream.com/v0/document/public/c7083d6a-4cb2-4f76-a325-628e0883331c_joshua-chun-BV30M3K-EFk-unsplash_jpg.jpg",
      children: [
        {
          align: "align_center",
          children: [
            {
              color: "white",
              fontFamily: "libre_caslon_text",
              gradient: !1,
              styled: !0,
              text: ""
            },
            {
              color: "white",
              fontFamily: "libre_caslon_text",
              gradient: !1,
              styled: !0,
              text: "Now and forevermore."
            }
          ],
          openOptions: !1,
          type: "h1"
        },
        {
          align: "align_center",
          children: [
            {
              color: "white",
              fontSize: 20,
              fontStyle: "subtitle2",
              gradient: !1,
              lineHeight: 1.4,
              styled: !0,
              text: "We'd love to have you join us on our big day."
            }
          ],
          type: "p"
        }
      ],
      height: 901,
      openOptions: !1,
      type: "container",
      width: 1200,
      width_max: "100%"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 87,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Dear friends and family,"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "inter",
          fontSize: 16,
          fontStyle: "subtitle3",
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "Life update: we're getting married! "
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "We're so lucky to have you as our closest and could not be happier to share this news with you! It would mean the world to us for you to join us in celebration of this new chapter, online or in-person."
        }
      ],
      type: "p",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "default",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "We've sent a formal invite to your addresses with some goodies :) While you wait for the mail to arrive, enjoy these photos from our shoot!"
        }
      ],
      type: "p",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "Much love, "
        }
      ],
      type: "p",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "  "
        }
      ],
      type: "p",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "John & Ashley"
        }
      ],
      type: "p",
      width: 500
    }
  ],
  height: 1623.75,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, s = {
  align: "align_center",
  background: "default",
  backgroundDarken: !0,
  backgroundImage: "https://api.typedream.com/v0/document/public/3e9e73c8-b537-4469-aa23-27b945a5dbde_green-7_png.png?bucket=document",
  children: [
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Jobs @ Coinbooks"
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 1800
    },
    {
      children: [
        {
          color: "white",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: `If you love startups, building + shipping, moving fast, 
challenging people on their assumptions, and deliver 
BIG impact, you\u2019re in the right place!`
        }
      ],
      type: "p",
      width: 1800
    },
    {
      children: [
        {
          color: "white",
          fontFamily: "inter",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 1800
    },
    {
      background: "black",
      children: [
        {
          text: "View available roles \u2192"
        }
      ],
      color: "white",
      gradient: !1,
      internal_link: !1,
      type: "button",
      url: "#roles",
      width: 1800
    }
  ],
  height: 561,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 1900
}, h = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  text: "How to start an online business"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: "The Ultimate Guide To Making Your "
                },
                {
                  color: "purple",
                  gradient: !1,
                  styled: !0,
                  text: "1st Dollar"
                },
                {
                  text: " Online"
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  text: "Starting from content creation, building a service, to making templates, all you need to know about making money \u2013 online."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              aspect_ratio: 0.7496855345911949,
              children: [
                {
                  text: ""
                }
              ],
              height: 795,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/65b69570-98db-4a7d-ad6c-3a06fe6a2cbc_Group_18_png.png?bucket=document",
              width: 596
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 673,
      type: "column",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    }
  ],
  height: 853,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, g = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  fontFamily: "space_mono",
                  styled: !0,
                  text: "wagmi Podcast"
                }
              ],
              type: "h2"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Where founders, builders, artists, and creators on the web3 space share their experience, tips, and learnings"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              aspect_ratio: 1.3333333333333333,
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              height: 480,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/679ff40b-0543-4318-b1ac-c9fc84616914_austin-distel-wD1LRb9OeEo-unsplash_jpg.jpg?bucket=document",
              width: 640
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 355,
      type: "column",
      width: 900
    },
    {
      align: "align_center",
      children: [
        {
          children: [
            {
              aspect_ratio: 4.125,
              children: [
                {
                  text: ""
                }
              ],
              height: 34.23766165068657,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/5ee0110a-472e-4257-b0ea-d9cb5f6a78d2_US_UK_Apple_Podcasts_Listen_Badge_RGB_svg.svg?bucket=document",
              width: 141.2303543090821
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              aspect_ratio: 4.124999999999999,
              children: [
                {
                  text: ""
                }
              ],
              height: 35.183321866122164,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/6e2cefc7-5f15-4b01-b582-f32cf666169a_spotify-podcast-badge-blk-grn-660x160_svg.svg?bucket=document",
              width: 145.1312026977539
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 77.578125,
      mobile_direction: "direction_horizontal",
      type: "column",
      width: 300
    }
  ],
  height: 605,
  openOptions: !1,
  type: "container",
  width: 1500,
  width_max: "100%"
}, y = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          aspect_ratio: 1.7913043478260864,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "image"
                            }
                          ],
                          height: 317.8462530339807,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/4386685e-fa1d-47b8-aa69-434327bf3a9f/andre-benz-tPPi0jzLP_w-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=d8379cafed973e92c62d9a83bcde1945a677c6a795b12cef2b7c4d6ef6e035f6&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 569.359375
                        }
                      ],
                      type: "column_item",
                      width: 0.5973869346733671
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Location"
                                }
                              ],
                              color: "default",
                              fontFamily: "libre_caslon_text",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "Santorini, Greece"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              color: "rgba(170, 170, 170, 1)",
                              fontFamily: "libre_caslon_text",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: ""
                            }
                          ],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              background: "clear",
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "libre_caslon_text",
                              fontSize: 24,
                              fontStyle: "subtitle1",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Experiencing what it\u2019s like to live in Santorini"
                            }
                          ],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "snippet"
                                }
                              ],
                              color: "gray",
                              fontFamily: "libre_caslon_text",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "After my trip to history-rich Athens, I made my way to Santorini and spent a week living with locals."
                            }
                          ],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              text: ""
                            }
                          ],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "date"
                                }
                              ],
                              color: "rgba(170, 170, 170, 1)",
                              fontFamily: "libre_caslon_text",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "2022-02-12"
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.4026130653266331
                    }
                  ],
                  height: 417.0625,
                  type: "column",
                  width: 900,
                  width_max: "100%"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "722af3f5-4d2f-4f2d-951a-ac8125294a32",
              collection_link_slug: "experiencing-what-it-s-like-to-live-in-santorini",
              height: 544.03125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_center",
              width: 900,
              width_max: "100%"
            }
          ],
          collection_item_id: "722af3f5-4d2f-4f2d-951a-ac8125294a32",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        }
      ],
      collection_data_id: "3684efd5-8027-443e-953c-e3517ff838f0",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/57af7a35f5e0483588050c3fdb2f6a8b?v=838051e8fb784ba88bf12a53b50ab491"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_4.png",
          imageDark: "/images/collectionTemplates/blog_4_dark.png"
        },
        schema: {
          "Link-4dk0": null,
          Location: "Location",
          date: "Date",
          image: "Cover",
          snippet: "Snippet",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "date",
          "image",
          "snippet",
          "title",
          "url",
          "Link-4dk0",
          "Location"
        ],
        settings: {
          numCol: 1
        },
        template: {
          align: "align_left",
          children: [
            {
              align: "align_left",
              children: [
                {
                  children: [
                    {
                      align: "align_center",
                      aspect_ratio: 1.7913043478260868,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "image"
                        }
                      ],
                      height: 317.8462530339807,
                      type: "img",
                      url: "https://typedream-assets.sfo3.digitaloceanspaces.com/knowledgebase-1.png",
                      width: 569.359375
                    }
                  ],
                  type: "column_item",
                  width: 0.5973869346733671
                },
                {
                  children: [
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "Location"
                            }
                          ],
                          color: "default",
                          fontFamily: "libre_caslon_text",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Sartorini, Greece"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          color: "rgba(170, 170, 170, 1)",
                          fontFamily: "libre_caslon_text",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: ""
                        }
                      ],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          background: "clear",
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "title"
                            }
                          ],
                          color: "default",
                          fontFamily: "libre_caslon_text",
                          fontSize: 24,
                          fontStyle: "subtitle1",
                          gradient: !1,
                          lineHeight: 1.4,
                          styled: !0,
                          text: "How to Build a Startup 101"
                        }
                      ],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "snippet"
                            }
                          ],
                          color: "gray",
                          fontFamily: "libre_caslon_text",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Target a big market, build an MVP with the least amount of tech, and launch!"
                        }
                      ],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          text: ""
                        }
                      ],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "date"
                            }
                          ],
                          color: "rgba(170, 170, 170, 1)",
                          fontFamily: "libre_caslon_text",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Feb 2020"
                        }
                      ],
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.4026130653266331
                }
              ],
              height: 417.0625,
              type: "column",
              width: 900,
              width_max: "100%"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 544.03125,
          openOptions: !1,
          style: "plain",
          type: "container",
          vertical_align: "align_center",
          width: 900,
          width_max: "100%"
        },
        templateKey: "blog:blog_4"
      },
      openOptions: !1,
      type: "collection",
      width: 1400
    }
  ],
  height: 615.515625,
  openOptions: !1,
  type: "container",
  width: 1500
}, f = {
  align: "align_center",
  background: "clear",
  children: [
    {
      align: "align_right",
      animation: {
        direction: "down",
        type: "slide_in"
      },
      children: [
        {
          color: "default",
          fontFamily: "quicksand",
          gradient: !1,
          styled: !0,
          text: "I'm"
        },
        {
          color: "default",
          fontFamily: "quicksand",
          gradient: !1,
          styled: !0,
          text: " Bruno"
        },
        {
          color: "default",
          fontFamily: "quicksand",
          gradient: !1,
          styled: !0,
          text: "."
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 1200
    },
    {
      align: "align_right",
      animation: {
        direction: "down",
        type: "slide_in"
      },
      children: [
        {
          bold: !0,
          color: "default",
          fontFamily: "quicksand",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "A product designer at Wrapped \u{1F4E6}"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1200
    }
  ],
  height: 196,
  openOptions: !1,
  type: "container",
  width: 1300
}, u = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      background: "#121212",
      children: [
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 505,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/086afd68-087e-48e6-88c5-9c5058d3cdb5_profile-pic_png.png?bucket=document",
              width: 505
            }
          ],
          type: "column_item",
          width: 0.3704702042711242
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.10584624883936836
        },
        {
          children: [
            {
              children: [
                {
                  color: "white",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  gradient: !1,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: `Hey.
My name is`
                },
                {
                  color: "default",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: " "
                },
                {
                  color: "rgba(245, 183, 184, 1)",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  gradient: !1,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: "Alice"
                },
                {
                  color: "default",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: `.
`
                },
                {
                  color: "white",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  gradient: !1,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: "But I also go by"
                },
                {
                  color: "default",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: `
`
                },
                {
                  animation: {
                    type: "scroll_words",
                    words: [
                      "Hyejin",
                      "\u6167\u73CD"
                    ]
                  },
                  color: "rgba(245, 183, 184, 1)",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  gradient: !1,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: "\uD61C\uC9C4"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: "Follow me on Instagram"
                }
              ],
              color: "white",
              gradient: !1,
              icon: {
                content: {
                  color: "white",
                  name: "instagram-alt",
                  type: "logo"
                },
                type: "icon"
              },
              internal_link: !1,
              openOptions: !1,
              open_in_new_tab: !0,
              type: "button",
              url: "http://instagram.com/typedreamhq"
            }
          ],
          type: "column_item",
          width: 0.5236735468895085
        }
      ],
      height: 958,
      type: "column",
      width: 900
    }
  ],
  height: 998,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, m = {
  align: "align_center",
  background: "white",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      animation: {
        direction: "right",
        type: "slide_in"
      },
      children: [
        {
          color: "#121212",
          gradient: !1,
          styled: !0,
          text: "Welcome home."
        }
      ],
      type: "h3",
      width: 1e3
    },
    {
      align: "align_left",
      animation: {
        direction: "right",
        type: "slide_in"
      },
      children: [
        {
          color: "black",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "We're stoked to have "
        },
        {
          color: "black",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "you"
        },
        {
          color: "black",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: " join our team. "
        }
      ],
      type: "p",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      animation: {
        type: "fade_in"
      },
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "Your team \u2022 "
        },
        {
          children: [
            {
              color: "gray",
              gradient: !1,
              styled: !0,
              text: "Setup checklist"
            }
          ],
          internal_link: !1,
          open_in_new_tab: !1,
          type: "a",
          url: "#checklist"
        },
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: " \u2022\xA0"
        },
        {
          children: [
            {
              color: "gray",
              gradient: !1,
              styled: !0,
              text: "Resources & Tools"
            }
          ],
          internal_link: !1,
          open_in_new_tab: !1,
          type: "a",
          url: "#resources"
        },
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: " \u2022\xA0"
        },
        {
          children: [
            {
              color: "gray",
              gradient: !1,
              styled: !0,
              text: "FAQs"
            }
          ],
          internal_link: !1,
          open_in_new_tab: !1,
          type: "a",
          url: "#FAQ"
        },
        {
          text: ""
        }
      ],
      type: "p",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    }
  ],
  height: 411.25,
  openOptions: !1,
  type: "container",
  width: 1100
}, _ = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      optionIndex: 2,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontFamily: "space_mono",
          gradient: !1,
          styled: !0,
          text: "/* tiny builds */"
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          fontFamily: "space_mono",
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "What we're building right now "
        }
      ],
      type: "h3"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        },
        {
          children: [
            {
              color: "gray",
              fontSize: 20,
              fontStyle: "subtitle2",
              lineHeight: 1.4,
              styled: !0,
              text: "Take jam.ai for a spin \u2192"
            }
          ],
          internal_link: !1,
          open_in_new_tab: !1,
          type: "a",
          url: "http://typedream.com"
        },
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      aspect_ratio: 1.7777777777777777,
      children: [
        {
          text: ""
        }
      ],
      height: 393.75,
      openOptions: !1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/ee0602f3-212b-4722-a314-e020f0ac99e0_Twitter_post_-_1_1_png.png?bucket=document",
      width: 700
    }
  ],
  height: 685,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, b = {
  align: "align_center",
  background: "white",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "#121212",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "bye bye wrinkles."
        }
      ],
      type: "h2"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "the only skin care product you'll ever need."
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 500,
      html: "<iframe src='https://my.spline.design/facialcreamboxcopy-86ae7d5649fbf6adec57dbcc51713e14/' frameborder='0' width='100%' height='100%'></iframe>",
      openOptions: !1,
      type: "media_embed",
      width: 824.671875
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      background: "white",
      children: [
        {
          bold: !0,
          fontFamily: "quicksand",
          styled: !0,
          text: "checkout"
        }
      ],
      color: "black",
      gradient: !1,
      internal_link: !1,
      openOptions: !1,
      open_in_new_tab: !0,
      type: "button",
      url: "https://checkout.stripe.com/pay/cs_test_b1MTUAcJLD7hg0eac7yMor3RvJMXk4jGh5wWWVm0Y02lhzOkZnsd5rkQgz?demoWallet=applePay&demoPolicies=true#fidkdWxOYHwnPyd1blpxYHZxWjA0TUM1Yl9GT1c0a25sZjdSa2ppakZgQzdxVz1qYk9pcUBnNTc9Z11kVnc9b0F%2FREgxfE5oQ31Dd2dGME9sQVx2Tm1wc3RyRGhPZjIwTzRLYmd3TnJDSjJMNTVJMUBtbWNMQycpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPydocGlxbFpscWBoJyknYGtkZ2lgVWlkZmBtamlhYHd2Jz9xd3BgeCUl"
    }
  ],
  height: 995,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, x = {
  align: "align_center",
  background: "#18181a",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          strikethrough: !0,
          styled: !0,
          text: "not so"
        },
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: " ordinary tee."
        }
      ],
      type: "h2"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "made from 100% recycled cotton."
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 500,
      html: "<iframe src='https://my.spline.design/tshirtbodymaledarkcopy-f33d8651047c6a26e7dd62660ea94c1d/' frameborder='0' width='100%' height='100%'></iframe>",
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      background: "white",
      children: [
        {
          bold: !0,
          text: "buy"
        }
      ],
      color: "black",
      gradient: !1,
      internal_link: !1,
      openOptions: !1,
      open_in_new_tab: !0,
      type: "button",
      url: "https://checkout.stripe.com/pay/cs_test_b1MTUAcJLD7hg0eac7yMor3RvJMXk4jGh5wWWVm0Y02lhzOkZnsd5rkQgz?demoWallet=applePay&demoPolicies=true#fidkdWxOYHwnPyd1blpxYHZxWjA0TUM1Yl9GT1c0a25sZjdSa2ppakZgQzdxVz1qYk9pcUBnNTc9Z11kVnc9b0F%2FREgxfE5oQ31Dd2dGME9sQVx2Tm1wc3RyRGhPZjIwTzRLYmd3TnJDSjJMNTVJMUBtbWNMQycpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPydocGlxbFpscWBoJyknYGtkZ2lgVWlkZmBtamlhYHd2Jz9xd3BgeCUl"
    }
  ],
  height: 950,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, w = {
  align: "align_center",
  background: "rgba(201, 226, 101, 1)",
  children: [
    {
      aspect_ratio: 1,
      children: [
        {
          text: ""
        }
      ],
      height: 514.859375,
      openOptions: !1,
      type: "img",
      url: "https://api.typedream.com/v0/document/public/a2016e00-f8d7-484e-9d84-b15ec25952e4_Vintage_Hipster_Sweet_Dessert_Shop_Logo_png.png?bucket=document",
      width: 514.859375
    },
    {
      align: "align_center",
      background: "rgba(46, 189, 1, 1)",
      children: [
        {
          bold: !0,
          fontFamily: "quicksand",
          styled: !0,
          text: "Pre-order your tub"
        }
      ],
      color: "text_on_accent",
      openOptions: !1,
      type: "button",
      url: ""
    }
  ],
  height: 861,
  openOptions: !1,
  type: "container",
  width: 900,
  width_max: "100%"
}, O = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/3a14cba8-183c-40b7-ab37-6966f7d37f3f_evie-s-uuCjYxJVf4o-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  align: "align_right",
                  children: [
                    {
                      color: "black",
                      fontFamily: "libre_caslon_text",
                      gradient: !1,
                      styled: !0,
                      text: "\u65E5"
                    }
                  ],
                  openOptions: !1,
                  type: "h1",
                  width: 900
                }
              ],
              internal_link: !1,
              openOptions: !1,
              style: "card",
              type: "container",
              url: "#day",
              vertical_align: "align_center"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/e2c32735-df6b-4939-be8f-af0fbaaedc74_mak-3hV6Y42xUJk-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  align: "align_left",
                  children: [
                    {
                      color: "white",
                      fontFamily: "libre_caslon_text",
                      gradient: !1,
                      styled: !0,
                      text: "\u591C"
                    }
                  ],
                  openOptions: !1,
                  type: "h1",
                  width: 900
                }
              ],
              internal_link: !1,
              openOptions: !1,
              style: "card",
              type: "container",
              url: "#night",
              vertical_align: "align_center"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 822,
      type: "column",
      width: 1200
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          color: "default",
          fontFamily: "inter",
          styled: !0,
          text: "  alex phan. "
        }
      ],
      type: "h3",
      width: 1100
    },
    {
      children: [
        {
          text: "  photographer based in nyc."
        }
      ],
      type: "p",
      width: 1100
    }
  ],
  height: 1013.25,
  openOptions: !1,
  type: "container",
  width: 1100
};
export {
  u as HERO_ALICE_PERSONAL_SITE,
  x as HERO_APPAREL,
  y as HERO_BACKPACK_TRAVEL_BLOG,
  O as HERO_DAY_NIGHT,
  a as HERO_GRADIENT,
  w as HERO_GUAC,
  r as HERO_INVESTOR_PORTFOLIO,
  s as HERO_JOB_BOARD,
  f as HERO_KANBAN_PERSONAL_SITE,
  e as HERO_LANDING_CENTER,
  i as HERO_LIGHT_PORFOLIO,
  c as HERO_NFT_MINTING,
  m as HERO_ONBOARDING_GUIDE,
  l as HERO_ORANGE_PRODUCT,
  o as HERO_ORGANIC,
  t as HERO_PERSONAL_SITE,
  d as HERO_PERSONAL_WEB3,
  g as HERO_PODCAST,
  n as HERO_PRODUCT_PROMO,
  _ as HERO_PRODUCT_ROADMAP,
  b as HERO_REGIMENT,
  h as HERO_UX_COURSE,
  p as HERO_WEDDING
};
