import React, {
  Dispatch, PropsWithChildren, SetStateAction,
} from 'react';
import classNames from 'classnames';
import { X } from '@styled-icons/bootstrap';
import * as Dialog from '@radix-ui/react-dialog';
import { StyledDialogModal, StyledDialogModalContent, StyledDialogOverlay } from '@/components/Dialog';
import { BaseProps } from '../../types';
import styles from './Popup.module.css';

export interface PopupProps extends PropsWithChildren<BaseProps>{
  open: boolean;
  setopen: Dispatch<SetStateAction<boolean>>;
  width: number | string;
  height: number | string;
}

function Popup({
  className,
  open,
  setopen,
  width,
  height,
  children,
}: PopupProps) {
  if (typeof document === 'undefined') return null;
  return (
    <Dialog.Root
      open={open}
      onOpenChange={setopen}
    >
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <StyledDialogOverlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <StyledDialogModal
            height={Number.isFinite(height) ? `min(${height}px, 90vh)` : height}
            width={Number.isFinite(width) ? `min(${width}px, 90vw)` : width}
          >
            <StyledDialogModalContent className={classNames(className)}>
              {children}
            </StyledDialogModalContent>
            <div
              className="is-flex is-align-items-center is-justify-content-flex-end"
              style={{ width: '100%' }}
            >
              <Dialog.Close asChild>
                <div data-testid="Popup_close_button" role="none" className={styles.close}>
                  <X size={16} className="with-light-text-color" />
                </div>
              </Dialog.Close>
            </div>
          </StyledDialogModal>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Popup;
