const t = (e) => {
  try {
    return new Date(e) instanceof Date && new Date(e).toString() !== "Invalid Date" && new Date(e).toISOString() && !Number.isNaN(new Date(e));
  } catch {
    return !1;
  }
};
export {
  t as isDate
};
