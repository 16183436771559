/* eslint-disable no-use-before-define */

import { Node } from 'slate';
import { VISITOR_FILTER_STYLE_OPTIONS } from '@typedream/components';
import { ITemplate, TEMPLATE_TYPE } from '@typedream/data';
import type {
  DreamElement, MediaEmbedElement, TextLeaf,
  ITransclusionReference as ITransclusionReferenceCore,
} from '@typedream/core';
import { NavbarContent } from './sharedComponents/TextEditor/components/Navbar/types';
import type { DreamValue } from './sharedComponents/TextEditor/plate';

export interface BaseProps {
  className?: string;
  [key: string]: unknown;
}
export type OrNull<T> = T | null;

export enum SPACE_ROLE {
  OWNER = 'owner',
  ADMIN = 'admin',
  WRITER = 'writer',
  READER = 'reader',
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
export enum SUBSCRIPTION_PLAN {
  FREE = 'free',
  MINI = 'mini',
  LAUNCH = 'launch',
  PRO = 'pro',
}
export enum SUBSCRIPTION_PLAN_CYCLE {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
}

export interface ISubscriptionDiscount {
  'checkout_session': string,
  'coupon': IStripeCoupon,
  'customer': unknown,
  'deleted': boolean,
  'end': number,
  'id':string,
  'invoice': string
  'invoice_item': string
  'object': string,
  'promotion_code': string | null,
  'start': number,
  'subscription': string
}

export interface ISubscriptionMetadata {
  'affiliate': boolean,
  'analytics_activated': boolean,
  'read_advanced_analytics': boolean,
  'analytics_timezone': string,
  'discount': ISubscriptionDiscount | null,
  'subscription': {
    'cycle': 'month' | 'year',
    'price': number,
    'price_1ILQxNKqrvH8BszmLfET99Mw'?: string,
    'price_1ILR09KqrvH8BszmA71uyAOx'?: string,
  },
  'susbcription_email': string,
  'subscription_status': 'active' | 'inactive',
  'current_period_start': number,
  'current_period_end': number,
  'trial_start': number,
  'trial_end': number,
  'subscription_created': number,
  'subscription_start_date': number,
  'cancel_at': number,
  'cancel_at_period_end': boolean,
}

export interface ISpaceMetadata {
  page_type?: 'link_in_bio' | undefined
  metatags: {
    title: string;
    description: string;
  },
  twitter: {
    image: string;
    title: string;
    description: string;
    username: string;
  },
  facebook: {
    image: string;
    title: string;
    description: string;
  },
}

export interface ISpaceRoute {
  page_id: string;
  children_template_page_id?: string;
  children_keys?: string[];
  children?: { [slug: string]: ISpaceRoute };
}

export type ITransclusionReference = ITransclusionReferenceCore

export type ITransclusionReferenceItem = ITransclusionReferenceCore extends {
  [id: string]: infer T;
} ? T : never;

export type IPageMetadata = ISpaceMetadata & {
  page_type?: 'link_in_bio' | undefined
};
export interface IPageCustomization extends ISpaceCustomization {
  collection_data?: ICollectionData // for info about collection lists in this page

  // If page is a children template, this stores info about the data/schema
  item_page_data?: IItemPageData
}

export interface ISpaceCustomization {
  theme?: 'dark' | 'light',
  enable_theme_toggle?: boolean,
  header_code?: string,
  body_code?: string,
  transclusion_reference?: ITransclusionReference,
  collection_item_data?: ICollectionPageData,
  redirect_custom_domain?: string,
}

(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

// TODO: move this to core
export interface ILockNFTMetadata {
  afterFailureRedirectURL?: string;
  contractAddress?: string;
  chainId?: string;
  tokenId?: string; // this is actually a big number
  minimumAmount?: number;
  // this value is only used when we first create lock
  // which then will be hashed in the database.
  password?: string;
}

export type LockType = 'erc721' | 'erc1155' | 'erc20' | 'password'

// TODO: ILock should be refactored into core as it is one of the core
// primitive
export interface ILock {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  type: LockType;
  space_id: string;
  metadata: ILockNFTMetadata;
  pages: Array<Pick<IPage, 'id' | 'name'>>
}

export interface ISpace {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  icon: string;
  slug: string;
  custom_domain: string;
  metadata: ISpaceMetadata;
  customization: ISpaceCustomization;
  routes: ISpaceRoute;
  navbar: NavbarContent;
  public: boolean;
}
export interface IPage {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  content: DreamValue;
  content_updated_at: string;
  published_content: DreamValue;
  published_at: string;
  customization: IPageCustomization;
  metadata: Partial<IPageMetadata>;
  public: boolean;
  allow_duplicate: boolean;
  space_id: string;
  locks: Array<ILock>;
}
export interface IUser {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  email: string;
  photo: string;
  metadata: any;
  source: string;
  cotter_id: string;
  last_auth: string;
  identifier?: string
}

export type PageTemplateType = ITemplate<TEMPLATE_TYPE.PAGE>
export type SectionTemplateType = ITemplate<TEMPLATE_TYPE.SECTION>
export type FormTemplateType = ITemplate<TEMPLATE_TYPE.FORM>

export interface ISpacePermission {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  user_id: string;
  space_id: string;
  role: SPACE_ROLE;
  user?: IUser
}

export interface INewSpacePermission {
  space_id: string;
  role: SPACE_ROLE;
  email: string
}

export interface ITransferSpacePermission {
  email: string
}

export interface ISubscription {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  space_id: string;
  product_id: string;
  price_id: string;
  status: SUBSCRIPTION_STATUS;
  metadata: ISubscriptionMetadata;
  customer_id: string;
  subscription_id: string;
}

export enum SPACE_ACTION {
  PromoteToOwner = 'PromoteToOwner',
  InviteMember = 'InviteMember',
  RemoveMember = 'RemoveMember',
  ViewMembers = 'ViewMembers',
  TransferOwnership = 'TransferOwnership',
  UpdatePermissions = 'UpdatePermissions',
  ReadSettings = 'ReadSettings',
  UpdateSettings = 'UpdateSettings',
  UpdateCustomDomain = 'UpdateCustomDomain',
  UpdateMetadata = 'UpdateMetadata',
  UpdateCustomization = 'UpdateCustomization',
  UpdateSubdomain = 'UpdateSubdomain',
  CreateLock = 'CreateLock',
  DeleteSpace = 'DeleteSpace',
  CreatePage = 'CreatePage',
  EditContent = 'EditContent',
  ReadDraft = 'ReadDraft',
  ReadPublishedContent = 'ReadPublishedContent',
  ReadPageInfo = 'ReadPageInfo',
  UpdateBilling = 'UpdateBilling',
  ReadBilling = 'ReadBilling',
  UpdateAffiliate = 'UpdateAffiliate',
  ReadBasicAnalytics = 'ReadBasicAnalytics',
  ReadAdvancedAnalytics = 'ReadAdvancedAnalytics',
  Collection = 'Collection',
  EmailCollectionCard = 'EmailCollectionCard',
}

export interface ICreateSubscriptionReq {
  paymentMethodId: string,
  plan: SUBSCRIPTION_PLAN,
  cycle: string,
  promoCode?: string,
}

export interface IUpdateSubscriptionReq {
  plan: SUBSCRIPTION_PLAN,
  cycle: string,
  promo_code?: string,
  free_trial: boolean,
}

export interface IStripePortal {
  return_url: string,
}

export interface ICustomDomainConfig {
  type: string
  name: string
  value: string
}

export interface IRecommendedConfiguration {
  domain: string
  redirect_to_domain: string
}
export interface ICheckDomain {
  domain: string,
  conflicts: any[],
  intended_configuration: ICustomDomainConfig[],
  recommended_configuration: IRecommendedConfiguration,
}

export interface IDomainConfigResponse {
  config: ICheckDomain,
  domain: string,
  redirect: IDomainConfigResponse,
}
export interface ICheckDomainResponse {
  domain: string,
  ssl_enabled: boolean,
  configured: boolean,
  redirect: ICheckDomainResponse,
}

export interface IStripeCoupon {
  'id': string,
  'object': 'coupon',
  'amount_off': number,
  'created': number,
  'currency': string,
  'duration': 'once' | 'repeating' | 'forever',
  'duration_in_months': number, // If duration is repeating, the number of months the coupon applies. Null if coupon duration is forever or once.
  'livemode': boolean,
  'max_redemptions': number,
  'metadata': unknown, // Used for billing cycle plan {"annual": "false"} if cannot for annual
  'name': string,
  'percent_off': number,
  'redeem_by': any,
  'times_redeemed': number,
  'valid': boolean
}

export enum IStripeCouponBoolean {
  TRUE = 'true',
  FALSE = 'false'
}

export enum ICollectionIntegration {
  AIRTABLE = 'airtable',
  NOTION = 'notion',
  STRIPE = 'stripe'
}

export interface ICollectionData {
  [dataID: string]: ICollectionDataItem
}
export interface ICollectionPageData {
  [dataID: string]: ICollectionPageDataItem
}
export interface IOAuthRequest {
  grant_type: string;
  code: string;
  redirect_uri: string;
}

export enum ICollectionDataItemMetadataKeys {
  MAX_ITEMS = 'max_items',
  FILTER_COLUMN = 'filter_column',
  FILTER_COLUMN_TYPE = 'filter_column_type',
  FILTER_CONDITION = 'filter_condition',
  FILTER_VALUE = 'filter_value',
  SORT_COLUMN = 'sort_column',
  SORT_DIRECTION = 'sort_direction',
  SEARCH_COLUMNS = 'search_columns',
  SHOW_SEARCH_BAR = 'show_search_bar',
  VISITOR_FILTER_COLUMN = 'visitor_filter_column',
  VISITOR_FILTER_COLUMN_TYPE = 'visitor_filter_column_type',
  VISITOR_FILTER_STYLE = 'visitor_filter_style',
  VISITOR_FILTER_COLOR = 'visitor_filter_color',
}

export interface ICollectionDataItemMetadata {
  [ICollectionDataItemMetadataKeys.MAX_ITEMS]?: number,
  [ICollectionDataItemMetadataKeys.FILTER_COLUMN]?: string,
  [ICollectionDataItemMetadataKeys.FILTER_COLUMN_TYPE]?: string,
  [ICollectionDataItemMetadataKeys.FILTER_CONDITION]?: string,
  [ICollectionDataItemMetadataKeys.FILTER_VALUE]?: string | boolean,
  [ICollectionDataItemMetadataKeys.SORT_COLUMN]?: string,
  [ICollectionDataItemMetadataKeys.SORT_DIRECTION]?: string,
  [ICollectionDataItemMetadataKeys.SEARCH_COLUMNS]?: string[],
  [ICollectionDataItemMetadataKeys.SHOW_SEARCH_BAR]?: boolean,
  [ICollectionDataItemMetadataKeys.VISITOR_FILTER_COLUMN]?: string,
  [ICollectionDataItemMetadataKeys.VISITOR_FILTER_COLUMN_TYPE]?: string,
  [ICollectionDataItemMetadataKeys.VISITOR_FILTER_STYLE]?: VISITOR_FILTER_STYLE_OPTIONS | string,
  [ICollectionDataItemMetadataKeys.VISITOR_FILTER_COLOR]?: string,
}
export interface ICollectionDataItem {
  integration: ICollectionIntegration,
  [key: string]: any
  metadata?: ICollectionDataItemMetadata,
  data?: { [field: string]: any }[],
  hasMore?: boolean,
  nextCursor?: string,
  fetchedCursor?: string,
  query?: IAppProxyQuery,
  queryResp?: any,
  tableID?: string
}
export interface ICollectionPageDataItem extends ICollectionDataItem {
  childrenTemplatePageID: string
}
export interface IItemPageData {
  integration: ICollectionIntegration,
  [key: string]: any,

  // for page level info like Page Name, Metadata, etc
  pageSchema: IItemPageSchema,

  // for content level info in the JSON
  contentSchema?: { [key: string]: string },
  contentSchemaOrder?: string[],
  data?: { [field: string]: any },

  // to know if template is selected
  templateSelected?: boolean
}

export interface IItemPageSchema {
  name: string,
  metadata: string,
  slug: string,
  content: string,
  published_content: string,
}
export interface IApp {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  space_id: string;
  token: { [key: string]: any };
}

export interface IAppRequest {
  method: string
  url: string
  header?: { [key: string]: string }
  body?: any
}

export interface IAppProxyQuery {
  path: string,
  app: ICollectionIntegration,
  data: IAppRequest,
}
export interface ICollectionTable {
  id: string;
  name: string;
  [key: string]: any;
}

export enum ICollectionColumnType {
  FILES = 'files'
}
export interface ICollectionColumn {
  id: string;
  name: string;
  type: string | ICollectionColumnType;
}

export interface ITableInfo {
  id: string; // table id
  name: string; // table name
  url?: string;// table url to real database if available
  integration: ICollectionIntegration;
  childrenTemplatePageID: string
}
export interface ICollectionUpdateRowRequest {
  column: ICollectionColumn,
  value: any,
}
export interface ICollectionUpdateRowRawRequest {
  column: ICollectionColumn,
  value: any, // keep value raw, like just the text or number
}
export interface ICollectionDataResult {
  data: ICollectionDataResultItem[],
  has_more: boolean,
  next_cursor: string,
}

export interface ICollectionCursorState {
  hasMore: boolean,
  fetchedCursor: string,
  nextCursor: string,
  data: { [field: string]: any }[],
  itemsPerPage: number,
  err: string,
  searchQuery?: string,
}

export interface ICollectionDataResultItem {
  id: string,
    [field: string]: any,
}

export interface INewPage {
  name: string,
  slug: string,
  path: string[],
}

export interface ICustomizationUpdateRequest {
  customization_value: any,
  customization_path: string[],
  customization_delete?: boolean,
}

export interface IConnectCollectionRequest {
  page_id: string,
  update_page_customization: boolean,
  page_customization: ICustomizationUpdateRequest,
  template_page: INewPage,
  template_page_content: DreamValue,
  create_template_home_page: boolean,
  template_home_page: INewPage,
  template_home_page_content: DreamValue,
  template_home_page_customization: ICustomizationUpdateRequest,
  template_page_customization: ICustomizationUpdateRequest,
  space_customization: ICustomizationUpdateRequest,
  space_customization_template_id_key: string,
}

export interface IConnectCollectionResponse {
  space: ISpace,
  page: IPage,
  template_page: IPage,
  template_home_page: IPage,
}

export type SerializableNode =
  | TextLeaf
  | Exclude<DreamElement, MediaEmbedElement>
  | (MediaEmbedElement & { containerClassName?: string });
