import { makeApiSliceCreator as c } from "@typedream/zustand-query";
import { checkStripeConnection as i, deleteApp as p, connectStripe as o } from "./actions.js";
const S = c("appApi", (e) => ({
  endpoints: (t) => ({
    checkStripeConnection: t.query(i(e)),
    deleteApp: t.mutation(p(e), {
      invalidateQueries: ({ args: n }) => [
        ["checkStripeConnection", n[0].spaceID]
      ]
    }),
    connectStripe: t.mutation(o(e), {
      invalidateQueries: ({ args: n }) => [
        ["checkStripeConnection", n[0].spaceID]
      ]
    })
  })
}));
export {
  S as createAppApiSlice
};
