var e;
(function(t) {
  t.STRING = "string", t.TRUE = "true", t.NUMBER = "number", t.BOOLEAN = "boolean", t.DATE = "date";
})(e || (e = {}));
const s = {
  title: {
    key: "contains",
    type: e.STRING
  },
  text: {
    key: "contains",
    type: e.STRING
  },
  rich_text: {
    key: "contains",
    type: e.STRING
  },
  number: {
    key: "equals",
    type: e.NUMBER
  },
  checkbox: {
    key: "equals",
    type: e.BOOLEAN
  },
  select: {
    key: "equals",
    type: e.STRING
  },
  multi_select: {
    key: "contains",
    type: e.STRING
  },
  date: {
    key: "equals",
    type: e.DATE
  },
  url: {
    key: "contains",
    type: e.STRING
  },
  email: {
    key: "contains",
    type: e.STRING
  },
  phone: {
    key: "contains",
    type: e.STRING
  },
  phone_number: {
    key: "contains",
    type: e.STRING
  },
  created_time: {
    key: "equals",
    type: e.DATE
  },
  last_edited_time: {
    key: "equals",
    type: e.DATE
  }
}, n = {
  title: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  text: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  rich_text: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  number: {
    equals: e.NUMBER,
    does_not_equal: e.NUMBER,
    greater_than: e.NUMBER,
    less_than: e.NUMBER,
    greater_than_or_equal_to: e.NUMBER,
    less_than_or_equal_to: e.NUMBER,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  checkbox: {
    equals: e.BOOLEAN,
    does_not_equal: e.BOOLEAN
  },
  select: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  multi_select: {
    contains: e.STRING,
    does_not_contain: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  date: {
    equals: e.STRING,
    before: e.STRING,
    after: e.STRING,
    on_or_before: e.STRING,
    on_or_after: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  files: {
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  url: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  email: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  phone: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  phone_number: {
    equals: e.STRING,
    does_not_equal: e.STRING,
    contains: e.STRING,
    does_not_contain: e.STRING,
    starts_with: e.STRING,
    ends_with: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  created_time: {
    equals: e.STRING,
    before: e.STRING,
    after: e.STRING,
    on_or_before: e.STRING,
    on_or_after: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  },
  last_edited_time: {
    equals: e.STRING,
    before: e.STRING,
    after: e.STRING,
    on_or_before: e.STRING,
    on_or_after: e.STRING,
    is_empty: e.TRUE,
    is_not_empty: e.TRUE
  }
};
var _;
(function(t) {
  t.TABLE_ID = "tableID";
})(_ || (_ = {}));
export {
  n as NOTION_FILTER,
  s as NOTION_FILTER_SEARCH_CONDITION,
  e as NOTION_FILTER_TYPE,
  _ as NOTION_KEYS
};
