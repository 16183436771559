var u = Object.defineProperty;
var f = (c, t, e) => t in c ? u(c, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : c[t] = e;
var g = (c, t, e) => (f(c, typeof t != "symbol" ? t + "" : t, e), e);
import { EditorAPI as p } from "../EditorAPI.js";
import { getSpacePermissionConfig as o } from "../../utils/getSpacePermissionConfig.js";
class y {
  constructor(t) {
    g(this, "api");
    this.api = t;
  }
  async list(t, e, n, i) {
    const s = new p(this.api), a = new URLSearchParams("");
    e && a.append("space_only", e.toString()), Object.keys(i || {}).forEach((h) => {
      a.append(h, String(i == null ? void 0 : i[h]) || "");
    });
    let r = "/template/list";
    r = `${r}?${a.toString()}`;
    const w = await o(t, n);
    return s.get(r, !0, w);
  }
  async listPublic(t) {
    const e = new p(this.api), n = new URLSearchParams();
    Object.keys(t || {}).forEach((s) => {
      n.append(s, String(t == null ? void 0 : t[s]) || "");
    });
    let i = "/template/list/public";
    return i = `${i}?${n.toString()}`, e.get(i, !0);
  }
  async getPublic(t) {
    const e = `/template/public/${t}`;
    return new p(this.api).get(e, !0);
  }
  async get(t, e, n) {
    const i = await o(t, n), s = `/template/${e}`;
    return new p(this.api).get(s, !0, i);
  }
  async use(t, e, n) {
    const i = await o(t, n), s = `/template/use/${e}`;
    return new p(this.api).get(s, !0, i);
  }
  async create(t, e, n) {
    const i = await o(t, n), s = "/template";
    return new p(this.api).post(s, e, !0, i);
  }
  async update(t, e, n, i) {
    const s = await o(t, i), a = `/template/${e}`;
    return new p(this.api).put(a, n, !0, s);
  }
  async listSection() {
    const t = "/template/section";
    return await new p(this.api).get(t, !1);
  }
}
export {
  y as TemplateAPI
};
