import { useTheme } from 'next-themes';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, Theme as ToastTheme } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function Notification() {
  const { theme } = useTheme();
  const ref = React.useRef<HTMLElement | null>();

  React.useEffect(() => {
    ref.current = document.body;
  }, []);

  return ref.current ? ReactDOM.createPortal(
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={theme as ToastTheme}
      style={{
        fontSize: '14px',
      }}
    />,
    ref.current,
  ) : null;
}

export default Notification;
