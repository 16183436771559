const e = {
  type: "container",
  background: "default",
  align: "align_center",
  children: [
    {
      type: "divider",
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      optionIndex: 0,
      width: 1200,
      width_max: "100%"
    },
    {
      align: null,
      background: null,
      backgroundImage: null,
      children: [
        {
          children: [
            {
              align: "align_left",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: "38.875",
              open_in_new_tab: !1,
              type: "img",
              url: "https://build.typedream.com/images/logo@100.png",
              width: "38.875",
              width_max: "undefined"
            },
            {
              align: "align_left",
              children: [
                {
                  text: "Cardy"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "gray",
                  fontSize: 13,
                  gradient: !1,
                  styled: !0,
                  text: "Copyright \xA9 2022 Govest, Inc. All rights reserved."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: "0.5358719646799112"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  text: "Company"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Twitter"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://twitter.com/typedreamHQ"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "team@typedream.com"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "mailto:team@typedream.com"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: "0.2295805739514348"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  text: "Legal"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Terms of Service"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://typedream.sfo3.digitaloceanspaces.com/Typedream_Terms_of_Service.pdf"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Privacy Policy"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://typedream.sfo3.digitaloceanspaces.com/Typedream_Privacy_Policy.pdf"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: "0.23454746136865331"
        }
      ],
      height: "200",
      type: "column",
      vertical_align: "align_start",
      width: "1100"
    }
  ],
  openOptions: !1,
  width_max: "100%",
  height: 267
}, t = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 44.796875,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/4d84fc4f-e739-482e-a70f-11713c124994_0350a75e-abcc-4387-800a-f1371f3cbd12_png.png",
              width: 44.796875
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Cardy"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.635625596943648
        },
        {
          children: [
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Product"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Overview"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Business Account"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Credit card"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Reports"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Analytics"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Team management"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Integrations"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.19340974212034379
        },
        {
          children: [
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Company"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "About Cardy"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Contact"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Careers"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Blog"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Press"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.17096466093600768
        }
      ],
      height: 328,
      type: "column",
      vertical_align: "align_start",
      width: 1200
    },
    {
      children: [
        {
          color: "white",
          fontSize: 13,
          fontStyle: "small",
          gradient: !1,
          styled: !0,
          text: "Copyright \xA9 2022 Govest, Inc. All rights reserved."
        }
      ],
      type: "p",
      width: 1200
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    }
  ],
  height: 501,
  openOptions: !1,
  type: "container",
  width: 1300
}, i = {
  align: "align_center",
  background: "rgba(243, 236, 228, 1)",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 28,
              openOptions: !1,
              type: "spacer"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Company"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Twitter"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://twitter.com/typedreamHQ"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "team@typedream.com"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "mailto:team@typedream.com"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.19053338983050863
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 28,
              openOptions: !1,
              type: "spacer"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Legal"
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Terms of Service"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://typedream.sfo3.digitaloceanspaces.com/Typedream_Terms_of_Service.pdf"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      color: "gray",
                      fontSize: 13,
                      gradient: !1,
                      styled: !0,
                      text: "Privacy Policy"
                    }
                  ],
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://typedream.sfo3.digitaloceanspaces.com/Typedream_Privacy_Policy.pdf"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.1807876271186438
        },
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  text: "Faraland",
                  styled: !0,
                  color: "black",
                  gradient: !1
                }
              ],
              type: "h1"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Get the beauty you deserve"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 65,
              openOptions: !1,
              type: "spacer"
            },
            {
              align: "align_right",
              children: [
                {
                  color: "gray",
                  fontSize: 13,
                  gradient: !1,
                  styled: !0,
                  text: "Copyright \xA9 2022 Govest, Inc. All rights reserved."
                }
              ],
              type: "p",
              width: 1e3
            }
          ],
          type: "column_item",
          width: 0.6286689830508476
        }
      ],
      height: 315,
      type: "column",
      vertical_align: "align_start",
      width: 1100
    }
  ],
  height: 553,
  openOptions: !1,
  type: "container",
  width: 2300,
  width_max: "100%"
}, l = {
  align: "align_center",
  backgroundImage: "https://api.typedream.com/v0/document/public/c927c679-1327-43b7-933f-02942318e116_BG_IMAGE_png.png?bucket=document",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  gradient: !1,
                  styled: !0,
                  text: "wagmi."
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "let's chat."
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5882513661202183
        },
        {
          children: [
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "email"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "invest@cardy.fund"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "twitter"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "space_mono",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "cardyfund"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.4117486338797818
        }
      ],
      height: 247.96876525878906,
      type: "column",
      width: 1e3
    }
  ],
  height: 344.8125,
  openOptions: !1,
  type: "container",
  width: 2200,
  width_max: "100%"
}, n = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "let's grab boba"
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 800
    },
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "shoot me an email sally@sally.me"
        }
      ],
      type: "p",
      width: 800
    }
  ],
  height: 310,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 800
}, o = {
  align: "align_center",
  background: "rgba(242, 239, 233, 1)",
  children: [
    {
      align: "align_center",
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "Say hi!"
        }
      ],
      openOptions: !1,
      type: "h2"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "Drop us a message!"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 259.5625,
      openOptions: !1,
      type: "media_embed",
      url: "https://tally.so/embed/wLNZlm?alignLeft=1&hideTitle=1&transparentBackground=1",
      width: 500
    }
  ],
  height: 516.5625,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, a = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://api.typedream.com/v0/document/public/e8da89be-5121-40ed-96df-61a42afcf339_gradient_7_png.png?bucket=document",
  children: [
    {
      children: [
        {
          color: "default",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "default",
          gradient: !1,
          styled: !0,
          text: "Submit a web3 word you know"
        }
      ],
      type: "h2",
      width: 600
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 318.71875,
      openOptions: !1,
      type: "media_embed",
      url: "https://tally.so/embed/3lk2Xm?alignLeft=1&hideTitle=1&transparentBackground=1",
      width: 500
    }
  ],
  height: 555.6875,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 900
}, r = {
  align: "align_center",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "default",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "The UX Lab \u{1F351}"
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "quicksand",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Everything you need to know on UI/UX for beginners"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5882513661202183
        },
        {
          children: [
            {
              children: [
                {
                  bold: !0,
                  color: "default",
                  fontFamily: "quicksand",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "email"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "quicksand",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "hello@theuxlab.com"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "quicksand",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  color: "default",
                  fontFamily: "quicksand",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "twitter"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "quicksand",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "theUXLab"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.4117486338797818
        }
      ],
      height: 248,
      type: "column",
      width: 1e3
    }
  ],
  height: 344.8125,
  openOptions: !1,
  type: "container",
  width: 2200,
  width_max: "100%"
}, d = {
  align: "align_center",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Travel with me?"
                }
              ],
              openOptions: !1,
              type: "h1"
            }
          ],
          type: "column_item",
          width: 0.5882513661202183
        },
        {
          children: [
            {
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Email"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "alfred@backpack.travels"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Phone"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontFamily: "libre_caslon_text",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "+14158735235"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.4117486338797818
        }
      ],
      height: 548,
      type: "column",
      width: 1200
    }
  ],
  height: 588,
  openOptions: !1,
  type: "container",
  width: 1700,
  width_max: "100%"
}, s = {
  align: "align_center",
  children: [
    {
      children: [
        {
          color: "default",
          gradient: !1,
          styled: !0,
          text: "Work with me. "
        }
      ],
      openOptions: !1,
      type: "h3",
      width: 1e3
    },
    {
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "Let's see how we nail this project."
        }
      ],
      type: "p",
      width: 1e3
    },
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 1e3
    },
    {
      background: "black",
      children: [
        {
          bold: !0,
          text: "Book a call with me"
        }
      ],
      color: "white",
      gradient: !1,
      internal_link: !1,
      openOptions: !1,
      open_in_new_tab: !0,
      type: "button",
      url: "https://typedream.com/docs/integration/calcom",
      width: 1e3
    }
  ],
  height: 303,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 1200
}, p = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 150.78125,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontFamily: "inter",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          gradient: !1,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "Coffee chat?"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      align: "align_center",
      background: "black",
      children: [
        {
          background: "clear",
          bold: !0,
          styled: !0,
          text: "Shoot me an email."
        }
      ],
      color: "white",
      gradient: !1,
      internal_link: !1,
      openOptions: !1,
      type: "button",
      url: "mailto:team@typedream.com",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 150.78125,
      openOptions: !1,
      type: "spacer",
      width: 700
    }
  ],
  height: 647,
  openOptions: !1,
  type: "container",
  width: 2300,
  width_max: "100%"
}, c = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      children: [
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "\u2192 FAQs"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  color: "black",
                  gradient: !1,
                  styled: !0,
                  text: "What's the remote work policy?"
                }
              ],
              openOptions: !1,
              type: "h1"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  color: "black",
                  gradient: !1,
                  styled: !0,
                  text: "We leave that for you and your team to decide. Some teams come into the office 3 times a week, others every day. As long as you prioritize productivity, we as a company don't really care whether you're in a desk or in a hammock."
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 222,
      type: "column",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  color: "black",
                  gradient: !1,
                  styled: !0,
                  text: "How long are sprints at Cardy?"
                }
              ],
              openOptions: !1,
              type: "h1"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_right",
              children: [
                {
                  color: "black",
                  gradient: !1,
                  styled: !0,
                  text: "Typically, sprints span across two weeks. We believe that this time frame is optimal for you to understand the task at hand and finish most of what is assigned to you. However, we do understand that spillovers happen and that's okay. That's why we strongly encourage you to have daily standups and biweekly 1-on-1's with your team. "
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 222,
      type: "column",
      width: 1e3
    }
  ],
  height: 624.5,
  openOptions: !1,
  type: "container",
  width: 1100
}, h = {
  align: "align_center",
  children: [
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  color: "white",
                  fontFamily: "inter",
                  gradient: !1,
                  styled: !0,
                  text: "We're also hiring."
                }
              ],
              openOptions: !1,
              type: "h1"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Looking for passionate people to come build awesome projects with us."
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.5282185613088458
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  color: "white",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Email"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "team@tiny.builds"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "white",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Twitter"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "tinybuildsHQ"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.47178143869115413
        }
      ],
      height: 548,
      type: "column",
      width: 1e3
    }
  ],
  height: 588,
  openOptions: !1,
  type: "container",
  width: 700
}, y = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      optionIndex: 1,
      type: "p",
      width: 1100
    },
    {
      children: [
        {
          text: "email"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1100
    },
    {
      children: [
        {
          color: "default",
          fontFamily: "inter",
          fontSize: 80,
          fontStyle: "title",
          fontWeight: 800,
          letterSpacing: -2.5,
          lineHeight: "90px",
          styled: !0,
          text: "me@alex.phan"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1100
    }
  ],
  height: 202,
  openOptions: !1,
  type: "container",
  width: 1200
};
export {
  p as FOOTER_ALICE_PERSONAL_SITE,
  d as FOOTER_BACKPACK_TRAVEL_BLOG,
  i as FOOTER_BEIGE,
  t as FOOTER_BLACK,
  r as FOOTER_BLOG_COLORFUL,
  y as FOOTER_DAY_NIGHT,
  l as FOOTER_INVESTOR_PORTFOLIO,
  s as FOOTER_KANBAN_PERSONAL_SITE,
  c as FOOTER_ONBOARDING_GUIDE,
  n as FOOTER_PERSONAL_WEB3,
  h as FOOTER_PRODUCT_ROADMAP,
  e as FOOTER_SMALL,
  a as FOOTER_WEB3_BUZZWORDS,
  o as FOOTER_WEDDING
};
