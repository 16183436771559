import { makeApiSliceCreator as t } from "@typedream/zustand-query";
import { getSubscription as o } from "./actions.js";
const c = t("subscriptionApi", (i) => ({
  endpoints: (r) => ({
    getSubscription: r.query(o(i))
  })
}));
export {
  c as createSubscriptionApiSlice
};
