import { API as i } from "../../api/api.js";
const k = (n) => async ({ spaceID: e, id: t, appName: r }) => {
  if (!e)
    return;
  const { getState: s } = n, { tokenHandlers: o } = s(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } = o;
  return (await i.getOrCreateInstance().app().delete(e, t, r, a, c)).data;
}, l = (n) => async (e) => {
  if (!e)
    return;
  const { getState: t } = n, { tokenHandlers: r } = t(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: o } = r;
  return (await i.getOrCreateInstance().stripe().checkConnection(e, s, o)).data;
}, u = (n) => async ({ spaceID: e, refreshURL: t, returnURL: r }) => {
  if (!e)
    return;
  const { getState: s } = n, { tokenHandlers: o } = s(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } = o;
  return (await i.getOrCreateInstance().stripe().connect(e, t, r, a, c)).data;
};
export {
  l as checkStripeConnection,
  u as connectStripe,
  k as deleteApp
};
