var l = Object.defineProperty;
var g = (m, t, a) => t in m ? l(m, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : m[t] = a;
var h = (m, t, a) => (g(m, typeof t != "symbol" ? t + "" : t, a), a);
import { EditorAPI as p } from "../EditorAPI.js";
import { getSpacePermissionConfig as y } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as r } from "../../utils/getPermissionAndSubscriptionConfig.js";
class P {
  constructor(t) {
    h(this, "api");
    this.api = t;
  }
  async get(t, a, e) {
    const s = await r(t, a, e), n = `/space/${t}`;
    return await new p(this.api).get(n, !0, s);
  }
  async getPublic(t, a) {
    const e = a === "SLUG" ? `/space/public?slug=${t}` : `/space/public?custom_domain=${t}`;
    return await new p(this.api).get(e, !1);
  }
  async list() {
    const t = "/space/list";
    return await new p(this.api).get(t);
  }
  async updateNavbar(t, a, e, s, n, i, c) {
    const o = {
      navbar: a,
      updated_at: e
    };
    return this.update(t, o, s, "navbar", n, i, c);
  }
  async updateSettings(t, a, e, s) {
    const n = {
      name: a.name,
      icon: a.icon,
      slug: a.slug
    };
    return this.update(t, n, !0, "site", e, s);
  }
  async updateMetadata(t, a, e, s) {
    const n = {
      metadata: a
    };
    return this.update(t, n, !0, "metadata", e, s);
  }
  async updateCustomizationPartial(t, a, e, s) {
    const n = await r(t, e, s), i = `/space/customization_partial/${t}?force=true`;
    return await new p(this.api).put(i, a, !0, n);
  }
  async updateRoutes(t, a, e, s) {
    const n = {
      routes: a
    };
    return this.update(t, n, !0, "routes", e, s);
  }
  async create(t) {
    const a = "/space/create?create_type=template";
    return await new p(this.api).post(a, t);
  }
  async createNoPages(t) {
    const a = "/space/create?create_type=empty";
    return await new p(this.api).post(a, t);
  }
  async delete(t, a) {
    const e = `/space/${t}`, s = await y(t, a);
    return await new p(this.api).delete(e, null, !0, s);
  }
  async publish(t, a, e, s, n) {
    const i = await r(t, s, n), c = {
      space: a
    }, o = `/space/publish/${t}?all_public=${e.toString()}`;
    return await new p(this.api).put(o, c, !0, i);
  }
  async publishByPageIDs(t, a, e, s, n, i, c) {
    const o = await r(t, i, c), d = {
      space: a,
      page_ids: e
    };
    let u = `/space/publish_by_page_id/${t}?all_public=${s.toString()}`;
    return n && (u = `${u}&keep_published=${n}`), await new p(this.api).put(u, d, !0, o);
  }
  async updateCustomDomain(t, a, e, s, n) {
    const i = {
      custom_domain: a,
      redirect_custom_domain: e
    };
    return this.update(t, i, !0, "custom_domain", s, n);
  }
  async updateCustomDomainRedirect(t, a, e, s) {
    const n = {
      redirect_custom_domain: a
    }, i = await r(t, e, s), c = `/space/custom_domain/redirect/${t}`;
    return await new p(this.api).put(c, n, !0, i);
  }
  async checkCustomDomain(t, a, e, s) {
    const n = await r(t, e, s), i = `/space/custom_domain/${t}?domain=${a}`;
    return await new p(this.api).get(i, !0, n);
  }
  async getCustomDomainConfig(t, a, e, s) {
    const n = await r(t, e, s), i = `/space/custom_domain/config/${t}?domain=${a}`;
    return await new p(this.api).get(i, !0, n);
  }
  async deleteCustomDomain(t, a, e, s) {
    const n = await r(t, e, s), i = `/space/custom_domain/${t}?domain=${a}`;
    return await new p(this.api).delete(i, null, !0, n);
  }
  async deleteCustomDomainRedirect(t, a, e, s) {
    const n = await r(t, e, s), i = `/space/custom_domain/redirect/${t}?domain=${a}`;
    return await new p(this.api).delete(i, null, !0, n);
  }
  async update(t, a, e, s, n, i, c) {
    const o = await r(t, n, i, c), d = `/space/${s}/${t}?force=${e}`, u = new p(this.api);
    return u.setLogoutCallback(c), await u.put(d, a, !0, o);
  }
}
export {
  P as SpaceAPI
};
