import { makeApiSliceCreator as i } from "@typedream/zustand-query";
import { NETWORK_ERROR as o } from "../../constants.js";
import { saveContentLocal as g } from "../../api/page/offline.js";
import { listPages as u, getPage as P, createPage as D, createHomePage as I, deletePage as S, saveContent as d, updatePageInfo as l, updatePageMetadata as m, updatePageCustomization as v, duplicatePage as A } from "./actions.js";
const C = i("pagesApi", (t) => ({
  endpoints: (c) => ({
    listPages: c.query(u(t)),
    getPage: c.query(P(t)),
    createPage: c.mutation(D(t), {
      onSuccess: ({ args: e }) => {
        const { spacesApi: a, pagesApi: s } = t.getState();
        a.invalidateQueries(["getSpace", e[0].spaceID]), s.invalidateQueries(["listPages", e[0].spaceID]);
      }
    }),
    createHomePage: c.mutation(I(t), {
      onSuccess: ({ args: e }) => {
        const { spacesApi: a, pagesApi: s } = t.getState();
        a.invalidateQueries(["getSpace", e[0].spaceID]), s.invalidateQueries(["listPages", e[0].spaceID]);
      }
    }),
    deletePage: c.mutation(S(t), {
      onSuccess: ({ args: e }) => {
        const { spacesApi: a, pagesApi: s } = t.getState();
        a.invalidateQueries(["getSpace", e[0].spaceID]), s.invalidateQueries(["listPages", e[0].spaceID], ["getPage", e[0].spaceID, e[0].pageID]);
      }
    }),
    saveContent: c.mutation(d(t), {
      onSuccess: ({ args: e, data: a }) => {
        const { pagesApi: s } = t.getState();
        a && s.endpoints.getPage.get(e[0].spaceID, e[0].pageID).updateData((n) => ({
          ...n,
          content: a.content,
          content_updated_at: a.content_updated_at
        }));
      },
      onError: ({ args: e, error: a }) => {
        var n;
        const { pagesApi: s } = t.getState();
        if (a.message === o) {
          const p = (n = s.endpoints.getPage.get(e[0].spaceID, e[0].pageID)) == null ? void 0 : n.data;
          g(p, e[0].newContent);
        }
      },
      invalidateQueries: ({ args: e }) => [
        ["listPages", e[0].spaceID]
      ]
    }),
    updatePageInfo: c.mutation(l(t), {
      onSuccess: ({ args: e }) => {
        const { spacesApi: a, pagesApi: s } = t.getState();
        s.invalidateQueries(["listPages", e[0].spaceID], ["getPage", e[0].spaceID, e[0].pageID]), a.invalidateQueries(["getSpace", e[0].spaceID]);
      }
    }),
    updatePageMetadata: c.mutation(m(t), {
      onSuccess: ({ args: e }) => {
        const { spacesApi: a, pagesApi: s } = t.getState();
        s.invalidateQueries(["listPages", e[0].spaceID], ["getPage", e[0].spaceID, e[0].pageID]), a.invalidateQueries(["getSpace", e[0].spaceID]);
      }
    }),
    updatePageCustomization: c.mutation(v(t), {
      onSuccess: ({ args: e, data: a }) => {
        const { spacesApi: s, pagesApi: n } = t.getState();
        n.invalidateQueries(["listPages", e[0].spaceID], ["getPage", e[0].spaceID, e[0].pageID]), n.endpoints.getPage.get(e[0].spaceID, e[0].pageID).updateData((p) => ({
          ...p,
          content_updated_at: a.content_updated_at
        })), s.invalidateQueries(["getSpace", e[0].spaceID]);
      }
    }),
    duplicatePage: c.mutation(A(t), {
      onSuccess: ({ data: e }) => {
        const { spacesApi: a, pagesApi: s } = t.getState();
        s.invalidateQueries(["listPages", e == null ? void 0 : e.id]), a.invalidateQueries(["getSpace", e == null ? void 0 : e.id]);
      }
    })
  })
}));
export {
  C as createPagesApiSlice
};
