const e = {
  children: [
    {
      children: [
        {
          align: "align_center",
          background: "melon",
          children: [
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "book",
                  type: "solid"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Reading List"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "A collection of interesting articles that I read this past month"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "See reading list ->"
                    }
                  ],
                  type: "a",
                  url: "/"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          width: 300,
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/green.png"
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          children: [
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "note",
                  type: "solid"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Notes"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Occasionally, I take notes on specific podcasts and videos."
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "See notes ->"
                    }
                  ],
                  type: "a",
                  url: "/"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          width: 300,
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/green.png"
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          children: [
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "pen",
                  type: "solid"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Blog"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "An archive of my past blog posts published on Substack or Medium"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: " "
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "See blogs ->"
                    }
                  ],
                  type: "a",
                  url: "/"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          width: 300,
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/green.png"
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    }
  ],
  height: 400,
  type: "column",
  width: 1100
}, t = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_left",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Latest Blog"
        }
      ],
      type: "h2",
      width: 1100
    },
    {
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          children: [
            {
              color: "white",
              gradient: !1,
              styled: !0,
              text: "See all blogs ->"
            }
          ],
          type: "a",
          url: "/"
        },
        {
          text: ""
        }
      ],
      type: "p",
      width: 1100
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "white",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 350,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-1.png",
                  width: 350
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "black",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "100 Very Short Rules for a Better Summer"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Summer may be fun, but there's a lot of things to consider when bathing your skin under the sun."
                    }
                  ],
                  type: "p"
                },
                {
                  type: "p",
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ]
                },
                {
                  type: "p",
                  children: [
                    {
                      styled: !0,
                      text: "Read more \u2192",
                      color: "accent",
                      gradient: !1
                    }
                  ]
                }
              ],
              height: 426,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 400,
              vertical_align: "align_start"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              background: "white",
              children: [
                {
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 287,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-2.png",
                  width: 287
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "black",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "A Complete Primer to Waxing"
                    }
                  ],
                  type: "p"
                },
                {
                  type: "p",
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Waxing any part of your body \u2014 from your brows to your bikini line \u2014 can be costly, painful, and at times, just plain awkward."
                    }
                  ]
                },
                {
                  type: "p",
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ]
                },
                {
                  type: "p",
                  children: [
                    {
                      styled: !0,
                      text: "Read more \u2192",
                      color: "accent",
                      gradient: !1
                    }
                  ]
                }
              ],
              height: 426,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 400,
              vertical_align: "align_start"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              background: "white",
              children: [
                {
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 287,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-3.png",
                  width: 287
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "black",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "What exactly is your lipstick made of?"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Most lipsticks are made from three basic ingredients: wax, oil, and pigment."
                    }
                  ],
                  type: "p"
                },
                {
                  type: "p",
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ]
                },
                {
                  type: "p",
                  children: [
                    {
                      styled: !0,
                      text: "Read more \u2192",
                      color: "accent",
                      gradient: !1
                    }
                  ]
                }
              ],
              height: 426,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 400,
              vertical_align: "align_start"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        }
      ],
      height: 631,
      type: "column",
      width: 1100
    }
  ],
  height: 843,
  openOptions: !1,
  type: "container",
  width: 1100,
  width_max: "100%"
}, n = {
  align: "align_center",
  children: [
    {
      align: "align_center",
      background: "white",
      children: [
        {
          align: "align_left",
          children: [
            {
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 140.421875,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-1.png",
                  width: 140.421875
                }
              ],
              type: "column_item",
              width: 0.5
            },
            {
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "black",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "100 Very Short Rules for a Better Summer"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Summer may be fun, but there's a lot of things to consider when bathing your skin under the sun."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "accent",
                      gradient: !1,
                      styled: !0,
                      text: "Read more \u2192"
                    }
                  ],
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.5
            }
          ],
          height: 196,
          type: "column",
          width: 700
        }
      ],
      height: 236,
      openOptions: !1,
      style: "card",
      type: "container",
      vertical_align: "align_center",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      background: "white",
      children: [
        {
          align: "align_left",
          children: [
            {
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 140.421875,
                  type: "img",
                  url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-2.png",
                  width: 140.421875
                }
              ],
              type: "column_item",
              width: 0.5
            },
            {
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "black",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "A Complete Primer to Waxing"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Waxing any part of your body \u2014 from your brows to your bikini line \u2014 can be costly, painful, and at times, just plain awkward."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "accent",
                      gradient: !1,
                      styled: !0,
                      text: "Read more \u2192"
                    }
                  ],
                  type: "p"
                }
              ],
              type: "column_item",
              width: 0.5
            }
          ],
          height: 196,
          type: "column",
          width: 700
        }
      ],
      height: 236,
      openOptions: !1,
      style: "card",
      type: "container",
      vertical_align: "align_start",
      width: 800
    }
  ],
  height: 544,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, l = {
  children: [
    {
      children: [
        {
          align: "align_center",
          background: "white",
          children: [
            {
              background: "indigo",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "white",
                  name: "plane-alt",
                  type: "solid"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "Vacay"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  color: "black",
                  gradient: !1,
                  styled: !0,
                  text: "A vacation management app that allows users to plan and manage their vacation together easily."
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "black",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              aspect_ratio: 1.4874074074074075,
              children: [
                {
                  text: ""
                }
              ],
              height: 470.61752988047806,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/demo-showcase.png",
              width: 700
            }
          ],
          height: 302.1875,
          openOptions: !1,
          style: "card",
          type: "container",
          vertical_align: "align_start",
          width: 800
        }
      ],
      type: "column_item",
      width: 0.5
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/gradient-7.png",
          children: [
            {
              background: "white",
              children: [
                {
                  text: ""
                }
              ],
              color: "text_on_accent",
              icon: {
                content: {
                  color: "purple",
                  name: "credit-card-alt",
                  type: "solid"
                },
                type: "icon"
              },
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Runway Tracker"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "A Runway Tracker platform for startups to help them keep track of their capital and runway."
                }
              ],
              type: "p"
            },
            {
              aspect_ratio: 1.1017699115044248,
              children: [
                {
                  text: ""
                }
              ],
              height: 302.1649927810919,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/graph.png",
              width: 332.9162973561588
            }
          ],
          height: 533.859375,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 400
        }
      ],
      type: "column_item",
      width: 0.5
    }
  ],
  height: 585.15625,
  type: "column",
  width: 900
}, c = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          color: "white",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "Projects"
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 270.28125,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/c74c2594-e8fd-452a-9d62-ec7d376c3f1d_New_Group_1_0_5x_png.png",
              width: 270.28125
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Savage Online Store"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Website Design"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 255,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/cd62750c-ccc3-49b5-aa8c-12b9439b7ecd_New_Group_2_0_5x_png.png",
              width: 255
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "La Femme Product Site"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Website Design"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 255,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/a7ef9a9b-ffbd-48d3-a1bf-ae93dc4ae06b_New_Group_3_0_5x_png.png",
              width: 255
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Food Delivery App"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_center",
              children: [
                {
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Mobile Application Design"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        }
      ],
      height: 492.28125,
      type: "column",
      width: 1100
    }
  ],
  height: 741,
  openOptions: !1,
  type: "container",
  width: 1700,
  width_max: "100%"
}, a = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 66.859375,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      children: [
        {
          fontFamily: "space_mono",
          styled: !0,
          text: "Pudgy Penguin"
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 1200
    },
    {
      children: [
        {
          color: "gray",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: "Located in the freezing cold, arctic region of the metaverse you can find 8,888 cute chubby Pudgy Penguins sliding around on the ETH blockchain."
        }
      ],
      type: "p",
      width: 1200
    },
    {
      children: [
        {
          bold: !0,
          color: "default",
          fontSize: 24,
          fontStyle: "subtitle1",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "See collection \u2192"
        }
      ],
      type: "p",
      width: 1200
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 66.859375,
      openOptions: !1,
      type: "spacer",
      width: 700
    },
    {
      children: [
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "You need to "
        },
        {
          bold: !0,
          color: "accent",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: "Publish"
        },
        {
          color: "gray",
          fontSize: 16,
          fontStyle: "paragraph",
          gradient: !1,
          styled: !0,
          text: " your site to see the embedded NFTs. Embed new NFTs with "
        },
        {
          children: [
            {
              color: "gray",
              fontSize: 16,
              fontStyle: "paragraph",
              gradient: !1,
              styled: !0,
              text: "https://nfte.app/"
            }
          ],
          type: "a",
          url: "https://nfte.app/Party"
        },
        {
          text: ""
        }
      ],
      type: "p",
      width: 1200
    },
    {
      children: [
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 781.125,
              html: `<div className="nft-embed"></div>
<script
  async
  src="https://nfte.app/api/embed.js?contract=0xBd3531dA5CF5857e7CfAA92426877b022e612cf8&tokenId=2434">
<\/script>`,
              openOptions: !1,
              type: "media_embed",
              width: 370
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 777.703125,
              html: `<div className="nft-embed"></div>
<script
  async
  src="https://nfte.app/api/embed.js?contract=0xBd3531dA5CF5857e7CfAA92426877b022e612cf8&tokenId=6849">
<\/script>`,
              openOptions: !1,
              type: "media_embed",
              width: 370
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 779.140625,
              html: `<div className="nft-embed"></div>
<script
  async
  src="https://nfte.app/api/embed.js?contract=0xBd3531dA5CF5857e7CfAA92426877b022e612cf8&tokenId=5935">
<\/script>`,
              openOptions: !1,
              type: "media_embed",
              width: 370
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 829.71875,
      type: "column",
      vertical_align: "align_start",
      width: 1200
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 66.859375,
      openOptions: !1,
      type: "spacer",
      width: 700
    }
  ],
  height: 1399.234375,
  openOptions: !1,
  type: "container",
  width: 1600,
  width_max: "100%"
}, o = {
  align: "align_center",
  background: "rgba(243, 236, 228, 1)",
  children: [
    {
      children: [
        {
          color: "black",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "Skin Care"
        }
      ],
      type: "h1",
      width: 1500
    },
    {
      children: [
        {
          color: "rgba(0, 0, 0, 0.5)",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "You deserve it, your skin deserve it. 100% Natural, no animal testing."
        }
      ],
      type: "p",
      width: 1500
    },
    {
      children: [
        {
          color: "black",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "See all products \u2192"
        }
      ],
      type: "p",
      width: 1500
    },
    {
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 1500
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 0.6666666666666666,
              children: [
                {
                  text: ""
                }
              ],
              height: 754.5,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/654cbd5b-50be-4b12-bc19-22a7b4f00946_sun_png.png",
              width: 503
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Protect your skin from the sun"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "See sunscreen \u2192"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              aspect_ratio: 0.6675567423230975,
              children: [
                {
                  text: ""
                }
              ],
              height: 753.494,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/f28ff0ec-afd3-4678-a15f-e3c23cdeaf6c_pipet_png.png",
              width: 503
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Smoother than butter"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "See serum and skin vitamins \u2192"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              aspect_ratio: 0.6666666666666666,
              children: [
                {
                  text: ""
                }
              ],
              height: 754.5,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/2b975096-0093-4326-870a-245234eef53c_moisturizer_png.png",
              width: 503
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Keep your skin moisturized"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            },
            {
              align: "align_left",
              children: [
                {
                  color: "black",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "See moisturizers \u2192"
                }
              ],
              openOptions: !1,
              optionIndex: 1,
              type: "p",
              width: 300
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 895.5,
      type: "column",
      width: 1600
    }
  ],
  height: 1378.5,
  openOptions: !1,
  type: "container",
  width: 2300,
  width_max: "100%"
}, i = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 10,
      html: '<div id="build"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "what else am I working on?"
        },
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "default",
              backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/hue-6.gif",
              children: [
                {
                  align: "align_right",
                  children: [
                    {
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "\u6599"
                    },
                    {
                      text: "  "
                    }
                  ],
                  openOptions: !1,
                  type: "h1"
                }
              ],
              height: 150,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_center",
              background: "gray",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "an"
                    },
                    {
                      text: " nft generator "
                    },
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "that creates"
                    },
                    {
                      text: " anime-styled food "
                    },
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "photos"
                    },
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "."
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 304,
      type: "column",
      width: 800
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "gray",
              children: [
                {
                  align: "align_right",
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "gradients "
                    },
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "based on what you "
                    },
                    {
                      color: "default",
                      gradient: !1,
                      styled: !0,
                      text: "write"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: " "
                    },
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "and how you"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: " "
                    },
                    {
                      color: "default",
                      gradient: !1,
                      styled: !0,
                      text: "write"
                    },
                    {
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              height: 150,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_center",
              background: "default",
              backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/hue-9.gif",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "grdients"
                    }
                  ],
                  openOptions: !1,
                  type: "h1"
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 304,
      type: "column",
      width: 800
    }
  ],
  height: 766.5,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, r = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 10,
      html: '<div id="write"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "m"
        },
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "y 2 a.m. thoughts"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/abad4c51-110a-4b55-85a6-9a0720c2103a/milad-fakurian-HE1_K4_-QT8-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220502%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220502T045405Z&X-Amz-Expires=3600&X-Amz-Signature=598bed42ee01b75ae2e4acf61a1fbd1efd4c2532dbaabb91517f8c14673b0029&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "category"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Opinion"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Will Web3.0 Change the Way You Interact With the Internet?"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "A blog discussing Web3.0, its impact, and how it will change the natural way we use the internet. "
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Sally Perez"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2022-04-05"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "17a552ef-fc05-4663-ad13-84e6282d2cd8",
              collection_link_slug: "will-web3-0-change-the-way-you-interact-with-the-internet-",
              height: 317.8125,
              internal_link: !0,
              style: "card",
              type: "container",
              url: "",
              vertical_align: "align_end",
              width: 600
            }
          ],
          collection_item_id: "17a552ef-fc05-4663-ad13-84e6282d2cd8",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/30d8d8ef-b68f-4c54-8104-cb212feee6d5/milad-fakurian-bexwsdM5BCw-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220502%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220502T045405Z&X-Amz-Expires=3600&X-Amz-Signature=b04041579dba364c5c5626b4f9d23ef07be0167f1af5e4ca84629efdd8e41444&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "category"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Deep Dive"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Designing for Web3: From the View to the Machine"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: 'How exactly do you make "Visual Web3"? A blog about how to work with a new set of backend, frontend and design practices.'
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Sally Perez"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2022-04-12"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "ea2be9d4-3b58-453d-87c2-a45da443c7ee",
              collection_link_slug: "designing-for-web3-from-the-view-to-the-machine",
              height: 317.8125,
              internal_link: !0,
              style: "card",
              type: "container",
              url: "",
              vertical_align: "align_end",
              width: 600
            }
          ],
          collection_item_id: "ea2be9d4-3b58-453d-87c2-a45da443c7ee",
          type: "collection_item"
        }
      ],
      collection_data_id: "d07097a7-ac6d-47cd-9b10-0b2d5bdff04b",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/ef4f6735a8904ba899efb44adbdc7221?v=6f3f250d00674b10836d4def2457bc21"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_3.png",
          imageDark: "/images/collectionTemplates/blog_3_dark.png"
        },
        schema: {
          author: "Author",
          background: "Cover",
          category: "Categories",
          date: "Date",
          snippet: "Snippet",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "author",
          "background",
          "category",
          "date",
          "snippet",
          "title",
          "url"
        ],
        settings: {
          height: 350,
          numCol: 1
        },
        template: {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://api.typedream.com/v0/document/public/fdc3b292-d0d5-4460-ae5b-2929bc9a3b50_gradient_png.png",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "category"
                    }
                  ],
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Build in Public"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Calm Your Inner Thoughts"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "snippet"
                    }
                  ],
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Startup ideas change over time. So, the thing you want to do is to pick a good starting point."
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "author"
                    }
                  ],
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Hailey Brown"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "date"
                    }
                  ],
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "11-29-2021"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 317.8125,
          style: "card",
          type: "container",
          vertical_align: "align_end",
          width: 600
        },
        templateKey: "blog:blog_3"
      },
      openOptions: !1,
      type: "collection",
      width: 800
    }
  ],
  height: 935.171875,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, d = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "current commute playlist"
        },
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: " "
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 24,
      html: '<div id="listen"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/hue-6.png",
      children: [
        {
          children: [
            {
              text: ""
            }
          ],
          height: 237,
          openOptions: !1,
          type: "media_embed",
          url: "https://open.spotify.com/embed/episode/3GDlG7w6f1KXjk7Aw2yslR?utm_source=generator&theme=0",
          width: 500
        }
      ],
      height: 277,
      openOptions: !1,
      style: "card",
      type: "container",
      width: 600
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      backgroundImage: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/hue-9.png",
      children: [
        {
          children: [
            {
              text: ""
            }
          ],
          height: 237,
          openOptions: !1,
          type: "media_embed",
          url: "https://open.spotify.com/embed/episode/4gB2jVFPt7ntiwEdn7Ut67?utm_source=generator&theme=0",
          width: 500
        }
      ],
      height: 277,
      openOptions: !1,
      style: "card",
      type: "container",
      width: 600
    }
  ],
  height: 758.5,
  openOptions: !1,
  type: "container"
}, s = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 15,
      html: '<div id="nft"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "my nft's"
        },
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 800
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      background: "default",
      children: [
        {
          align: "align_left",
          children: [
            {
              color: "gray",
              fontSize: 16,
              fontStyle: "paragraph",
              gradient: !1,
              styled: !0,
              text: "You need to "
            },
            {
              bold: !0,
              color: "accent",
              fontSize: 16,
              fontStyle: "paragraph",
              gradient: !1,
              styled: !0,
              text: "Publish"
            },
            {
              color: "gray",
              fontSize: 16,
              fontStyle: "paragraph",
              gradient: !1,
              styled: !0,
              text: " your site to see the embedded NFTs. Embed new NFTs with "
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "https://nfte.app/"
                }
              ],
              type: "a",
              url: "https://nfte.app/Party"
            },
            {
              text: ""
            }
          ],
          type: "p",
          width: 700
        },
        {
          children: [
            {
              children: [
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 781.125,
                  html: `<div className="nft-embed"></div>
<script
  async
  src="https://nfte.app/api/embed.js?contract=0xBd3531dA5CF5857e7CfAA92426877b022e612cf8&tokenId=2434">
<\/script>`,
                  openOptions: !1,
                  type: "media_embed",
                  width: 370
                }
              ],
              type: "column_item",
              width: 0.5
            },
            {
              children: [
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 777.703125,
                  html: `<div className="nft-embed"></div>
<script
  async
  src="https://nfte.app/api/embed.js?contract=0xBd3531dA5CF5857e7CfAA92426877b022e612cf8&tokenId=6849">
<\/script>`,
                  openOptions: !1,
                  type: "media_embed",
                  width: 370
                }
              ],
              type: "column_item",
              width: 0.5
            }
          ],
          height: 829.71875,
          type: "column",
          vertical_align: "align_start",
          width: 700
        }
      ],
      height: 992.578125,
      openOptions: !1,
      type: "container",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 46,
      openOptions: !1,
      type: "spacer",
      width: 700
    }
  ],
  height: 1202.078125,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, _ = {
  align: "align_center",
  background: "rgba(252, 245, 229, 1)",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "gray",
          fontFamily: "libre_caslon_text",
          gradient: !1,
          styled: !0,
          text: "From the shoot"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 500
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 0.6666666666666666,
              children: [
                {
                  text: ""
                }
              ],
              height: 1050,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/1eabfa24-cc75-48b5-be11-200791ee1da6_vino-li-KiKn-FCXu_o-unsplash_jpg.jpg",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              aspect_ratio: 0.6666666666666666,
              children: [
                {
                  text: ""
                }
              ],
              height: 628.390625,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/dc91f0e8-af43-4426-99e2-6d569ab1b895_brandon-cormier-fq8eRBLZl40-unsplash_jpg.jpg",
              width: 418.9270833333333
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 740.953125,
      type: "column",
      width: 900
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1.5000000000000002,
              children: [
                {
                  text: ""
                }
              ],
              height: 466.66666666666663,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/6511c281-c636-40ee-8f98-f25914d5fed2_inna-lesyk-oSaJDA9XJmY-unsplash_jpg.jpg",
              width: 700
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              aspect_ratio: 1.4991628296358308,
              children: [
                {
                  text: ""
                }
              ],
              height: 466.9272651123831,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/b2b57c6a-1c55-4182-8480-3092d8c2ed89_marc-a-sporys-NO8Sj4dKE8k-unsplash_jpg.jpg",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              aspect_ratio: 0.6666666666666666,
              children: [
                {
                  text: ""
                }
              ],
              height: 616.3359374999999,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/8d1a93ac-b2fc-493e-8845-0699ffb1a779_leandra-rieger-EZx0WeqYhcM-unsplash_jpg.jpg",
              width: 410.8906249999999
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 688.328125,
      type: "column",
      width: 900
    }
  ],
  height: 1643.28125,
  openOptions: !1,
  type: "container",
  width: 1200,
  width_max: "100%"
}, p = {
  align: "align_center",
  background: "default",
  backgroundImage: "https://api.typedream.com/v0/document/public/9c0cb608-0fd3-405b-b755-eef89c2b4eb9_gradient_3_png.png?bucket=document",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "black",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/c0d8ec4a-0ed5-4796-b40a-73e9563cecf8/Frame_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032411Z&X-Amz-Expires=3600&X-Amz-Signature=795d1e50c7c6f16ef0ef4a812814d0c4be70a8fabc804d18b23f8de44e841816&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Andressen Horowitz \u2B50\uFE0F"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Categories"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Blog"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                },
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "cover"
                }
              ],
              height: 256.890625,
              style: "card",
              type: "container",
              url: "https://a16z.com/category/blockchain-cryptocurrencies/",
              vertical_align: "align_start",
              width: 300
            }
          ],
          collection_item_id: "6bcf5f43-7ca1-4078-8ff1-75ffb082a40f",
          collection_item_page_id: "cb5b7401-5ec8-472d-9347-c2d162c715e4",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/2dc1cf66-2727-4e6b-bbbd-f6138eee4aab/Frame_16.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032411Z&X-Amz-Expires=3600&X-Amz-Signature=13045b88807a6acac0e89a6d0573fecf987d1760a561037d1d01515f0d6e3e8a&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Bankless \u2B50\uFE0F"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Categories"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Blog"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                },
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "cover"
                }
              ],
              height: 256.890625,
              style: "card",
              type: "container",
              url: "https://banklesshq.com/",
              vertical_align: "align_start",
              width: 300
            }
          ],
          collection_item_id: "381b0307-ac48-4ac8-925d-26411088c9f1",
          collection_item_page_id: "cb5b7401-5ec8-472d-9347-c2d162c715e4",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/1a0317f6-5fa6-48f5-8865-70aedf4eb1c2/Frame_36.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032411Z&X-Amz-Expires=3600&X-Amz-Signature=f18ea17e753066912f88bd3f34eac087f9ceefccaed5809a29e6605d795cc93a&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Delphi Digital \u2B50\uFE0F"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Categories"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Research"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                },
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "cover"
                }
              ],
              height: 256.890625,
              style: "card",
              type: "container",
              url: "https://members.delphidigital.io/?podcasts",
              vertical_align: "align_start",
              width: 300
            }
          ],
          collection_item_id: "dc2b7962-7be3-478a-97f3-fe3d59e6d028",
          collection_item_page_id: "cb5b7401-5ec8-472d-9347-c2d162c715e4",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/4e4aef7b-2344-4b74-9068-12da196b287c/Frame_34.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032411Z&X-Amz-Expires=3600&X-Amz-Signature=66a642a398926f9599312f0128a9bbd6d0c6668445bdcf33313d1551fd3f6158&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Li's Newsletter \u2B50\uFE0F"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Categories"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Newsletter"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                },
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "cover"
                }
              ],
              height: 256.890625,
              style: "card",
              type: "container",
              url: "https://li.substack.com/",
              vertical_align: "align_start",
              width: 300
            }
          ],
          collection_item_id: "b406ee20-8694-4d35-af05-12ffcff9b92d",
          collection_item_page_id: "cb5b7401-5ec8-472d-9347-c2d162c715e4",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/0e08603d-ae59-4170-ac45-00814cc6d767/Frame_29.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032411Z&X-Amz-Expires=3600&X-Amz-Signature=fcd0c4c11726532d8db8a7534f764d185496aa11b3f1e66c073497a14bb09705&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Not Boring \u2B50\uFE0F"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Categories"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Newsletter"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                },
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "cover"
                }
              ],
              height: 256.890625,
              style: "card",
              type: "container",
              url: "https://www.notboring.co",
              vertical_align: "align_start",
              width: 300
            }
          ],
          collection_item_id: "7674d953-a1b4-421d-a974-2171d23a0184",
          collection_item_page_id: "cb5b7401-5ec8-472d-9347-c2d162c715e4",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/572cdbce-bc0b-44a2-a769-43e3212c9eab/Frame_32.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032411Z&X-Amz-Expires=3600&X-Amz-Signature=51d2a601c9d6fd950dc3b5be407702db9e1ee6d26f432abac45579197c66b93c&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Paradigm \u2B50\uFE0F"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "h3"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Categories"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Research"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                },
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "cover"
                }
              ],
              height: 256.890625,
              style: "card",
              type: "container",
              url: "https://www.paradigm.xyz/writing",
              vertical_align: "align_start",
              width: 300
            }
          ],
          collection_item_id: "00a57956-18ab-42ac-b494-23398eb89198",
          collection_item_page_id: "cb5b7401-5ec8-472d-9347-c2d162c715e4",
          type: "collection_item"
        }
      ],
      collection_data_id: "bb0f915c-5052-4404-86e6-a4de93b52a41",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/9791fae463734afdb22a93ccece886fa?v=c50424e0388c49468342998793d73309"
        },
        previewImage: {
          image: "/images/collectionTemplates/directory_horizontal.png",
          imageDark: "/images/collectionTemplates/directory_horizontal_dark.png"
        },
        schema: {
          Categories: "Category",
          Description: "Description",
          Logo: "Cover",
          "Website Link Text": "URL",
          "Website Link URL": "URL",
          cover: "Cover",
          title: "Name",
          url: "URL"
        },
        schemaOrder: [
          "Categories",
          "Description",
          "Logo",
          "Website Link Text",
          "Website Link URL",
          "title",
          "url",
          "cover"
        ],
        settings: {
          height: 350,
          numCol: 3
        },
        template: {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Typedream"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Categories"
                    }
                  ],
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "Website Builder"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            },
            {
              blockElementKey: "backgroundImage",
              schemaKey: "cover"
            }
          ],
          height: 256.890625,
          style: "card",
          type: "container",
          vertical_align: "align_start",
          width: 300
        },
        templateKey: "lists:directory_horizontal"
      },
      openOptions: !1,
      type: "collection",
      width: 1600
    }
  ],
  height: 672.796875,
  openOptions: !1,
  style: "card",
  type: "container",
  width: 1700
}, y = {
  children: [
    {
      children: [
        {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "Logo"
                }
              ],
              height: 136.3125,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/963401de-5b17-4557-8979-611d45a95d9c/Airdrop.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T031553Z&X-Amz-Expires=3600&X-Amz-Signature=a1ecefb0b5e4e4d436a4eb863f3b742cbbe0a4ab60366947a1700358e7d7dc6c&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 136.3125
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Airdrop"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Title-f7jk"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "When you receive a digital asset or token for participating in a new protocol or service for free"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 350.8125,
          style: "card",
          type: "container",
          url: "https://www.sofi.com/learn/content/crypto-airdrop/",
          vertical_align: "align_start",
          width: 300
        }
      ],
      collection_item_id: "0f5ca195-0bad-48f1-b6c2-f2d79ec7fd17",
      collection_item_page_id: "4bef5f15-7061-4cd4-a6df-1c5f5bc2f177",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "Logo"
                }
              ],
              height: 136.3125,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/70c03678-e182-4ec5-b4d4-2b79368b0cbd/Maxi.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T031553Z&X-Amz-Expires=3600&X-Amz-Signature=67b10904f1e2e114521f39764f56c03da62d109dc0323441a7cb677250063d0e&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 136.3125
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Maxi"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Title-f7jk"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Someone who believes that their chain of choice is the only real chain and everything else is a scam"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 350.8125,
          style: "card",
          type: "container",
          url: "https://www.investopedia.com/terms/b/bitcoin-maximalism.asp",
          vertical_align: "align_start",
          width: 300
        }
      ],
      collection_item_id: "2be5d8e1-c260-4db9-85c1-aa0cbc9f97c9",
      collection_item_page_id: "4bef5f15-7061-4cd4-a6df-1c5f5bc2f177",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "Logo"
                }
              ],
              height: 136.3125,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/9a8f043b-24bd-409f-85ed-e079a61985e5/Floor.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T031553Z&X-Amz-Expires=3600&X-Amz-Signature=454ec49ada4e4beef2fdb5ded09e391e00f713f04dcf3e56d8aa3e1efca6386c&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 136.3125
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Floor"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Title-f7jk"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "The minimum price of an NFT in a collection"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 350.8125,
          style: "card",
          type: "container",
          url: "https://nftpricefloor.com",
          vertical_align: "align_start",
          width: 300
        }
      ],
      collection_item_id: "55e862dc-8b57-486c-b08e-9c578c2572d7",
      collection_item_page_id: "4bef5f15-7061-4cd4-a6df-1c5f5bc2f177",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "Logo"
                }
              ],
              height: 136.3125,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/c3824c1e-4297-481c-b2d9-5b2653f284de/Burn.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T031553Z&X-Amz-Expires=3600&X-Amz-Signature=32691550d8a88e17dc777581097ac8dcf82ac9f660ced73d071c392ea47dc884&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 136.3125
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Burn"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Title-f7jk"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "To send a token or NFT to the null address on a chain that nobody can access"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 350.8125,
          style: "card",
          type: "container",
          url: "https://www.fool.com/investing/stock-market/market-sectors/financials/cryptocurrency-stocks/coin-burn/",
          vertical_align: "align_start",
          width: 300
        }
      ],
      collection_item_id: "223f05e1-bb51-4932-9875-71e44684cf29",
      collection_item_page_id: "4bef5f15-7061-4cd4-a6df-1c5f5bc2f177",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "Logo"
                }
              ],
              height: 136.3125,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/50709351-0b80-408e-a354-020447222b7e/Degen.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T031553Z&X-Amz-Expires=3600&X-Amz-Signature=70c96d5892791e244b2b7c6458e42437b3be4c6f5c32eb050c8ca6d81d2b0348&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 136.3125
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Degen"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Title-f7jk"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Person who buys tokens or NFTs without doing any research and operating purely off profit potential or vibes"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 350.8125,
          style: "card",
          type: "container",
          url: "",
          vertical_align: "align_start",
          width: 300
        }
      ],
      collection_item_id: "89f0a88c-1c4b-4794-9273-8a72d1dcade6",
      collection_item_page_id: "4bef5f15-7061-4cd4-a6df-1c5f5bc2f177",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          background: "black",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "Logo"
                }
              ],
              height: 136.3125,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/48cef934-1196-42fc-bc96-5a61baef4409/NGMI.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T031553Z&X-Amz-Expires=3600&X-Amz-Signature=2ec1f3e5af68f7b74caf7976ce446c2f532fd309a6596bb256d548f7373b5573&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 136.3125
            },
            {
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "NGMI"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              openOptions: !1,
              type: "h3"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Title-f7jk"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Not Gonna Make It \u2639\uFE0F"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 350.8125,
          style: "card",
          type: "container",
          url: "https://knowyourmeme.com/memes/wagmi-ngmi",
          vertical_align: "align_start",
          width: 300
        }
      ],
      collection_item_id: "08a04fd1-1e77-440a-b9cd-a2971c8e1eb1",
      collection_item_page_id: "4bef5f15-7061-4cd4-a6df-1c5f5bc2f177",
      type: "collection_item"
    }
  ],
  collection_data_id: "ee4e72ab-5710-4f0c-8458-2f3a51175903",
  collection_template: {
    custom: !0,
    databaseTemplateURL: {
      notion: "https://typedream.notion.site/9791fae463734afdb22a93ccece886fa?v=c50424e0388c49468342998793d73309"
    },
    previewImage: {
      image: "/images/collectionTemplates/directory_horizontal.png",
      imageDark: "/images/collectionTemplates/directory_horizontal_dark.png"
    },
    schema: {
      Categories: "Categories",
      Description: "Definition",
      Logo: "Cover",
      "Title-80gj": "Term",
      "Title-f7jk": "Definition",
      "Website Link Text": "Website URL",
      "Website Link URL": "Website URL",
      title: "Term",
      url: "Links"
    },
    schemaOrder: [
      "Categories",
      "Description",
      "Logo",
      "Title-80gj",
      "Title-f7jk",
      "Website Link Text",
      "Website Link URL",
      "title",
      "url"
    ],
    settings: {
      height: 350,
      numCol: 3
    },
    template: {
      align: "align_center",
      background: "black",
      backgroundDarken: !0,
      children: [
        {
          children: [
            {
              color: "white",
              gradient: !1,
              styled: !0,
              text: ""
            }
          ],
          type: "p"
        },
        {
          align: "align_center",
          aspect_ratio: 1,
          children: [
            {
              text: ""
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Logo"
            }
          ],
          height: 136.3125,
          openOptions: !1,
          type: "img",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/logo_square_transparent.png",
          width: 136.3125
        },
        {
          children: [
            {
              color: "white",
              gradient: !1,
              styled: !0,
              text: ""
            }
          ],
          type: "p"
        },
        {
          children: [
            {
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "text",
                  schemaKey: "title"
                }
              ],
              color: "white",
              gradient: !1,
              styled: !0,
              text: "Typedream"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [],
          openOptions: !1,
          type: "h3"
        },
        {
          children: [
            {
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "text",
                  schemaKey: "Title-f7jk"
                }
              ],
              color: "white",
              gradient: !1,
              styled: !0,
              text: "Description"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [],
          type: "p"
        }
      ],
      collection_connect_data: !0,
      collection_connect_data_keys: [
        {
          blockElementKey: "url",
          schemaKey: "url"
        }
      ],
      height: 350.8125,
      openOptions: !0,
      style: "card",
      type: "container",
      vertical_align: "align_start",
      width: 300
    },
    templateKey: "lists:directory_horizontal"
  },
  openOptions: !1,
  type: "collection",
  width: 900
}, h = {
  children: [
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/6fa800bb-b0fc-4751-af22-3c8b218d9b50/Rectangle_17.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=846260be4f0e2c14d218d509d7a702550b6d667cf2d2554b782df14358c9f99a&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Lido"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://lido.fi/",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "af701e1a-a3eb-45ce-aba2-1f1da3d994a4",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/e9857ef7-63bd-4ba2-a93d-2b3eac4dd081/Rectangle_15.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=7827104d3185323b41e2d462c6510f3821b101b942bbf23c9381cfe6cd7e3c7e&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Kraken"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://www.kraken.com/features/staking-coins",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "1ffc258c-10be-4252-be1b-b41020d3aab3",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/9c26f0c6-dbcd-4105-af77-bd3a235b8c80/Rectangle_16.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=9e533b444b5f424fb01715288c911a67c29ecee5a44f23da45db73a5f64723b2&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "TradeStation"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://www.tradestation.com/crypto/earn-crypto/",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "367568ba-85e4-47dd-bcb6-70802758fa92",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/5685b163-c2af-43bf-bae6-61fee6081d0b/Rectangle_10.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=c92550b6d63ec5406348d2c5c2a2db6cae33518add27aa98fc5df13a4de1e3d8&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Crypto.com"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://crypto.com/earn",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "6f4745e8-6d5c-4acb-889e-055ef5648e84",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/2598d424-6821-42f2-a26d-4001a41b7951/Rectangle_8.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=8fc015f343dde04b8b650d834c790f7bac613c7b2147d63badd4008e2d817496&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "BlockFi"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://blockfi.com/rates/",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "aeb45cf5-2a4c-4ec3-814f-fbcb2b2c5f63",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/5540b34f-301f-4359-b6d9-caf28dfa0284/Rectangle_12.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=d60c5a74b1b03fa9cd3fb0a3ea390a95854a1268e989a27715be4f333b0eb349&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Gemini"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://www.gemini.com/earn",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "e5ee500a-de73-4566-b6e9-b3c36203e8bd",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/9573e15d-0c6a-4edb-80bd-ebbae1432c17/Rectangle_14.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=eb9d8ef8d4b698ec9530bc82ecbc6b38e5ba8411166c0fb7f42c57acdcb21076&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Ribbon Finance"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://www.ribbon.finance/",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "0c365166-3d34-40e4-b1f8-e1bed83e22cd",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/5737890a-b093-4290-a74b-21727578bce4/Rectangle_13.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=d10d2ee516a6b027db86f9fad92f0c131902453da5fd55cdd62effde507771ea&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Katana"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://app.katana.so/",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "90f22892-9c40-4f96-8a4e-57957b5248b3",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/8ef0f35a-2c29-4208-bc99-805778bb6e0f/Rectangle_9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T032806Z&X-Amz-Expires=3600&X-Amz-Signature=fcd4052a0619f7f1fd1e6bc293136ccdb3ca2927eda6ff2e9eba0940d984cdbc&X-Amz-SignedHeaders=host&x-id=GetObject",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Coinbase Earn"
                }
              ],
              type: "h2"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "card",
          type: "container",
          url: "https://www.coinbase.com/earn",
          vertical_align: "align_end",
          width: 300
        }
      ],
      collection_item_id: "04fb0125-9bba-4713-a47f-cebb1e7c2e77",
      collection_item_page_id: "7189437e-8de9-4eec-98e5-3b55018c771e",
      type: "collection_item"
    }
  ],
  collection_data_id: "44a42626-b2ba-4ea2-b3c0-105a8f6be584",
  collection_template: {
    custom: !0,
    databaseTemplateURL: {
      notion: "https://typedream.notion.site/ef4f6735a8904ba899efb44adbdc7221?v=6f3f250d00674b10836d4def2457bc21"
    },
    previewImage: {
      image: "/images/collectionTemplates/blog_cover_horizontal.png",
      imageDark: "/images/collectionTemplates/blog_cover_horizontal_dark.png"
    },
    schema: {
      author: "Author",
      background: "Gradient",
      title: "Name",
      url: "URL"
    },
    schemaOrder: [
      "title",
      "background",
      "author",
      "url"
    ],
    settings: {
      height: 350,
      numCol: 3
    },
    template: {
      align: "align_center",
      backgroundDarken: !0,
      backgroundImage: "https://api.typedream.com/v0/document/public/fdc3b292-d0d5-4460-ae5b-2929bc9a3b50_gradient_png.png",
      children: [
        {
          children: [
            {
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "text",
                  schemaKey: "title"
                }
              ],
              color: "white",
              gradient: !1,
              styled: !0,
              text: "Calm Your Inner Thoughts"
            }
          ],
          type: "h2"
        }
      ],
      collection_connect_data: !0,
      collection_connect_data_keys: [
        {
          blockElementKey: "backgroundImage",
          schemaKey: "background"
        },
        {
          blockElementKey: "url",
          schemaKey: "url"
        }
      ],
      height: 275,
      openOptions: !0,
      style: "card",
      type: "container",
      vertical_align: "align_end",
      width: 300
    },
    templateKey: "blog:blog_cover_horizontal"
  },
  openOptions: !1,
  type: "collection",
  width: 1100
}, g = {
  children: [
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/6d0d5a01-7ace-49af-b206-f348fc425b2e/Rectangle_22.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=0e4b63309a577289ab295d1c232d3b0eca423f10636eb89cda00f4ba7dfc52a5&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "Chain Runners"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "95c7b8d7-6c1f-432c-a4d3-f34b510ad736",
          collection_link_slug: "chain-runners",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "95c7b8d7-6c1f-432c-a4d3-f34b510ad736",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/dc9ee91c-17f5-429b-a3bd-b2c350175984/Rectangle_20.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=272ccd3d084a62aa8bba1c0c1556fb8c33bd83f3e3989e1ddc6ab503ab922f0d&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "CrypToadz"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "001daed1-ebd5-4706-b3a2-044df2e51f79",
          collection_link_slug: "cryptoadz",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "001daed1-ebd5-4706-b3a2-044df2e51f79",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/a0e28ef3-e49d-4dd0-8bf7-84ff953edc79/Rectangle_21.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=53a69744f234589748a95d09a3781538221387a8c02238a4f07da11f442f0037&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "CyberKongs"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "1bee76c8-125e-41df-822b-c24f860c1462",
          collection_link_slug: "cyberkongs",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "1bee76c8-125e-41df-822b-c24f860c1462",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/32e8889d-1302-446b-9155-5bf029679bff/Rectangle_19.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=92bf6a6c9a9cf6acdb601b6c12e26210e0e2eb2cc99966cc9587f35afe73c065&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "CryptoKitties"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "6490b978-b22d-4ae1-9632-1dbbc426af9a",
          collection_link_slug: "",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "6490b978-b22d-4ae1-9632-1dbbc426af9a",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/84685490-79c0-4469-ab77-09bc33cdacc8/Rectangle_18.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=c58359a861c92c5be443436a61d5006bb48f4fb47cc89c3c8ffb5e794b45c053&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "Meebits"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "9a8282cc-5fef-4230-8b84-0fea3e3abeb8",
          collection_link_slug: "",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "9a8282cc-5fef-4230-8b84-0fea3e3abeb8",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/37211b58-7e1c-4d39-a624-ad42718ae33a/Rectangle_17.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=dd2b44df9bda7fa27b61dd5314282c0bbd4ff36cb4e693f10c7df0fc958936b5&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "Clone X"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "909397f3-871a-422a-bf98-74da760e8f65",
          collection_link_slug: "",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "909397f3-871a-422a-bf98-74da760e8f65",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/9b513ff1-f389-49b7-82a0-3e815d67ba6e/Rectangle_16.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=0f48de10030b9bb2e6a2c9f446ca9144099de208b56928318957a3b44f8d911d&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "The Sandbox"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "6bf5b538-f6d8-46d2-85e0-579902032e4c",
          collection_link_slug: "",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "6bf5b538-f6d8-46d2-85e0-579902032e4c",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/951f8f8e-60b3-4a8d-9fcf-aa2464e21e73/Rectangle_15.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220505%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220505T040410Z&X-Amz-Expires=3600&X-Amz-Signature=1d879f8e63f60405e0b5946374fb177d385822d919706f7d7f15e578919bb68c&X-Amz-SignedHeaders=host&x-id=GetObject",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "Mutant Ape Yacht Club"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "fcf464ae-ccd5-4d51-bf07-ce991dfc88b4",
          collection_link_slug: "",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "fcf464ae-ccd5-4d51-bf07-ce991dfc88b4",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
          children: [
            {
              align: "align_center",
              aspect_ratio: 0.7976878612716763,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 313.40579710144925,
              openOptions: !1,
              type: "img",
              url: "https://rndmcharacters-s3.s3.amazonaws.com/invisible-montage-600w.gif",
              width: 250
            },
            {
              align: "align_left",
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontFamily: "quicksand",
                  gradient: !1,
                  styled: !0,
                  text: "Invisible Friends"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "Link-4ffr"
            }
          ],
          collection_internal_link: !0,
          collection_link_data_id: "dc773d1b-96c1-4e15-b9da-159f0d92040b",
          collection_link_slug: "",
          height: 452.765625,
          internal_link: !0,
          page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
          style: "card",
          type: "container",
          url: "/top-nft-projects",
          width: 300
        }
      ],
      collection_item_id: "dc773d1b-96c1-4e15-b9da-159f0d92040b",
      collection_item_page_id: "3af77cfa-aefc-4540-b6e1-de021a1c40c2",
      type: "collection_item"
    }
  ],
  collection_data_id: "404c27dd-3ce4-4db9-893c-dd82a69ee4b5",
  collection_template: {
    custom: !0,
    databaseTemplateURL: {
      notion: "https://typedream.notion.site/9791fae463734afdb22a93ccece886fa?v=c50424e0388c49468342998793d73309"
    },
    previewImage: {
      image: "/images/collectionTemplates/shop_3.png",
      imageDark: "/images/collectionTemplates/shop_3_dark.png"
    },
    schema: {
      Description: "Description",
      "Link-4ffr": "ITEM_PAGE_URL",
      "Website Link Text": "Website URL",
      "Website Link URL": "Website URL",
      image: "Image",
      link: "ITEM_PAGE_URL",
      price: "Price",
      rating: "Rating",
      title: "Name",
      url: "ITEM_PAGE_URL"
    },
    schemaOrder: [
      "Description",
      "Website Link Text",
      "Website Link URL",
      "image",
      "link",
      "price",
      "rating",
      "title",
      "url",
      "Link-4ffr"
    ],
    settings: {
      height: 350,
      numCol: 3
    },
    template: {
      align: "align_center",
      backgroundImage: "https://api.typedream.com/v0/document/public/54eb1317-dcb7-45c2-8748-b4dbda6a3793_zoltan-tasi-jjv1OW1T2bI-unsplash_jpg.jpg?bucket=document",
      children: [
        {
          align: "align_center",
          aspect_ratio: 0.7976878612716763,
          children: [
            {
              text: ""
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "image"
            }
          ],
          height: 313.40579710144925,
          openOptions: !1,
          type: "img",
          url: "https://api.typedream.com/v0/document/public/220204e8-9949-47d9-9882-6504863f0f0a_Screen_Shot_2022-03-09_at_6_47_1_png.png?bucket=document",
          width: 250
        },
        {
          align: "align_left",
          children: [
            {
              bold: !0,
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "text",
                  schemaKey: "title"
                }
              ],
              color: "white",
              fontFamily: "quicksand",
              gradient: !1,
              styled: !0,
              text: "iPad mini"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [],
          type: "h3"
        }
      ],
      collection_connect_data: !0,
      collection_connect_data_keys: [
        {
          blockElementKey: "url",
          schemaKey: "Link-4ffr"
        }
      ],
      height: 452.765625,
      openOptions: !0,
      style: "card",
      type: "container",
      width: 300
    },
    templateKey: "shop:shop_3"
  },
  openOptions: !1,
  type: "collection",
  width: 1e3
}, u = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "\u{1F30E}"
                    },
                    {
                      text: ""
                    }
                  ],
                  type: "h1",
                  width: 1800
                },
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "If you\u2019re good at what you do"
                    },
                    {
                      text: " "
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: "and want to join us, then"
                    },
                    {
                      text: " "
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "we don\u2019t really care where you\u2019re based."
                    },
                    {
                      text: " "
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: "We\u2019ll make it work. "
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "h2",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#a5a5a5",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "We\u2019re a remote company that meets up IRL multiple times a year to hack/work together. "
                    }
                  ],
                  type: "p",
                  width: 1800
                }
              ],
              height: 150,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "We do have a preference for people in the US since we usually meet up within the US, so traveling is easier. But, not required."
                    }
                  ],
                  type: "p",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 518.5,
      type: "column",
      width: 1200
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#a5a5a5",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "We\u2019re looking for ex-founders, people who have worked at startups in the past, people who have built + shipped their own personal projects/products to real users, and "
                    },
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "really anyone that\u2019s made an honest effort to do cool shit in the real world.",
                      underline: !0
                    }
                  ],
                  type: "p",
                  width: 1800
                }
              ],
              height: 150,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "We don\u2019t care about FAANG experience, degrees, your age, or what college you attended. We do care deeply about your history building products that shipped to actual users."
                    }
                  ],
                  type: "p",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      text: "\u{1F608}"
                    }
                  ],
                  type: "h1",
                  width: 1800
                },
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "We want  shippers."
                    },
                    {
                      text: " "
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: "We ship. And we ship fast. Come help us ship faster."
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "h2",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 478.328125,
      type: "column",
      width: 1200
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Why join us?"
                    }
                  ],
                  type: "p",
                  width: 1800
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p",
                  width: 1800
                },
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          children: [
                            {
                              color: "white",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: `Dive into the rapidly-growing Web3 Industry
`
                            }
                          ],
                          type: "lic",
                          width: 1800
                        }
                      ],
                      type: "li"
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              color: "white",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: `Accelerate your learnings
`
                            }
                          ],
                          type: "lic"
                        }
                      ],
                      type: "li"
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              color: "white",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: `Tackle interesting problems
`
                            }
                          ],
                          type: "lic"
                        }
                      ],
                      type: "li"
                    }
                  ],
                  type: "ol"
                }
              ],
              height: 150,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_center",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "\u{1F4AA}\u{1F3FD}"
                    },
                    {
                      text: ""
                    }
                  ],
                  type: "h1",
                  width: 1800
                },
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: "Startups are the best possible career choice for"
                    },
                    {
                      text: " "
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "learning fast and gaining specific knowledge. "
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "h2",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#a5a5a5",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "We\u2019re an early-stage crypto startup. That means you will get to hone cutting-edge skills, hack and ship products to billions, wear multiple hats, and work super closely with users."
                    }
                  ],
                  type: "p",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 499.125,
      type: "column",
      width: 1200
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      text: "\u26A1\uFE0F"
                    }
                  ],
                  type: "h1",
                  width: 1800
                },
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "We're backed by the best"
                    }
                  ],
                  openOptions: !1,
                  type: "h2",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#a5a5a5",
              children: [
                {
                  aspect_ratio: 1.5539906103286385,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  height: 213,
                  openOptions: !1,
                  type: "img",
                  url: "https://api.typedream.com/v0/document/public/2a3b0cc1-9046-4066-8198-c38104fd66e9_Group_21_png.png?bucket=document",
                  width: 331
                }
              ],
              height: 150,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_center",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2c2c2c",
              children: [
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      text: "\u{1F91D}"
                    }
                  ],
                  type: "h1",
                  width: 1800
                },
                {
                  align: "align_left",
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "We're also an equal opportunity employer."
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: " "
                    },
                    {
                      color: "#a5a5a5",
                      gradient: !1,
                      styled: !0,
                      text: "We don't discriminate, period."
                    }
                  ],
                  type: "h2",
                  width: 1800
                }
              ],
              height: 146,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 457.546875,
      type: "column",
      width: 1200
    }
  ],
  height: 2013.09375,
  openOptions: !1,
  type: "container",
  width: 1300
}, m = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Benefits"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1200
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F469}\u200D\u2695\uFE0F"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Full health benefits"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Medical, vision, dental."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F4BB}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Lapto"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "ps of your choice"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "The latest and greatest."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "\u{1F4AA}\u{1F3FD}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "$400/mo. fitness & mental health stipend"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Personal trainers, therapy, tennis classes, etc."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F4DA}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "$50/mo. on books"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Fill your brain with knowledge and teach us cool stuff."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end"
            }
          ],
          type: "column_item",
          width: 0.25
        }
      ],
      height: 326.25,
      type: "column",
      width: 1200
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F37F}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Subscriptions"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Superhuman, Sunsama, Crunchyroll, and "
                    },
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "AMC A-List ofc."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u2708\uFE0F"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Travel"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "We'll fly you out to team meetups in SF, and other cool spots so we can hack IRL."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F308}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Unlimited PTO"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Minimum 2-weeks. Time-off/breaks are actually a superpower."
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F37C}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: `Maternity/
Paternity Leave`
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Family comes first."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end"
            }
          ],
          type: "column_item",
          width: 0.25
        }
      ],
      height: 315.75,
      type: "column",
      width: 1200
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "#2a2a2a",
              children: [
                {
                  children: [
                    {
                      text: ""
                    },
                    {
                      text: "\u{1F3E1}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Small team"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "We're a tight bunch!"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 203.75,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_end",
              width: 700
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.25
        }
      ],
      height: 284.71875,
      type: "column",
      width: 1200
    }
  ],
  height: 1075.21875,
  openOptions: !1,
  type: "container",
  width: 1300
}, f = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 31,
      html: '<div id="roles"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Available Roles"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1200
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "black",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "role"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Sales Lead"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "equity"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "0.25% - 1%"
                    },
                    {
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "salary"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "$70k - $150k"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "447cc524-d378-47fb-9b01-b359d632464c",
              height: 134.28125,
              internal_link: !0,
              openOptions: !1,
              style: "card",
              type: "container",
              url: "",
              vertical_align: "align_center",
              width: 700
            }
          ],
          collection_item_id: "447cc524-d378-47fb-9b01-b359d632464c",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "role"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Founding Product Designer"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "equity"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "0.25% - 2.5%"
                    },
                    {
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "salary"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "$80k - $180k"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "84e9384c-504f-4f44-8117-6f728c30a494",
              height: 134.28125,
              internal_link: !0,
              openOptions: !1,
              style: "card",
              type: "container",
              url: "",
              vertical_align: "align_center",
              width: 700
            }
          ],
          collection_item_id: "84e9384c-504f-4f44-8117-6f728c30a494",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              background: "black",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "role"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Full-stack Engineer"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "equity"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "0.25% - 2.5%"
                    },
                    {
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "salary"
                        }
                      ],
                      color: "white",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "$80k - $220k"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "4236d4fc-d701-4de4-ad6c-e2a8838c09ee",
              height: 134.28125,
              internal_link: !0,
              openOptions: !1,
              style: "card",
              type: "container",
              url: "",
              vertical_align: "align_center",
              width: 700
            }
          ],
          collection_item_id: "4236d4fc-d701-4de4-ad6c-e2a8838c09ee",
          type: "collection_item"
        }
      ],
      collection_data_id: "b48be21b-b138-4a05-a8c5-5449683d75dd",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/ef4f6735a8904ba899efb44adbdc7221?v=6f3f250d00674b10836d4def2457bc21"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_standard_vertical.png",
          imageDark: "/images/collectionTemplates/blog_standard_vertical_dark.png"
        },
        schema: {
          equity: "Equity",
          image: "Employment Type",
          role: "Role",
          salary: "Salary",
          snippet: "Snippet",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "equity",
          "image",
          "role",
          "salary",
          "snippet",
          "url"
        ],
        settings: {
          numCol: 1
        },
        template: {
          align: "align_center",
          background: "black",
          children: [
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "role"
                    }
                  ],
                  color: "white",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Full-stack Engineer"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "equity"
                    }
                  ],
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "0.25% - 2.5%"
                },
                {
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: " \u2022 "
                },
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "salary"
                    }
                  ],
                  color: "white",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  gradient: !1,
                  styled: !0,
                  text: "$80k - $220k"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 134.28125,
          openOptions: !1,
          style: "card",
          type: "container",
          vertical_align: "align_center",
          width: 700
        },
        templateKey: "blog:blog_standard_vertical"
      },
      openOptions: !1,
      type: "collection",
      width: 700
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          color: "white",
          fontSize: 13,
          fontStyle: "small",
          gradient: !1,
          italic: !0,
          styled: !0,
          text: "We believe diversity is part of excellence. Applications by members of all underrepresented groups are encouraged."
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          color: "default",
          fontSize: 13,
          fontStyle: "small",
          italic: !0,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          color: "white",
          fontSize: 13,
          fontStyle: "small",
          gradient: !1,
          italic: !0,
          styled: !0,
          text: "As an equal opportunity employer, we don't discriminate against people because of their race, ethnicity, sex, age, religion, national origin, marital status, pregnancy, personal appearance, veteran status, disability, sexual orientation, gender identity or expression, family responsibilities, or political affiliation."
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          color: "default",
          fontSize: 13,
          fontStyle: "small",
          italic: !0,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    }
  ],
  height: 934.34375,
  openOptions: !1,
  type: "container",
  width: 1300
}, b = {
  children: [
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-1.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-01"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "blue, pink, green"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-1.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "b997cc46-521b-44c7-99e2-0d6d223bfb69",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-2.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-02"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "blue, pink, green"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-2.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "5e02d1ef-c290-428b-aa4e-7efa6164a9c9",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-3.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-03"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "blue, white, pink, purple"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-3.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "202927c6-55f9-4a61-9988-61dadd25e341",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-4.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-04"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "green, pink, grey"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-4.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "9e637952-7abb-46a5-8f1c-e7d020b900ea",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-5.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-05"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "blue, orange"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-5.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "43b9a2f3-147a-441e-8a5a-c4d9f7771a36",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-6.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-06"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "blue, orange"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-6.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "4d5cbae4-ad88-4a9c-8ff5-03592ea805ff",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-7.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-07"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "purple, orange, green"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-7.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "525dd74e-c309-43b8-a697-1cb594c44f61",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-8.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-08"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "white, blue, orange"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-8.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "3148502c-a90e-46a4-891c-8ce9451e275c",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-9.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-09"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "white, blue, green"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-9.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "30cdede0-2b27-4691-9395-6f4126b7040c",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-1.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-10"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "purple, pink, dark"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-1.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "f90e5da8-84df-4dd4-9476-67e0158aca5a",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-2.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-11"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "green, blue, dark"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-2.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "07a4756a-850c-4e96-9914-b07db339f6fe",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-3.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-12"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "orange, pink, blue, dark"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-3.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "00a38e48-74b2-4bf5-bc6b-8c7bf26781a4",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-4.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-13"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "purple, orange, dark"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-4.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "e83d4fe1-2a0f-4255-b0e6-d066e42859be",
      type: "collection_item"
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1.4006677796327214,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 249.88081048867696,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-5.png",
              width: 350
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "space_mono",
                  fontSize: 16,
                  fontStyle: "paragraph",
                  styled: !0,
                  text: "grad-14"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "tags"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "space_mono",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "blue, yellow, purple"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 357.15625,
          openOptions: !1,
          style: "plain",
          type: "container",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/dark-5.png",
          vertical_align: "align_start",
          width: 400
        }
      ],
      collection_item_id: "2f57e187-1a28-4907-8a56-8c2683fd5dde",
      type: "collection_item"
    }
  ],
  collection_data_id: "54e1e30c-0919-40ac-aa62-cbad026809da",
  collection_default_item_page_template_key: "blog:blog_standard",
  collection_template: {
    custom: !0,
    databaseTemplateURL: {
      notion: "https://typedream.notion.site/b93fabcd974e467cbf31c892edf0871c?v=694d8aa84d0c47d1975ffd86b70b2af0"
    },
    previewImage: {
      image: "/images/collectionTemplates/blog_1.png",
      imageDark: "/images/collectionTemplates/blog_1_dark.png"
    },
    schema: {
      image: "Image",
      tags: "Tags",
      title: "Name",
      url: "Image"
    },
    schemaOrder: [
      "image",
      "tags",
      "title",
      "url"
    ],
    settings: {
      numCol: 3
    },
    template: {
      align: "align_center",
      children: [
        {
          aspect_ratio: 1.4006677796327214,
          children: [
            {
              text: ""
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "image"
            }
          ],
          height: 249.88081048867696,
          openOptions: !1,
          type: "img",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/illustrations/gradients/blue-1.png",
          width: 350
        },
        {
          children: [
            {
              bold: !0,
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "text",
                  schemaKey: "title"
                }
              ],
              color: "default",
              fontFamily: "space_mono",
              fontSize: 16,
              fontStyle: "paragraph",
              styled: !0,
              text: "grad-01"
            }
          ],
          openOptions: !1,
          type: "p"
        },
        {
          children: [
            {
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "text",
                  schemaKey: "tags"
                }
              ],
              color: "rgba(170, 170, 170, 1)",
              fontFamily: "space_mono",
              fontSize: 13,
              fontStyle: "small",
              gradient: !1,
              styled: !0,
              text: "blue, pink"
            }
          ],
          type: "p"
        }
      ],
      collection_connect_data: !0,
      collection_connect_data_keys: [
        {
          blockElementKey: "url",
          schemaKey: "url"
        }
      ],
      height: 357.15625,
      openOptions: !1,
      style: "plain",
      type: "container",
      vertical_align: "align_start",
      width: 400
    },
    templateKey: "blog:blog_1"
  },
  openOptions: !1,
  type: "collection",
  width: 1100
}, k = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      children: [
        {
          text: "What's included"
        }
      ],
      openOptions: !1,
      type: "h3"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/9077c7e5-3d2b-4591-bd53-2e2868fc2307/orange.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090907Z&X-Amz-Expires=3600&X-Amz-Signature=187704cbf876f9c7550fbc67ee59fbf127868c0354d70498b048a2b86ab5aef2&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "chapter"
                        }
                      ],
                      color: "rgba(255,255,255,0.3)",
                      fontSize: 80,
                      fontStyle: "title",
                      fontWeight: 800,
                      gradient: !1,
                      letterSpacing: -2.5,
                      lineHeight: "90px",
                      styled: !0,
                      text: "1"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Why build a business online?"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "7a10c48b-ab44-4443-bc27-9c35d564767c",
              collection_link_slug: "why-build-a-business-online-",
              height: 275,
              internal_link: !0,
              page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
              style: "plain",
              type: "container",
              url: "/templates/templates/ux-course/course",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "7a10c48b-ab44-4443-bc27-9c35d564767c",
          collection_item_page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/c8497ac8-86f6-4498-953b-bfe7305d77aa/purple-orange.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090907Z&X-Amz-Expires=3600&X-Amz-Signature=c54748bd639c2477006117a5156a3126ea08372c250a7f9af6596e618af9dd9d&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "chapter"
                        }
                      ],
                      color: "rgba(255,255,255,0.3)",
                      fontSize: 80,
                      fontStyle: "title",
                      fontWeight: 800,
                      gradient: !1,
                      letterSpacing: -2.5,
                      lineHeight: "90px",
                      styled: !0,
                      text: "2"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Different ways to make money online"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "f67e02a3-8003-4ba6-8da5-33389d4685f3",
              collection_link_slug: "different-ways-to-make-money-online",
              height: 275,
              internal_link: !0,
              page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
              style: "plain",
              type: "container",
              url: "/templates/templates/ux-course/course",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "f67e02a3-8003-4ba6-8da5-33389d4685f3",
          collection_item_page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/7f73e8a0-1993-42c3-b1dc-93cb1bffcf8f/gradient-6.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090907Z&X-Amz-Expires=3600&X-Amz-Signature=130402cc5bd8db2ae57a095aab58c9d0f8b363e805bc3a158e70bb16a2698761&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "chapter"
                        }
                      ],
                      color: "rgba(255,255,255,0.3)",
                      fontSize: 80,
                      fontStyle: "title",
                      fontWeight: 800,
                      gradient: !1,
                      letterSpacing: -2.5,
                      lineHeight: "90px",
                      styled: !0,
                      text: "3"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "How to make content, and why"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "43797297-8903-4866-a413-58903c299afa",
              collection_link_slug: "how-to-make-content",
              height: 275,
              internal_link: !0,
              page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
              style: "plain",
              type: "container",
              url: "/templates/templates/ux-course/course",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "43797297-8903-4866-a413-58903c299afa",
          collection_item_page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/82ecbc60-817e-4f73-874f-e2511b770a23/gradient-10.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090907Z&X-Amz-Expires=3600&X-Amz-Signature=c06521ad74e203e054a5906d6a1e1120a60d4079975358970ce70b5e03064323&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "chapter"
                        }
                      ],
                      color: "rgba(255,255,255,0.3)",
                      fontSize: 80,
                      fontStyle: "title",
                      fontWeight: 800,
                      gradient: !1,
                      letterSpacing: -2.5,
                      lineHeight: "90px",
                      styled: !0,
                      text: "4"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "How to build a business with no-code?"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "53df1258-fccf-4f54-a0da-86b908613db2",
              collection_link_slug: "what-is-nocode-",
              height: 275,
              internal_link: !0,
              page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
              style: "plain",
              type: "container",
              url: "/templates/templates/ux-course/course",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "53df1258-fccf-4f54-a0da-86b908613db2",
          collection_item_page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/f6ece7d2-df0c-4c14-b8e1-ffe1f2cc9001/gradient-3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090907Z&X-Amz-Expires=3600&X-Amz-Signature=0ad5167a93d78e74e953fbdb4b79fd90aa09e54ffe1f22c487fed385c9d864a7&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "chapter"
                        }
                      ],
                      color: "rgba(255,255,255,0.3)",
                      fontSize: 80,
                      fontStyle: "title",
                      fontWeight: 800,
                      gradient: !1,
                      letterSpacing: -2.5,
                      lineHeight: "90px",
                      styled: !0,
                      text: "5"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "How content marketing works"
                    }
                  ],
                  openOptions: !1,
                  type: "h3"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "c4a8eb98-c67f-45ca-b38f-01b21b201dee",
              collection_link_slug: "how-content-marketing-works",
              height: 275,
              internal_link: !0,
              page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
              style: "plain",
              type: "container",
              url: "/templates/templates/ux-course/course",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "c4a8eb98-c67f-45ca-b38f-01b21b201dee",
          collection_item_page_id: "a9b71d6a-b23e-4327-a5dd-5e7c8d1f3873",
          type: "collection_item"
        }
      ],
      collection_data_id: "2751a3b4-e2b6-4afd-a1ea-c5f448d0498d",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/fbd5732656ae4a42bb071d26057cbb4b?v=fef9b57c5368410c9a330b11afbf2afd"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_5.png",
          imageDark: "/images/collectionTemplates/blog_5_dark.png"
        },
        schema: {
          background: "Cover",
          chapter: "Chapter",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "background",
          "title",
          "url",
          "chapter"
        ],
        settings: {
          height: 350,
          numCol: 3
        },
        template: {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://api.typedream.com/v0/document/public/fdc3b292-d0d5-4460-ae5b-2929bc9a3b50_gradient_png.png",
          children: [
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "chapter"
                    }
                  ],
                  color: "rgba(255,255,255,0.3)",
                  fontSize: 80,
                  fontStyle: "title",
                  fontWeight: 800,
                  gradient: !1,
                  letterSpacing: -2.5,
                  lineHeight: "90px",
                  styled: !0,
                  text: "1"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Calm Your Inner Thoughts"
                }
              ],
              openOptions: !1,
              type: "h3"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "plain",
          type: "container",
          vertical_align: "align_end",
          width: 300
        },
        templateKey: "blog:blog_5"
      },
      openOptions: !1,
      type: "collection"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      children: [
        {
          text: ""
        },
        {
          children: [
            {
              color: "default",
              fontSize: 80,
              fontStyle: "title",
              fontWeight: 800,
              letterSpacing: -2.5,
              lineHeight: "90px",
              styled: !0,
              text: "Start \u2192"
            }
          ],
          internal_link: !0,
          open_in_new_tab: !1,
          page_id: "5dc46f53-173c-4ac8-98cf-cf17fdf54f43",
          type: "a",
          url: "/templates/templates/dandelion-goat-7526/course"
        },
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    }
  ],
  height: 899.25,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, x = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          color: "white",
          fontFamily: "space_mono",
          gradient: !1,
          styled: !0,
          text: "Latest Episodes"
        }
      ],
      type: "h2"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/4f987837-f153-48e7-a16f-4bd31944165c/stephanie-liverani-DiA1DpZyOmM-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090455Z&X-Amz-Expires=3600&X-Amz-Signature=c99e42c02a3c80b036a1d0245a30416e368da24e05827da36dfeffe37f55ba9b&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "E"
                    },
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "episode"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "5"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "  "
                    },
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "\u2022  "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-14"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "These PHDs Built a DAO for Researchers"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "316559fd-b925-4d81-af99-645579920da6",
              collection_link_slug: "these-phds-built-a-dao-for-researchers",
              height: 275,
              internal_link: !0,
              page_id: "5412c114-16ad-492d-9110-972dba752f76",
              style: "plain",
              type: "container",
              url: "/templates/templates/blog-dark-podcast/podcasts",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "316559fd-b925-4d81-af99-645579920da6",
          collection_item_page_id: "5412c114-16ad-492d-9110-972dba752f76",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/4d1bd416-6cc9-498a-a8e0-c3ca109cd817/marshal-quast-HtzbW4UjJeU-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090455Z&X-Amz-Expires=3600&X-Amz-Signature=9bf7f47930f4695b69b3d6e60bbfda892b55ee295df71371dd210e81131c5241&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "E"
                    },
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "episode"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "4"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "  "
                    },
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "\u2022  "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-14"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Clara Jenkins on Women in Web3"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "4a6b1586-78e8-4086-a56c-820e3b45d9dc",
              collection_link_slug: "clara-jenkins-on-women-in-web3",
              height: 275,
              internal_link: !0,
              page_id: "5412c114-16ad-492d-9110-972dba752f76",
              style: "plain",
              type: "container",
              url: "/templates/templates/blog-dark-podcast/podcasts",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "4a6b1586-78e8-4086-a56c-820e3b45d9dc",
          collection_item_page_id: "5412c114-16ad-492d-9110-972dba752f76",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/3c21f280-f386-4cc1-beec-ace404ff5ab9/taylor-grote-UiVe5QvOhao-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090455Z&X-Amz-Expires=3600&X-Amz-Signature=0cb9133a5421cfde34742046e52759efd6a0e6d34ec924712a4205ad6c986d6d&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "E"
                    },
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "episode"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "3"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "  "
                    },
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "\u2022  "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-21"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "At 26-Years Old, Tom Launched a Multi-Million Dollar Web3 Project"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "2e6ca4e6-f6eb-4a42-aba6-a620ba0e2bcf",
              collection_link_slug: "at-26-years-old-tom-launched-a-multi-million-dollar-web3-project",
              height: 275,
              internal_link: !0,
              page_id: "5412c114-16ad-492d-9110-972dba752f76",
              style: "plain",
              type: "container",
              url: "/templates/templates/blog-dark-podcast/podcasts",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "2e6ca4e6-f6eb-4a42-aba6-a620ba0e2bcf",
          collection_item_page_id: "5412c114-16ad-492d-9110-972dba752f76",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/6c1e509d-9749-4605-8673-c33cbabd3159/annie-spratt-sggw4-qDD54-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T090455Z&X-Amz-Expires=3600&X-Amz-Signature=e023edc844e592b257071a8144dc80ab0ceb9820d77b53c1684f36cac21e5c95&X-Amz-SignedHeaders=host&x-id=GetObject",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "E"
                    },
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "episode"
                        }
                      ],
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "2"
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "  "
                    },
                    {
                      bold: !0,
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "\u2022  "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "white",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-28"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "How Web3 NYC Brought Together Some of The Most Influential People in the US"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "backgroundImage",
                  schemaKey: "background"
                },
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "123f2b45-2200-439c-b87a-a028fde068df",
              collection_link_slug: "how-web3-nyc-brought-together-some-of-the-most-influential-people-in-the-us",
              height: 275,
              internal_link: !0,
              page_id: "5412c114-16ad-492d-9110-972dba752f76",
              style: "plain",
              type: "container",
              url: "/templates/templates/blog-dark-podcast/podcasts",
              vertical_align: "align_end",
              width: 300
            }
          ],
          collection_item_id: "123f2b45-2200-439c-b87a-a028fde068df",
          collection_item_page_id: "5412c114-16ad-492d-9110-972dba752f76",
          type: "collection_item"
        }
      ],
      collection_data_id: "b1e0760c-7a54-47af-adf4-75d91ee83ccc",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/54be1ada07cb4f10a177a344910911cc?v=5174a88114fa44469f32ed3ec674d857"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_5.png",
          imageDark: "/images/collectionTemplates/blog_5_dark.png"
        },
        schema: {
          author: "Author",
          background: "Cover",
          date: "Date",
          episode: "Episode",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "author",
          "background",
          "title",
          "url",
          "episode",
          "date"
        ],
        settings: {
          height: 350,
          numCol: 2
        },
        template: {
          align: "align_center",
          backgroundDarken: !0,
          backgroundImage: "https://api.typedream.com/v0/document/public/fdc3b292-d0d5-4460-ae5b-2929bc9a3b50_gradient_png.png",
          children: [
            {
              children: [
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "E"
                },
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "episode"
                    }
                  ],
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "2"
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "  "
                },
                {
                  bold: !0,
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "\u2022  "
                },
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "date"
                    }
                  ],
                  color: "white",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "2020-12-04"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "white",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Calm Your Inner Thoughts"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "backgroundImage",
              schemaKey: "background"
            },
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 275,
          style: "plain",
          type: "container",
          vertical_align: "align_end",
          width: 300
        },
        templateKey: "blog:blog_5"
      },
      openOptions: !1,
      type: "collection"
    }
  ],
  height: 861,
  openOptions: !1,
  type: "container",
  width: 1e3,
  width_max: "100%"
}, w = {
  align: "align_center",
  background: "black",
  children: [
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Check out my guides \u{1F447}"
        }
      ],
      openOptions: !1,
      type: "h3"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "gray",
                      styled: !0,
                      text: "\u{1F6E0}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Building a Product"
                    }
                  ],
                  type: "p"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "gray",
                      fontSize: 16,
                      fontStyle: "subtitle3",
                      styled: !0,
                      text: "How I approach product development, from user interviews, design, and code."
                    }
                  ],
                  type: "p"
                }
              ],
              height: 216.5,
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "gray",
                      styled: !0,
                      text: "\u{1F469}\u200D\u{1F4BB}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "On My Desk"
                    }
                  ],
                  type: "p"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "gray",
                      fontSize: 16,
                      fontStyle: "subtitle3",
                      styled: !0,
                      text: "Setting up a cozy & productive workspace, with budget-friendly items"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 216.5,
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      color: "gray",
                      styled: !0,
                      text: "\u{1F4F9}"
                    }
                  ],
                  openOptions: !1,
                  type: "h2"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      bold: !0,
                      color: "default",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "YouTubing"
                    }
                  ],
                  type: "p"
                },
                {
                  align: "align_center",
                  children: [
                    {
                      color: "gray",
                      fontSize: 16,
                      fontStyle: "subtitle3",
                      styled: !0,
                      text: "Start a channel on the side and start growing. Find out a couple tips and my journey"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 216.5,
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 420.5,
      type: "column",
      width: 1e3
    }
  ],
  height: 547.75,
  openOptions: !1,
  type: "container",
  width: 1300,
  width_max: "100%"
}, A = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  color: "default",
                  gradient: !1,
                  styled: !0,
                  text: "Explore"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          children: [
                            {
                              color: "gray",
                              styled: !0,
                              text: "\u{1F4D5}"
                            }
                          ],
                          openOptions: !1,
                          type: "h1"
                        }
                      ],
                      type: "column_item",
                      width: 0.23863636363636334
                    },
                    {
                      children: [
                        {
                          align: "align_left",
                          children: [
                            {
                              bold: !0,
                              color: "default",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Courses"
                            }
                          ],
                          type: "p"
                        },
                        {
                          align: "align_left",
                          children: [
                            {
                              color: "gray",
                              fontSize: 16,
                              fontStyle: "subtitle3",
                              styled: !0,
                              text: "How I approach product development, from user interviews, design, and code."
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.7613636363636366
                    }
                  ],
                  height: 159,
                  type: "column",
                  width: 400
                }
              ],
              height: 216.5,
              openOptions: !1,
              style: "card",
              type: "container"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          children: [
                            {
                              color: "gray",
                              styled: !0,
                              text: "\u{1F4AD}"
                            }
                          ],
                          openOptions: !1,
                          type: "h1"
                        }
                      ],
                      type: "column_item",
                      width: 0.23917748917748877
                    },
                    {
                      children: [
                        {
                          align: "align_left",
                          children: [
                            {
                              bold: !0,
                              color: "default",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Blog"
                            }
                          ],
                          type: "p"
                        },
                        {
                          align: "align_left",
                          children: [
                            {
                              color: "gray",
                              fontSize: 16,
                              fontStyle: "subtitle3",
                              styled: !0,
                              text: "Everyday learnings as a technical co-founder building a no-code product, and creating content."
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.7608225108225112
                    }
                  ],
                  height: 159,
                  type: "column",
                  width: 400
                }
              ],
              height: 216.5,
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.5
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  color: "default",
                  gradient: !1,
                  styled: !0,
                  text: "Latest Articles"
                }
              ],
              openOptions: !1,
              type: "h3"
            },
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          children: [
                            {
                              children: [
                                {
                                  bold: !0,
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "title"
                                    }
                                  ],
                                  color: "default",
                                  fontSize: 16,
                                  fontStyle: "paragraph",
                                  styled: !0,
                                  text: "10 Website Design Process Steps for a High Converting Website"
                                }
                              ],
                              type: "p"
                            },
                            {
                              children: [
                                {
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "categories"
                                    }
                                  ],
                                  color: "gray",
                                  fontSize: 13,
                                  fontStyle: "small",
                                  gradient: !1,
                                  styled: !0,
                                  text: "Build In Public"
                                }
                              ],
                              collection_connect_data: !0,
                              collection_connect_data_keys: [],
                              type: "p"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "url"
                            }
                          ],
                          collection_internal_link: !0,
                          collection_link_data_id: "0dd4bc06-edbd-445c-859e-a8f07553c8fe",
                          collection_link_slug: "10-website-design-process-steps-for-a-high-converting-website",
                          height: 106.46875,
                          internal_link: !0,
                          openOptions: !1,
                          page_id: "08c95504-ab78-40ac-bdcc-63bb5afe8026",
                          style: "plain",
                          type: "container",
                          url: "/cms/examples/blog",
                          vertical_align: "align_center",
                          width: 500
                        }
                      ],
                      collection_item_id: "0dd4bc06-edbd-445c-859e-a8f07553c8fe",
                      collection_item_page_id: "08c95504-ab78-40ac-bdcc-63bb5afe8026",
                      type: "collection_item"
                    },
                    {
                      children: [
                        {
                          align: "align_center",
                          children: [
                            {
                              children: [
                                {
                                  bold: !0,
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "title"
                                    }
                                  ],
                                  color: "default",
                                  fontSize: 16,
                                  fontStyle: "paragraph",
                                  styled: !0,
                                  text: "Common Mistakes When Coming Up with Startup Ideas"
                                }
                              ],
                              type: "p"
                            },
                            {
                              children: [
                                {
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "categories"
                                    }
                                  ],
                                  color: "gray",
                                  fontSize: 13,
                                  fontStyle: "small",
                                  gradient: !1,
                                  styled: !0,
                                  text: "Build In Public"
                                }
                              ],
                              collection_connect_data: !0,
                              collection_connect_data_keys: [],
                              type: "p"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "url"
                            }
                          ],
                          collection_internal_link: !0,
                          collection_link_data_id: "6c4fef89-9c4f-4eeb-9c4c-4be1ca90b7ff",
                          collection_link_slug: "common-mistakes-when-coming-up-with-startup-ideas",
                          height: 106.46875,
                          internal_link: !0,
                          openOptions: !1,
                          page_id: "08c95504-ab78-40ac-bdcc-63bb5afe8026",
                          style: "plain",
                          type: "container",
                          url: "/cms/examples/blog",
                          vertical_align: "align_center",
                          width: 500
                        }
                      ],
                      collection_item_id: "6c4fef89-9c4f-4eeb-9c4c-4be1ca90b7ff",
                      collection_item_page_id: "08c95504-ab78-40ac-bdcc-63bb5afe8026",
                      type: "collection_item"
                    },
                    {
                      children: [
                        {
                          align: "align_center",
                          children: [
                            {
                              children: [
                                {
                                  bold: !0,
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "title"
                                    }
                                  ],
                                  color: "default",
                                  fontSize: 16,
                                  fontStyle: "paragraph",
                                  styled: !0,
                                  text: "How To Build A Startup 101"
                                }
                              ],
                              type: "p"
                            },
                            {
                              children: [
                                {
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "categories"
                                    }
                                  ],
                                  color: "gray",
                                  fontSize: 13,
                                  fontStyle: "small",
                                  gradient: !1,
                                  styled: !0,
                                  text: "Build In Public"
                                }
                              ],
                              collection_connect_data: !0,
                              collection_connect_data_keys: [],
                              type: "p"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "url"
                            }
                          ],
                          collection_internal_link: !0,
                          collection_link_data_id: "c2609392-afc8-471d-a90b-a9f14b7108c0",
                          collection_link_slug: "how-to-build-a-startup-101",
                          height: 106.46875,
                          internal_link: !0,
                          openOptions: !1,
                          page_id: "08c95504-ab78-40ac-bdcc-63bb5afe8026",
                          style: "plain",
                          type: "container",
                          url: "/cms/examples/blog",
                          vertical_align: "align_center",
                          width: 500
                        }
                      ],
                      collection_item_id: "c2609392-afc8-471d-a90b-a9f14b7108c0",
                      collection_item_page_id: "08c95504-ab78-40ac-bdcc-63bb5afe8026",
                      type: "collection_item"
                    }
                  ],
                  collection_data_id: "c7b1be74-b67c-45c5-ba2c-453e95267d2f",
                  collection_default_item_page_template_key: "blog:blog_standard",
                  collection_template: {
                    custom: !0,
                    databaseTemplateURL: {
                      notion: "https://typedream.notion.site/ef4f6735a8904ba899efb44adbdc7221?v=6f3f250d00674b10836d4def2457bc21"
                    },
                    previewImage: {
                      image: "/images/collectionTemplates/blog_4.png",
                      imageDark: "/images/collectionTemplates/blog_4_dark.png"
                    },
                    schema: {
                      categories: "Categories",
                      image: "Cover",
                      snippet: "Snippet",
                      title: "Name",
                      url: "ITEM_PAGE_URL"
                    },
                    schemaOrder: [
                      "image",
                      "snippet",
                      "title",
                      "url",
                      "categories"
                    ],
                    settings: {
                      numCol: 1
                    },
                    template: {
                      align: "align_center",
                      children: [
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontSize: 16,
                              fontStyle: "paragraph",
                              styled: !0,
                              text: "How to Build a Startup 101"
                            }
                          ],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "categories"
                                }
                              ],
                              color: "gray",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "Build in Public"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      height: 106.46875,
                      openOptions: !1,
                      style: "plain",
                      type: "container",
                      vertical_align: "align_center",
                      width: 500
                    },
                    templateKey: "blog:blog_4"
                  },
                  openOptions: !1,
                  type: "collection"
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.5
        }
      ],
      height: 593.65625,
      type: "column",
      width: 1e3
    }
  ],
  height: 695.71875,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, S = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          fontFamily: "quicksand",
          styled: !0,
          text: "The UX Lab \u{1F351}"
        }
      ],
      type: "h1",
      width: 1e3
    },
    {
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: "Everything you need to know on UI/UX for beginners"
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          fontSize: 24,
          fontStyle: "subtitle1",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "divider",
      width: 1e3
    },
    {
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      openOptions: !1,
      type: "p",
      width: 1e3
    },
    {
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          styled: !0,
          text: "Featured"
        }
      ],
      openOptions: !1,
      type: "h3",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/89e04bc7-2c3c-47c0-a2a5-60a5bd003bc9/Mask_group.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=86cabd78251b2754fe4733bec2bebbfe1526b4390a0793b68bbcdea121388c35&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "How to Design a Landing Page for Your Project"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-14"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "4e24851a-65ce-469f-91b9-67b5e479ea24",
              collection_link_slug: "how-to-design-a-landing-page-for-your-project",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "4e24851a-65ce-469f-91b9-67b5e479ea24",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/c19b91c3-e7bb-4a43-927c-5d8e93451deb/Mask_group-2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=054331e3f4b3c46f731c7c1386aaf162f0cbe72ef2b2119f19388ed54e1cf66a&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "How to Start UX Research with Your Early Users"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-14"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "c6a00122-6100-4bc4-bce8-47a9d825ecd8",
              collection_link_slug: "how-to-start-ux-research-with-your-early-users",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "c6a00122-6100-4bc4-bce8-47a9d825ecd8",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/7133f46c-5bf0-4089-a331-8201f1db5573/Mask_group-4.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=46180662c16a57b7dbff32f35c77610360cda1a1f3b506e57d47e3172220afa7&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "10 Things to Include when Designing App Mockups"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-21"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "db21c677-4f78-4a9e-b236-1bea99583dc8",
              collection_link_slug: "10-things-to-include-when-designing-app-mockups",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "db21c677-4f78-4a9e-b236-1bea99583dc8",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        }
      ],
      collection_data_id: "f9ff3c6c-61d8-49ba-8f58-b9e4df69889a",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/07677e605d2546e09b643525f66adc49?v=6e2ea1a28aa34644bf91f25c6a068abb"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_2.png",
          imageDark: "/images/collectionTemplates/blog_2_dark.png"
        },
        schema: {
          author: "Author",
          date: "Date",
          image: "Cover",
          snippet: "Snippet",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "author",
          "date",
          "image",
          "snippet",
          "title",
          "url"
        ],
        settings: {
          numCol: 3
        },
        template: {
          align: "align_center",
          children: [
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 462,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/4154022e-0f39-4230-821d-9400a4a2a43a_Mask_group-6_png.png?bucket=document",
              width: 462
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "quicksand",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "How to Build a Startup 101"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "date"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "quicksand",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Feb 2020"
                },
                {
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "quicksand",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: " \u2022 "
                },
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "author"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "quicksand",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Putri Karunia"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 492.28125,
          openOptions: !1,
          style: "card",
          type: "container",
          vertical_align: "align_start",
          width: 400
        },
        templateKey: "blog:blog_2"
      },
      openOptions: !1,
      type: "collection",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    }
  ],
  height: 975.71875,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, K = {
  align: "align_center",
  background: "gray",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    },
    {
      align: "align_left",
      children: [
        {
          color: "default",
          fontFamily: "quicksand",
          gradient: !1,
          styled: !0,
          text: "Latest Post"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "column_dynamic",
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "image"
                            }
                          ],
                          height: 415,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/74e669cc-d945-4bae-8619-2b2761b070e5/Mask_group-6.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=5fb70ec43016abcf18c4f238f07565bb3f7016305e54ff945d8d87845c8cec22&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 415
                        }
                      ],
                      type: "column_item",
                      width: 0.5210050251256289
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              gradient: !1,
                              styled: !0,
                              text: "3 Steps to Develop Your UI/UX Skills"
                            }
                          ],
                          type: "h2"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "snippet"
                                }
                              ],
                              color: "gray",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Start improving your UI/UX skills by implementing these 3 steps in your product development cycle."
                            }
                          ],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "date"
                                }
                              ],
                              color: "rgba(170, 170, 170, 1)",
                              fontFamily: "quicksand",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "2021-11-29"
                            },
                            {
                              color: "rgba(170, 170, 170, 1)",
                              fontFamily: "quicksand",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: " \u2022 "
                            },
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "author"
                                }
                              ],
                              color: "rgba(170, 170, 170, 1)",
                              fontFamily: "quicksand",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "Putri Karunia"
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.47899497487437126
                    }
                  ],
                  height: 455,
                  type: "column",
                  width: 900,
                  width_max: "100%"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "e2e3db04-0851-4cf9-b26b-12d09dc98a11",
              collection_link_slug: "3-steps-to-develop-your-ui-ux-skills",
              height: 495,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "plain",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_center",
              width: 900,
              width_max: "100%"
            }
          ],
          collection_item_id: "e2e3db04-0851-4cf9-b26b-12d09dc98a11",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        }
      ],
      collection_data_id: "1f29d29d-d1c5-4445-81d9-f81d8bc0d83e",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/07677e605d2546e09b643525f66adc49?v=6e2ea1a28aa34644bf91f25c6a068abb"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_4.png",
          imageDark: "/images/collectionTemplates/blog_4_dark.png"
        },
        schema: {
          author: "Author",
          date: "Date",
          image: "Cover",
          snippet: "Snippet",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "author",
          "date",
          "image",
          "snippet",
          "title",
          "url"
        ],
        settings: {
          numCol: 1
        },
        template: {
          align: "align_center",
          children: [
            {
              align: "column_dynamic",
              children: [
                {
                  children: [
                    {
                      align: "align_center",
                      aspect_ratio: 1,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "image"
                        }
                      ],
                      height: 415,
                      type: "img",
                      url: "https://api.typedream.com/v0/document/public/3c847ef2-e6d4-4ca7-88bb-da6075820e07_Mask_group-6_png.png?bucket=document",
                      width: 415
                    }
                  ],
                  type: "column_item",
                  width: 0.5210050251256289
                },
                {
                  children: [
                    {
                      children: [
                        {
                          bold: !0,
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "title"
                            }
                          ],
                          color: "default",
                          fontFamily: "quicksand",
                          gradient: !1,
                          styled: !0,
                          text: "How to Build a Startup 101"
                        }
                      ],
                      type: "h2"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "snippet"
                            }
                          ],
                          color: "gray",
                          fontFamily: "quicksand",
                          fontSize: 20,
                          fontStyle: "subtitle2",
                          lineHeight: 1.4,
                          styled: !0,
                          text: "Target a big market, build an MVP with the least amount of tech, and launch!"
                        }
                      ],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "date"
                            }
                          ],
                          color: "rgba(170, 170, 170, 1)",
                          fontFamily: "quicksand",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Feb 2020"
                        },
                        {
                          color: "rgba(170, 170, 170, 1)",
                          fontFamily: "quicksand",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: " \u2022 "
                        },
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "author"
                            }
                          ],
                          color: "rgba(170, 170, 170, 1)",
                          fontFamily: "quicksand",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Putri Karunia"
                        }
                      ],
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.47899497487437126
                }
              ],
              height: 455,
              type: "column",
              width: 900,
              width_max: "100%"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 495,
          openOptions: !1,
          style: "plain",
          type: "container",
          vertical_align: "align_center",
          width: 900,
          width_max: "100%"
        },
        templateKey: "blog:blog_4"
      },
      openOptions: !1,
      type: "collection",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    }
  ],
  height: 809.5,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, z = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    },
    {
      align: "align_left",
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          styled: !0,
          text: "All Posts"
        }
      ],
      openOptions: !1,
      optionIndex: 4,
      type: "h3",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/74e669cc-d945-4bae-8619-2b2761b070e5/Mask_group-6.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=5fb70ec43016abcf18c4f238f07565bb3f7016305e54ff945d8d87845c8cec22&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "3 Steps to Develop Your UI/UX Skills"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-11-29"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "e2e3db04-0851-4cf9-b26b-12d09dc98a11",
              collection_link_slug: "3-steps-to-develop-your-ui-ux-skills",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "e2e3db04-0851-4cf9-b26b-12d09dc98a11",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/b54107f9-d9ce-4d7a-99a9-d0d49800bf1a/Mask_group-3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=00bac89b6124adfeb05066a9ec886c91a36de8b1b8865e39b2e57ae5a037a702&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "An Easy Guide on Selecting Colors for Your Platform"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-28"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "386f13b6-6411-4cd3-89a0-667fecc4a02f",
              collection_link_slug: "an-easy-guide-on-selecting-colors-for-your-platform",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "386f13b6-6411-4cd3-89a0-667fecc4a02f",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/7133f46c-5bf0-4089-a331-8201f1db5573/Mask_group-4.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=46180662c16a57b7dbff32f35c77610360cda1a1f3b506e57d47e3172220afa7&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "10 Things to Include when Designing App Mockups"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-21"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "db21c677-4f78-4a9e-b236-1bea99583dc8",
              collection_link_slug: "10-things-to-include-when-designing-app-mockups",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "db21c677-4f78-4a9e-b236-1bea99583dc8",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/89e04bc7-2c3c-47c0-a2a5-60a5bd003bc9/Mask_group.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=86cabd78251b2754fe4733bec2bebbfe1526b4390a0793b68bbcdea121388c35&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "How to Design a Landing Page for Your Project"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-14"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "4e24851a-65ce-469f-91b9-67b5e479ea24",
              collection_link_slug: "how-to-design-a-landing-page-for-your-project",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "4e24851a-65ce-469f-91b9-67b5e479ea24",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              children: [
                {
                  align: "align_center",
                  aspect_ratio: 1,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 462,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/c19b91c3-e7bb-4a43-927c-5d8e93451deb/Mask_group-2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220602%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220602T081625Z&X-Amz-Expires=3600&X-Amz-Signature=054331e3f4b3c46f731c7c1386aaf162f0cbe72ef2b2119f19388ed54e1cf66a&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 462
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "quicksand",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "How to Start UX Research with Your Early Users"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-10-14"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "author"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "quicksand",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Putri Karunia"
                    }
                  ],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "c6a00122-6100-4bc4-bce8-47a9d825ecd8",
              collection_link_slug: "how-to-start-ux-research-with-your-early-users",
              height: 492.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
              style: "card",
              type: "container",
              url: "/templates/templates/blog-colorful/posts",
              vertical_align: "align_start",
              width: 400
            }
          ],
          collection_item_id: "c6a00122-6100-4bc4-bce8-47a9d825ecd8",
          collection_item_page_id: "b55392ab-052e-445d-b16a-470a0be999dd",
          type: "collection_item"
        }
      ],
      collection_data_id: "fec1d703-0d87-4f21-966f-dbdc23ac29ad",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/07677e605d2546e09b643525f66adc49?v=6e2ea1a28aa34644bf91f25c6a068abb"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_2.png",
          imageDark: "/images/collectionTemplates/blog_2_dark.png"
        },
        schema: {
          author: "Author",
          date: "Date",
          image: "Cover",
          snippet: "Snippet",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "author",
          "date",
          "image",
          "snippet",
          "title",
          "url"
        ],
        settings: {
          numCol: 3
        },
        template: {
          align: "align_center",
          children: [
            {
              align: "align_center",
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 462,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/4154022e-0f39-4230-821d-9400a4a2a43a_Mask_group-6_png.png?bucket=document",
              width: 462
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "quicksand",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "How to Build a Startup 101"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "date"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "quicksand",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Feb 2020"
                },
                {
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "quicksand",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: " \u2022 "
                },
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "author"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "quicksand",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Putri Karunia"
                }
              ],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 492.28125,
          openOptions: !1,
          style: "card",
          type: "container",
          vertical_align: "align_start",
          width: 400
        },
        templateKey: "blog:blog_2"
      },
      openOptions: !1,
      type: "collection",
      width: 1e3
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 50,
      openOptions: !1,
      type: "spacer"
    }
  ],
  height: 1196.8125,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, E = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  aspect_ratio: 1.5444126074498574,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 291.3729128014841,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/eaa48442-5817-46e6-a8ee-580178cf5774/vera-davidova-syADksVMrKk-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=ee561efad50ec389696da1656a4ea851879a214ea078ccceb6c363ff21dba2e6&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 450
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "I am a digital nomad but this is real nomadic life."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "gray",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Ended the year with an amazing trip to the Wahiba desert. Loved the culture of the people there and learned a lot about the area\u2019s history."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-12-16"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Location"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Wahiba Sands, Oman"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "30b12172-843d-4a1e-87d8-4cc7099718cb",
              collection_link_slug: "i-am-a-digital-nomad-but-this-is-real-nomadic-life-",
              height: 524.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_start",
              width: 500
            }
          ],
          collection_item_id: "30b12172-843d-4a1e-87d8-4cc7099718cb",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  aspect_ratio: 1.5444126074498574,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 291.3729128014841,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/9affae1a-9ddd-43c3-9f44-8e195dc327a7/clarisse-meyer-359AOEwnYcw-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=ff2f242ff6227c707a6e811b88ef279ff7eab5006b7c1f2b19319309c48feb1e&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 450
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Trekking by day, stargazing by night"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "gray",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Before visiting family, I decided to take a short excursion to Alabama Hills. Being in the vast, open space was quite zen, definitely a humbling experience."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2022-01-27"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Location"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Lone Pine, USA"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "6ac8caa4-48dc-4050-ae42-68dde6c56909",
              collection_link_slug: "trekking-by-day-stargazing-by-night",
              height: 524.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_start",
              width: 500
            }
          ],
          collection_item_id: "6ac8caa4-48dc-4050-ae42-68dde6c56909",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  aspect_ratio: 1.5444126074498574,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 291.3729128014841,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/ceae76d7-2784-4960-baf7-26419d474549/kristaps-grundsteins-phv0kzqMJyk-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=5877bd96995ef671867747bdbee69ef6e1f779cebf3e5b48d4051fe539e07df9&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 450
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "A true winter wonderland"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "gray",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "I\u2019ve always wanted to visit Finland and as expected, it did not disappoint! It was super cold but the people were warm and the scenery was just fantastic."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2021-11-30"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Location"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Kilpisj\xE4rvi, Finland"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "7915733d-5884-42d7-8142-96cd6e7cbb3e",
              collection_link_slug: "a-true-winter-wonderland",
              height: 524.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_start",
              width: 500
            }
          ],
          collection_item_id: "7915733d-5884-42d7-8142-96cd6e7cbb3e",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  aspect_ratio: 1.5444126074498574,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 291.3729128014841,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/2bf167ce-52d0-4ea9-a0e5-e2fa3665adc2/william-kusno-tq_h8bCNhKQ-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=34788cffa8b039132417bb57e4e0471f1d2c9f73abe306f84467457565b81ae3&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 450
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Snorkeling in the hottest new tourist destination in Indonesia"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "gray",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "When I plunged into the crystal clear waters, I hardly believed that I was on Planet Earth! The breathtaking diversity of marine life was a sight to behold."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2022-04-06"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Location"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Labuan Bajo, Indonesia"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "68b1143f-c323-4715-95b2-e8414c76f9c1",
              collection_link_slug: "snorkeling-in-the-hottest-new-tourist-destination-in-indonesia",
              height: 524.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_start",
              width: 500
            }
          ],
          collection_item_id: "68b1143f-c323-4715-95b2-e8414c76f9c1",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  aspect_ratio: 1.5444126074498574,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 291.3729128014841,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/65ed6f00-9170-4ed3-ae5b-25e0477254db/anton-nazaretian-JCHlsKSgbSk-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=72fe7e132812161d5089bdf6f6362c11932d524fcb45f12252dd4aa34a715721&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 450
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "The men and women running Japan\u2019s world renowned ramen shops. "
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "gray",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "I had the unique opportunity to see the behind-the-scenes of running a busy ramen shop in Central Tokyo and learn about the famous noodle\u2019s history."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2022-03-01"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Location"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Tokyo, Japan"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "facb72c0-59ff-4b32-be8c-aa43e771f253",
              collection_link_slug: "the-men-and-women-running-japan-s-world-renowned-ramen-shops-",
              height: 524.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_start",
              width: 500
            }
          ],
          collection_item_id: "facb72c0-59ff-4b32-be8c-aa43e771f253",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_left",
              children: [
                {
                  align: "align_left",
                  aspect_ratio: 1.5444126074498574,
                  children: [
                    {
                      text: ""
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "image"
                    }
                  ],
                  height: 291.3729128014841,
                  type: "img",
                  url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/07be5d4f-06b8-4a34-aea6-f29d1dcf569b/toms-rits-ryfptJi3fAM-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220803%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220803T060805Z&X-Amz-Expires=3600&X-Amz-Signature=5c5a3cf1c89b99b78be570219245a104ef57a7da760054086c0c525e99e256c4&X-Amz-SignedHeaders=host&x-id=GetObject",
                  width: 450
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "title"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Exploring the coastlines of Portugal in a camper van"
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "snippet"
                        }
                      ],
                      color: "gray",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "I spent 48 hours in a small camper van and saw the most scenic coastline I\u2019ve ever seen."
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "date"
                        }
                      ],
                      color: "rgba(170, 170, 170, 1)",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "2022-05-17"
                    },
                    {
                      color: "rgba(170, 170, 170, 1)",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: " \u2022 "
                    },
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Location"
                        }
                      ],
                      color: "default",
                      fontFamily: "libre_caslon_text",
                      fontSize: 13,
                      fontStyle: "small",
                      gradient: !1,
                      styled: !0,
                      text: "Nazar\xE9, Portugal"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "31f2ad70-fcf6-4e7d-bab5-0d57470a7bc6",
              collection_link_slug: "exploring-the-coastlines-of-portugal-in-a-camper-van",
              height: 524.28125,
              internal_link: !0,
              openOptions: !1,
              page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
              style: "plain",
              type: "container",
              url: "/templates/templates/travel/travel-blog",
              vertical_align: "align_start",
              width: 500
            }
          ],
          collection_item_id: "31f2ad70-fcf6-4e7d-bab5-0d57470a7bc6",
          collection_item_page_id: "3d15613e-6730-4c82-8421-76bd0cb4349c",
          type: "collection_item"
        }
      ],
      collection_data_id: "5df58924-6ffb-4475-bc85-86a5aadda5c3",
      collection_default_item_page_template_key: "blog:blog_standard",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/57af7a35f5e0483588050c3fdb2f6a8b?v=838051e8fb784ba88bf12a53b50ab491"
        },
        previewImage: {
          image: "/images/collectionTemplates/blog_1.png",
          imageDark: "/images/collectionTemplates/blog_1_dark.png"
        },
        schema: {
          Location: "Location",
          author: "Author",
          date: "Date",
          image: "Cover",
          snippet: "Snippet",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "author",
          "date",
          "image",
          "snippet",
          "title",
          "url",
          "Location"
        ],
        settings: {
          numCol: 3
        },
        template: {
          align: "align_left",
          children: [
            {
              align: "align_left",
              aspect_ratio: 1.5444126074498574,
              children: [
                {
                  text: ""
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "image"
                }
              ],
              height: 291.3729128014841,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/278c5cfb-4ede-47f5-b686-ac3e4b70a439_Screen_Shot_2022-03-08_at_5_27_10_PM_png.png?bucket=document",
              width: 450
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "title"
                    }
                  ],
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "How to Build a Startup 101"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "snippet"
                    }
                  ],
                  color: "gray",
                  fontFamily: "libre_caslon_text",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Target a big market, build an MVP with the least amount of tech, and launch!"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "date"
                    }
                  ],
                  color: "rgba(170, 170, 170, 1)",
                  fontFamily: "libre_caslon_text",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Feb 2020"
                },
                {
                  color: "rgba(170, 170, 170, 1)",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: " \u2022 "
                },
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Location"
                    }
                  ],
                  color: "default",
                  fontFamily: "libre_caslon_text",
                  fontSize: 13,
                  fontStyle: "small",
                  gradient: !1,
                  styled: !0,
                  text: "Location"
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 524.28125,
          openOptions: !1,
          style: "plain",
          type: "container",
          vertical_align: "align_start",
          width: 500
        },
        templateKey: "blog:blog_1"
      },
      openOptions: !1,
      type: "collection",
      width: 1300
    }
  ],
  height: 1107.03125,
  openOptions: !1,
  type: "container",
  width: 1500
}, O = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          fontFamily: "quicksand",
          styled: !0,
          text: ""
        },
        {
          fontFamily: "quicksand",
          styled: !0,
          text: "What do I do?"
        }
      ],
      openOptions: !1,
      type: "h1",
      width: 1300
    },
    {
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: `I design packaging for fast-moving consumer goods.
Anything from soap bars to cereal boxes, I've got you covered.`
        }
      ],
      type: "p",
      width: 1300
    },
    {
      children: [
        {
          color: "gray",
          fontFamily: "quicksand",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: ""
        }
      ],
      type: "p",
      width: 1400
    },
    {
      children: [
        {
          children: [
            {
              background: "red",
              children: [
                {
                  bold: !0,
                  fontFamily: "quicksand",
                  styled: !0,
                  text: "Todo"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/ca1560fc-3f98-4719-b5bb-0a0fd190c026/pmv-chamara-6CRT2abmtpk-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=c609a25d1da18aeae487656f8bcd3be6f7093e359862eafe81878bf7864e75f4&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Luxury cosmetics."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "717df26f-6ffc-4426-89ff-7bab3bcace7d",
                      collection_link_slug: "luxury-cosmetics-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "717df26f-6ffc-4426-89ff-7bab3bcace7d",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                }
              ],
              collection_data_id: "e2a12585-0318-4fc7-ba47-6c991520f844",
              collection_default_item_page_template_key: "knowledge_base:blog_documentation",
              collection_template: {
                custom: !0,
                databaseTemplateURL: {
                  notion: "https://typedream.notion.site/042709eb461540e48a2765e0d35fc74e?v=87a937ce3e434e1491db1f700ec9848a"
                },
                previewImage: {
                  image: "/images/collectionTemplates/knowledge_base_2.png",
                  imageDark: "/images/collectionTemplates/knowledge_base_2_dark.png"
                },
                schema: {
                  "Background Image": "Cover",
                  Logo: "Cover",
                  title: "Project",
                  url: "ITEM_PAGE_URL"
                },
                schemaOrder: [
                  "Background Image",
                  "Logo",
                  "title",
                  "url"
                ],
                settings: {
                  height: 350,
                  numCol: 1
                },
                template: {
                  align: "align_center",
                  backgroundDarken: !0,
                  children: [
                    {
                      align: "align_center",
                      background: "default",
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "backgroundImage",
                          schemaKey: "Background Image"
                        }
                      ],
                      height: 170,
                      openOptions: !1,
                      style: "card",
                      type: "container"
                    },
                    {
                      children: [
                        {
                          bold: !0,
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "title"
                            }
                          ],
                          color: "default",
                          fontFamily: "quicksand",
                          fontSize: 20,
                          fontStyle: "subtitle2",
                          gradient: !1,
                          lineHeight: 1.4,
                          styled: !0,
                          text: "Typedream"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "url"
                    }
                  ],
                  height: 246.640625,
                  openOptions: !0,
                  style: "card",
                  type: "container",
                  vertical_align: "align_start",
                  width: 300
                },
                templateKey: "knowledge_base:knowledge_base_2"
              },
              openOptions: !1,
              type: "collection"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              background: "purple",
              children: [
                {
                  bold: !0,
                  fontFamily: "quicksand",
                  styled: !0,
                  text: "In Progress"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/dc9a7578-bb3c-406a-aefc-e8e0ab836e7d/haku-scent-MC_tTrVDs00-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=adaf1a7523888580f013f268650c5dc1a99adc4d6da20915e34c2ce6c95119f9&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Essential oils box."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "072ee8e8-1e6e-4541-8e5f-573999c6ca11",
                      collection_link_slug: "essential-oils-box-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "072ee8e8-1e6e-4541-8e5f-573999c6ca11",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                },
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/ed0387f1-38b5-4186-91a3-4a397a586b20/tom-crew-7HuTGlUfQSo-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=d18ea77854be9136d742c89b7fca6f2bd8d1b5622e4bd720a3edc065c6f11119&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Matcha bowls."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "24be79d8-4908-4b81-b811-e6a8b28fed0b",
                      collection_link_slug: "matcha-bowls-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "24be79d8-4908-4b81-b811-e6a8b28fed0b",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                },
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/70fb5fe8-3446-4b67-bd19-84d2b16fccef/glenna-haug-DuNXXPScbJM-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=208538cdfcf03b0080eb51a5b9eb8ab27cbfe5704ef784a14a05d9a2a3556d06&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Hair products packaging."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "3d788c79-f8fc-41b3-b83e-dda098f024dd",
                      collection_link_slug: "hair-products-packaging-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "3d788c79-f8fc-41b3-b83e-dda098f024dd",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                }
              ],
              collection_data_id: "e18515cf-8fb4-449e-b8ea-c5770f009003",
              collection_default_item_page_template_key: "knowledge_base:blog_documentation",
              collection_template: {
                custom: !0,
                databaseTemplateURL: {
                  notion: "https://typedream.notion.site/042709eb461540e48a2765e0d35fc74e?v=87a937ce3e434e1491db1f700ec9848a"
                },
                previewImage: {
                  image: "/images/collectionTemplates/knowledge_base_2.png",
                  imageDark: "/images/collectionTemplates/knowledge_base_2_dark.png"
                },
                schema: {
                  "Background Image": "Cover",
                  Logo: "Cover",
                  title: "Project",
                  url: "ITEM_PAGE_URL"
                },
                schemaOrder: [
                  "Background Image",
                  "Logo",
                  "title",
                  "url"
                ],
                settings: {
                  height: 350,
                  numCol: 1
                },
                template: {
                  align: "align_center",
                  backgroundDarken: !0,
                  children: [
                    {
                      align: "align_center",
                      background: "default",
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "backgroundImage",
                          schemaKey: "Background Image"
                        }
                      ],
                      height: 170,
                      openOptions: !1,
                      style: "card",
                      type: "container"
                    },
                    {
                      children: [
                        {
                          bold: !0,
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "title"
                            }
                          ],
                          color: "default",
                          fontFamily: "quicksand",
                          fontSize: 20,
                          fontStyle: "subtitle2",
                          gradient: !1,
                          lineHeight: 1.4,
                          styled: !0,
                          text: "Typedream"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "url"
                    }
                  ],
                  height: 246.640625,
                  openOptions: !0,
                  style: "card",
                  type: "container",
                  vertical_align: "align_start",
                  width: 300
                },
                templateKey: "knowledge_base:knowledge_base_2"
              },
              openOptions: !1,
              type: "collection"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              background: "green",
              children: [
                {
                  bold: !0,
                  fontFamily: "quicksand",
                  styled: !0,
                  text: "Done"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/ac6cfd9f-4721-4cbb-94db-ff358addf6d2/mk-2-XoiBIpYkPJA-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=84ac02cdc0e095e9f3c6d4d942cddf5a83ffdd4e1ccca7f1ea089bcfa5e06e94&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Coffee bean pouch."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "a38e6e45-3ece-41c0-98b4-62b8f2c99a65",
                      collection_link_slug: "coffee-bean-pouch-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "a38e6e45-3ece-41c0-98b4-62b8f2c99a65",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                },
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/73f3e056-19af-4831-bd36-4da6eaf0f2e9/studio-blackthorns-YsFtudfV-w0-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=982ba07b1661afea5365d15b7cd73bc60e9c9859ba2b37c8ea8ce3c78b841103&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Condensation-free beverage cans."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "06023c6b-edbb-48b9-913a-81c8b8c5ed73",
                      collection_link_slug: "condensation-free-beverage-cans-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "06023c6b-edbb-48b9-913a-81c8b8c5ed73",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                },
                {
                  children: [
                    {
                      align: "align_center",
                      backgroundDarken: !0,
                      children: [
                        {
                          align: "align_center",
                          background: "default",
                          backgroundImage: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/8e249885-e503-401e-8d4b-ca541796baaf/agenlaku-indonesia-QMdyTr9AAKM-unsplash.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T084039Z&X-Amz-Expires=3600&X-Amz-Signature=458978cf867e8ab3b961ef870c5033c9dca2b4f3a2b98523853174bd974f5042&X-Amz-SignedHeaders=host&x-id=GetObject",
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "backgroundImage",
                              schemaKey: "Background Image"
                            }
                          ],
                          height: 170,
                          openOptions: !1,
                          style: "card",
                          type: "container"
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "default",
                              fontFamily: "quicksand",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "A better lunchbox."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "url"
                        }
                      ],
                      collection_internal_link: !0,
                      collection_link_data_id: "060a7e11-d8bd-4a9f-978e-2a219d96898a",
                      collection_link_slug: "a-better-lunchbox-",
                      height: 246.640625,
                      internal_link: !0,
                      openOptions: !0,
                      page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                      style: "card",
                      type: "container",
                      url: "/templates/templates/kanban/projects",
                      vertical_align: "align_start",
                      width: 300
                    }
                  ],
                  collection_item_id: "060a7e11-d8bd-4a9f-978e-2a219d96898a",
                  collection_item_page_id: "bedefd9a-2231-4e44-8e40-a800c54f0a45",
                  type: "collection_item"
                }
              ],
              collection_data_id: "2068df5a-204f-46dd-88a7-fe2be4ef48cf",
              collection_default_item_page_template_key: "knowledge_base:blog_documentation",
              collection_template: {
                custom: !0,
                databaseTemplateURL: {
                  notion: "https://typedream.notion.site/042709eb461540e48a2765e0d35fc74e?v=87a937ce3e434e1491db1f700ec9848a"
                },
                previewImage: {
                  image: "/images/collectionTemplates/knowledge_base_2.png",
                  imageDark: "/images/collectionTemplates/knowledge_base_2_dark.png"
                },
                schema: {
                  "Background Image": "Cover",
                  Logo: "Cover",
                  title: "Project",
                  url: "ITEM_PAGE_URL"
                },
                schemaOrder: [
                  "Background Image",
                  "Logo",
                  "title",
                  "url"
                ],
                settings: {
                  height: 350,
                  numCol: 1
                },
                template: {
                  align: "align_center",
                  backgroundDarken: !0,
                  children: [
                    {
                      align: "align_center",
                      background: "default",
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "backgroundImage",
                          schemaKey: "Background Image"
                        }
                      ],
                      height: 170,
                      openOptions: !1,
                      style: "card",
                      type: "container"
                    },
                    {
                      children: [
                        {
                          bold: !0,
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "title"
                            }
                          ],
                          color: "default",
                          fontFamily: "quicksand",
                          fontSize: 20,
                          fontStyle: "subtitle2",
                          gradient: !1,
                          lineHeight: 1.4,
                          styled: !0,
                          text: "Typedream"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "url",
                      schemaKey: "url"
                    }
                  ],
                  height: 246.640625,
                  openOptions: !0,
                  style: "card",
                  type: "container",
                  vertical_align: "align_start",
                  width: 300
                },
                templateKey: "knowledge_base:knowledge_base_2"
              },
              openOptions: !1,
              type: "collection"
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 1016.640625,
      type: "column",
      vertical_align: "align_start",
      width: 1300
    }
  ],
  height: 1276.640625,
  openOptions: !1,
  type: "container",
  width: 1400
}, X = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      children: [
        {
          children: [
            {
              align: "align_right",
              animation: {
                direction: "right",
                type: "slide_in"
              },
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "I was born and raised in"
                },
                {
                  text: " "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "San Ramon, CA"
                }
              ],
              type: "h2"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              animation: {
                direction: "right",
                type: "slide_in"
              },
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "My parents immigrated from"
                },
                {
                  text: " "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Busan, South Korea"
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ", where many of my relatives still reside"
                }
              ],
              type: "h2"
            }
          ],
          type: "column_item",
          width: 0.5273875673166207
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.03899388115134623
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "left",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/7a899aad-c33d-45a0-80b4-0904ce47250e_gradient1_png.png?bucket=document",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      text: "\u{1F468}\u200D\u{1F469}\u200D\u{1F467} \u{1F1FA}\u{1F1F8} \u{1F1F0}\u{1F1F7}"
                    }
                  ],
                  type: "h3"
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.4336085515320339
        }
      ],
      height: 549,
      type: "column",
      width: 900
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "right",
                type: "slide_in"
              },
              backgroundImage: "https://typedream.sfo3.digitaloceanspaces.com/illustrations/gradients/black.png",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      text: "\u{1F477}\u{1F3FB}\u200D\u2640\uFE0F \u{1F3D7}"
                    }
                  ],
                  type: "h3"
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.44010808727948003
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.05292145775301751
        },
        {
          children: [
            {
              animation: {
                direction: "left",
                type: "slide_in"
              },
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "I am a"
                },
                {
                  text: " "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "third-year civil engineering student."
                }
              ],
              type: "h2"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              type: "p"
            },
            {
              animation: {
                direction: "left",
                type: "slide_in"
              },
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Super excited to be interning in Chicago this summer."
                }
              ],
              type: "h2"
            }
          ],
          type: "column_item",
          width: 0.5069604549675026
        }
      ],
      height: 468,
      type: "column",
      width: 900
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_right",
              animation: {
                direction: "right",
                type: "slide_in"
              },
              children: [
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "Soccer"
                },
                {
                  text: " "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "is probably my favorite sport."
                }
              ],
              type: "h2"
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_right",
              animation: {
                direction: "right",
                type: "slide_in"
              },
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "I also love "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "dancing"
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: " and "
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "choreography"
                },
                {
                  color: "white",
                  gradient: !1,
                  styled: !0,
                  text: "."
                }
              ],
              type: "h2"
            }
          ],
          type: "column_item",
          width: 0.5273875673166207
        },
        {
          children: [
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.03899388115134623
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "left",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/7a899aad-c33d-45a0-80b4-0904ce47250e_gradient1_png.png?bucket=document",
              children: [
                {
                  align: "align_center",
                  children: [
                    {
                      text: "\u26BD\uFE0F \u{1F483}\u{1F3FB}"
                    }
                  ],
                  type: "h3"
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.4336085515320339
        }
      ],
      height: 405.0000305175781,
      type: "column",
      width: 900
    }
  ],
  height: 1590,
  openOptions: !1,
  type: "container",
  width: 2e3,
  width_max: "100%"
}, F = {
  align: "align_center",
  background: "default",
  children: [
    {
      children: [
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "\u2192 Your Cardy family"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 459,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/be67499c-46b1-4fb1-8e5d-6b8d03605158_Mask_group_2_png.png?bucket=document",
              width: 459
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  text: "Ashley Fong"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Associate Product Manager"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 459,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/7ab27aa3-8339-4281-b959-c422b9cde587_Mask_group_3_png.png?bucket=document",
              width: 459
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  text: "Jemima Hayden"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Solution Architect"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 459,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/67f4afcd-f798-48cf-953c-c2eabc70da77_Mask_group_4_png.png?bucket=document",
              width: 459
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  text: "Adam McCoy"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Frontend Engineer"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 471,
      type: "column",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 459,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/e433c375-5ea3-4f04-b270-ec2da433a3b5_Mask_group_5_png.png?bucket=document",
              width: 459
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  text: "Nick Gomez"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Backend Engineer"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 459,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/a55eae13-12ad-4f68-858d-5321dbe5d582_Mask_group_7_png.png?bucket=document",
              width: 459
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  text: "Isaiah Jones"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "QA Specialist"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 459,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/27d7a041-22fd-4e43-8c0e-fc6ad245e177_Mask_group_6_png.png?bucket=document",
              width: 459
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  bold: !0,
                  text: "Sophie Park"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              align: "align_center",
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "UI/UX Designer"
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.33333
        }
      ],
      height: 471,
      type: "column",
      width: 1e3
    }
  ],
  height: 1058.5,
  openOptions: !1,
  type: "container",
  width: 1100
}, I = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      optionIndex: 1,
      type: "p"
    },
    {
      children: [
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: ""
        },
        {
          bold: !0,
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "\u2192 Resources & Tools"
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1e3
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_left",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "Logo"
                            }
                          ],
                          height: 124.4375,
                          openOptions: !1,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/b71cd83f-f4a2-434e-a9ec-637786a1eed6/explorer-dynamic-gradient.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T083612Z&X-Amz-Expires=3600&X-Amz-Signature=18aca11f44f5b9e4d8db7f0bb59b0ccef813c72b8449ab8ed7744cc7019d88c6&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 124.4375
                        }
                      ],
                      type: "column_item",
                      width: 0.3232931726907626
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              color: "black",
                              gradient: !1,
                              styled: !0,
                              text: ""
                            },
                            {
                              children: [
                                {
                                  bold: !0,
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "title"
                                    }
                                  ],
                                  color: "black",
                                  fontSize: 20,
                                  fontStyle: "subtitle2",
                                  gradient: !1,
                                  lineHeight: 1.4,
                                  styled: !0,
                                  text: "Campus map"
                                }
                              ],
                              collection_connect_data: !1,
                              collection_connect_data_keys: [],
                              internal_link: !1,
                              open_in_new_tab: !0,
                              type: "a",
                              url: "ITEM_PAGE_URL"
                            },
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Categories"
                                }
                              ],
                              color: "gray",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "Directory"
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.6767068273092379
                    }
                  ],
                  height: 165.875,
                  type: "column",
                  width: 600
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Description"
                        }
                      ],
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "We admit it\u2019s easy to get lost at our campus. Use this map to help you get to where you need to be."
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !1,
              collection_connect_data_keys: [],
              height: 263.515625,
              style: "plain",
              type: "container",
              vertical_align: "align_start",
              width: 600
            }
          ],
          collection_item_id: "2d074ee0-9a68-4c86-b964-df77ef456643",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_left",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "Logo"
                            }
                          ],
                          height: 124.4375,
                          openOptions: !1,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/d4c09a65-d1d3-4915-9a07-f8ead7d95d00/wifi-dynamic-gradient.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T083612Z&X-Amz-Expires=3600&X-Amz-Signature=27c38bd53e4f4ea1921a9baf3861558315e9533dc9de0cb842aa44e06db7099f&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 124.4375
                        }
                      ],
                      type: "column_item",
                      width: 0.3232931726907626
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              color: "black",
                              gradient: !1,
                              styled: !0,
                              text: ""
                            },
                            {
                              children: [
                                {
                                  bold: !0,
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "title"
                                    }
                                  ],
                                  color: "black",
                                  fontSize: 20,
                                  fontStyle: "subtitle2",
                                  gradient: !1,
                                  lineHeight: 1.4,
                                  styled: !0,
                                  text: "CardyVPN"
                                }
                              ],
                              collection_connect_data: !1,
                              collection_connect_data_keys: [],
                              internal_link: !1,
                              open_in_new_tab: !0,
                              type: "a",
                              url: "ITEM_PAGE_URL"
                            },
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Categories"
                                }
                              ],
                              color: "gray",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "Network"
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.6767068273092379
                    }
                  ],
                  height: 165.875,
                  type: "column",
                  width: 600
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Description"
                        }
                      ],
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Secure network connection you\u2019ll need to access our DB."
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !1,
              collection_connect_data_keys: [],
              height: 263.515625,
              style: "plain",
              type: "container",
              vertical_align: "align_start",
              width: 600
            }
          ],
          collection_item_id: "85e36dd9-0d3e-429c-b3b6-0ae26ca40c45",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_left",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "Logo"
                            }
                          ],
                          height: 124.4375,
                          openOptions: !1,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/4bc5be9e-cd8a-42b3-96b8-1ca5cfb03b71/travel-dynamic-gradient.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220726%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220726T083612Z&X-Amz-Expires=3600&X-Amz-Signature=a5ecc9a7ac02908364f6c00c4cbf051ab26cc06644a4845d7e50a113e1b4e957&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 124.4375
                        }
                      ],
                      type: "column_item",
                      width: 0.3232931726907626
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              color: "black",
                              gradient: !1,
                              styled: !0,
                              text: ""
                            },
                            {
                              children: [
                                {
                                  bold: !0,
                                  collection_connect_data: !0,
                                  collection_connect_data_keys: [
                                    {
                                      blockElementKey: "text",
                                      schemaKey: "title"
                                    }
                                  ],
                                  color: "black",
                                  fontSize: 20,
                                  fontStyle: "subtitle2",
                                  gradient: !1,
                                  lineHeight: 1.4,
                                  styled: !0,
                                  text: "Expensify"
                                }
                              ],
                              collection_connect_data: !1,
                              collection_connect_data_keys: [],
                              internal_link: !1,
                              open_in_new_tab: !0,
                              type: "a",
                              url: "ITEM_PAGE_URL"
                            },
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Categories"
                                }
                              ],
                              color: "gray",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "Finance"
                            }
                          ],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.6767068273092379
                    }
                  ],
                  height: 165.875,
                  type: "column",
                  width: 600
                },
                {
                  children: [
                    {
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "text",
                          schemaKey: "Description"
                        }
                      ],
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Log company-related expenses here, such as transportation, team lunches, purchases"
                    }
                  ],
                  collection_connect_data: !0,
                  collection_connect_data_keys: [],
                  type: "p"
                }
              ],
              collection_connect_data: !1,
              collection_connect_data_keys: [],
              height: 263.515625,
              style: "plain",
              type: "container",
              vertical_align: "align_start",
              width: 600
            }
          ],
          collection_item_id: "b09f1e0e-89ab-4832-b608-d02d0b077e08",
          type: "collection_item"
        }
      ],
      collection_data_id: "8e6a35be-b44f-404d-9220-7691a3cb6858",
      collection_default_item_page_template_key: "lists:app_directory_page",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/9791fae463734afdb22a93ccece886fa?v=c50424e0388c49468342998793d73309"
        },
        previewImage: {
          image: "/images/collectionTemplates/directory_2.png",
          imageDark: "/images/collectionTemplates/directory_2_dark.png"
        },
        schema: {
          Categories: "Tags",
          Description: "Description",
          Logo: "Icon",
          title: "Resource"
        },
        schemaOrder: [
          "Categories",
          "Description",
          "Logo",
          "title"
        ],
        settings: {
          height: 350,
          numCol: 3
        },
        template: {
          align: "align_center",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  children: [
                    {
                      align: "align_left",
                      aspect_ratio: 1,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "Logo"
                        }
                      ],
                      height: 124.4375,
                      openOptions: !1,
                      type: "img",
                      url: "https://typedream-assets.sfo3.digitaloceanspaces.com/logo_square_transparent.png",
                      width: 124.4375
                    }
                  ],
                  type: "column_item",
                  width: 0.3232931726907626
                },
                {
                  children: [
                    {
                      children: [
                        {
                          color: "black",
                          gradient: !1,
                          styled: !0,
                          text: ""
                        },
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "black",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Typedream"
                            }
                          ],
                          collection_connect_data: !1,
                          collection_connect_data_keys: [],
                          internal_link: !1,
                          open_in_new_tab: !0,
                          type: "a",
                          url: "ITEM_PAGE_URL"
                        },
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "Categories"
                            }
                          ],
                          color: "gray",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Website Builder"
                        }
                      ],
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.6767068273092379
                }
              ],
              height: 165.875,
              type: "column",
              width: 600
            },
            {
              children: [
                {
                  collection_connect_data: !0,
                  collection_connect_data_keys: [
                    {
                      blockElementKey: "text",
                      schemaKey: "Description"
                    }
                  ],
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "An easy-to-use no-code website builder with beautiful design defaults."
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [],
              type: "p"
            }
          ],
          collection_connect_data: !1,
          collection_connect_data_keys: [],
          height: 263.515625,
          style: "plain",
          type: "container",
          vertical_align: "align_start",
          width: 600
        },
        templateKey: "lists:directory_2"
      },
      openOptions: !1,
      type: "collection",
      width: 1e3
    }
  ],
  height: 531.375,
  openOptions: !1,
  type: "container",
  width: 1100
}, T = {
  align: "align_center",
  background: "#121212",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      optionIndex: 1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Product roadmap"
        }
      ],
      openOptions: !1,
      type: "h3"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: "Here's our plan so that you don't have to fight over the aux anymore."
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "Logo"
                            }
                          ],
                          height: 177.6875,
                          openOptions: !1,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/81dc7b45-b121-4b75-8b75-1810d1ccfc0c/Roadmap_-_3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220802%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220802T063912Z&X-Amz-Expires=3600&X-Amz-Signature=5a636f2a77d89587e38698525569bd40d89db61d908a88c56e7753a32dc4f20e&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 177.6875
                        }
                      ],
                      type: "column_item",
                      width: 0.4161147902869753
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "white",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Integration into Cars"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Date"
                                }
                              ],
                              color: "white",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "2022-09-22"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Description"
                                }
                              ],
                              color: "gray",
                              gradient: !1,
                              styled: !0,
                              text: "We\u2019ll integrate our software into select automobiles. We\u2019ll be conducitng extensive user testing and stress testing in this phase of development."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.5838852097130247
                    }
                  ],
                  height: 217.6875,
                  type: "column",
                  width: 700
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "4f1995c7-9477-4f5b-87ee-a8482f11e522",
              height: 257.6875,
              internal_link: !0,
              style: "plain",
              type: "container",
              url: "",
              vertical_align: "align_start",
              width: 700
            }
          ],
          collection_item_id: "4f1995c7-9477-4f5b-87ee-a8482f11e522",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "Logo"
                            }
                          ],
                          height: 177.6875,
                          openOptions: !1,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/335ee1c9-9a14-44a1-8833-fd40eddf96be/Roadmap_-_2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220802%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220802T063912Z&X-Amz-Expires=3600&X-Amz-Signature=f3d1c85943b4446181c516bb93ef75c493a9aa3a67ec9b8d71cae24445f0b37c&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 177.6875
                        }
                      ],
                      type: "column_item",
                      width: 0.4161147902869753
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "white",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Fusing Audio with Map Data"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Date"
                                }
                              ],
                              color: "white",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "2022-07-18"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Description"
                                }
                              ],
                              color: "gray",
                              gradient: !1,
                              styled: !0,
                              text: "We\u2019ll further train our algorithm by feeding it map data and testing its ability to adjust playlist creation based on changing traffic conditions"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.5838852097130247
                    }
                  ],
                  height: 217.6875,
                  type: "column",
                  width: 700
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "8048c1f0-7dfd-4b00-9adf-a7b76b5f5a30",
              height: 257.6875,
              internal_link: !0,
              style: "plain",
              type: "container",
              url: "",
              vertical_align: "align_start",
              width: 700
            }
          ],
          collection_item_id: "8048c1f0-7dfd-4b00-9adf-a7b76b5f5a30",
          type: "collection_item"
        },
        {
          children: [
            {
              align: "align_center",
              backgroundDarken: !0,
              children: [
                {
                  children: [
                    {
                      children: [
                        {
                          align: "align_center",
                          aspect_ratio: 1,
                          children: [
                            {
                              text: ""
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "url",
                              schemaKey: "Logo"
                            }
                          ],
                          height: 177.6875,
                          openOptions: !1,
                          type: "img",
                          url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/c797b676-e891-4c73-9c97-2a8e4549fbe9/Roadmap_-_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220802%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220802T063912Z&X-Amz-Expires=3600&X-Amz-Signature=03b796efbb8e5d78014e3315357630fad1de64774b5d346cc85f584ce2ffe7e0&X-Amz-SignedHeaders=host&x-id=GetObject",
                          width: 177.6875
                        }
                      ],
                      type: "column_item",
                      width: 0.4161147902869753
                    },
                    {
                      children: [
                        {
                          children: [
                            {
                              bold: !0,
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "title"
                                }
                              ],
                              color: "white",
                              fontSize: 20,
                              fontStyle: "subtitle2",
                              gradient: !1,
                              lineHeight: 1.4,
                              styled: !0,
                              text: "Audio Analysis"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Date"
                                }
                              ],
                              color: "white",
                              fontSize: 13,
                              fontStyle: "small",
                              gradient: !1,
                              styled: !0,
                              text: "2022-06-20"
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        },
                        {
                          children: [
                            {
                              collection_connect_data: !0,
                              collection_connect_data_keys: [
                                {
                                  blockElementKey: "text",
                                  schemaKey: "Description"
                                }
                              ],
                              color: "gray",
                              gradient: !1,
                              styled: !0,
                              text: "Leveraging the SpotifyAPI, we\u2019ll build an algorithm that constructs playlists based on constraints such as time, mood, weather."
                            }
                          ],
                          collection_connect_data: !0,
                          collection_connect_data_keys: [],
                          type: "p"
                        }
                      ],
                      type: "column_item",
                      width: 0.5838852097130247
                    }
                  ],
                  height: 217.6875,
                  type: "column",
                  width: 700
                }
              ],
              collection_connect_data: !0,
              collection_connect_data_keys: [
                {
                  blockElementKey: "url",
                  schemaKey: "url"
                }
              ],
              collection_internal_link: !0,
              collection_link_data_id: "90649310-4f56-4edd-b44f-23518cc6aa57",
              height: 257.6875,
              internal_link: !0,
              style: "plain",
              type: "container",
              url: "",
              vertical_align: "align_start",
              width: 700
            }
          ],
          collection_item_id: "90649310-4f56-4edd-b44f-23518cc6aa57",
          type: "collection_item"
        }
      ],
      collection_data_id: "6a16c5ac-f6a8-43dc-aab2-779c984df335",
      collection_default_item_page_template_key: "knowledge_base:blog_documentation",
      collection_template: {
        custom: !0,
        databaseTemplateURL: {
          notion: "https://typedream.notion.site/99e1c50d275d4d4f82cb33cfb9f815c1?v=6dfd8583e8ad45c0967dd6ba26793ffb"
        },
        previewImage: {
          image: "/images/collectionTemplates/knowledge_base_3.png",
          imageDark: "/images/collectionTemplates/knowledge_base_3_dark.png"
        },
        schema: {
          Date: "Stage Start",
          Description: "Snippet",
          Logo: "Cover",
          title: "Name",
          url: "ITEM_PAGE_URL"
        },
        schemaOrder: [
          "Description",
          "Logo",
          "title",
          "url",
          "Date"
        ],
        settings: {
          height: 350,
          numCol: 1
        },
        template: {
          align: "align_center",
          backgroundDarken: !0,
          children: [
            {
              children: [
                {
                  children: [
                    {
                      align: "align_center",
                      aspect_ratio: 1,
                      children: [
                        {
                          text: ""
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [
                        {
                          blockElementKey: "url",
                          schemaKey: "Logo"
                        }
                      ],
                      height: 177.6875,
                      openOptions: !1,
                      type: "img",
                      url: "https://typedream-assets.sfo3.digitaloceanspaces.com/logo_square_transparent.png",
                      width: 177.6875
                    }
                  ],
                  type: "column_item",
                  width: 0.4161147902869753
                },
                {
                  children: [
                    {
                      children: [
                        {
                          bold: !0,
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "title"
                            }
                          ],
                          color: "white",
                          fontSize: 20,
                          fontStyle: "subtitle2",
                          gradient: !1,
                          lineHeight: 1.4,
                          styled: !0,
                          text: "Typedream"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "Date"
                            }
                          ],
                          color: "white",
                          fontSize: 13,
                          fontStyle: "small",
                          gradient: !1,
                          styled: !0,
                          text: "Stage start date"
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    },
                    {
                      children: [
                        {
                          collection_connect_data: !0,
                          collection_connect_data_keys: [
                            {
                              blockElementKey: "text",
                              schemaKey: "Description"
                            }
                          ],
                          color: "gray",
                          gradient: !1,
                          styled: !0,
                          text: "An easy-to-use no-code website builder with beautiful design defaults."
                        }
                      ],
                      collection_connect_data: !0,
                      collection_connect_data_keys: [],
                      type: "p"
                    }
                  ],
                  type: "column_item",
                  width: 0.5838852097130247
                }
              ],
              height: 217.6875,
              type: "column",
              width: 700
            }
          ],
          collection_connect_data: !0,
          collection_connect_data_keys: [
            {
              blockElementKey: "url",
              schemaKey: "url"
            }
          ],
          height: 257.6875,
          style: "plain",
          type: "container",
          vertical_align: "align_start",
          width: 700
        },
        templateKey: "knowledge_base:knowledge_base_3"
      },
      openOptions: !1,
      type: "collection",
      width: 800
    }
  ],
  height: 1114.96875,
  openOptions: !1,
  type: "container",
  width: 900
}, C = {
  align: "align_center",
  background: "clear",
  children: [
    {
      children: [
        {
          text: " "
        },
        {
          text: "day",
          underline: !0
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1100
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/6e157bcf-e3bb-47e1-a448-a3c1982c1f0f_fabian-gieske-IDC5zZVNxmU-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/d573494f-7feb-4cfd-ab58-acccebf19881_jonathan-marchal-b-bMV95yKOE-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33267296977660976
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/d33763d8-218d-4a88-a17d-05748351cf17_augustine-wong-PxypFiQMkIk-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33398703022339027
        }
      ],
      height: 382,
      type: "column",
      width: 1100
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/f015a7c6-cba7-4523-8208-0ec5d3c9fe42_laurice-manaligod-9eupWltJLxg-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/7ba15e6c-87ee-440a-8bc8-0672a89a6880_sincerely-media-0KwFuwdW7pU-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33267296977660976
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              background: "default",
              backgroundImage: "https://api.typedream.com/v0/document/public/c755be6f-5291-42e3-9da3-c27c4c5f9eed_megha-ajith-WRST5Pops3E-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33398703022339027
        }
      ],
      height: 382,
      type: "column",
      width: 1100
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 35,
      html: '<div id="night"></div>',
      openOptions: !1,
      type: "media_embed",
      width: 500
    },
    {
      children: [
        {
          text: "  "
        },
        {
          text: "nigh",
          underline: !0
        },
        {
          text: "t"
        },
        {
          text: "",
          underline: !0
        }
      ],
      openOptions: !1,
      type: "h2",
      width: 1100
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/a9e52230-ea8b-46b9-afc0-8f1373914757_steve-harvey-U4wcrDteZ2Y-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/641e48d0-4013-497d-bae5-d5ab8d827d84_josh-nuttall-XVTWFHcNIko-unsplash_webp.webp?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33267296977660976
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/67f650ec-263d-492f-a0b4-439cfc6b17d5_freddie-marriage-w39PTDxKiK8-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33398703022339027
        }
      ],
      height: 382,
      type: "column",
      width: 1100
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/973d9d83-1c95-41ab-b1af-da0c41fb2352_tyler-lastovich-d0Sl3AH8Tlg-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33333
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/9390f22e-fa8a-4be1-9415-da5a01d6a18b_ryunosuke-kikuno-zE-MrTmjzd4-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33267296977660976
        },
        {
          children: [
            {
              align: "align_center",
              animation: {
                direction: "down",
                type: "slide_in"
              },
              backgroundImage: "https://api.typedream.com/v0/document/public/f47b312e-e9d2-44ec-8aa7-335f1b96da72_i-am_nah--S4OsO0c6Ts-unsplash_jpg.jpg?bucket=document",
              children: [
                {
                  text: ""
                }
              ],
              openOptions: !1,
              style: "card",
              type: "container"
            }
          ],
          type: "column_item",
          width: 0.33398703022339027
        }
      ],
      height: 382,
      type: "column",
      width: 1100
    }
  ],
  height: 1820,
  openOptions: !1,
  type: "container",
  width: 1100
};
export {
  X as COLLECTION_ALICE_PERSONAL_SITE,
  E as COLLECTION_BACKPACK_TRAVEL_BLOG,
  S as COLLECTION_BLOG_COLORFUL_1,
  K as COLLECTION_BLOG_COLORFUL_2,
  z as COLLECTION_BLOG_COLORFUL_3,
  t as COLLECTION_BLOG_HORIZONTAL,
  n as COLLECTION_BLOG_VERTICAL,
  c as COLLECTION_DARK_PROJECTS,
  C as COLLECTION_DAY_NIGHT,
  b as COLLECTION_GRADIENTS,
  m as COLLECTION_JOB_BOARD_BENEFITS,
  u as COLLECTION_JOB_BOARD_DESCRIPTIONS,
  f as COLLECTION_JOB_BOARD_OPENINGS,
  O as COLLECTION_KANBAN_PERSONAL_SITE,
  a as COLLECTION_NFT,
  F as COLLECTION_ONBOARDING_GUIDE_1,
  I as COLLECTION_ONBOARDING_GUIDE_2,
  w as COLLECTION_PERSONAL_BLOG_1,
  A as COLLECTION_PERSONAL_BLOG_2,
  i as COLLECTION_PERSONAL_WEB3,
  d as COLLECTION_PERSONAL_WEB3_AUDIO_PLAYLIST,
  s as COLLECTION_PERSONAL_WEB3_NFT,
  r as COLLECTION_PERSONAL_WEB3_THOUGHTS,
  x as COLLECTION_PODCAST,
  T as COLLECTION_PRODUCT_ROADMAP,
  l as COLLECTION_PROJECTS,
  e as COLLECTION_READS,
  o as COLLECTION_SKIN_CARE,
  g as COLLECTION_TOP_NFT_PROJECTS,
  h as COLLECTION_TOP_STAKING_PLATFORMS,
  k as COLLECTION_UX_COURSE,
  y as COLLECTION_WEB3_BUZZWORDS,
  p as COLLECTION_WEB3_MEDIAS,
  _ as COLLECTION_WEDDING
};
