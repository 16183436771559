var d = Object.defineProperty;
var f = (l, t, e) => t in l ? d(l, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : l[t] = e;
var u = (l, t, e) => (f(l, typeof t != "symbol" ? t + "" : t, e), e);
import { EditorAPI as c } from "../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as r } from "../../utils/getPermissionAndSubscriptionConfig.js";
class h {
  constructor(t) {
    u(this, "api");
    this.api = t;
  }
  async get(t, e, a, n) {
    const i = await r(e, a, n);
    return (await new c(this.api).get(`/collection/${e}/${t}`, !0, i)).data;
  }
  async queryAllItems(t, e, a, n) {
    const i = await r(t, a, n);
    return (await new c(this.api).get(`/collectionitem/list/${e}/all`, !0, i)).data;
  }
  async queryItems(t, e, a, n, i) {
    const s = await r(e, n, i), o = [];
    return t.limit ? o.push(`limit=${t.limit}`) : o.push("limit=50"), t.offset ? o.push(`offset=${t.offset}`) : o.push("offset=0"), (await new c(this.api).get(`/collectionitem/list/${a}${o.length > 0 ? `?${o.join("&")}` : ""}`, !0, s)).data;
  }
  async deleteItems(t, e, a, n, i) {
    const s = await r(e, n, i);
    return (await new c(this.api).delete(`/collectionitem/${a}`, JSON.stringify({
      ids: t
    }), !0, {
      ...s,
      headers: {
        ...s.headers,
        "Content-Type": "application/json"
      }
    })).data;
  }
  async queryItemsCount(t, e, a, n) {
    const i = await r(t, a, n);
    return (await new c(this.api).get(`/collectionitem/list/${e}/count`, !0, i)).data;
  }
  async query(t, e, a, n) {
    const i = await r(e, a, n);
    return (await new c(this.api).get(`/collection/list/${e}`, !0, i)).data;
  }
  async create(t, e, a) {
    const n = await r(t.space_id, e, a);
    return (await new c(this.api).post("/collection/create", t, !0, n)).data;
  }
  async update(t, e, a) {
    const n = await r(t.space_id, e, a), i = { ...t };
    return delete i.created_at, delete i.deleted_at, (await new c(this.api).post("/collection/update", i, !0, n)).data;
  }
  async updateField(t, e, a, n, i, s) {
    const o = await r(t, i, s);
    return (await new c(this.api).post(`/collection/update/${t}/${e}`, {
      path: a,
      value: n
    }, !0, o)).data;
  }
  async delete(t, e, a, n) {
    const i = await r(e, a, n), o = await new c(this.api).delete(`/collection/${e}/${t}`, void 0, !0, i);
    if (o.status !== 204 && o.status !== 200)
      throw Error("Error removing form");
  }
}
export {
  h as CollectionAPI
};
