import { makeApiSliceCreator as F } from "@typedream/zustand-query";
import { listForms as c, getForm as a, getFormContent as p, getPublishedFormContent as S, getFormSubmissions as d, getFormSubmissionsCount as l, createForm as f, createAndPublishForm as D, getAllFormSubmissions as C, updateForm as I, updateFormContent as g, publishFormContent as v, updateAndPublishFormContent as A, deleteForm as Q, renameForm as h, deleteSubmissions as q } from "./action.js";
const P = F("formsApi", (t) => ({
  endpoints: (o) => ({
    listForms: o.query(c(t)),
    getForm: o.query(a(t)),
    getFormContent: o.query(p(t)),
    getPublishedFormContent: o.query(S(t)),
    listFormSubmissions: o.query(d(t)),
    getFormSubmissionCount: o.query(l(t)),
    createForm: o.mutation(f(t), {
      invalidateQueries: ({ args: e }) => [
        ["getForm", e[0]],
        ["listForms", e[0]]
      ]
    }),
    createAndPublishForm: o.mutation(D(t), {
      invalidateQueries: ({ args: e }) => [
        ["getForm", e[0]],
        ["listForms", e[0]]
      ]
    }),
    getAllFormSubmissions: o.query(C(t)),
    updateForm: o.mutation(I(t), {
      invalidateQueries: ({ args: e }) => [
        ["listForms", e[1].space_id],
        ["getForm", e[0], e[1].id]
      ]
    }),
    updateFormContent: o.mutation(g(t), {
      onSuccess({ args: e, data: r }) {
        const { spacesApi: i } = t.getState();
        i.endpoints.getSpace.get(e[0].spaceID).updateData((u) => {
          var m, s;
          const n = { ...u };
          return (s = (m = n.customization) == null ? void 0 : m.form_reference) != null && s.content && (n.customization.form_reference.content[e[0].formID] = {
            value: r,
            updated_at: new Date().toISOString()
          }), n;
        });
      },
      invalidateQueries: ({ args: e }) => [
        ["getForm", e[0].spaceID, e[0].formID],
        ["getFormContent", e[0].spaceID, e[0].formID]
      ]
    }),
    publishFormContent: o.mutation(v(t), {
      invalidateQueries: ({ args: e }) => [
        ["getFormContent", e[0].spaceID, e[0].formID],
        ["getSpace", e[0].spaceID]
      ]
    }),
    updateAndPublishFormContent: o.mutation(A(t), {
      onSuccess({ args: e, data: r }) {
        const { spacesApi: i } = t.getState();
        i.endpoints.getSpace.get(e[0].spaceID).updateData((u) => {
          var m, s;
          const n = { ...u };
          return (s = (m = n.customization) == null ? void 0 : m.form_reference) != null && s.content && (n.customization.form_reference.content[e[0].formID] = {
            value: r,
            updated_at: new Date().toISOString()
          }), n;
        });
      },
      invalidateQueries: ({ args: e }) => [
        ["getSpace", e[0].spaceID],
        ["getForm", e[0].spaceID, e[0].formID],
        ["getFormContent", e[0].spaceID, e[0].formID]
      ]
    }),
    deleteForm: o.mutation(Q(t), {
      invalidateQueries: ({ args: e }) => [
        ["listForms", e[0]],
        ["getFormContent", e[0], e[1]],
        ["getSpace", e[0]]
      ]
    }),
    renameForm: o.mutation(h(t), {
      invalidateQueries: ({ args: e }) => [
        ["listForms", e[0]],
        ["getForm", e[0], e[1]]
      ]
    }),
    deleteSubmission: o.mutation(q(t), {
      invalidateQueries: () => [
        ["getFormSubmissionCount", "*", "*"],
        ["listFormSubmissions", "*", "*", "*", "*"]
      ]
    })
  })
}));
export {
  P as createFormsApiSlice
};
