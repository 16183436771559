function h(i, n, d) {
  var l, c;
  if (!(i != null && i.page_id) && !(i != null && i.children_template_page_id))
    return {};
  let _ = {
    [i.page_id]: {
      id: i.page_id,
      path: n,
      name: (l = d == null ? void 0 : d[i.page_id]) == null ? void 0 : l.name
    }
  };
  return i != null && i.children_template_page_id && (_[i == null ? void 0 : i.children_template_page_id] = {
    id: i.children_template_page_id,
    path: n,
    name: (c = d == null ? void 0 : d[i.children_template_page_id]) == null ? void 0 : c.name,
    childTemplatePage: !0
  }), i.children && Object.keys(i.children).forEach((a) => {
    i.children && (_ = {
      ..._,
      ...h(i.children[a], [...n, a], d)
    });
  }), _;
}
export {
  h as getPageIDToPath
};
