import { makeApiSliceCreator as i } from "@typedream/zustand-query";
import { listCollectionItems as l, updateCollectionItem as c } from "./actions.js";
const s = i("collectionsApi", (t) => ({
  endpoints: (e) => ({
    listCollectionItems: e.query(l(t)),
    updateCollectionItem: e.mutation(c(t), {
      invalidateQueries: ({ args: o }) => [["listCollectionItems", o[0], "*", "*"]]
    })
  })
}));
export {
  s as createCollectionsApiSlice
};
