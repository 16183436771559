import { v4 as N } from "uuid";
import { NOTION_BLOCK_TYPE as t, BLOCK_TYPE as n, BLOCK_TYPE_UNSUPPORTED as u, CONTAINER_STYLE as P } from "../api/app/notion/types.js";
import { DocumentAPI as f } from "../api/document/api.js";
function O(e) {
  var s;
  const c = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g, l = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?(.+)/g, A = /"https(.*?)"/g;
  let i = 500, d = 500, o = e, a;
  return c.test(e) && (a = "https://player.vimeo.com/video/$1", i = 560, d = 315, o = e.replace(c, a)), l.test(e) && (a = "https://www.youtube.com/embed/$1", i = 560, d = 315, o = e.replace(l, a)), ((e == null ? void 0 : e.includes("iframe")) || (e == null ? void 0 : e.includes("div"))) && (e != null && e.includes("google.com/maps") ? (i = 600, d = 450) : e.includes("typeform.com") || e.includes("tally.so") ? (i = 700, d = 500) : (i = 560, d = 315), o = ((s = e.match(A)) == null ? void 0 : s.toString().replace(/"/g, "")) || e), { convertedURL: o, width: i, height: d };
}
const v = (e, c) => {
  if (c.has_children && (e === t.HEADING_1 || e === t.HEADING_2 || e === t.HEADING_3))
    return n.CONTAINER;
  switch (e) {
    case t.PARAGRAPH:
      return n.PARAGRAPH;
    case t.HEADING_1:
      return n.HEADING1;
    case t.HEADING_2:
      return n.HEADING2;
    case t.HEADING_3:
      return n.HEADING3;
    case t.BULLETED_LIST_ITEM:
      return n.BULLETED_LIST;
    case t.NUMBERED_LIST_ITEM:
      return n.NUMBERED_LIST;
    case t.TO_DO:
      return n.TODO_LIST;
    case t.TOGGLE:
      return n.CONTAINER;
    case t.CODE:
      return n.CODE_BLOCK;
    case t.CHILD_PAGE:
      return n.PARAGRAPH;
    case t.CHILD_DATABASE:
      return n.PARAGRAPH;
    case t.EMBED:
      return n.MEDIA_EMBED;
    case t.IMAGE:
      return n.IMAGE;
    case t.VIDEO:
      return n.MEDIA_EMBED;
    case t.FILE:
      return n.PARAGRAPH;
    case t.PDF:
      return n.MEDIA_EMBED;
    case t.BOOKMARK:
      return n.PARAGRAPH;
    case t.CALLOUT:
      return n.CONTAINER;
    case t.QUOTE:
      return n.BLOCKQUOTE;
    case t.EQUATION:
      return n.CODE_BLOCK;
    case t.DIVIDER:
      return n.DIVIDER;
    case t.TABLE_OF_CONTENTS:
      return u;
    case t.BREADCRUMB:
      return u;
    case t.COLUMN:
      return n.COLUMN_ITEM;
    case t.COLUMN_LIST:
      return n.COLUMN;
    case t.LINK_PREVIEW:
      return n.PARAGRAPH;
    case t.SYNCED_BLOCK:
      return n.CONTAINER;
    case t.TEMPLATE:
      return u;
    case t.LINK_TO_PAGE:
      return u;
    case t.TABLE:
      return u;
    case t.TABLE_ROW:
      return u;
    case t.UNSUPPORTED:
      return u;
    default:
      return n.PARAGRAPH;
  }
}, U = (e) => ({
  id: e(),
  type: "p",
  children: [
    {
      text: "Unsupported block type",
      color: "gray"
    }
  ]
}), w = (e) => [
  {
    children: [
      {
        id: e(),
        type: n.SPACER,
        height: 70,
        children: [
          {
            text: ""
          }
        ]
      },
      {
        id: e(),
        type: n.PARAGRAPH,
        children: [
          {
            text: ""
          }
        ]
      }
    ]
  }
], L = (e) => e === "default", G = (e, c, l, A) => {
  const i = A == null ? void 0 : A.includes("background"), d = (A == null ? void 0 : A.split("_")[0]) || void 0, o = e.annotations.color.includes("background"), a = e.annotations.color.split("_")[0];
  let s, r;
  c !== t.CALLOUT && (o && !L(a) ? r = a : i && (r = d)), !o && !L(a) ? s = a : i || (s = d);
  const I = Boolean(e.href), _ = {
    id: l(),
    text: e.plain_text || "",
    bold: e.annotations.bold || void 0,
    italic: e.annotations.italic || void 0,
    strikethrough: e.annotations.strikethrough || void 0,
    underline: e.annotations.underline || void 0,
    code: e.annotations.code || e.type === "equation" || void 0,
    color: s && !L(s) ? s : void 0,
    background: r && !L(r) ? r : void 0,
    styled: Boolean(s && !L(s) || r && !L(r))
  };
  return I ? {
    type: n.LINK,
    url: e.href || "",
    open_in_new_tab: !1,
    id: l(),
    children: [_]
  } : _;
}, M = async (e, c, l, A, i, d) => {
  var _, D;
  const o = c.type, a = c[c.type].type;
  let s = {};
  e === n.CONTAINER && (s = {
    ...s,
    style: P.CARD
  });
  let r = (D = (_ = c[o]) == null ? void 0 : _[a]) == null ? void 0 : D.url, I = "";
  if (a === t.FILE && r)
    try {
      const E = await fetch(r);
      I = E.headers.get("content-type");
      const p = await E.blob(), R = new File([p], N(), { type: I }), h = new f(A), T = (await h.upload(R, l, i, d)).data.file_name;
      r = h.getURL(T);
    } catch (E) {
      console.error("Fail `getAdditionalProps` when importing Notion content", E);
    }
  switch (o) {
    case t.IMAGE:
    case t.PDF:
      s = {
        ...s,
        url: r
      };
      break;
    case t.VIDEO: {
      const { convertedURL: E } = O(r), p = I.includes("video");
      s = {
        ...s,
        url: p ? void 0 : E,
        html: p ? `<video playsinline="" controls="" preload="metadata" src=${r} style="display: block; width: 100%; height: 100%;object-fit: contain;"></video>` : void 0,
        width: 560,
        height: 315,
        keep_aspect_ratio: !0
      };
      break;
    }
    case t.EMBED: {
      const { convertedURL: E, height: p, width: R } = O(c[o].url);
      s = {
        ...s,
        url: E,
        width: R,
        height: p
      };
      break;
    }
    case t.CALLOUT: {
      const { color: E } = c[o], R = E.includes("background") ? E.split("_")[0] : void 0;
      s = {
        ...s,
        background: R
      };
      break;
    }
  }
  return s;
};
export {
  v as convertNotionBlockTypeToTdType,
  G as convertNotionTextToTdTextLeaf,
  M as getAdditionalProps,
  w as initialValueEmpty,
  U as unsupportedBlockPlaceholder
};
