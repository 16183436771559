import React from 'react';
import Script from 'next/script';

declare global {
  interface Window {
    gtag_report_conversion: Function;
  }
}

//  <!-- Event snippet for Typedream Sign-up conversion page
//       In your html page, add the snippet and
//       call gtag_report_conversion when someone clicks
//       on the chosen link or button. -->
function AdsTrackConversion() {
  return (
    <Script id="gtag-report-conversion">
      {` 
      function gtag_report_conversion() {
        gtag('event', 'conversion', {
            'send_to': 'AW-664087335/AYVnCIzjvvoCEKfW1LwC'
        });
        return false;
      }
    `}
    </Script>
  );
}
export default AdsTrackConversion;
