import { TEMPLATE_CATEGORY as e } from "../../../types.js";
import { CALL_TO_ACTION_GRADIENT as _, CALL_TO_ACTION_SUBSCRIBE as t, CALL_TO_ACTION_WAITLIST as s, CALL_TO_ACTION_EMAIL as o, CALL_TO_ACTION_BIG_WHITE as m, CALL_TO_ACTION_PERSONAL_BLOG as n, CALL_TO_ACTION_SOMETHINGS_COOKING as g } from "./callToAction.js";
import { CLIENTS_INVESTOR_PORTFOLIO as l, CLIENTS_LOGOS as r, CLIENTS_NUMBERS_DARK as d, CLIENTS_TESTIMONIAL_ONE as c, CLIENTS_TESTIMONIAL_MANY as p, CLIENTS_TESTIMONIAL_PURPLE as b, CLIENTS_UX_COURSE as T } from "./clients.js";
import { COLLECTION_SKIN_CARE as a, COLLECTION_NFT as i, COLLECTION_PERSONAL_WEB3 as k, COLLECTION_PERSONAL_WEB3_THOUGHTS as O, COLLECTION_WEB3_MEDIAS as u, COLLECTION_PERSONAL_WEB3_NFT as h, COLLECTION_WEDDING as E, COLLECTION_WEB3_BUZZWORDS as L, COLLECTION_TOP_STAKING_PLATFORMS as C, COLLECTION_TOP_NFT_PROJECTS as f, COLLECTION_READS as I, COLLECTION_BLOG_HORIZONTAL as R, COLLECTION_DARK_PROJECTS as N, COLLECTION_BLOG_VERTICAL as A, COLLECTION_PERSONAL_WEB3_AUDIO_PLAYLIST as S, COLLECTION_PROJECTS as P, COLLECTION_JOB_BOARD_DESCRIPTIONS as G, COLLECTION_JOB_BOARD_BENEFITS as w, COLLECTION_JOB_BOARD_OPENINGS as B, COLLECTION_GRADIENTS as D, COLLECTION_UX_COURSE as H, COLLECTION_PODCAST as F, COLLECTION_PERSONAL_BLOG_1 as U, COLLECTION_PERSONAL_BLOG_2 as v, COLLECTION_BLOG_COLORFUL_1 as M, COLLECTION_BLOG_COLORFUL_2 as j, COLLECTION_BLOG_COLORFUL_3 as W, COLLECTION_BACKPACK_TRAVEL_BLOG as x, COLLECTION_KANBAN_PERSONAL_SITE as y, COLLECTION_ALICE_PERSONAL_SITE as K, COLLECTION_ONBOARDING_GUIDE_1 as z, COLLECTION_ONBOARDING_GUIDE_2 as V, COLLECTION_PRODUCT_ROADMAP as J, COLLECTION_DAY_NIGHT as Y } from "./collection.js";
import { EVENTS_WEDDING as X } from "./events.js";
import { FEATURES_LEFT_RIGHT as Z, FEATURES_3_CARDS as q, FEATURES_HIGHLIGHTS as Q, FEATURES_3_BENEFITS as $, FEATURES_LIGHT_PORTFOLIO as ee, FEATURES_PHILOSOPHY as ae, FEATURES_SOURCE as ie, FEATURES_UX_COURSE_1 as _e, FEATURES_UX_COURSE_2 as te } from "./features.js";
import { FOOTER_INVESTOR_PORTFOLIO as se, FOOTER_PERSONAL_WEB3 as oe, FOOTER_WEB3_BUZZWORDS as me, FOOTER_SMALL as ne, FOOTER_BLACK as ge, FOOTER_BEIGE as le, FOOTER_WEDDING as re, FOOTER_BLOG_COLORFUL as de, FOOTER_BACKPACK_TRAVEL_BLOG as ce, FOOTER_KANBAN_PERSONAL_SITE as pe, FOOTER_ALICE_PERSONAL_SITE as be, FOOTER_ONBOARDING_GUIDE as Te, FOOTER_PRODUCT_ROADMAP as ke, FOOTER_DAY_NIGHT as Oe } from "./footer.js";
import { HERO_INVESTOR_PORTFOLIO as ue, HERO_NFT_MINTING as he, HERO_PERSONAL_WEB3 as Ee, HERO_WEDDING as Le, HERO_LANDING_CENTER as Ce, HERO_LIGHT_PORFOLIO as fe, HERO_GRADIENT as Ie, HERO_ORGANIC as Re, HERO_ORANGE_PRODUCT as Ne, HERO_PERSONAL_SITE as Ae, HERO_PRODUCT_PROMO as Se, HERO_JOB_BOARD as Pe, HERO_UX_COURSE as Ge, HERO_PODCAST as we, HERO_BACKPACK_TRAVEL_BLOG as Be, HERO_KANBAN_PERSONAL_SITE as De, HERO_ALICE_PERSONAL_SITE as He, HERO_ONBOARDING_GUIDE as Fe, HERO_PRODUCT_ROADMAP as Ue, HERO_REGIMENT as ve, HERO_APPAREL as Me, HERO_GUAC as je, HERO_DAY_NIGHT as We } from "./hero.js";
import { PRICING_3_TIERS as xe, PRICING_2_LEFT_RIGHT as ye } from "./pricing.js";
import { SHOP_1_ITEM as Ke, SHOP_3_ITEMS as ze, SHOP_PRODUCT_DESCRIPTION as Ve, SHOP_FUEL_PRODUCTS as Je } from "./shop.js";
const Ye = {
  [e.HERO]: {
    title: "Hero",
    templates: {
      hero_investor_portfolio: {
        published_content: ue,
        metadata: {
          image: "/images/sectionTemplates/hero_investor_portfolio.png",
          image_dark: "/images/sectionTemplates/hero_investor_portfolio.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_nft_minting: {
        published_content: he,
        metadata: {
          image: "/images/sectionTemplates/hero_nft_minting.png",
          image_dark: "/images/sectionTemplates/hero_nft_minting.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_personal_web3: {
        published_content: Ee,
        metadata: {
          image: "/images/sectionTemplates/hero_personal_web3.png",
          image_dark: "/images/sectionTemplates/hero_personal_web3.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_wedding: {
        published_content: Le,
        metadata: {
          image: "/images/sectionTemplates/hero_wedding.png",
          image_dark: "/images/sectionTemplates/hero_wedding.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_landing_center: {
        published_content: Ce,
        metadata: {
          image: "/images/sectionTemplates/hero_landing_center.png",
          image_dark: "/images/sectionTemplates/hero_landing_center_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_light_portfolio: {
        published_content: fe,
        metadata: {
          image: "/images/sectionTemplates/hero_light_portfolio.png",
          image_dark: "/images/sectionTemplates/hero_light_portfolio_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_gradient: {
        published_content: Ie,
        metadata: {
          image: "/images/sectionTemplates/hero_gradient.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: "/images/sectionTemplates/hero_gradient_small.png"
        }
      },
      hero_organic: {
        published_content: Re,
        metadata: {
          image: "/images/sectionTemplates/hero_organic.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: "/images/sectionTemplates/hero_organic_small.png"
        }
      },
      hero_orange_product: {
        published_content: Ne,
        metadata: {
          image: "/images/sectionTemplates/hero_orange_product.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_personal_site: {
        published_content: Ae,
        metadata: {
          image: "/images/sectionTemplates/hero_personal_site.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: "/images/sectionTemplates/hero_personal_site_small.png"
        }
      },
      hero_product_promo: {
        published_content: Se,
        metadata: {
          image: "/images/sectionTemplates/hero_product_promo.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_job_board: {
        published_content: Pe,
        metadata: {
          image: "/images/sectionTemplates/hero_job_board.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_ux_course: {
        published_content: Ge,
        metadata: {
          image: "/images/sectionTemplates/hero_ux_course.png",
          image_dark: "/images/sectionTemplates/hero_ux_course_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_podcast: {
        published_content: we,
        metadata: {
          image: "/images/sectionTemplates/hero_podcast.png",
          image_dark: "/images/sectionTemplates/hero_podcast_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_backpack_travel_blog: {
        published_content: Be,
        metadata: {
          image: "/images/sectionTemplates/hero_backpack_travel_blog.png",
          image_dark: "/images/sectionTemplates/hero_backpack_travel_blog_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_kanban_personal_site: {
        published_content: De,
        metadata: {
          image: "/images/sectionTemplates/hero_kanban_personal_site.png",
          image_dark: "/images/sectionTemplates/hero_kanban_personal_site_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_alice_personal_site: {
        published_content: He,
        metadata: {
          image: "/images/sectionTemplates/hero_alice_personal_site.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_onboarding_guide: {
        published_content: Fe,
        metadata: {
          image: "/images/sectionTemplates/hero_onboarding_guide.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_product_roadmap: {
        published_content: Ue,
        metadata: {
          image: "/images/sectionTemplates/hero_product_roadmap.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_regiment: {
        published_content: ve,
        metadata: {
          image: "/images/sectionTemplates/hero_regiment.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_apparel: {
        published_content: Me,
        metadata: {
          image: "/images/sectionTemplates/hero_apparel.png",
          image_dark: "/images/sectionTemplates/hero_apparel_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_guac: {
        published_content: je,
        metadata: {
          image: "/images/sectionTemplates/hero_guac.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      hero_day_night: {
        published_content: We,
        metadata: {
          image: "/images/sectionTemplates/hero_day_night.png",
          image_dark: "/images/sectionTemplates/hero_day_night_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.FEATURES]: {
    title: "Features",
    templates: {
      features_left_right: {
        published_content: Z,
        metadata: {
          image: "/images/sectionTemplates/features_left_right.png",
          image_dark: "/images/sectionTemplates/features_left_right_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/features_left_right_dark_small.png",
          image_sidebar: "/images/sectionTemplates/features_left_right_small.png"
        }
      },
      features_3_cards: {
        published_content: q,
        metadata: {
          image: "/images/sectionTemplates/features_3_cards.png",
          image_dark: "/images/sectionTemplates/features_3_cards_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_highlights: {
        published_content: Q,
        metadata: {
          image: "/images/sectionTemplates/features_highlights.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_3_benefits: {
        published_content: $,
        metadata: {
          image: "/images/sectionTemplates/features_3_benefits.png",
          image_dark: "/images/sectionTemplates/features_3_benefits_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_light_portfolio: {
        published_content: ee,
        metadata: {
          image: "/images/sectionTemplates/features_light_portfolio.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_philosophy: {
        published_content: ae,
        metadata: {
          image: "/images/sectionTemplates/features_philosophy.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_source: {
        published_content: ie,
        metadata: {
          image: "/images/sectionTemplates/features_source.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_ux_course_1: {
        published_content: _e,
        metadata: {
          image: "/images/sectionTemplates/features_ux_course_1.png",
          image_dark: "/images/sectionTemplates/features_ux_course_1_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      features_ux_course_2: {
        published_content: te,
        metadata: {
          image: "/images/sectionTemplates/features_ux_course_2.png",
          image_dark: "/images/sectionTemplates/features_ux_course_2_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.CLIENTS]: {
    title: "Clients",
    templates: {
      clients_investor_portfolio: {
        published_content: l,
        metadata: {
          image: "/images/sectionTemplates/clients_investor_portfolio.png",
          image_dark: "/images/sectionTemplates/clients_investor_portfolio.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      clients_logos: {
        published_content: r,
        metadata: {
          image: "/images/sectionTemplates/clients_logos.png",
          image_dark: "/images/sectionTemplates/clients_logos_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/clients_logos_dark_small.png",
          image_sidebar: "/images/sectionTemplates/clients_logos_small.png"
        }
      },
      clients_numbers_dark: {
        published_content: d,
        metadata: {
          image: "/images/sectionTemplates/clients_numbers_dark.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: "/images/sectionTemplates/clients_numbers_dark_small.png"
        }
      },
      clients_testimonial_one: {
        published_content: c,
        metadata: {
          image: "/images/sectionTemplates/clients_testimonial_one.png",
          image_dark: "/images/sectionTemplates/clients_testimonial_one_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/clients_testimonial_one_dark_small.png",
          image_sidebar: "/images/sectionTemplates/clients_testimonial_one_small.png"
        }
      },
      clients_testimonial_many: {
        published_content: p,
        metadata: {
          image: "/images/sectionTemplates/clients_testimonial_many.png",
          image_dark: "/images/sectionTemplates/clients_testimonial_many_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      clients_testimonial_purple: {
        published_content: b,
        metadata: {
          image: "/images/sectionTemplates/clients_testimonial_purple.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: "/images/sectionTemplates/clients_testimonial_purple_small.png"
        }
      },
      clients_ux_course: {
        published_content: T,
        metadata: {
          image: "/images/sectionTemplates/clients_ux_course.png",
          image_dark: "/images/sectionTemplates/clients_ux_course_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.EVENTS]: {
    title: "Events",
    templates: {
      events_wedding: {
        published_content: X,
        metadata: {
          image: "/images/sectionTemplates/events_wedding.png",
          image_dark: "/images/sectionTemplates/events_wedding.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.PRICING]: {
    title: "Pricing",
    templates: {
      pricing_3_tiers: {
        published_content: xe,
        metadata: {
          image: "/images/sectionTemplates/pricing_3_tiers.png",
          image_dark: "/images/sectionTemplates/pricing_3_tiers_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/pricing_3_tiers_dark_small.png",
          image_sidebar: "/images/sectionTemplates/pricing_3_tiers_small.png"
        }
      },
      pricing_2_left_right: {
        published_content: ye,
        metadata: {
          image: "/images/sectionTemplates/pricing_2_left_right.png",
          image_dark: "/images/sectionTemplates/pricing_2_left_right_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/pricing_2_left_right_dark_small.png",
          image_sidebar: "/images/sectionTemplates/pricing_2_left_right_small.png"
        }
      }
    }
  },
  [e.SHOP]: {
    title: "Shop",
    templates: {
      shop_1_item: {
        published_content: Ke,
        metadata: {
          image: "/images/sectionTemplates/shop_1_item.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      shop_3_items: {
        published_content: ze,
        metadata: {
          image: "/images/sectionTemplates/shop_3_items.png",
          image_dark: "/images/sectionTemplates/shop_3_items_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/shop_3_items_dark_small.png",
          image_sidebar: "/images/sectionTemplates/shop_3_items_small.png"
        }
      },
      shop_product_description: {
        published_content: Ve,
        metadata: {
          image: "/images/sectionTemplates/shop_product_description.png",
          image_dark: "/images/sectionTemplates/shop_product_description_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/shop_product_description_dark_small.png",
          image_sidebar: "/images/sectionTemplates/shop_product_description_small.png"
        }
      },
      collection_skin_care: {
        published_content: a,
        metadata: {
          image: "/images/sectionTemplates/collection_skin_care.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      shop_fuel_products: {
        published_content: Je,
        metadata: {
          image: "/images/sectionTemplates/shop_fuel_products.png",
          image_dark: "/images/sectionTemplates/shop_fuel_products_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_nft: {
        published_content: i,
        metadata: {
          image: "/images/sectionTemplates/collection_nft.png",
          image_dark: "/images/sectionTemplates/collection_nft_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.COLLECTION]: {
    title: "Collections",
    templates: {
      collection_personal_web3: {
        published_content: k,
        metadata: {
          image: "/images/sectionTemplates/collection_personal_web3.png",
          image_dark: "/images/sectionTemplates/collection_personal_web3.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_personal_web3_thoughts: {
        published_content: O,
        metadata: {
          image: "/images/sectionTemplates/collection_personal_web3_thoughts.png",
          image_dark: "/images/sectionTemplates/collection_personal_web3_thoughts.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_web3_medias: {
        published_content: u,
        metadata: {
          image: "/images/sectionTemplates/collection_web3_medias.png",
          image_dark: "/images/sectionTemplates/collection_web3_medias.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_personal_web3_nft: {
        published_content: h,
        metadata: {
          image: "/images/sectionTemplates/collection_personal_web3_nft.png",
          image_dark: "/images/sectionTemplates/collection_personal_web3_nft.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_wedding: {
        published_content: E,
        metadata: {
          image: "/images/sectionTemplates/collection_wedding.png",
          image_dark: "/images/sectionTemplates/collection_wedding.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_web3_buzzwords: {
        published_content: L,
        metadata: {
          image: "/images/sectionTemplates/collection_web3_buzzwords.png",
          image_dark: "/images/sectionTemplates/collection_web3_buzzwords.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_top_staking_platforms: {
        published_content: C,
        metadata: {
          image: "/images/sectionTemplates/collection_top_staking_platforms.png",
          image_dark: "/images/sectionTemplates/collection_top_staking_platforms.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_top_nft_projects: {
        published_content: f,
        metadata: {
          image: "/images/sectionTemplates/collection_top_nft_projects.png",
          image_dark: "/images/sectionTemplates/collection_top_nft_projects.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_reads: {
        published_content: I,
        metadata: {
          image: "/images/sectionTemplates/collection_reads.png",
          image_dark: "/images/sectionTemplates/collection_reads_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/collection_reads_dark_small.png",
          image_sidebar: "/images/sectionTemplates/collection_reads_small.png"
        }
      },
      collection_blog_horizontal: {
        published_content: R,
        metadata: {
          image: "/images/sectionTemplates/collection_blog_horizontal.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_dark_projects: {
        published_content: N,
        metadata: {
          image: "/images/sectionTemplates/collection_dark_projects.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: "/images/sectionTemplates/collection_dark_projects_small.png"
        }
      },
      collection_skin_care: {
        published_content: a,
        metadata: {
          image: "/images/sectionTemplates/collection_skin_care.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_nft: {
        published_content: i,
        metadata: {
          image: "/images/sectionTemplates/collection_nft.png",
          image_dark: "/images/sectionTemplates/collection_nft_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_blog_vertical: {
        published_content: A,
        metadata: {
          image: "/images/sectionTemplates/collection_blog_vertical.png",
          image_dark: "/images/sectionTemplates/collection_blog_vertical_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/collection_blog_vertical_dark_small.png",
          image_sidebar: "/images/sectionTemplates/collection_blog_vertical_small.png"
        }
      },
      collection_personal_web3_audio_playlist: {
        published_content: S,
        metadata: {
          image: "/images/sectionTemplates/collection_personal_web3_audio_playlist.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_projects: {
        published_content: P,
        metadata: {
          image: "/images/sectionTemplates/collection_projects.png",
          image_dark: "/images/sectionTemplates/collection_projects_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_job_board_descriptions: {
        published_content: G,
        metadata: {
          image: "/images/sectionTemplates/collection_job_board_descriptions.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_job_board_benefits: {
        published_content: w,
        metadata: {
          image: "/images/sectionTemplates/collection_job_board_benefits.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_job_board_openings: {
        published_content: B,
        metadata: {
          image: "/images/sectionTemplates/collection_job_board_openings.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_gradients: {
        published_content: D,
        metadata: {
          image: "/images/sectionTemplates/collection_gradients.png",
          image_dark: "/images/sectionTemplates/collection_gradients_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_ux_course: {
        published_content: H,
        metadata: {
          image: "/images/sectionTemplates/collection_ux_course.png",
          image_dark: "/images/sectionTemplates/collection_ux_course_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_podcast: {
        published_content: F,
        metadata: {
          image: "/images/sectionTemplates/collection_podcast.png",
          image_dark: "/images/sectionTemplates/collection_podcast_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_personal_blog_1: {
        published_content: U,
        metadata: {
          image: "/images/sectionTemplates/collection_personal_blog_1.png",
          image_dark: "/images/sectionTemplates/collection_personal_blog_1_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_personal_blog_2: {
        published_content: v,
        metadata: {
          image: "/images/sectionTemplates/collection_personal_blog_2.png",
          image_dark: "/images/sectionTemplates/collection_personal_blog_2_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_blog_colorful_1: {
        published_content: M,
        metadata: {
          image: "/images/sectionTemplates/collection_blog_colorful_1.png",
          image_dark: "/images/sectionTemplates/collection_blog_colorful_1_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_blog_colorful_2: {
        published_content: j,
        metadata: {
          image: "/images/sectionTemplates/collection_blog_colorful_2.png",
          image_dark: "/images/sectionTemplates/collection_blog_colorful_2_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_blog_colorful_3: {
        published_content: W,
        metadata: {
          image: "/images/sectionTemplates/collection_blog_colorful_3.png",
          image_dark: "/images/sectionTemplates/collection_blog_colorful_3_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_backpack_travel_blog: {
        published_content: x,
        metadata: {
          image: "/images/sectionTemplates/collection_backpack_travel_blog.png",
          image_dark: "/images/sectionTemplates/collection_backpack_travel_blog_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_kanban_personal_site: {
        published_content: y,
        metadata: {
          image: "/images/sectionTemplates/collection_kanban_personal_site.png",
          image_dark: "/images/sectionTemplates/collection_kanban_personal_site_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_alice_personal_site: {
        published_content: K,
        metadata: {
          image: "/images/sectionTemplates/collection_alice_personal_site.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_onboarding_guide_1: {
        published_content: z,
        metadata: {
          image: "/images/sectionTemplates/collection_onboarding_guide_1.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_onboarding_guide_2: {
        published_content: V,
        metadata: {
          image: "/images/sectionTemplates/collection_onboarding_guide_2.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_product_roadmap: {
        published_content: J,
        metadata: {
          image: "/images/sectionTemplates/collection_product_roadmap.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      collection_day_night: {
        published_content: Y,
        metadata: {
          image: "/images/sectionTemplates/collection_day_night.png",
          image_dark: "/images/sectionTemplates/collection_day_night_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.FOOTER]: {
    title: "Footer",
    templates: {
      footer_investor_portfolio: {
        published_content: se,
        metadata: {
          image: "/images/sectionTemplates/footer_investor_portfolio.png",
          image_dark: "/images/sectionTemplates/footer_investor_portfolio.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_personal_web3: {
        published_content: oe,
        metadata: {
          image: "/images/sectionTemplates/footer_personal_web3.png",
          image_dark: "/images/sectionTemplates/footer_personal_web3.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_web3_buzzwords: {
        published_content: me,
        metadata: {
          image: "/images/sectionTemplates/footer_web3_buzzwords.png",
          image_dark: "/images/sectionTemplates/footer_web3_buzzwords.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_small: {
        published_content: ne,
        metadata: {
          image: "/images/sectionTemplates/footer_small.png",
          image_dark: "/images/sectionTemplates/footer_small_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/footer_small_dark_small.png",
          image_sidebar: "/images/sectionTemplates/footer_small_small.png"
        }
      },
      footer_black: {
        published_content: ge,
        metadata: {
          image: "/images/sectionTemplates/footer_black.png",
          image_dark: "/images/sectionTemplates/footer_black_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/footer_black_small_dark.png",
          image_sidebar: "/images/sectionTemplates/footer_black_small.png"
        }
      },
      footer_beige: {
        published_content: le,
        metadata: {
          image: "/images/sectionTemplates/footer_beige.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_wedding: {
        published_content: re,
        metadata: {
          image: "/images/sectionTemplates/footer_wedding.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_blog_colorful: {
        published_content: de,
        metadata: {
          image: "/images/sectionTemplates/footer_blog_colorful.png",
          image_dark: "/images/sectionTemplates/footer_blog_colorful.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_backpack_travel_blog: {
        published_content: ce,
        metadata: {
          image: "/images/sectionTemplates/footer_backpack_travel_blog.png",
          image_dark: "/images/sectionTemplates/footer_backpack_travel_blog_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_kanban_personal_site: {
        published_content: pe,
        metadata: {
          image: "/images/sectionTemplates/footer_kanban_personal_site.png",
          image_dark: "/images/sectionTemplates/footer_kanban_personal_site_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_alice_personal_site: {
        published_content: be,
        metadata: {
          image: "/images/sectionTemplates/footer_alice_personal_site.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_onboarding_guide: {
        published_content: Te,
        metadata: {
          image: "/images/sectionTemplates/footer_onboarding_guide.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_product_roadmap: {
        published_content: ke,
        metadata: {
          image: "/images/sectionTemplates/footer_product_roadmap.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      footer_day_night: {
        published_content: Oe,
        metadata: {
          image: "/images/sectionTemplates/footer_day_night.png",
          image_dark: "/images/sectionTemplates/footer_day_night_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  },
  [e.CALL_TO_ACTION]: {
    title: "CTA (Call to Action)",
    templates: {
      call_to_action_gradient: {
        published_content: _,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_gradient.png",
          image_dark: "/images/sectionTemplates/call_to_action_gradient_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/call_to_action_gradient_dark_small.png",
          image_sidebar: "/images/sectionTemplates/call_to_action_gradient_small.png"
        }
      },
      call_to_action_subscribe: {
        published_content: t,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_subscribe.png",
          image_dark: "/images/sectionTemplates/call_to_action_subscribe_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/call_to_action_subscribe_dark_small.png",
          image_sidebar: "/images/sectionTemplates/call_to_action_subscribe_small.png"
        }
      },
      call_to_action_waitlist: {
        published_content: s,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_waitlist.png",
          image_dark: "/images/sectionTemplates/call_to_action_waitlist_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/call_to_action_waitlist_dark_small.png",
          image_sidebar: "/images/sectionTemplates/call_to_action_waitlist_small.png"
        }
      },
      call_to_action_email: {
        published_content: o,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_email.png",
          image_dark: "/images/sectionTemplates/call_to_action_email_dark.png",
          image_sidebar_dark: "/images/sectionTemplates/call_to_action_email_dark_small.png",
          image_sidebar: "/images/sectionTemplates/call_to_action_email_small.png"
        }
      },
      call_to_action_big_white: {
        published_content: m,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_big_white.png",
          image_dark: "",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      call_to_action_personal_blog: {
        published_content: n,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_personal_blog.png",
          image_dark: "/images/sectionTemplates/call_to_action_personal_blog_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      },
      call_to_action_somethings_cooking: {
        published_content: g,
        metadata: {
          image: "/images/sectionTemplates/call_to_action_somethings_cooking.png",
          image_dark: "/images/sectionTemplates/call_to_action_somethings_cooking_dark.png",
          image_sidebar_dark: "",
          image_sidebar: ""
        }
      }
    }
  }
}, sa = Ye;
export {
  sa as default
};
