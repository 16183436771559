import React from 'react';
import Script from 'next/script';

declare global {
  interface Window {
    gtag: Function;
  }
}
function Analytics() {
  return (
    <>
      {/* <!-- Global site tag (gtag.js) - Google Ads: 664087335 -->  */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-664087335"
      />
      <Script id="global-site-tag">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'AW-664087335');
      `}
      </Script>
    </>
  );
}
export default Analytics;
