import { SPACE_ACTION as e, SPACE_ROLE as E } from "./types.js";
const T = "chrome_warning_popup_hide_expiry", N = "custom_domain_popup_expiry", I = "default_space", a = "space_permissions", n = "user", o = "spaces", O = "sp", D = "sb", u = "Space-Permission", S = "Subscription", i = "/app/auth/notion", c = "id", t = "TD", R = "TD:page_content_published", l = "TD:slug", _ = "TD:page_content", C = {
  METADATA: {
    fieldName: `${t}:metadata`,
    label: "Metadata",
    description: "For SEO Settings, Twitter cards, etc."
  },
  SLUG: {
    fieldName: `${t}:slug`,
    label: "Slug",
    description: "To specify the URL for each item"
  },
  PUBLISHED_CONTENT: {
    fieldName: R,
    label: "Page Content",
    description: "To store your page's published rich text content"
  },
  CONTENT: {
    fieldName: _,
    label: "Page Content Draft",
    description: "To store your page's draft rich text content"
  }
}, P = "FREE", M = {
  [e.InviteMember]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.RemoveMember]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.ViewMembers]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.TransferOwnership]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.UpdatePermissions]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.UpdateSettings]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.UpdateCustomDomain]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.UpdateSubdomain]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.ReadSettings]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !0,
    [E.READER]: !0
  },
  [e.ReadBilling]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.UpdateBilling]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.DeleteSpace]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !1,
    [E.READER]: !1
  },
  [e.CreatePage]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !0,
    [E.READER]: !1
  },
  [e.EditContent]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !0,
    [E.READER]: !1
  },
  [e.ReadDraft]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !0,
    [E.READER]: !1
  },
  [e.ReadPublishedContent]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !0,
    [E.READER]: !0
  },
  [e.ReadPageInfo]: {
    [E.OWNER]: !0,
    [E.ADMIN]: !0,
    [E.WRITER]: !0,
    [E.READER]: !0
  }
}, L = "Untitled", p = "untitled", f = 4, U = 10, s = "p", A = "f", W = ["api", "_preview", s, A], d = "TEMPLATE", G = "Network Error", m = "Current Item - ", g = "_CURRENT_ITEM_";
export {
  T as CHROME_WARNING_POPUP_HIDE_STORAGE_KEY,
  _ as COLLECTION_CONTENT_COLUMN_NAME,
  g as COLLECTION_FILTER_CURRENT_ITEM_PREFIX,
  m as COLLECTION_FILTER_CURRENT_ITEM_PREFIX_COPY,
  R as COLLECTION_PUBLISHED_CONTENT_COLUMN_NAME,
  l as COLLECTION_SLUG_COLUMN_NAME,
  N as CUSTOM_DOMAIN_POPUP_STORAGE_KEY,
  c as DEFAULT_COLLECTION_DATA_ID,
  L as DEFAULT_NEW_PAGE_NAME,
  p as DEFAULT_NEW_PAGE_SLUG,
  f as DEFAULT_NEW_PAGE_SLUG_RANDOM_LENGTH,
  I as DEFAULT_SPACE_STORAGE_KEY,
  d as DUPLICATED_TABLE_ID_PREFIX,
  C as ITEM_PAGE_DEFAULT_FIELDS,
  t as LOGO_NAME_ABBREVIATED,
  U as MAX_SLUG_RETRY,
  G as NETWORK_ERROR,
  i as OAUTH_REDIRECT_URI,
  s as PRODUCT_PAGE_SLUG,
  W as RESERVED_PATHS,
  M as SPACE_PERMISSIONS,
  O as SPACE_PERMISSIONS_STORAGE_KEY,
  u as SPACE_PERMISSION_HEADER_KEY,
  a as SPACE_PERMISSION_STORAGE_KEY,
  P as SPACE_PLAN_FREE_PRODUCT_ID,
  o as SPACE_STORAGE_KEY,
  S as SUBSCRIPTION_HEADER_KEY,
  D as SUBSCRIPTION_STORAGE_KEY,
  n as USER_STORAGE_KEY
};
