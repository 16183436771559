var w = Object.defineProperty;
var b = (e, t, n) => t in e ? w(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var m = (e, t, n) => (b(e, typeof t != "symbol" ? t + "" : t, n), n);
import { getPermissionAndSubscriptionConfig as d } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as u } from "../EditorAPI.js";
class U {
  constructor(t) {
    m(this, "api");
    this.api = t;
  }
  async upload(t, n, o, i, s = !0, a) {
    if (!n)
      return this.nonUserUpload(t);
    const r = new FormData();
    r.append("file", t), r.append("file_name", t.name);
    const l = await d(n, o, i), f = new u(this.api);
    let c = `/document/${n}`;
    const p = new URLSearchParams();
    return s || p.set("public", "false"), a && p.set("bucket", a), c = `${c}?${p.toString()}`, f.post(c, r, !0, l);
  }
  async nonUserUpload(t) {
    const n = new FormData();
    n.append("file", t), n.append("file_name", t.name);
    const o = new u(this.api), i = "/document/?public=true";
    return o.post(i, n);
  }
  async listDocuments(t, n, o) {
    const i = await d(t, n, o), s = new u(this.api), a = `/document/${t}`;
    return (await s.get(a, !0, i)).data;
  }
  getPublicURL(t) {
    return `${this.api}/document/public/${t}`;
  }
  getURL(t) {
    return `${this.api}/document/public/${t}`;
  }
}
export {
  U as DocumentAPI
};
