const e = {
  align: "align_center",
  background: "black",
  children: [
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          gradient: !1,
          styled: !0,
          text: "Just Type."
        }
      ],
      type: "h1"
    },
    {
      align: "align_center",
      children: [
        {
          color: "white",
          fontFamily: "space_mono",
          fontSize: 20,
          fontStyle: "subtitle2",
          gradient: !1,
          lineHeight: 1.4,
          styled: !0,
          text: "AVAILABLE NOW"
        }
      ],
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          color: "indigo",
          fontFamily: "space_mono",
          gradient: !1,
          styled: !0,
          text: ""
        }
      ],
      type: "p"
    },
    {
      aspect_ratio: 0.9794801641586868,
      children: [
        {
          text: ""
        }
      ],
      height: 444.67510474860336,
      openOptions: !1,
      type: "img",
      url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/hoodie-just-type-purple.png",
      width: 435.55044459644324
    },
    {
      children: [
        {
          text: ""
        }
      ],
      openOptions: !1,
      type: "p"
    },
    {
      align: "align_center",
      children: [
        {
          bold: !0,
          color: "white",
          fontFamily: "inter",
          gradient: !1,
          styled: !0,
          text: "by typedream.com"
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      height: 70,
      type: "spacer"
    }
  ],
  height: 875.671875,
  openOptions: !1,
  type: "container",
  width: 1100,
  width_max: "100%"
}, t = {
  children: [
    {
      children: [
        {
          align: "align_center",
          background: "default",
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 240,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-1.png",
              width: 240
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: "Facial Soap"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$28"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      text: "Buy now \u2192"
                    }
                  ],
                  internal_link: !1,
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://buy.stripe.com/28o7swfhV4a1fjq7st"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          height: 393.703125,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 700
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    },
    {
      children: [
        {
          align: "align_center",
          background: "default",
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 233,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-2.png",
              width: 233
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: "Wax"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$13"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      text: "Buy now \u2192"
                    }
                  ],
                  internal_link: !1,
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://buy.stripe.com/28o7swfhV4a1fjq7st"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          height: 393.703125,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 700
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    },
    {
      children: [
        {
          align: "align_center",
          background: "default",
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 233,
              openOptions: !1,
              type: "img",
              url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-3.png",
              width: 233
            },
            {
              children: [
                {
                  bold: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  bold: !0,
                  text: "Lipstick"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$18"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  text: ""
                },
                {
                  children: [
                    {
                      text: "Buy now \u2192"
                    }
                  ],
                  internal_link: !1,
                  open_in_new_tab: !1,
                  type: "a",
                  url: "https://buy.stripe.com/28o7swfhV4a1fjq7st"
                },
                {
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          height: 393.703125,
          openOptions: !1,
          style: "card",
          type: "container",
          width: 700
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    }
  ],
  height: 477,
  type: "column",
  width: 1e3
}, l = {
  children: [
    {
      children: [
        {
          aspect_ratio: 1,
          children: [
            {
              text: ""
            }
          ],
          height: 500,
          openOptions: !1,
          type: "img",
          url: "https://typedream-assets.sfo3.digitaloceanspaces.com/TemplateAssets/item-1.png",
          width: 500
        }
      ],
      type: "column_item",
      width: 0.5
    },
    {
      children: [
        {
          children: [
            {
              bold: !0,
              text: "Moonbeam"
            }
          ],
          type: "p"
        },
        {
          children: [
            {
              text: "Jet Lag Mask",
              styled: !0,
              fontStyle: "subtitle2",
              fontSize: 20,
              lineHeight: 1.4,
              color: "default",
              gradient: !1
            }
          ],
          type: "p"
        },
        {
          type: "p",
          children: [
            {
              styled: !0,
              fontStyle: "subtitle2",
              fontSize: 20,
              lineHeight: 1.4,
              color: "default",
              gradient: !1,
              text: "$48"
            }
          ]
        },
        {
          type: "p",
          children: [
            {
              styled: !0,
              fontStyle: "subtitle2",
              fontSize: 20,
              lineHeight: 1.4,
              color: "default",
              gradient: !1,
              text: ""
            }
          ]
        },
        {
          type: "p",
          children: [
            {
              text: "An enhanced, fragrance-free formula that nourishes, hydrates, and calms your skin with niacinamide, glycerin, hyaluronic acid, and antioxidants.",
              styled: !0,
              color: "gray",
              gradient: !1
            }
          ]
        },
        {
          type: "p",
          children: [
            {
              styled: !0,
              color: "gray",
              gradient: !1,
              text: ""
            }
          ]
        },
        {
          type: "p",
          children: [
            {
              styled: !0,
              color: "gray",
              gradient: !1,
              text: "Skin Type:",
              bold: !0
            },
            {
              styled: !0,
              color: "gray",
              gradient: !1,
              text: " Normal, Dry, Combination, and Oily"
            }
          ]
        },
        {
          type: "p",
          children: [
            {
              styled: !0,
              color: "gray",
              gradient: !1,
              text: ""
            }
          ]
        },
        {
          type: "button",
          children: [
            {
              text: "Buy now \u2192"
            }
          ],
          openOptions: !1,
          url: "https://buy.stripe.com/28o7swfhV4a1fjq7st",
          background: "accent",
          color: "text_on_accent",
          internal_link: !1
        }
      ],
      type: "column_item",
      width: 0.5
    }
  ],
  height: 480,
  type: "column",
  width: 1e3
}, n = {
  children: [
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 0.9964028776978416,
              children: [
                {
                  text: ""
                }
              ],
              height: 326.734375,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/b6f85cb6-11df-48f7-9d80-6a0c0ffc2da2_fuel-orange_png.png",
              width: 325.55907149280574
            },
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Pineapple Fuel"
                }
              ],
              openOptions: !1,
              type: "h2"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$ 8.99"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Buy Now  \u2192"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          height: 583.734375,
          openOptions: !1,
          style: "plain",
          type: "container",
          width: 400
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 0.9982014388489209,
              children: [
                {
                  text: ""
                }
              ],
              height: 326.1456644144144,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/c828efc1-6089-48dc-b6df-623c4361e363_fuel-black_png.png",
              width: 325.55907149280574
            },
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Banana Fuel"
                }
              ],
              openOptions: !1,
              type: "h2"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$ 8.99"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Buy Now  \u2192"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          height: 583.734375,
          openOptions: !1,
          style: "plain",
          type: "container",
          width: 400
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    },
    {
      children: [
        {
          align: "align_center",
          children: [
            {
              aspect_ratio: 1,
              children: [
                {
                  text: ""
                }
              ],
              height: 325.55907149280574,
              openOptions: !1,
              type: "img",
              url: "https://api.typedream.com/v0/document/public/b5b6c6f7-12b2-4ab3-9c68-cabd3e472796_fuel-blue_png.png",
              width: 325.55907149280574
            },
            {
              children: [
                {
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Orange Fuel"
                }
              ],
              openOptions: !1,
              type: "h2"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$ 8.99"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              background: "black",
              children: [
                {
                  bold: !0,
                  fontFamily: "libre_caslon_text",
                  styled: !0,
                  text: "Buy Now  \u2192"
                }
              ],
              color: "text_on_accent",
              openOptions: !1,
              type: "button",
              url: ""
            }
          ],
          height: 583.734375,
          openOptions: !1,
          style: "plain",
          type: "container",
          width: 400
        }
      ],
      type: "column_item",
      width: 0.3333333333333333
    }
  ],
  height: 738.546875,
  type: "column",
  width: 1300
};
export {
  e as SHOP_1_ITEM,
  t as SHOP_3_ITEMS,
  n as SHOP_FUEL_PRODUCTS,
  l as SHOP_PRODUCT_DESCRIPTION
};
