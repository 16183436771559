import { API as a } from "../../api/api.js";
const d = (n) => async (t, e) => {
  const { getState: s } = n, { tokenHandlers: o } = s(), { spacePermissionTokenHandler: r, subscriptionTokenHandler: i } = o;
  return await a.getOrCreateInstance().history().getVersionList(t, e, r, i);
}, k = (n) => async (t, e) => {
  const { getState: s } = n, { tokenHandlers: o } = s(), { spacePermissionTokenHandler: r, subscriptionTokenHandler: i } = o;
  return await a.getOrCreateInstance().history().getVersionContent(t, e, r, i);
};
export {
  k as getVersionContent,
  d as getVersionList
};
