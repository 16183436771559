'use client';

import {
  RootSlice,
  createAuthSlice,
  createPagesApiSlice,
  createSpacesApiSlice,
  createLocksApiSlice,
  createAnalyticsApiSlice,
  createSubscriptionApiSlice,
  createTemplatesApiSlice,
  createFormsApiSlice,
  createAppApiSlice,
  createProductsApiSlice,
  createDocumentsApiSlice,
  createCollectionsApiSlice,
  createHistoryApiSlice,
  createTransclusionApiSlice,
  createPaymentApiSlice,
} from '@typedream/data';
import { generateHooks } from '@typedream/zustand-query';
import create, {
  GetState, Mutate, SetState, StoreApi,
} from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { IPublishSettingsPopupSlice, createPublishSettingsSlice } from './App';

type StoreSlice = RootSlice & IPublishSettingsPopupSlice

export const useStore = create<
  StoreSlice,
  SetState<StoreSlice>,
  GetState<StoreSlice>,
  Mutate<StoreApi<StoreSlice>, [['zustand/subscribeWithSelector', never]]>
>(
  subscribeWithSelector((set, get, api) => ({
    ...createAuthSlice(),

    // with zustand-query
    ...createPagesApiSlice(api),
    ...createSpacesApiSlice(api),
    ...createLocksApiSlice(api),
    ...createSubscriptionApiSlice(api),
    ...createAnalyticsApiSlice(api),
    ...createTemplatesApiSlice(api),
    ...createFormsApiSlice(api),
    ...createProductsApiSlice(api),
    ...createAppApiSlice(api),
    ...createDocumentsApiSlice(api),
    ...createCollectionsApiSlice(api),
    ...createHistoryApiSlice(api),
    ...createPaymentApiSlice(api),
    ...createTransclusionApiSlice(api),
    ...createPublishSettingsSlice(set, get),
  })),
);

export const {
  useListPagesQuery,
  useGetPageQuery,
  useCreatePageMutation,
  useDeletePageMutation,
  useDuplicatePageMutation,
  useSaveContentMutation,
  useCreateHomePageMutation,
  useUpdatePageCustomizationMutation,
  useUpdatePageInfoMutation,
  useUpdatePageMetadataMutation,
} = generateHooks(useStore, createPagesApiSlice);

export const {
  useAddSpacePermissionMutation,
  useCreateSpaceMutation,
  useCreateSpaceNoPagesMutation,
  useDeleteCustomDomainMutation,
  useDeleteCustomDomainRedirectMutation,
  useDeleteSpaceMutation,
  useGetSpacePermissionsQuery,
  useGetSpaceQuery,
  useListSpacesQuery,
  usePublishMutation,
  useRemoveSpacePermissionMutation,
  useSaveNavbarMutation,
  useTransferSpacePermissionMutation,
  useUpdateCustomDomainMutation,
  useUpdateCustomDomainRedirectMutation,
  useUpdateRoutesMutation,
  useUpdateSpaceCustomizationPartialMutation,
  useUpdateSpaceMetadataMutation,
  useUpdateSpaceSettingsMutation,
} = generateHooks(useStore, createSpacesApiSlice);

export const {
  useListLocksQuery,
  useAddLockMutation,
  useCreateLockMutation,
  useDeleteLockMutation,
  useRemoveLockMutation,
  useUpdateLockMutation,
} = generateHooks(useStore, createLocksApiSlice);

export const {
  useGetSubscriptionQuery,
} = generateHooks(useStore, createSubscriptionApiSlice);

export const {
  useGetSharedLinkQuery,
  useCreateSiteMutation,
} = generateHooks(useStore, createAnalyticsApiSlice);

export const {
  useGetTemplateQuery,
  useGetPublicTemplateQuery,
  useListSectionTemplatesQuery,
  useListTemplatesQuery,
  useListTemplatesPublicQuery,
  useUpdateTemplateUsageMutation,
} = generateHooks(useStore, createTemplatesApiSlice);

export const {
  useCreateFormMutation,
  useCreateAndPublishFormMutation,
  useGetFormContentQuery,
  useGetPublishedFormContentQuery,
  useListFormSubmissionsQuery,
  useGetFormSubmissionCountQuery,
  useGetAllFormSubmissionsQuery,
  useUpdateFormContentMutation,
  useListFormsQuery,
  usePublishFormContentMutation,
  useUpdateAndPublishFormContentMutation,
  useRenameFormMutation,
  useDeleteFormMutation,
  useGetFormQuery,
  useUpdateFormMutation,
  useDeleteSubmissionMutation,
} = generateHooks(useStore, createFormsApiSlice);

export const {
  useCreateProductMutation,
  useQueryProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  usePublishProductMutation,
  useGetProductCollectionQuery,
  useUpdateContactEmailMutation,
  useCountProductsQuery,
} = generateHooks(useStore, createProductsApiSlice);

export const {
  useCheckStripeConnectionQuery,
  useConnectStripeMutation,
  useDeleteAppMutation,
} = generateHooks(useStore, createAppApiSlice);

export const {
  useListDocumentsQuery,
} = generateHooks(useStore, createDocumentsApiSlice);

export const {
  useListCollectionItemsQuery,
  useUpdateCollectionItemMutation,
} = generateHooks(useStore, createCollectionsApiSlice);

export const {
  useGetVersionListQuery,
  useGetVersionContentQuery,
} = generateHooks(useStore, createHistoryApiSlice);

export const {
  useListPaymentsQuery,
  useListAllPaymentsQuery,
  useCountPaymentsQuery,
} = generateHooks(useStore, createPaymentApiSlice);

export const {
  useGetAllSyncedContainersQuery,
  useCreateTransclusionReferenceMutation,
} = generateHooks(useStore, createTransclusionApiSlice);

// App state hooks
export const useOpenPublishPopupDispatch = () => useStore(
  (state) => state.publishSettings.openPopup,
);
export const useOpenPublishPopup = () => useStore(
  (state) => state.publishSettings.open,
);
export const useClosePublishPopupDispatch = () => useStore(
  (state) => state.publishSettings.closePopup,
);

export * from './hooks';
export * from './selectors';
