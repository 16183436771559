import { makeApiSliceCreator as r } from "@typedream/zustand-query";
import { getAllSyncedContainers as t, createTransclusionReference as o } from "./action.js";
const s = r("transclusionApi", (e) => ({
  endpoints: (n) => ({
    getAllSyncedContainers: n.query(t(e)),
    createTransclusionReference: n.mutation(o(e))
  })
}));
export {
  s as createTransclusionApiSlice
};
