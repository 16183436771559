import React from 'react';
import Script from 'next/script';

function Rewardful() {
  return (
    <>
      {/* <!-- Rewardful -->  */}
      <Script
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful="64d1ae"
      />
      <Script id="rewardful">
        {'(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,\'rewardful\');'}
      </Script>
    </>
  );
}
export default Rewardful;
