const e = {
  type: "container",
  align: "align_center",
  children: [
    {
      align: "align_center",
      children: [
        {
          text: "Choose the plan that's right for you"
        }
      ],
      type: "h2"
    },
    {
      align: "align_center",
      children: [
        {
          color: "gray",
          fontSize: 20,
          fontStyle: "subtitle2",
          lineHeight: 1.4,
          styled: !0,
          text: "Flexible monthly pricing for teams of all sizes."
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          text: ""
        }
      ],
      type: "p"
    },
    {
      children: [
        {
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Free",
                      color: "default",
                      gradient: !1
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: `For individuals and small teams
`
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "indigo",
                      fontSize: 24,
                      fontStyle: "subtitle1",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p",
                  width: 700
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "indigo",
                      fontSize: 24,
                      fontStyle: "subtitle1",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "$0"
                    }
                  ],
                  type: "p",
                  width: 700
                }
              ],
              height: 280,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start",
              width: 700
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 27,
              openOptions: !1,
              type: "spacer"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Free bank account"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Up to 5 team members"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Automated reports"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              background: "indigo",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Team"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "Advanced collaboration for organizations"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      bold: !0,
                      color: "white",
                      fontSize: 24,
                      fontStyle: "subtitle1",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: "$35"
                    },
                    {
                      color: "white",
                      fontSize: 24,
                      fontStyle: "subtitle1",
                      gradient: !1,
                      lineHeight: 1.4,
                      styled: !0,
                      text: " "
                    },
                    {
                      color: "white",
                      gradient: !1,
                      styled: !0,
                      text: "/month"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                }
              ],
              height: 280,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start",
              width: 700
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 27,
              openOptions: !1,
              type: "spacer"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Everything in Free"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Up to 35 team members"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Advanced insights"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "API integration"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        },
        {
          children: [
            {
              align: "align_center",
              background: "default",
              children: [
                {
                  children: [
                    {
                      bold: !0,
                      fontSize: 20,
                      fontStyle: "subtitle2",
                      lineHeight: 1.4,
                      styled: !0,
                      text: "Enterprise",
                      color: "default",
                      gradient: !1
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "gray",
                      gradient: !1,
                      styled: !0,
                      text: "Security, compliance, and all your custom needs"
                    }
                  ],
                  openOptions: !1,
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "indigo",
                      gradient: !1,
                      styled: !0,
                      text: ""
                    }
                  ],
                  type: "p"
                },
                {
                  children: [
                    {
                      color: "indigo",
                      fontSize: 16,
                      fontStyle: "paragraph",
                      gradient: !1,
                      styled: !0,
                      text: "Contact us"
                    }
                  ],
                  type: "p"
                }
              ],
              height: 280,
              openOptions: !1,
              style: "card",
              type: "container",
              vertical_align: "align_start",
              width: 700
            },
            {
              children: [
                {
                  text: ""
                }
              ],
              height: 27,
              openOptions: !1,
              type: "spacer"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Everything in Team"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Unlimited team members"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Reimbursement"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "Compliance"
                }
              ],
              type: "p"
            },
            {
              children: [
                {
                  color: "indigo",
                  gradient: !1,
                  styled: !0,
                  text: "\u2713 "
                },
                {
                  color: "gray",
                  gradient: !1,
                  styled: !0,
                  text: "SAML single sign-on"
                }
              ],
              type: "p"
            }
          ],
          type: "column_item",
          width: 0.3333333333333333
        }
      ],
      height: 471,
      type: "column",
      width: 1100
    }
  ],
  openOptions: !1,
  width_max: "100%",
  height: 687
}, t = {
  children: [
    {
      children: [
        {
          align: "align_left",
          children: [
            {
              text: "Simple Pricing"
            }
          ],
          type: "h2"
        },
        {
          align: "align_left",
          children: [
            {
              color: "gray",
              gradient: !1,
              styled: !0,
              text: "Start for free and scale with the Pro plan. With Cardy, you can focus on your product instead of fees."
            }
          ],
          type: "p"
        },
        {
          align: "align_left",
          children: [
            {
              color: "gray",
              gradient: !1,
              styled: !0,
              text: ""
            }
          ],
          type: "p"
        },
        {
          align: "align_left",
          background: "indigo",
          children: [
            {
              bold: !0,
              text: "Open an account ->"
            }
          ],
          color: "text_on_accent",
          openOptions: !1,
          type: "button",
          url: ""
        }
      ],
      type: "column_item",
      width: 0.4254385964912278
    },
    {
      children: [
        {
          align: "align_center",
          background: "default",
          children: [
            {
              children: [
                {
                  color: "indigo",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Free"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "default",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$0 "
                },
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "/month"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "Free bank account"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "Up to 5 users"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          height: 245,
          openOptions: !1,
          style: "card",
          type: "container",
          vertical_align: "align_start"
        },
        {
          children: [
            {
              text: ""
            }
          ],
          openOptions: !1,
          type: "p"
        }
      ],
      type: "column_item",
      width: 0.2866228070175439
    },
    {
      children: [
        {
          children: [
            {
              text: ""
            }
          ],
          type: "p"
        },
        {
          align: "align_center",
          background: "default",
          children: [
            {
              children: [
                {
                  color: "indigo",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "Pro"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "default",
                  fontSize: 20,
                  fontStyle: "subtitle2",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  fontSize: 24,
                  fontStyle: "subtitle1",
                  gradient: !1,
                  lineHeight: 1.4,
                  styled: !0,
                  text: "$35 "
                },
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "/month"
                }
              ],
              openOptions: !1,
              type: "p",
              width: 700
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "Free bank account"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "Unlimited users"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "Unlimited API access"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: "Free international wires"
                }
              ],
              openOptions: !1,
              type: "p"
            },
            {
              children: [
                {
                  color: "gray",
                  fontSize: 16,
                  fontStyle: "subtitle3",
                  gradient: !1,
                  styled: !0,
                  text: ""
                }
              ],
              openOptions: !1,
              type: "p"
            }
          ],
          height: 245,
          openOptions: !1,
          style: "card",
          type: "container",
          vertical_align: "align_start"
        }
      ],
      type: "column_item",
      width: 0.2879385964912281
    }
  ],
  height: 413.6000061035156,
  type: "column",
  width: 1200
};
export {
  t as PRICING_2_LEFT_RIGHT,
  e as PRICING_3_TIERS
};
