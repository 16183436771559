import { useRef as x, useEffect as b, useDebugValue as C } from "react";
import I from "zustand/shallow";
const M = (n) => n.__type === "query", O = (n) => n.__type === "mutation", $ = (n, K) => {
  const q = n.getState()[K.path], d = {};
  return Object.entries(q.endpoints).forEach(([g, t]) => {
    const f = `use${g.slice(0, 1).toUpperCase()}${g.slice(1)}`;
    if (M(t)) {
      const s = (h, a) => {
        const e = h || [], { select: c, skip: l, refetchOnMount: m, pollingInterval: r, ...u } = a || {}, o = n(() => {
          const y = t.get(...e);
          return c ? c(y) : y;
        }), i = t.get(...e);
        typeof window < "u" && !l && (!i || ["idle", "stale"].includes(i.status) || m) && t(e, u);
        const p = x(() => t(e, u));
        return b(() => {
          p.current = () => t(e, u);
        }, e), b(() => {
          function y() {
            p.current();
          }
          if (r && Number.isFinite(r) && r > 0) {
            const v = setInterval(y, r);
            return () => clearInterval(v);
          }
        }, [r]), C(o), o;
      };
      s.run = t, s.get = t.get, s.subscribe = t.subscribe, d[`${f}Query`] = s;
    } else if (O(t)) {
      const s = (h) => {
        const { select: a, ...e } = h || {}, c = x(e.fixedCacheKey || g);
        b(() => {
          e.fixedCacheKey && (c.current = e.fixedCacheKey);
        }, [e.fixedCacheKey]);
        const l = n(() => {
          const u = { ...t.get(c.current), mutate: (...o) => {
            const i = t(o, e);
            return c.current = e.fixedCacheKey || i.requestId, i;
          } };
          return a ? a(u) : u;
        }, (m, r) => {
          const { mutate: u, ...o } = m, { mutate: i, ...p } = r;
          return I(o, p);
        });
        return C(l), l;
      };
      s.run = t, s.get = t.get, s.subscribe = t.subscribe, d[`${f}Mutation`] = s;
    }
  }), d;
};
export {
  $ as generateHooks
};
