import { SPACE_PERMISSION_HEADER_KEY as s, SUBSCRIPTION_HEADER_KEY as a } from "../constants.js";
import { logout as c } from "./jwt/logout.js";
async function u(o, t, e, n) {
  const i = await t.getSpacePermission(o), r = await e.getSubscription(o);
  if (!r || !i)
    throw c(n), new Error("You are not authorized to view this page.");
  return {
    headers: {
      [s]: `Bearer ${i.token}`,
      [a]: `Bearer ${r.token}`
    }
  };
}
export {
  u as getPermissionAndSubscriptionConfig
};
