import { makeApiSliceCreator as S } from "@typedream/zustand-query";
import { SPACE_STORAGE_KEY as o, DEFAULT_SPACE_STORAGE_KEY as n } from "../../constants.js";
import { listSpaces as u, getSpace as m, getSpacePermissions as r, createSpace as d, createSpaceNoPages as g, deleteSpace as l, saveNavbar as D, updateRoutes as v, publish as I, updateSpaceCustomizationPartial as P, updateSpaceSettings as Q, updateSpaceMetadata as A, addSpacePermission as f, removeSpacePermission as C, transferSpacePermission as _, updateCustomDomain as R, updateCustomDomainRedirect as y, deleteCustomDomain as E, deleteCustomDomainRedirect as N } from "./actions.js";
import { sanitizeSpace as O } from "../../utils/sanitizeSpace.js";
const j = S("spacesApi", (t) => ({
  endpoints: (a) => ({
    listSpaces: a.query(u(), {
      onSuccess: ({ data: e }) => {
        e && localStorage.setItem(o, JSON.stringify(Object.values(e)));
      }
    }),
    getSpace: a.query(m(t), {
      onSuccess: ({ args: e, data: s }) => {
        var p;
        const { spacesApi: c } = t.getState(), i = {
          ...(p = c.endpoints.listSpaces.get()) == null ? void 0 : p.data,
          [e[0]]: s
        };
        localStorage.setItem(o, JSON.stringify(Object.values(i)));
      }
    }),
    getSpacePermissions: a.query(r(t)),
    createSpace: a.mutation(d(), {
      invalidateQueries: () => [["listSpaces"]]
    }),
    createSpaceNoPages: a.mutation(g(), {
      invalidateQueries: () => [["listSpaces"]]
    }),
    deleteSpace: a.mutation(l(t), {
      onSuccess: ({ args: e }) => {
        (localStorage == null ? void 0 : localStorage.getItem(n)) === e[0] && (localStorage == null || localStorage.removeItem(n));
      },
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0]]]
    }),
    saveNavbar: a.mutation(D(t), {
      onSuccess: ({ args: e, data: s }) => {
        const { spacesApi: c } = t.getState();
        s && c.endpoints.getSpace.get(e[0].spaceID).updateData((i) => ({
          ...i,
          navbar: s.navbar,
          updated_at: s.updated_at
        }));
      }
    }),
    updateRoutes: a.mutation(v(t), {
      onStart: async ({ args: e, promise: s }) => {
        const { spacesApi: c } = t.getState(), i = c.endpoints.getSpace.get(e[0].spaceID).updateData((p) => O({
          ...p,
          routes: e[0].routes
        }));
        try {
          await s;
        } catch {
          i.undo();
        }
      },
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0].spaceID]]
    }),
    publish: a.mutation(I(t), {
      onSuccess: ({ args: e }) => {
        const { pagesApi: s, spacesApi: c } = t.getState();
        s.invalidateQueries(["listPages", e[0].spaceID]), c.invalidateQueries(["getSpace", e[0].spaceID]);
      }
    }),
    updateSpaceCustomizationPartial: a.mutation(P(t), {
      onSuccess: ({ args: e, data: s }) => {
        const { spacesApi: c } = t.getState();
        c.endpoints.getSpace.get(e[0].spaceID).updateData((i) => ({
          ...i,
          updated_at: s.updated_at
        }));
      },
      invalidateQueries: ({ args: e }) => [["getSpace", e[0].spaceID]]
    }),
    updateSpaceSettings: a.mutation(Q(t), {
      invalidateQueries: ({ args: e }) => [["getSpace", e[0].spaceID]]
    }),
    updateSpaceMetadata: a.mutation(A(t), {
      invalidateQueries: ({ args: e }) => [["getSpace", e[0].spaceID]]
    }),
    addSpacePermission: a.mutation(f(t), {
      invalidateQueries: ({ args: e }) => [["getSpacePermissions", e[0].spaceID]]
    }),
    removeSpacePermission: a.mutation(C(t), {
      invalidateQueries: ({ args: e }) => [["getSpacePermissions", e[0].spaceID]]
    }),
    transferSpacePermission: a.mutation(_(t), {
      invalidateQueries: ({ args: e }) => [
        ["listSpaces"],
        ["getSpacePermissions", e[0].spaceID]
      ]
    }),
    updateCustomDomain: a.mutation(R(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0].spaceID]]
    }),
    updateCustomDomainRedirect: a.mutation(y(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0].spaceID]]
    }),
    deleteCustomDomain: a.mutation(E(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0]]]
    }),
    deleteCustomDomainRedirect: a.mutation(N(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0]]]
    })
  })
}));
export {
  j as createSpacesApiSlice
};
